import { getProfile } from "services/profile";
import { default as profileActions } from "./actions";

const {
  fetchProfileRequest,
  fetchProfileSuccess,
  fetchProfileError,
} = profileActions;

const fetchProfile = (id) => {
  return async (dispatch) => {
    dispatch(fetchProfileRequest());

    try {
      const profile = await getProfile(id);
      dispatch(fetchProfileSuccess(profile));
    } catch (err) {
      dispatch(fetchProfileError(err.message));
    }
  };
};

export default { fetchProfile };
