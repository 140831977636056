import { default as employeeMigrationInfoItemTypes } from "./types";

const {
  FETCH_EMPLOYEE_MIGRATION_INFO_ITEM_REQUEST,
  FETCH_EMPLOYEE_MIGRATION_INFO_ITEM_SUCCESS,
  FETCH_EMPLOYEE_MIGRATION_INFO_ITEM_FAILURE,
  ADD_EMPLOYEE_MIGRATION_INFO_ITEM_REQUEST,
  ADD_EMPLOYEE_MIGRATION_INFO_ITEM_SUCCESS,
  ADD_EMPLOYEE_MIGRATION_INFO_ITEM_FAILURE,
  EDIT_EMPLOYEE_MIGRATION_INFO_ITEM_REQUEST,
  EDIT_EMPLOYEE_MIGRATION_INFO_ITEM_SUCCESS,
  EDIT_EMPLOYEE_MIGRATION_INFO_ITEM_FAILURE,
  DELETE_EMPLOYEE_MIGRATION_INFO_ITEM_REQUEST,
  DELETE_EMPLOYEE_MIGRATION_INFO_ITEM_SUCCESS,
  DELETE_EMPLOYEE_MIGRATION_INFO_ITEM_FAILURE,
  CLEAR_EMPLOYEE_MIGRATION_INFO_ITEM,
} = employeeMigrationInfoItemTypes;

const fetchEmployeeMigrationInfoItemRequest = () => {
  return {
    type: FETCH_EMPLOYEE_MIGRATION_INFO_ITEM_REQUEST,
  };
};

const fetchEmployeeMigrationInfoItemSuccess = (employeeMigrationInfoItem) => {
  return {
    type: FETCH_EMPLOYEE_MIGRATION_INFO_ITEM_SUCCESS,
    payload: employeeMigrationInfoItem,
  };
};

const fetchEmployeeMigrationInfoItemError = (error) => {
  return {
    type: FETCH_EMPLOYEE_MIGRATION_INFO_ITEM_FAILURE,
    payload: error,
  };
};

const addEmployeeMigrationInfoItemRequest = () => {
  return {
    type: ADD_EMPLOYEE_MIGRATION_INFO_ITEM_REQUEST,
  };
};

const addEmployeeMigrationInfoItemSuccess = () => {
  return {
    type: ADD_EMPLOYEE_MIGRATION_INFO_ITEM_SUCCESS,
  };
};

const addEmployeeMigrationInfoItemError = (error) => {
  return {
    type: ADD_EMPLOYEE_MIGRATION_INFO_ITEM_FAILURE,
    payload: error,
  };
};

const editEmployeeMigrationInfoItemRequest = () => {
  return {
    type: EDIT_EMPLOYEE_MIGRATION_INFO_ITEM_REQUEST,
  };
};

const editEmployeeMigrationInfoItemSuccess = () => {
  return {
    type: EDIT_EMPLOYEE_MIGRATION_INFO_ITEM_SUCCESS,
  };
};

const editEmployeeMigrationInfoItemError = (error) => {
  return {
    type: EDIT_EMPLOYEE_MIGRATION_INFO_ITEM_FAILURE,
    payload: error,
  };
};

const deleteEmployeeMigrationInfoItemRequest = () => {
  return {
    type: DELETE_EMPLOYEE_MIGRATION_INFO_ITEM_REQUEST,
  };
};

const deleteEmployeeMigrationInfoItemSuccess = () => {
  return {
    type: DELETE_EMPLOYEE_MIGRATION_INFO_ITEM_SUCCESS,
  };
};

const deleteEmployeeMigrationInfoItemError = (error) => {
  return {
    type: DELETE_EMPLOYEE_MIGRATION_INFO_ITEM_FAILURE,
    payload: error,
  };
};

const clearEmployeeMigrationInfoItem = () => {
  return {
    type: CLEAR_EMPLOYEE_MIGRATION_INFO_ITEM,
  };
};

export default {
  fetchEmployeeMigrationInfoItemRequest,
  fetchEmployeeMigrationInfoItemSuccess,
  fetchEmployeeMigrationInfoItemError,
  addEmployeeMigrationInfoItemRequest,
  addEmployeeMigrationInfoItemSuccess,
  addEmployeeMigrationInfoItemError,
  editEmployeeMigrationInfoItemRequest,
  editEmployeeMigrationInfoItemSuccess,
  editEmployeeMigrationInfoItemError,
  deleteEmployeeMigrationInfoItemRequest,
  deleteEmployeeMigrationInfoItemSuccess,
  deleteEmployeeMigrationInfoItemError,
  clearEmployeeMigrationInfoItem,
};
