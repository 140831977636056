import { getRaiseNdTypes } from "services/raiseNdType";
import { default as raiseNdTypesActions } from "./actions";

const {
  fetchRaiseNdTypesRequest,
  fetchRaiseNdTypesSuccess,
  fetchRaiseNdTypesError,
} = raiseNdTypesActions;

const fetchRaiseNdTypes = () => {
  return async (dispatch) => {
    dispatch(fetchRaiseNdTypesRequest());

    try {
      const raiseNdTypes = await getRaiseNdTypes();
      dispatch(fetchRaiseNdTypesSuccess(raiseNdTypes));
    } catch (err) {
      dispatch(fetchRaiseNdTypesError(err.message));
    }
  };
};

export default {
  fetchRaiseNdTypes,
};
