import { getTT11_1Report } from "services/report";
import { default as hhoatReportActions } from "./actions";

const {
  fetchTT11_1ReportRequest,
  fetchTT11_1ReportSuccess,
  fetchTT11_1ReportError,
} = hhoatReportActions;

const fetchTT11_1Report = (year, month) => {
  return async (dispatch) => {
    dispatch(fetchTT11_1ReportRequest());

    try {
      const report = await getTT11_1Report(year, month);
      dispatch(fetchTT11_1ReportSuccess(report));
    } catch (err) {
      dispatch(fetchTT11_1ReportError(err.message));
    }
  };
};

export default {
  fetchTT11_1Report,
};
