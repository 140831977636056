const ADD_CONTRACT_REQUEST = "ADD_CONTRACT_REQUEST";
const ADD_CONTRACT_SUCCESS = "ADD_CONTRACT_SUCCESS";
const ADD_CONTRACT_FAILURE = "ADD_CONTRACT_FAILURE";
const EDIT_CONTRACT_REQUEST = "EDIT_CONTRACT_REQUEST";
const EDIT_CONTRACT_SUCCESS = "EDIT_CONTRACT_SUCCESS";
const EDIT_CONTRACT_FAILURE = "EDIT_CONTRACT_FAILURE";
const DELETE_CONTRACT_REQUEST = "DELETE_CONTRACT_REQUEST";
const DELETE_CONTRACT_SUCCESS = "DELETE_CONTRACT_SUCCESS";
const DELETE_CONTRACT_FAILURE = "DELETE_CONTRACT_FAILURE";
const FETCH_CONTRACTS_REQUEST = "FETCH_CONTRACTS_REQUEST";
const FETCH_CONTRACTS_SUCCESS = "FETCH_CONTRACTS_SUCCESS";
const FETCH_CONTRACTS_FAILURE = "FETCH_CONTRACTS_FAILURE";

export default {
  ADD_CONTRACT_REQUEST,
  ADD_CONTRACT_SUCCESS,
  ADD_CONTRACT_FAILURE,
  EDIT_CONTRACT_REQUEST,
  EDIT_CONTRACT_SUCCESS,
  EDIT_CONTRACT_FAILURE,
  DELETE_CONTRACT_REQUEST,
  DELETE_CONTRACT_SUCCESS,
  DELETE_CONTRACT_FAILURE,
  FETCH_CONTRACTS_REQUEST,
  FETCH_CONTRACTS_SUCCESS,
  FETCH_CONTRACTS_FAILURE,
};
