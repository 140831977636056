import { default as nationalitiesTypes } from "./types";

const {
  FETCH_NATIONALITIES_REQUEST,
  FETCH_NATIONALITIES_SUCCESS,
  FETCH_NATIONALITIES_FAILURE,
} = nationalitiesTypes;

const fetchNationalitiesRequest = () => {
  return {
    type: FETCH_NATIONALITIES_REQUEST,
  };
};

const fetchNationalitiesSuccess = (nationalities) => {
  return {
    type: FETCH_NATIONALITIES_SUCCESS,
    payload: nationalities,
  };
};

const fetchNationalitiesError = (error) => {
  return {
    type: FETCH_NATIONALITIES_FAILURE,
    payload: error,
  };
};

export default {
  fetchNationalitiesRequest,
  fetchNationalitiesSuccess,
  fetchNationalitiesError,
};
