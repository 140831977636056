import instance from "./axios.base";

const getMonthlyAttendanceArchives = async (year, month) => {
  const res = await instance.get(`/tamontharchive?year=${year}&month=${month}`);
  return res.data;
};

const getHalfMonthlyAttendanceArchives = async (year, month) => {
  const res = await instance.get(
    `/taadvancearchive?year=${year}&month=${month}`
  );
  return res.data;
};

const clearMonthlyAttendanceArchives = async (year, month) => {
  const res = await instance.delete(
    `/tamontharchive?year=${year}&month=${month}`
  );
  return res.data;
};

const clearHalfMonthlyAttendanceArchives = async (year, month) => {
  const res = await instance.delete(
    `/taadvancearchive?year=${year}&month=${month}`
  );
  return res.data;
};

export {
  getMonthlyAttendanceArchives,
  getHalfMonthlyAttendanceArchives,
  clearMonthlyAttendanceArchives,
  clearHalfMonthlyAttendanceArchives,
};
