import instance from "./axios.base";

const getDepartments = async () => {
  const res = await instance.get("/departments");
  
  return res.data;
};

const createDepartment = async (data) => {
  const res = await instance.post("/departments", data);

  return res.data;
};

const updateDepartment = async (data) => {
  const res = await instance.put("/departments", data);

  return res.data;
};

const removeDepartment = async (id) => {
  const res = await instance.delete(`/departments/${id}`);

  return res.data;
};

export { getDepartments, createDepartment, updateDepartment, removeDepartment };