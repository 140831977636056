import { getDistricts } from "services/district";
import { default as districtsActions } from "./actions";

const {
  fetchDistrictsRequest,
  fetchDistrictsSuccess,
  fetchDistrictsError,
} = districtsActions;

const fetchDistricts = (cityId) => {
  return async (dispatch) => {
    dispatch(fetchDistrictsRequest());

    try {
      const districts = await getDistricts(cityId);
      dispatch(fetchDistrictsSuccess(districts));
    } catch (err) {
      dispatch(fetchDistrictsError(err.message));
    }
  };
};

export default { fetchDistricts };
