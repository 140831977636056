import { getTasks, createTask, updateTask, removeTask } from "services/task";
import { default as tasksActions } from "./actions";

const {
  addTaskRequest,
  addTaskSuccess,
  addTaskError,
  fetchTasksRequest,
  fetchTasksSuccess,
  fetchTasksError,
} = tasksActions;

const fetchTasks = () => {
  return async (dispatch) => {
    dispatch(fetchTasksRequest());

    try {
      const tasks = await getTasks();
      dispatch(fetchTasksSuccess(tasks));
    } catch (err) {
      dispatch(fetchTasksError(err.message));
    }
  };
};

const addTask = (data) => {
  return async (dispatch) => {
    dispatch(addTaskRequest());

    try {
      await createTask(data);
      dispatch(addTaskSuccess());
    } catch (err) {
      dispatch(addTaskError(err.message));
    }
  };
};

const editTask = (data) => {
  return async (dispatch) => {
    dispatch(addTaskRequest());

    try {
      await updateTask(data);
      dispatch(addTaskSuccess());
    } catch (err) {
      dispatch(addTaskError(err.message));
    }
  }; 
};

const deleteTask = (data) => {
  return async (dispatch) => {
    dispatch(addTaskRequest());

    try {
      await removeTask(data);
      dispatch(addTaskSuccess());
    } catch (err) {
      dispatch(addTaskError(err.message));
    }
  }; 
};

export default { fetchTasks, addTask, editTask, deleteTask };
