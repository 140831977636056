import { default as empDeductionsTypes } from "./types";

const {
  ADD_EMP_DEDUCTION_REQUEST,
  ADD_EMP_DEDUCTION_SUCCESS,
  ADD_EMP_DEDUCTION_FAILURE,
  EDIT_EMP_DEDUCTION_REQUEST,
  EDIT_EMP_DEDUCTION_SUCCESS,
  EDIT_EMP_DEDUCTION_FAILURE,
  DELETE_EMP_DEDUCTION_REQUEST,
  DELETE_EMP_DEDUCTION_SUCCESS,
  DELETE_EMP_DEDUCTION_FAILURE,
  FETCH_EMP_DEDUCTIONS_REQUEST,
  FETCH_EMP_DEDUCTIONS_SUCCESS,
  FETCH_EMP_DEDUCTIONS_FAILURE,
  CLEAR_EMP_DEDUCTION,
} = empDeductionsTypes;

const initialState = {
  isLoading: false,
  list: [],
  detail: null,
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_EMP_DEDUCTION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_EMP_DEDUCTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case ADD_EMP_DEDUCTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case EDIT_EMP_DEDUCTION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case EDIT_EMP_DEDUCTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case EDIT_EMP_DEDUCTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case DELETE_EMP_DEDUCTION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case DELETE_EMP_DEDUCTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case DELETE_EMP_DEDUCTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case FETCH_EMP_DEDUCTIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_EMP_DEDUCTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: action.payload,
        error: "",
      };
    case FETCH_EMP_DEDUCTIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        list: [],
        error: action.payload,
      };
    case CLEAR_EMP_DEDUCTION:
      return {
        ...state,
        isLoading: false,
        detail: null,
        error: "",
      };
    default:
      return state;
  }
};

export default reducer;
