import { default as nationalitiesTypes } from "./types";

const {
  FETCH_NATIONALITIES_REQUEST,
  FETCH_NATIONALITIES_SUCCESS,
  FETCH_NATIONALITIES_FAILURE,
} = nationalitiesTypes;

const initialState = {
  isLoading: false,
  list: [],
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NATIONALITIES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_NATIONALITIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: action.payload,
        error: "",
      };
    case FETCH_NATIONALITIES_FAILURE:
      return {
        ...state,
        isLoading: false,
        list: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
