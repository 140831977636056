import { default as announcementsTypes } from "./types";

const {
  ADD_ANNOUNCEMENT_REQUEST,
  ADD_ANNOUNCEMENT_SUCCESS,
  ADD_ANNOUNCEMENT_FAILURE,
  FETCH_ANNOUNCEMENTS_REQUEST,
  FETCH_ANNOUNCEMENTS_SUCCESS,
  FETCH_ANNOUNCEMENTS_FAILURE,
} = announcementsTypes;

const addAnnouncementRequest = () => {
  return {
    type: ADD_ANNOUNCEMENT_REQUEST,
  };
};

const addAnnouncementSuccess = () => {
  return {
    type: ADD_ANNOUNCEMENT_SUCCESS,
  };
};

const addAnnouncementError = (error) => {
  return {
    type: ADD_ANNOUNCEMENT_FAILURE,
    payload: error,
  };
};

const fetchAnnouncementsRequest = () => {
  return {
    type: FETCH_ANNOUNCEMENTS_REQUEST,
  };
};

const fetchAnnouncementsSuccess = (announcements) => {
  return {
    type: FETCH_ANNOUNCEMENTS_SUCCESS,
    payload: announcements,
  };
};

const fetchAnnouncementsError = (error) => {
  return {
    type: FETCH_ANNOUNCEMENTS_FAILURE,
    payload: error,
  };
};

export default {
  addAnnouncementRequest,
  addAnnouncementSuccess,
  addAnnouncementError,
  fetchAnnouncementsRequest,
  fetchAnnouncementsSuccess,
  fetchAnnouncementsError,
};
