import { default as types } from "./types";

const {
  ADD_ORDER_REQUEST,
  ADD_ORDER_SUCCESS,
  ADD_ORDER_FAILURE,
  EDIT_ORDER_REQUEST,
  EDIT_ORDER_SUCCESS,
  EDIT_ORDER_FAILURE,
  DELETE_ORDER_REQUEST,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_FAILURE,
  FETCH_ORDERS_REQUEST,
  FETCH_ORDERS_SUCCESS,
  FETCH_ORDERS_FAILURE,
} = types;

const initialState = {
  isLoading: false,
  list: [],
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ORDER_REQUEST:
    case EDIT_ORDER_REQUEST:
    case DELETE_ORDER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_ORDER_SUCCESS:
    case EDIT_ORDER_SUCCESS:
    case DELETE_ORDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case ADD_ORDER_FAILURE:
    case EDIT_ORDER_FAILURE:
    case DELETE_ORDER_FAILURE:
      return {
        ...state,
        isLoading: false,
        list: [],
        error: action.payload,
      };
    case FETCH_ORDERS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_ORDERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: action.payload,
        error: "",
      };
    case FETCH_ORDERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        list: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
