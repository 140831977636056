const ADD_COMPANY_REQUEST = "ADD_COMPANY_REQUEST";
const ADD_COMPANY_SUCCESS = "ADD_COMPANY_SUCCESS";
const ADD_COMPANY_FAILURE = "ADD_COMPANY_FAILURE";
const FETCH_COMPANYS_REQUEST = "FETCH_COMPANYS_REQUEST";
const FETCH_COMPANYS_SUCCESS = "FETCH_COMPANYS_SUCCESS";
const FETCH_COMPANYS_FAILURE = "FETCH_COMPANYS_FAILURE";

export default {
  ADD_COMPANY_REQUEST,
  ADD_COMPANY_SUCCESS,
  ADD_COMPANY_FAILURE,
  FETCH_COMPANYS_REQUEST,
  FETCH_COMPANYS_SUCCESS,
  FETCH_COMPANYS_FAILURE,
};
