import { getInsurPeople } from "services/insurPerson";
import { default as insurPeopleActions } from "./actions";

const {
  fetchInsurPeopleRequest,
  fetchInsurPeopleSuccess,
  fetchInsurPeopleError,
} = insurPeopleActions;

const fetchInsurPeople = () => {
  return async (dispatch) => {
    dispatch(fetchInsurPeopleRequest());

    try {
      const insurPeople = await getInsurPeople();
      dispatch(fetchInsurPeopleSuccess(insurPeople));
    } catch (err) {
      dispatch(fetchInsurPeopleError(err.message));
    }
  };
};

export default { fetchInsurPeople };
