import { checkGuidGet, resetPasswordPost, resetPasswordPut } from "services/resetPassword";
import { default as resetPasswordActions } from "./actions";

const {
  resetPasswordRequest,
  resetPasswordSuccess,
  resetPasswordError,
  putPasswordRequest,
  putPasswordSuccess,
  putPasswordError,
  checkGuidRequest,
  checkGuidSuccess,
  checkGuidError
} = resetPasswordActions;

const resetPassword = (data) => {
  return async (dispatch) => {
    dispatch(resetPasswordRequest());

    try {
      await resetPasswordPost(data);
      dispatch(resetPasswordSuccess());
    } catch (err) {
      dispatch(resetPasswordError(err.message));
    }
  };
};

const checkGuid = (data) => {
  return async (dispatch) => {
    dispatch(checkGuidRequest());

    try {
      await checkGuidGet(data);
      dispatch(checkGuidSuccess());
    } catch (err) {
      dispatch(checkGuidError(err.message));
    }
  };
};

const putPassword = (data) => {
  return async (dispatch) => {
    dispatch(putPasswordRequest());

    try {
      await resetPasswordPut(data);
      dispatch(putPasswordSuccess());
    } catch (err) {
      dispatch(putPasswordError(err.message));
    }
  };
};

export default { resetPassword, checkGuid, putPassword };