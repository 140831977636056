import { default as trainingsTypes } from "./types";

const {
  ADD_TRAINING_REQUEST,
  ADD_TRAINING_SUCCESS,
  ADD_TRAINING_FAILURE,
  FETCH_TRAININGS_REQUEST,
  FETCH_TRAININGS_SUCCESS,
  FETCH_TRAININGS_FAILURE,
} = trainingsTypes;

const addTrainingRequest = () => {
  return {
    type: ADD_TRAINING_REQUEST,
  };
};

const addTrainingSuccess = () => {
  return {
    type: ADD_TRAINING_SUCCESS,
  };
};

const addTrainingError = (error) => {
  return {
    type: ADD_TRAINING_FAILURE,
    payload: error,
  };
};

const fetchTrainingsRequest = () => {
  return {
    type: FETCH_TRAININGS_REQUEST,
  };
};

const fetchTrainingsSuccess = (trainings) => {
  return {
    type: FETCH_TRAININGS_SUCCESS,
    payload: trainings,
  };
};

const fetchTrainingsError = (error) => {
  return {
    type: FETCH_TRAININGS_FAILURE,
    payload: error,
  };
};

export default {
  addTrainingRequest,
  addTrainingSuccess,
  addTrainingError,
  fetchTrainingsRequest,
  fetchTrainingsSuccess,
  fetchTrainingsError,
};
