import instance from "./axios.base";

const add = async (data) => {
  const res = await instance.post("/pradd", data);
  return res.data;
};

const edit = async (data) => {
  const res = await instance.put("/pradd", data);
  return res.data;
};

const remove = async (id) => {
  const res = await instance.delete(`/pradd/${id}`);
  return res.data;
};

const getRaises = async () => {
  const res = await instance.get("/pradd");
  return res.data;
};

const getRaise = async (id) => {
  const res = await instance.get(`/pradd/${id}`);
  return res.data;
};

const getRaiseNdTypes = async () => {
  const res = await instance.get("/service?name=ndtype&tablename=hr_pr_add");
  return res.data;
};

export { add, edit, remove, getRaises, getRaise, getRaiseNdTypes };
