import { default as citiesTypes } from "./types";

const {
  FETCH_CITIES_REQUEST,
  FETCH_CITIES_SUCCESS,
  FETCH_CITIES_FAILURE,
} = citiesTypes;

const fetchCitiesRequest = () => {
  return {
    type: FETCH_CITIES_REQUEST,
  };
};

const fetchCitiesSuccess = (cities) => {
  return {
    type: FETCH_CITIES_SUCCESS,
    payload: cities,
  };
};

const fetchCitiesError = (error) => {
  return {
    type: FETCH_CITIES_FAILURE,
    payload: error,
  };
};

export default {
  fetchCitiesRequest,
  fetchCitiesSuccess,
  fetchCitiesError,
};
