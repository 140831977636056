import instance from "./axios.base";

const addJournal = async (data) => {
  await instance.post("/taempschedule", data);
};

const deleteJournal = async (id) => {
  await instance.delete(`/taempschedule/${id}`);
};

const getJournals = async (date) => {
  const res = await instance.get(`/monthtime${date ? "?date=" + date : ""}`);
  return res.data;
};

const getJournalExportData = async (date) => {
  const res = await instance.get(`/monthtimelog${date ? "?date=" + date : ""}`);
  return res.data;
};

const getJournal = async (empCode, date) => {
  const res = await instance.get(`/dayrequest?empid=${empCode}&date=${date}`);
  return res.data;
};

const getStandardTime = async (date) => {
  const res = await instance.get(`/standartday?date=${date}`);
  return res.data;
};

const calculateSalaryJournal = async (data) => {
  await instance.get(`/calculatetime?date=${data}`);
};

const getEmpLateMinuteList = async (empId, year, month) => {
  const res = await instance.get(
    `/monthlateminute?empid=${empId}&year=${year}&month=${month}`
  );
  return res.data;
};

const archiveJournal = async (selected) => {
  const res = await instance.post("/tamontharchive", selected);
  return res.data;
};

const editOvertime = async (data) => {
  const res = await instance.put("/monthovertime", data);
  return res.data;
};

export {
  addJournal,
  deleteJournal,
  getJournals,
  getJournal,
  getStandardTime,
  calculateSalaryJournal,
  getEmpLateMinuteList,
  archiveJournal,
  editOvertime,
  getJournalExportData,
};
