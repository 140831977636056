import { default as employeeLangSkillInfoTypes } from "./types";

const {
  FETCH_EMPLOYEE_LANG_SKILL_INFO_REQUEST,
  FETCH_EMPLOYEE_LANG_SKILL_INFO_SUCCESS,
  FETCH_EMPLOYEE_LANG_SKILL_INFO_FAILURE,
  EDIT_EMPLOYEE_LANG_SKILL_INFO_REQUEST,
  EDIT_EMPLOYEE_LANG_SKILL_INFO_SUCCESS,
  EDIT_EMPLOYEE_LANG_SKILL_INFO_FAILURE,
  DELETE_EMPLOYEE_LANG_SKILL_INFO_REQUEST,
  DELETE_EMPLOYEE_LANG_SKILL_INFO_SUCCESS,
  DELETE_EMPLOYEE_LANG_SKILL_INFO_FAILURE,
} = employeeLangSkillInfoTypes;

const fetchEmployeeLangSkillInfoRequest = () => {
  return {
    type: FETCH_EMPLOYEE_LANG_SKILL_INFO_REQUEST,
  };
};

const fetchEmployeeLangSkillInfoSuccess = (employeeLangSkillInfo) => {
  return {
    type: FETCH_EMPLOYEE_LANG_SKILL_INFO_SUCCESS,
    payload: employeeLangSkillInfo,
  };
};

const fetchEmployeeLangSkillInfoError = (error) => {
  return {
    type: FETCH_EMPLOYEE_LANG_SKILL_INFO_FAILURE,
    payload: error,
  };
};

const editEmployeeLangSkillInfoRequest = () => {
  return {
    type: EDIT_EMPLOYEE_LANG_SKILL_INFO_REQUEST,
  };
};

const editEmployeeLangSkillInfoSuccess = () => {
  return {
    type: EDIT_EMPLOYEE_LANG_SKILL_INFO_SUCCESS,
  };
};

const editEmployeeLangSkillInfoError = (error) => {
  return {
    type: EDIT_EMPLOYEE_LANG_SKILL_INFO_FAILURE,
    payload: error,
  };
};

const deleteEmployeeLangSkillInfoRequest = () => {
  return {
    type: DELETE_EMPLOYEE_LANG_SKILL_INFO_REQUEST,
  };
};

const deleteEmployeeLangSkillInfoSuccess = () => {
  return {
    type: DELETE_EMPLOYEE_LANG_SKILL_INFO_SUCCESS,
  };
};

const deleteEmployeeLangSkillInfoError = (error) => {
  return {
    type: DELETE_EMPLOYEE_LANG_SKILL_INFO_FAILURE,
    payload: error,
  };
};

export default {
  fetchEmployeeLangSkillInfoRequest,
  fetchEmployeeLangSkillInfoSuccess,
  fetchEmployeeLangSkillInfoError,
  editEmployeeLangSkillInfoRequest,
  editEmployeeLangSkillInfoSuccess,
  editEmployeeLangSkillInfoError,
  deleteEmployeeLangSkillInfoRequest,
  deleteEmployeeLangSkillInfoSuccess,
  deleteEmployeeLangSkillInfoError,
};
