import { getDashboardAge, getDashboardEmpCount, getDashboardDegree, getDashboardGender, getDashboardMarital, getDashboardSalary, getDashboardWorkYear } from "services/dashboard";
import { default as dashboardsActions } from "./actions";

const {
  fetchDashboardsRequest,
  fetchDashboardAgeSuccess,
  fetchDashboardAgeError,
  fetchDashboardEmpCountSuccess,
  fetchDashboardEmpCountError,
  fetchDashboardDegreeSuccess,
  fetchDashboardDegreeError,
  fetchDashboardGenderSuccess,
  fetchDashboardGenderError,
  fetchDashboardMaritalSuccess,
  fetchDashboardMaritalError,
  fetchDashboardSalarySuccess,
  fetchDashboardSalaryError,
  fetchDashboardWorkedYearSuccess,
  fetchDashboardWorkedYearError,
} = dashboardsActions;

const fetchDashboardAge = () => {
  return async (dispatch) => {
    dispatch(fetchDashboardsRequest());

    try {
      
      let labels = [], backgroundColor = [], 
      hoverBackgroundColor = [], data = [], chartData = {
        labels: [],
        datasets: []
      }, dataset = {};

      const dashboards = await getDashboardAge();
      
      dashboards.age_data.map((row, index) => {
        labels.push(row.type);
        data.push(row.cnt);
      });
      chartData.labels = labels;
      dataset.label = 'Ангилал';
      dataset.data = data;
      let color = '#'+Math.floor(Math.random()*16777215).toString(16);
      dataset.backgroundColor = color;
      dataset.borderColor = color;
      dataset.fill = false;
      dataset.lineTension = 0.5;
      dataset.borderWidth = 1;
      dataset.min = 0;
      chartData.datasets.push(dataset);

      dispatch(fetchDashboardAgeSuccess(chartData));
    } catch (err) {
      dispatch(fetchDashboardAgeError(err.message));
    }
  };
};

const fetchDashboardEmpCount = () => {
  return async (dispatch) => {
    dispatch(fetchDashboardsRequest());

    try {
      const dashboards = await getDashboardEmpCount();
      dispatch(fetchDashboardEmpCountSuccess(dashboards));
    } catch (err) {
      dispatch(fetchDashboardEmpCountError(err.message));
    }
  };
};

const fetchDashboardDegree = () => {
  return async (dispatch) => {
    dispatch(fetchDashboardsRequest());

    try {
      let labels = [], backgroundColor = [], 
      hoverBackgroundColor = [], data = [], chartData = {
        labels: [],
        datasets: []
      }, dataset = {};

      const dashboards = await getDashboardDegree();
      
      dashboards.map((row, index) => {
        labels.push(row.name);
        data.push(row.cnt);
        backgroundColor.push('#'+Math.floor(Math.random()*16777215).toString(16));
      });
      chartData.labels = labels;
      dataset.label = 'Боловсрол';
      dataset.data = data;
      dataset.backgroundColor = backgroundColor;
      dataset.hoverBackgroundColor = backgroundColor;
      chartData.datasets.push(dataset);

      dispatch(fetchDashboardDegreeSuccess(chartData));
    } catch (err) {
      dispatch(fetchDashboardDegreeError(err.message));
    }
  };
};

const fetchDashboardGender = () => {
  return async (dispatch) => {
    dispatch(fetchDashboardsRequest());

    try {
      let labels = [], backgroundColor = [], 
      hoverBackgroundColor = [], data = [], chartData = {
        labels: [],
        datasets: []
      }, dataset = {};

      const dashboards = await getDashboardGender();
      
      dashboards.map((row, index) => {
        labels.push(row.name);
        data.push(row.cnt);
        backgroundColor.push('#'+Math.floor(Math.random()*16777215).toString(16));
      });
      chartData.labels = labels;
      dataset.label = 'Боловсрол';
      dataset.data = data;
      dataset.backgroundColor = backgroundColor;
      dataset.hoverBackgroundColor = backgroundColor;
      chartData.datasets.push(dataset);

      dispatch(fetchDashboardGenderSuccess(chartData));
    } catch (err) {
      dispatch(fetchDashboardGenderError(err.message));
    }
  };
};

const fetchDashboardMarital = () => {
  return async (dispatch) => {
    dispatch(fetchDashboardsRequest());

    try {
      let labels = [], backgroundColor = [], 
      hoverBackgroundColor = [], data = [], chartData = {
        labels: [],
        datasets: []
      }, dataset = {};

      const dashboards = await getDashboardMarital();
      
      dashboards.map((row, index) => {
        labels.push(row.name);
        data.push(row.cnt);
        backgroundColor.push('#'+Math.floor(Math.random()*16777215).toString(16));
      });
      chartData.labels = labels;
      dataset.label = 'Боловсрол';
      dataset.data = data;
      dataset.backgroundColor = backgroundColor;
      dataset.hoverBackgroundColor = backgroundColor;
      chartData.datasets.push(dataset);

      dispatch(fetchDashboardMaritalSuccess(chartData));
    } catch (err) {
      dispatch(fetchDashboardMaritalError(err.message));
    }
  };
};

const fetchDashboardSalary = () => {
  return async (dispatch) => {
    dispatch(fetchDashboardsRequest());

    try {
      let labels = [], backgroundColor = [], 
      hoverBackgroundColor = [], data = [], chartData = {
        labels: [],
        datasets: []
      }, dataset = {};

      const dashboards = await getDashboardSalary();
      
      dashboards.map((row, index) => {
        labels.push(row.name);
        data.push(row.cnt);
        backgroundColor.push('#'+Math.floor(Math.random()*16777215).toString(16));
      });
      chartData.labels = labels;
      dataset.label = 'Боловсрол';
      dataset.data = data;
      dataset.backgroundColor = backgroundColor;
      dataset.hoverBackgroundColor = backgroundColor;
      chartData.datasets.push(dataset);

      dispatch(fetchDashboardSalarySuccess(dashboards));
    } catch (err) {
      dispatch(fetchDashboardSalaryError(err.message));
    }
  };
};

const fetchDashboardWorkedYear = () => {
  return async (dispatch) => {
    dispatch(fetchDashboardsRequest());

    try {
      let labels = [], backgroundColor = [], 
      hoverBackgroundColor = [], data = [], chartData = {
        labels: [],
        datasets: []
      }, dataset = {};

      const dashboards = await getDashboardWorkYear();
      
      dashboards.map((row, index) => {
        labels.push(row.type);
        data.push(row.cnt);
      });
      chartData.labels = labels;
      dataset.label = 'Ангилал';
      dataset.data = data;
      let color = '#'+Math.floor(Math.random()*16777215).toString(16);
      dataset.backgroundColor = color;
      dataset.borderColor = color;
      dataset.fill = false;
      dataset.lineTension = 0.5;
      dataset.borderWidth = 1;
      dataset.min = 0;
      chartData.datasets.push(dataset);

      dispatch(fetchDashboardWorkedYearSuccess(chartData));
    } catch (err) {
      dispatch(fetchDashboardWorkedYearError(err.message));
    }
  };
};

export default { fetchDashboardAge, fetchDashboardEmpCount, fetchDashboardDegree, fetchDashboardGender, fetchDashboardMarital, fetchDashboardSalary, fetchDashboardWorkedYear};