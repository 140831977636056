import { getLunarSigns } from "services/lunarsign";
import { default as lunarSignsActions } from "./actions";

const {
  fetchLunarSignsRequest,
  fetchLunarSignsSuccess,
  fetchLunarSignsError,
} = lunarSignsActions;

const fetchLunarSigns = () => {
  return async (dispatch) => {
    dispatch(fetchLunarSignsRequest());

    try {
      const lunarSigns = await getLunarSigns();
      dispatch(fetchLunarSignsSuccess(lunarSigns));
    } catch (err) {
      dispatch(fetchLunarSignsError(err.message));
    }
  };
};

export default { fetchLunarSigns };
