import { default as prepaymentJournalsTypes } from "./types";

const {
  ADD_PREPAYMENT_JOURNAL_REQUEST,
  ADD_PREPAYMENT_JOURNAL_SUCCESS,
  ADD_PREPAYMENT_JOURNAL_FAILURE,
  EDIT_PREPAYMENT_JOURNAL_REQUEST,
  EDIT_PREPAYMENT_JOURNAL_SUCCESS,
  EDIT_PREPAYMENT_JOURNAL_FAILURE,
  FETCH_PREPAYMENT_JOURNALS_REQUEST,
  FETCH_PREPAYMENT_JOURNALS_SUCCESS,
  FETCH_PREPAYMENT_JOURNALS_FAILURE,
  FETCH_PREPAYMENT_JOURNAL_REQUEST,
  FETCH_PREPAYMENT_JOURNAL_SUCCESS,
  FETCH_PREPAYMENT_JOURNAL_FAILURE,
  ARCHIVE_PREPAYMENT_JOURNAL_REQUEST,
  ARCHIVE_PREPAYMENT_JOURNAL_SUCCESS,
  ARCHIVE_PREPAYMENT_JOURNAL_FAILURE,
  CALCULATE_PREPAYMENT_JOURNAL_REQUEST,
  CALCULATE_PREPAYMENT_JOURNAL_SUCCESS,
  CALCULATE_PREPAYMENT_JOURNAL_FAILURE,
} = prepaymentJournalsTypes;

const initialState = {
  isCalculating: false,
  isLoading: false,
  list: [],
  detail: null,
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PREPAYMENT_JOURNAL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_PREPAYMENT_JOURNAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case ADD_PREPAYMENT_JOURNAL_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case EDIT_PREPAYMENT_JOURNAL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case EDIT_PREPAYMENT_JOURNAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case EDIT_PREPAYMENT_JOURNAL_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case FETCH_PREPAYMENT_JOURNALS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_PREPAYMENT_JOURNALS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: action.payload,
        error: "",
      };
    case FETCH_PREPAYMENT_JOURNALS_FAILURE:
      return {
        ...state,
        isLoading: false,
        list: [],
        error: action.payload,
      };
    case FETCH_PREPAYMENT_JOURNAL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_PREPAYMENT_JOURNAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        detail: action.payload,
        error: "",
      };
    case FETCH_PREPAYMENT_JOURNAL_FAILURE:
      return {
        ...state,
        isLoading: false,
        detail: null,
        error: action.payload,
      };
    case ARCHIVE_PREPAYMENT_JOURNAL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ARCHIVE_PREPAYMENT_JOURNAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case ARCHIVE_PREPAYMENT_JOURNAL_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case CALCULATE_PREPAYMENT_JOURNAL_REQUEST:
      return {
        ...state,
        isCalculating: true,
      };
    case CALCULATE_PREPAYMENT_JOURNAL_SUCCESS:
      return {
        ...state,
        isCalculating: false,
        error: "",
      };
    case CALCULATE_PREPAYMENT_JOURNAL_FAILURE:
      return {
        ...state,
        isCalculating: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
