import { toast } from "react-toastify";
import {
  getOrders,
  addOrder as createOrder,
  editOrder as updateOrder,
  deleteOrder as removeOrder,
} from "services/order";
import { default as ordersActions } from "./actions";

const {
  addOrderRequest,
  addOrderSuccess,
  addOrderError,
  editOrderRequest,
  editOrderSuccess,
  editOrderError,
  deleteOrderRequest,
  deleteOrderSuccess,
  deleteOrderError,
  fetchOrdersRequest,
  fetchOrdersSuccess,
  fetchOrdersError,
} = ordersActions;

const addOrder = (data) => {
  return async (dispatch) => {
    dispatch(addOrderRequest());

    try {
      await createOrder(data);
      dispatch(addOrderSuccess());
      toast.success("Амжилттай нэмлээ");
    } catch (err) {
      dispatch(addOrderError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const editOrder = (data) => {
  return async (dispatch) => {
    dispatch(editOrderRequest());

    try {
      await updateOrder(data);
      dispatch(editOrderSuccess());
      toast.success("Амжилттай заслаа");
    } catch (err) {
      dispatch(editOrderError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const deleteOrder = (id) => {
  return async (dispatch) => {
    dispatch(deleteOrderRequest());

    try {
      await removeOrder(id);
      dispatch(deleteOrderSuccess());
      toast.success("Амжилттай устгалаа");
    } catch (err) {
      dispatch(deleteOrderError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const fetchOrders = () => {
  return async (dispatch) => {
    dispatch(fetchOrdersRequest());

    try {
      const orders = await getOrders();
      dispatch(fetchOrdersSuccess(orders));
    } catch (err) {
      dispatch(fetchOrdersError(err.message));
    }
  };
};

export default {
  addOrder,
  editOrder,
  deleteOrder,
  fetchOrders,
};
