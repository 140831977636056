import instance from "./axios.base";

const getAidsInfo = async (id) => {
  const res = await instance.get(`/aids/${id}`);
  return res.data;
};

const addAidsInfo = async (data) => {
  await instance.post("/aids", data);
};

const editAidsInfo = async (data) => {
  await instance.put("/aids", data);
};

const deleteAidsInfo = async (id) => {
  await instance.delete(`/aids/${id}`);
};

export { getAidsInfo, addAidsInfo, editAidsInfo, deleteAidsInfo };
