import { default as raisesTypes } from "./types";

const {
  ADD_RAISE_REQUEST,
  ADD_RAISE_SUCCESS,
  ADD_RAISE_FAILURE,
  EDIT_RAISE_REQUEST,
  EDIT_RAISE_SUCCESS,
  EDIT_RAISE_FAILURE,
  DELETE_RAISE_REQUEST,
  DELETE_RAISE_SUCCESS,
  DELETE_RAISE_FAILURE,
  FETCH_RAISES_REQUEST,
  FETCH_RAISES_SUCCESS,
  FETCH_RAISES_FAILURE,
  FETCH_RAISE_REQUEST,
  FETCH_RAISE_SUCCESS,
  FETCH_RAISE_FAILURE,
  CLEAR_RAISE,
} = raisesTypes;

const initialState = {
  isLoading: false,
  list: [],
  ndTypesList: [],
  detail: null,
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_RAISE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_RAISE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case ADD_RAISE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case EDIT_RAISE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case EDIT_RAISE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case EDIT_RAISE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case DELETE_RAISE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case DELETE_RAISE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case DELETE_RAISE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case FETCH_RAISES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_RAISES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: action.payload,
        error: "",
      };
    case FETCH_RAISES_FAILURE:
      return {
        ...state,
        isLoading: false,
        list: [],
        error: action.payload,
      };
    case FETCH_RAISE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_RAISE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        detail: action.payload,
        error: "",
      };
    case FETCH_RAISE_FAILURE:
      return {
        ...state,
        isLoading: false,
        detail: null,
        error: action.payload,
      };
    case CLEAR_RAISE:
      return {
        ...state,
        isLoading: false,
        detail: null,
        error: "",
      };
    default:
      return state;
  }
};

export default reducer;
