import instance from "./axios.base";

const getNDSH7AReport = async () => {
  const res = await instance.get("/prreport/nd7a");
  return res.data;
};

const getNDSH7BReport = async (year, month) => {
  const res = await instance.get(`/prreport/nd7b?year=${year}&month=${month}`);
  return res.data;
};

const getNDSH8Report = async (year, month) => {
  const res = await instance.get(`/prreport/nd8?year=${year}&month=${month}`);
  return res.data;
};

const getTT11Report = async (year, month) => {
  const res = await instance.get(`/prreport/tt11?year=${year}&month=${month}`);
  return res.data;
};

const getTT11_1Report = async (year, month) => {
  const res = await instance.get(
    `/prreport/tt11-1?year=${year}&month=${month}`
  );
  return res.data;
};

export {
  getTT11Report,
  getTT11_1Report,
  getNDSH7AReport,
  getNDSH7BReport,
  getNDSH8Report,
};
