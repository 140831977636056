import { toast } from "react-toastify";
import {
  addEmpSchedule,
  deleteEmpSchedule,
  getEmpSchedules,
} from "services/empSchedule";
import { default as empSchedulesActions } from "./actions";

const {
  addEmpScheduleRequest,
  addEmpScheduleSuccess,
  addEmpScheduleError,
  deleteEmpScheduleRequest,
  deleteEmpScheduleSuccess,
  deleteEmpScheduleError,
  fetchEmpSchedulesRequest,
  fetchEmpSchedulesSuccess,
  fetchEmpSchedulesError,
} = empSchedulesActions;

const add = (data) => {
  return async (dispatch) => {
    dispatch(addEmpScheduleRequest());

    try {
      await addEmpSchedule(data);
      dispatch(addEmpScheduleSuccess());
      toast.success("Амжилттай нэмлээ");
    } catch (err) {
      dispatch(addEmpScheduleError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const remove = (id) => {
  return async (dispatch) => {
    dispatch(deleteEmpScheduleRequest());

    try {
      await deleteEmpSchedule(id);
      dispatch(deleteEmpScheduleSuccess());
      toast.success("Амжилттай устгалаа");
    } catch (err) {
      dispatch(deleteEmpScheduleError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const fetchEmpSchedules = (date) => {
  return async (dispatch) => {
    dispatch(fetchEmpSchedulesRequest());

    try {
      const empSchedules = await getEmpSchedules(date);
      dispatch(fetchEmpSchedulesSuccess(empSchedules));
    } catch (err) {
      dispatch(fetchEmpSchedulesError(err.message));
    }
  };
};

export default {
  add,
  remove,
  fetchEmpSchedules,
};
