import { default as empDeductionsTypes } from "./types";

const {
  ADD_EMP_DEDUCTION_REQUEST,
  ADD_EMP_DEDUCTION_SUCCESS,
  ADD_EMP_DEDUCTION_FAILURE,
  EDIT_EMP_DEDUCTION_REQUEST,
  EDIT_EMP_DEDUCTION_SUCCESS,
  EDIT_EMP_DEDUCTION_FAILURE,
  DELETE_EMP_DEDUCTION_REQUEST,
  DELETE_EMP_DEDUCTION_SUCCESS,
  DELETE_EMP_DEDUCTION_FAILURE,
  FETCH_EMP_DEDUCTIONS_REQUEST,
  FETCH_EMP_DEDUCTIONS_SUCCESS,
  FETCH_EMP_DEDUCTIONS_FAILURE,
  CLEAR_EMP_DEDUCTION,
} = empDeductionsTypes;

const addEmpDeductionRequest = () => {
  return {
    type: ADD_EMP_DEDUCTION_REQUEST,
  };
};

const addEmpDeductionSuccess = () => {
  return {
    type: ADD_EMP_DEDUCTION_SUCCESS,
  };
};

const addEmpDeductionError = (error) => {
  return {
    type: ADD_EMP_DEDUCTION_FAILURE,
    payload: error,
  };
};

const editEmpDeductionRequest = () => {
  return {
    type: EDIT_EMP_DEDUCTION_REQUEST,
  };
};

const editEmpDeductionSuccess = () => {
  return {
    type: EDIT_EMP_DEDUCTION_SUCCESS,
  };
};

const editEmpDeductionError = (error) => {
  return {
    type: EDIT_EMP_DEDUCTION_FAILURE,
    payload: error,
  };
};

const deleteEmpDeductionRequest = () => {
  return {
    type: DELETE_EMP_DEDUCTION_REQUEST,
  };
};

const deleteEmpDeductionSuccess = () => {
  return {
    type: DELETE_EMP_DEDUCTION_SUCCESS,
  };
};

const deleteEmpDeductionError = (error) => {
  return {
    type: DELETE_EMP_DEDUCTION_FAILURE,
    payload: error,
  };
};

const fetchEmpDeductionsRequest = () => {
  return {
    type: FETCH_EMP_DEDUCTIONS_REQUEST,
  };
};

const fetchEmpDeductionsSuccess = (empDeductions) => {
  return {
    type: FETCH_EMP_DEDUCTIONS_SUCCESS,
    payload: empDeductions,
  };
};

const fetchEmpDeductionsError = (error) => {
  return {
    type: FETCH_EMP_DEDUCTIONS_FAILURE,
    payload: error,
  };
};

const clearEmpDeduction = () => {
  return {
    type: CLEAR_EMP_DEDUCTION,
  };
};

export default {
  addEmpDeductionRequest,
  addEmpDeductionSuccess,
  addEmpDeductionError,
  editEmpDeductionRequest,
  editEmpDeductionSuccess,
  editEmpDeductionError,
  deleteEmpDeductionRequest,
  deleteEmpDeductionSuccess,
  deleteEmpDeductionError,
  fetchEmpDeductionsRequest,
  fetchEmpDeductionsSuccess,
  fetchEmpDeductionsError,
  clearEmpDeduction,
};
