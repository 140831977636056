const ADD_JOURNAL_REQUEST = "ADD_JOURNAL_REQUEST";
const ADD_JOURNAL_SUCCESS = "ADD_JOURNAL_SUCCESS";
const ADD_JOURNAL_FAILURE = "ADD_JOURNAL_FAILURE";
const DELETE_JOURNAL_REQUEST = "DELETE_JOURNAL_REQUEST";
const DELETE_JOURNAL_SUCCESS = "DELETE_JOURNAL_SUCCESS";
const DELETE_JOURNAL_FAILURE = "DELETE_JOURNAL_FAILURE";
const FETCH_JOURNALS_REQUEST = "FETCH_JOURNALS_REQUEST";
const FETCH_JOURNALS_SUCCESS = "FETCH_JOURNALS_SUCCESS";
const FETCH_JOURNALS_FAILURE = "FETCH_JOURNALS_FAILURE";
const FETCH_JOURNALDATA_REQUEST = "FETCH_JOURNALDATA_REQUEST";
const FETCH_JOURNALDATA_SUCCESS = "FETCH_JOURNALDATA_SUCCESS";
const FETCH_JOURNALDATA_FAILURE = "FETCH_JOURNALDATA_FAILURE";
const FETCH_JOURNAL_REQUEST = "FETCH_JOURNAL_REQUEST";
const FETCH_JOURNAL_SUCCESS = "FETCH_JOURNAL_SUCCESS";
const FETCH_JOURNAL_FAILURE = "FETCH_JOURNAL_FAILURE";
const FETCH_STANDARD_TIME_REQUEST = "FETCH_STANDARD_TIME_REQUEST";
const FETCH_STANDARD_TIME_SUCCESS = "FETCH_STANDARD_TIME_SUCCESS";
const FETCH_STANDARD_TIME_FAILURE = "FETCH_STANDARD_TIME_FAILURE";
const CALCULATE_JOURNAL_REQUEST = "CALCULATE_JOURNAL_REQUEST";
const CALCULATE_JOURNAL_SUCCESS = "CALCULATE_JOURNAL_SUCCESS";
const CALCULATE_JOURNAL_FAILURE = "CALCULATE_JOURNAL_FAILURE";
const FETCH_EMP_LATE_MINUTES_REQUEST = "FETCH_EMP_LATE_MINUTES_REQUEST";
const FETCH_EMP_LATE_MINUTES_SUCCESS = "FETCH_EMP_LATE_MINUTES_SUCCESS";
const FETCH_EMP_LATE_MINUTES_FAILURE = "FETCH_EMP_LATE_MINUTES_FAILURE";
const ARCHIVE_JOURNAL_REQUEST = "ARCHIVE_JOURNAL_REQUEST";
const ARCHIVE_JOURNAL_SUCCESS = "ARCHIVE_JOURNAL_SUCCESS";
const ARCHIVE_JOURNAL_FAILURE = "ARCHIVE_JOURNAL_FAILURE";
const EDIT_OVERTIME_REQUEST = "EDIT_OVERTIME_REQUEST";
const EDIT_OVERTIME_SUCCESS = "EDIT_OVERTIME_SUCCESS";
const EDIT_OVERTIME_FAILURE = "EDIT_OVERTIME_FAILURE";
const CLEAR_JOURNAL = "CLEAR_JOURNAL";

export default {
  ADD_JOURNAL_REQUEST,
  ADD_JOURNAL_SUCCESS,
  ADD_JOURNAL_FAILURE,
  DELETE_JOURNAL_REQUEST,
  DELETE_JOURNAL_SUCCESS,
  DELETE_JOURNAL_FAILURE,
  FETCH_JOURNALS_REQUEST,
  FETCH_JOURNALS_SUCCESS,
  FETCH_JOURNALS_FAILURE,
  FETCH_JOURNALDATA_REQUEST,
  FETCH_JOURNALDATA_SUCCESS,
  FETCH_JOURNALDATA_FAILURE,
  FETCH_JOURNAL_REQUEST,
  FETCH_JOURNAL_SUCCESS,
  FETCH_JOURNAL_FAILURE,
  FETCH_STANDARD_TIME_REQUEST,
  FETCH_STANDARD_TIME_SUCCESS,
  FETCH_STANDARD_TIME_FAILURE,
  CALCULATE_JOURNAL_REQUEST,
  CALCULATE_JOURNAL_SUCCESS,
  CALCULATE_JOURNAL_FAILURE,
  FETCH_EMP_LATE_MINUTES_REQUEST,
  FETCH_EMP_LATE_MINUTES_SUCCESS,
  FETCH_EMP_LATE_MINUTES_FAILURE,
  ARCHIVE_JOURNAL_REQUEST,
  ARCHIVE_JOURNAL_SUCCESS,
  ARCHIVE_JOURNAL_FAILURE,
  EDIT_OVERTIME_REQUEST,
  EDIT_OVERTIME_SUCCESS,
  EDIT_OVERTIME_FAILURE,
  CLEAR_JOURNAL,
};
