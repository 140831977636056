const ADD_EVENT_REQUEST = "ADD_EVENT_REQUEST";
const ADD_EVENT_SUCCESS = "ADD_EVENT_SUCCESS";
const ADD_EVENT_FAILURE = "ADD_EVENT_FAILURE";
const FETCH_EVENTS_REQUEST = "FETCH_EVENTS_REQUEST";
const FETCH_EVENTS_SUCCESS = "FETCH_EVENTS_SUCCESS";
const FETCH_EVENTS_FAILURE = "FETCH_EVENTS_FAILURE";

export default {
  ADD_EVENT_REQUEST,
  ADD_EVENT_SUCCESS,
  ADD_EVENT_FAILURE,
  FETCH_EVENTS_REQUEST,
  FETCH_EVENTS_SUCCESS,
  FETCH_EVENTS_FAILURE,
};
