import { getPermissions } from "services/references/permissions";
import { default as permissionsActions } from "./actions";

const {
  fetchPermissionsRequest,
  fetchPermissionsSuccess,
  fetchPermissionsError,
} = permissionsActions;

const fetchPermissions = () => {
  return async (dispatch) => {
    dispatch(fetchPermissionsRequest());

    try {
      const permissions = await getPermissions();
      dispatch(fetchPermissionsSuccess(permissions));
    } catch (err) {
      dispatch(fetchPermissionsError(err.message));
    }
  };
};

export default { fetchPermissions };
