import instance from "./axios.base";

const addBasicSalary = async (data) => {
  await instance.post("/taempschedule", data);
};

const editBasicSalary = async (data) => {
  await instance.put("/prmonthpayroll", data);
};

const deleteBasicSalary = async (id) => {
  await instance.delete(`/taempschedule/${id}`);
};

const getBasicSalaries = async (year, month) => {
  const res = await instance.get(`/prmonthpayroll?year=${year}&month=${month}`);
  return res.data;
};

const getBasicSalary = async (empCode, date) => {
  const res = await instance.get(`/dayrequest?empid=${empCode}&date=${date}`);
  return res.data;
};

const archiveBasicSalaries = async (selectedIds) => {
  const res = await instance.post("/prmontharchivepayroll", selectedIds);
  return res.data;
};

const recalculateSalaries = async (year, month) => {
  const res = await instance.post("/prmonthpayroll", { year, month });
  return res.data;
};

export {
  addBasicSalary,
  editBasicSalary,
  deleteBasicSalary,
  getBasicSalaries,
  getBasicSalary,
  archiveBasicSalaries,
  recalculateSalaries,
};
