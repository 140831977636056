import { default as positionsTypes } from "./types";

const {
  ADD_POSITION_REQUEST,
  ADD_POSITION_SUCCESS,
  ADD_POSITION_FAILURE,
  FETCH_POSITIONS_REQUEST,
  FETCH_POSITIONS_SUCCESS,
  FETCH_POSITIONS_FAILURE,
} = positionsTypes;

const initialState = {
  isLoading: false,
  list: [],
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_POSITION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_POSITION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case ADD_POSITION_FAILURE:
      return {
        ...state,
        isLoading: false,
        list: [],
        error: action.payload,
      };
    case FETCH_POSITIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_POSITIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: action.payload,
        error: "",
      };
    case FETCH_POSITIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        list: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
