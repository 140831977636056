import { toast } from "react-toastify";
import {
  getExperienceInfo,
  editExperienceInfo,
  deleteExperienceInfo,
} from "services/experienceInfo";
import { default as employeeExperienceInfoActions } from "./actions";

const {
  fetchEmployeeExperienceInfoRequest,
  fetchEmployeeExperienceInfoSuccess,
  fetchEmployeeExperienceInfoError,
  editEmployeeExperienceInfoRequest,
  editEmployeeExperienceInfoSuccess,
  editEmployeeExperienceInfoError,
  deleteEmployeeExperienceInfoRequest,
  deleteEmployeeExperienceInfoSuccess,
  deleteEmployeeExperienceInfoError,
} = employeeExperienceInfoActions;

const fetchEmployeeExperienceInfo = (data) => {
  return async (dispatch) => {
    dispatch(fetchEmployeeExperienceInfoRequest());

    try {
      const employeeExperienceInfo = await getExperienceInfo(data);
      dispatch(fetchEmployeeExperienceInfoSuccess(employeeExperienceInfo));
    } catch (err) {
      dispatch(fetchEmployeeExperienceInfoError(err.message));
    }
  };
};

const editEmployeeExperienceInfo = (data) => {
  return async (dispatch) => {
    dispatch(editEmployeeExperienceInfoRequest());

    try {
      await editExperienceInfo(data);
      dispatch(editEmployeeExperienceInfoSuccess());
      toast.success("Амжилттай заслаа");
    } catch (err) {
      dispatch(editEmployeeExperienceInfoError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const deleteEmployeeExperienceInfo = (id) => {
  return async (dispatch) => {
    dispatch(deleteEmployeeExperienceInfoRequest());

    try {
      await deleteExperienceInfo(id);
      dispatch(deleteEmployeeExperienceInfoSuccess());
      toast.success("Амжилттай устгалаа");
    } catch (err) {
      dispatch(deleteEmployeeExperienceInfoError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

export default {
  fetchEmployeeExperienceInfo,
  editEmployeeExperienceInfo,
  deleteEmployeeExperienceInfo,
};
