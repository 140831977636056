import { toast } from "react-toastify";
import {
  getAidsInfo,
  addAidsInfo,
  editAidsInfo,
  deleteAidsInfo,
} from "services/aidsInfo";
import { default as employeeAidsInfoActions } from "./actions";

const {
  fetchEmployeeAidsInfoRequest,
  fetchEmployeeAidsInfoSuccess,
  fetchEmployeeAidsInfoError,
  addEmployeeAidsInfoRequest,
  addEmployeeAidsInfoSuccess,
  addEmployeeAidsInfoError,
  editEmployeeAidsInfoRequest,
  editEmployeeAidsInfoSuccess,
  editEmployeeAidsInfoError,
  deleteEmployeeAidsInfoRequest,
  deleteEmployeeAidsInfoSuccess,
  deleteEmployeeAidsInfoError,
} = employeeAidsInfoActions;

const fetchEmployeeAidsInfo = (empId) => {
  return async (dispatch) => {
    dispatch(fetchEmployeeAidsInfoRequest());

    try {
      const employeeAidsInfo = await getAidsInfo(empId);
      dispatch(fetchEmployeeAidsInfoSuccess(employeeAidsInfo));
    } catch (err) {
      dispatch(fetchEmployeeAidsInfoError(err.message));
    }
  };
};

const addEmployeeAidsInfo = (data) => {
  return async (dispatch) => {
    dispatch(addEmployeeAidsInfoRequest());

    try {
      await addAidsInfo(data);
      dispatch(addEmployeeAidsInfoSuccess());
      toast.success("Амжилттай нэмлээ");
    } catch (err) {
      dispatch(addEmployeeAidsInfoError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const editEmployeeAidsInfo = (data) => {
  return async (dispatch) => {
    dispatch(editEmployeeAidsInfoRequest());

    try {
      await editAidsInfo(data);
      dispatch(editEmployeeAidsInfoSuccess());
      toast.success("Амжилттай заслаа");
    } catch (err) {
      dispatch(editEmployeeAidsInfoError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const deleteEmployeeAidsInfo = (id) => {
  return async (dispatch) => {
    dispatch(deleteEmployeeAidsInfoRequest());

    try {
      await deleteAidsInfo(id);
      dispatch(deleteEmployeeAidsInfoSuccess());
      toast.success("Амжилттай устгалаа");
    } catch (err) {
      dispatch(deleteEmployeeAidsInfoError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

export default {
  fetchEmployeeAidsInfo,
  addEmployeeAidsInfo,
  editEmployeeAidsInfo,
  deleteEmployeeAidsInfo,
};
