import { default as types } from "./types";

const {
  FETCH_NDSH7A_REPORT_REQUEST,
  FETCH_NDSH7A_REPORT_SUCCESS,
  FETCH_NDSH7A_REPORT_FAILURE,
} = types;

const fetchNDSH7AReportRequest = () => {
  return {
    type: FETCH_NDSH7A_REPORT_REQUEST,
  };
};

const fetchNDSH7AReportSuccess = (report) => {
  return {
    type: FETCH_NDSH7A_REPORT_SUCCESS,
    payload: report,
  };
};

const fetchNDSH7AReportError = (error) => {
  return {
    type: FETCH_NDSH7A_REPORT_FAILURE,
    payload: error,
  };
};

export default {
  fetchNDSH7AReportRequest,
  fetchNDSH7AReportSuccess,
  fetchNDSH7AReportError,
};
