import instance from "./axios.base";

const addPreJournal = async (data) => {
  await instance.post("/taempschedule", data);
};

const deletePreJournal = async (id) => {
  await instance.delete(`/taempschedule/${id}`);
};

const getPreJournals = async (startDate, finishDate) => {
  const res = await instance.get(
    `/advancetime?date=${startDate}&date2=${finishDate}`
  );
  return res.data;
};

const getPreJournal = async (empCode, date) => {
  const res = await instance.get(`/dayrequest?empid=${empCode}&date=${date}`);
  return res.data;
};

const archivePreJournal = async (selected) => {
  const res = await instance.post("/taadvancearchive", selected);
  return res.data;
};

const calculatePreJournal = async (data) => {
  await instance.get(`/calculateadvancetime?date=${data}`);
};

export {
  addPreJournal,
  deletePreJournal,
  getPreJournals,
  getPreJournal,
  archivePreJournal,
  calculatePreJournal,
};
