import { default as sectorsTypes } from "./types";

const {
  ADD_SECTOR_REQUEST,
  ADD_SECTOR_SUCCESS,
  ADD_SECTOR_FAILURE,
  FETCH_SECTORS_REQUEST,
  FETCH_SECTORS_SUCCESS,
  FETCH_SECTORS_FAILURE,
} = sectorsTypes;

const initialState = {
  isLoading: false,
  list: [],
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SECTOR_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_SECTOR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case ADD_SECTOR_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case FETCH_SECTORS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_SECTORS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: action.payload,
        error: "",
      };
    case FETCH_SECTORS_FAILURE:
      return {
        ...state,
        isLoading: false,
        list: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
