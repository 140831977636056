const ADD_EMP_SCHEDULE_REQUEST = "ADD_EMP_SCHEDULE_REQUEST";
const ADD_EMP_SCHEDULE_SUCCESS = "ADD_EMP_SCHEDULE_SUCCESS";
const ADD_EMP_SCHEDULE_FAILURE = "ADD_EMP_SCHEDULE_FAILURE";
const DELETE_EMP_SCHEDULE_REQUEST = "DELETE_EMP_SCHEDULE_REQUEST";
const DELETE_EMP_SCHEDULE_SUCCESS = "DELETE_EMP_SCHEDULE_SUCCESS";
const DELETE_EMP_SCHEDULE_FAILURE = "DELETE_EMP_SCHEDULE_FAILURE";
const FETCH_EMP_SCHEDULES_REQUEST = "FETCH_EMP_SCHEDULES_REQUEST";
const FETCH_EMP_SCHEDULES_SUCCESS = "FETCH_EMP_SCHEDULES_SUCCESS";
const FETCH_EMP_SCHEDULES_FAILURE = "FETCH_EMP_SCHEDULES_FAILURE";

export default {
  ADD_EMP_SCHEDULE_REQUEST,
  ADD_EMP_SCHEDULE_SUCCESS,
  ADD_EMP_SCHEDULE_FAILURE,
  DELETE_EMP_SCHEDULE_REQUEST,
  DELETE_EMP_SCHEDULE_SUCCESS,
  DELETE_EMP_SCHEDULE_FAILURE,
  FETCH_EMP_SCHEDULES_REQUEST,
  FETCH_EMP_SCHEDULES_SUCCESS,
  FETCH_EMP_SCHEDULES_FAILURE,
};
