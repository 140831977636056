import instance from "./axios.base";

const addPrepaymentJournal = async (data) => {
  await instance.post("/taempschedule", data);
};

const deletePrepaymentJournal = async (id) => {
  await instance.delete(`/taempschedule/${id}`);
};

const getPrepaymentJournals = async (year, month) => {
  const res = await instance.get(
    `/prmonthadvancepayroll?year=${year}&month=${month}`
  );
  return res.data;
};

const getPrepaymentJournal = async (empCode, date) => {
  const res = await instance.get(`/dayrequest?empid=${empCode}&date=${date}`);
  return res.data;
};

const archivePrepaymentJournal = async (selected) => {
  const res = await instance.post("/prmonthadvancearchivepayroll", selected);
  return res.data;
};

const calculatePrepaymentJournal = async (year, month) => {
  await instance.post("/prmonthadvancepayroll", { year, month });
};

export {
  addPrepaymentJournal,
  deletePrepaymentJournal,
  getPrepaymentJournals,
  getPrepaymentJournal,
  archivePrepaymentJournal,
  calculatePrepaymentJournal,
};
