import { default as horoscopesTypes } from "./types";

const {
  FETCH_HOROSCOPES_REQUEST,
  FETCH_HOROSCOPES_SUCCESS,
  FETCH_HOROSCOPES_FAILURE,
} = horoscopesTypes;

const initialState = {
  isLoading: false,
  list: [],
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_HOROSCOPES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_HOROSCOPES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: action.payload,
        error: "",
      };
    case FETCH_HOROSCOPES_FAILURE:
      return {
        ...state,
        isLoading: false,
        list: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
