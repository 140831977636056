const FETCH_EMPLOYEE_MIGRATION_INFO_ITEM_REQUEST =
  "FETCH_EMPLOYEE_MIGRATION_INFO_ITEM_REQUEST";
const FETCH_EMPLOYEE_MIGRATION_INFO_ITEM_SUCCESS =
  "FETCH_EMPLOYEE_MIGRATION_INFO_ITEM_SUCCESS";
const FETCH_EMPLOYEE_MIGRATION_INFO_ITEM_FAILURE =
  "FETCH_EMPLOYEE_MIGRATION_INFO_ITEM_FAILURE";
const ADD_EMPLOYEE_MIGRATION_INFO_ITEM_REQUEST =
  "ADD_EMPLOYEE_MIGRATION_INFO_ITEM_REQUEST";
const ADD_EMPLOYEE_MIGRATION_INFO_ITEM_SUCCESS =
  "ADD_EMPLOYEE_MIGRATION_INFO_ITEM_SUCCESS";
const ADD_EMPLOYEE_MIGRATION_INFO_ITEM_FAILURE =
  "ADD_EMPLOYEE_MIGRATION_INFO_ITEM_FAILURE";
const EDIT_EMPLOYEE_MIGRATION_INFO_ITEM_REQUEST =
  "EDIT_EMPLOYEE_MIGRATION_INFO_ITEM_REQUEST";
const EDIT_EMPLOYEE_MIGRATION_INFO_ITEM_SUCCESS =
  "EDIT_EMPLOYEE_MIGRATION_INFO_ITEM_SUCCESS";
const EDIT_EMPLOYEE_MIGRATION_INFO_ITEM_FAILURE =
  "EDIT_EMPLOYEE_MIGRATION_INFO_ITEM_FAILURE";
const DELETE_EMPLOYEE_MIGRATION_INFO_ITEM_REQUEST =
  "DELETE_EMPLOYEE_MIGRATION_INFO_ITEM_REQUEST";
const DELETE_EMPLOYEE_MIGRATION_INFO_ITEM_SUCCESS =
  "DELETE_EMPLOYEE_MIGRATION_INFO_ITEM_SUCCESS";
const DELETE_EMPLOYEE_MIGRATION_INFO_ITEM_FAILURE =
  "DELETE_EMPLOYEE_MIGRATION_INFO_ITEM_FAILURE";
const CLEAR_EMPLOYEE_MIGRATION_INFO_ITEM = "CLEAR_EMPLOYEE_MIGRATION_INFO_ITEM";

export default {
  FETCH_EMPLOYEE_MIGRATION_INFO_ITEM_REQUEST,
  FETCH_EMPLOYEE_MIGRATION_INFO_ITEM_SUCCESS,
  FETCH_EMPLOYEE_MIGRATION_INFO_ITEM_FAILURE,
  ADD_EMPLOYEE_MIGRATION_INFO_ITEM_REQUEST,
  ADD_EMPLOYEE_MIGRATION_INFO_ITEM_SUCCESS,
  ADD_EMPLOYEE_MIGRATION_INFO_ITEM_FAILURE,
  EDIT_EMPLOYEE_MIGRATION_INFO_ITEM_REQUEST,
  EDIT_EMPLOYEE_MIGRATION_INFO_ITEM_SUCCESS,
  EDIT_EMPLOYEE_MIGRATION_INFO_ITEM_FAILURE,
  DELETE_EMPLOYEE_MIGRATION_INFO_ITEM_REQUEST,
  DELETE_EMPLOYEE_MIGRATION_INFO_ITEM_SUCCESS,
  DELETE_EMPLOYEE_MIGRATION_INFO_ITEM_FAILURE,
  CLEAR_EMPLOYEE_MIGRATION_INFO_ITEM,
};
