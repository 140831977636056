import instance from "./axios.base";
import { dateFormat } from "utils/formatter";

const getSpecialDays = async () => {
  const res = await instance.get("/specialdays");
  res.data.map((row) => {
    row.date = dateFormat(row.date)
  })
  
  return res.data;
};

const createSpecialDay = async (data) => {
  const res = await instance.post("/specialdays", data);

  return res.data;
};

const updateSpecialDay = async (data) => {
  const res = await instance.put("/specialdays", data);

  return res.data;
};

const removeSpecialDay = async (data) => {
  const res = await instance.delete(`/specialdays/${data.id}`);

  return res.data;
};

export { getSpecialDays, createSpecialDay, updateSpecialDay, removeSpecialDay };