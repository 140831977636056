import { toast } from "react-toastify";
import { add, edit, remove, getRaises, getRaise } from "services/raise";
import { default as raisesActions } from "./actions";

const {
  addRaiseRequest,
  addRaiseSuccess,
  addRaiseError,
  editRaiseRequest,
  editRaiseSuccess,
  editRaiseError,
  deleteRaiseRequest,
  deleteRaiseSuccess,
  deleteRaiseError,
  fetchRaisesRequest,
  fetchRaisesSuccess,
  fetchRaisesError,
  fetchRaiseRequest,
  fetchRaiseSuccess,
  fetchRaiseError,
} = raisesActions;

const addRaise = (data) => {
  return async (dispatch) => {
    dispatch(addRaiseRequest());

    try {
      await add(data);
      dispatch(addRaiseSuccess());
      toast.success("Амжилттай нэмлээ");
    } catch (err) {
      dispatch(addRaiseError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const editRaise = (data) => {
  return async (dispatch) => {
    dispatch(editRaiseRequest());

    try {
      await edit(data);
      dispatch(editRaiseSuccess());
      toast.success("Амжилттай заслаа");
    } catch (err) {
      dispatch(editRaiseError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const deleteRaise = (id) => {
  return async (dispatch) => {
    dispatch(deleteRaiseRequest());

    try {
      await remove(id);
      dispatch(deleteRaiseSuccess());
      toast.success("Амжилттай устгалаа");
    } catch (err) {
      dispatch(deleteRaiseError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const fetchRaises = () => {
  return async (dispatch) => {
    dispatch(fetchRaisesRequest());

    try {
      const raises = await getRaises();
      dispatch(fetchRaisesSuccess(raises));
    } catch (err) {
      dispatch(fetchRaisesError(err.message));
    }
  };
};

const fetchRaise = (id) => {
  return async (dispatch) => {
    dispatch(fetchRaiseRequest());

    try {
      const raise = await getRaise(id);
      dispatch(fetchRaiseSuccess(raise));
    } catch (err) {
      dispatch(fetchRaiseError(err.message));
    }
  };
};

export default {
  addRaise,
  editRaise,
  deleteRaise,
  fetchRaises,
  fetchRaise,
};
