import { default as documentfoldersTypes } from "./types";

const {
  ADD_DOCUMENTFOLDER_REQUEST,
  ADD_DOCUMENTFOLDER_SUCCESS,
  ADD_DOCUMENTFOLDER_FAILURE,
  FETCH_DOCUMENTFOLDERS_REQUEST,
  FETCH_DOCUMENTFOLDERS_SUCCESS,
  FETCH_DOCUMENTFOLDERS_FAILURE,
} = documentfoldersTypes;

const addDocumentFolderRequest = () => {
  return {
    type: ADD_DOCUMENTFOLDER_REQUEST,
  };
};

const addDocumentFolderSuccess = () => {
  return {
    type: ADD_DOCUMENTFOLDER_SUCCESS,
  };
};

const addDocumentFolderError = (error) => {
  return {
    type: ADD_DOCUMENTFOLDER_FAILURE,
    payload: error,
  };
};

const fetchDocumentFoldersRequest = () => {
  return {
    type: FETCH_DOCUMENTFOLDERS_REQUEST,
  };
};

const fetchDocumentFoldersSuccess = (documentfolders) => {
  return {
    type: FETCH_DOCUMENTFOLDERS_SUCCESS,
    payload: documentfolders,
  };
};

const fetchDocumentFoldersError = (error) => {
  return {
    type: FETCH_DOCUMENTFOLDERS_FAILURE,
    payload: error,
  };
};

export default {
  addDocumentFolderRequest,
  addDocumentFolderSuccess,
  addDocumentFolderError,
  fetchDocumentFoldersRequest,
  fetchDocumentFoldersSuccess,
  fetchDocumentFoldersError,
};
