import { default as employeeHealthInfoTypes } from "./types";

const {
  FETCH_EMPLOYEE_HEALTH_INFO_REQUEST,
  FETCH_EMPLOYEE_HEALTH_INFO_SUCCESS,
  FETCH_EMPLOYEE_HEALTH_INFO_FAILURE,
  ADD_EMPLOYEE_HEALTH_INFO_REQUEST,
  ADD_EMPLOYEE_HEALTH_INFO_SUCCESS,
  ADD_EMPLOYEE_HEALTH_INFO_FAILURE,
  EDIT_EMPLOYEE_HEALTH_INFO_REQUEST,
  EDIT_EMPLOYEE_HEALTH_INFO_SUCCESS,
  EDIT_EMPLOYEE_HEALTH_INFO_FAILURE,
  DELETE_EMPLOYEE_HEALTH_INFO_REQUEST,
  DELETE_EMPLOYEE_HEALTH_INFO_SUCCESS,
  DELETE_EMPLOYEE_HEALTH_INFO_FAILURE,
} = employeeHealthInfoTypes;

const fetchEmployeeHealthInfoRequest = () => {
  return {
    type: FETCH_EMPLOYEE_HEALTH_INFO_REQUEST,
  };
};

const fetchEmployeeHealthInfoSuccess = (employeeHealthInfo) => {
  return {
    type: FETCH_EMPLOYEE_HEALTH_INFO_SUCCESS,
    payload: employeeHealthInfo,
  };
};

const fetchEmployeeHealthInfoError = (error) => {
  return {
    type: FETCH_EMPLOYEE_HEALTH_INFO_FAILURE,
    payload: error,
  };
};

const addEmployeeHealthInfoRequest = () => {
  return {
    type: ADD_EMPLOYEE_HEALTH_INFO_REQUEST,
  };
};

const addEmployeeHealthInfoSuccess = () => {
  return {
    type: ADD_EMPLOYEE_HEALTH_INFO_SUCCESS,
  };
};

const addEmployeeHealthInfoError = (error) => {
  return {
    type: ADD_EMPLOYEE_HEALTH_INFO_FAILURE,
    payload: error,
  };
};

const editEmployeeHealthInfoRequest = () => {
  return {
    type: EDIT_EMPLOYEE_HEALTH_INFO_REQUEST,
  };
};

const editEmployeeHealthInfoSuccess = () => {
  return {
    type: EDIT_EMPLOYEE_HEALTH_INFO_SUCCESS,
  };
};

const editEmployeeHealthInfoError = (error) => {
  return {
    type: EDIT_EMPLOYEE_HEALTH_INFO_FAILURE,
    payload: error,
  };
};

const deleteEmployeeHealthInfoRequest = () => {
  return {
    type: DELETE_EMPLOYEE_HEALTH_INFO_REQUEST,
  };
};

const deleteEmployeeHealthInfoSuccess = () => {
  return {
    type: DELETE_EMPLOYEE_HEALTH_INFO_SUCCESS,
  };
};

const deleteEmployeeHealthInfoError = (error) => {
  return {
    type: DELETE_EMPLOYEE_HEALTH_INFO_FAILURE,
    payload: error,
  };
};

export default {
  fetchEmployeeHealthInfoRequest,
  fetchEmployeeHealthInfoSuccess,
  fetchEmployeeHealthInfoError,
  addEmployeeHealthInfoRequest,
  addEmployeeHealthInfoSuccess,
  addEmployeeHealthInfoError,
  editEmployeeHealthInfoRequest,
  editEmployeeHealthInfoSuccess,
  editEmployeeHealthInfoError,
  deleteEmployeeHealthInfoRequest,
  deleteEmployeeHealthInfoSuccess,
  deleteEmployeeHealthInfoError,
};
