import { default as employeeExperienceInfoTypes } from "./types";

const {
  FETCH_EMPLOYEE_EXPERIENCE_INFO_REQUEST,
  FETCH_EMPLOYEE_EXPERIENCE_INFO_SUCCESS,
  FETCH_EMPLOYEE_EXPERIENCE_INFO_FAILURE,
  EDIT_EMPLOYEE_EXPERIENCE_INFO_REQUEST,
  EDIT_EMPLOYEE_EXPERIENCE_INFO_SUCCESS,
  EDIT_EMPLOYEE_EXPERIENCE_INFO_FAILURE,
  DELETE_EMPLOYEE_EXPERIENCE_INFO_REQUEST,
  DELETE_EMPLOYEE_EXPERIENCE_INFO_SUCCESS,
  DELETE_EMPLOYEE_EXPERIENCE_INFO_FAILURE,
} = employeeExperienceInfoTypes;

const fetchEmployeeExperienceInfoRequest = () => {
  return {
    type: FETCH_EMPLOYEE_EXPERIENCE_INFO_REQUEST,
  };
};

const fetchEmployeeExperienceInfoSuccess = (employeeExperienceInfo) => {
  return {
    type: FETCH_EMPLOYEE_EXPERIENCE_INFO_SUCCESS,
    payload: employeeExperienceInfo,
  };
};

const fetchEmployeeExperienceInfoError = (error) => {
  return {
    type: FETCH_EMPLOYEE_EXPERIENCE_INFO_FAILURE,
    payload: error,
  };
};

const editEmployeeExperienceInfoRequest = () => {
  return {
    type: EDIT_EMPLOYEE_EXPERIENCE_INFO_REQUEST,
  };
};

const editEmployeeExperienceInfoSuccess = () => {
  return {
    type: EDIT_EMPLOYEE_EXPERIENCE_INFO_SUCCESS,
  };
};

const editEmployeeExperienceInfoError = (error) => {
  return {
    type: EDIT_EMPLOYEE_EXPERIENCE_INFO_FAILURE,
    payload: error,
  };
};

const deleteEmployeeExperienceInfoRequest = () => {
  return {
    type: DELETE_EMPLOYEE_EXPERIENCE_INFO_REQUEST,
  };
};

const deleteEmployeeExperienceInfoSuccess = () => {
  return {
    type: DELETE_EMPLOYEE_EXPERIENCE_INFO_SUCCESS,
  };
};

const deleteEmployeeExperienceInfoError = (error) => {
  return {
    type: DELETE_EMPLOYEE_EXPERIENCE_INFO_FAILURE,
    payload: error,
  };
};

export default {
  fetchEmployeeExperienceInfoRequest,
  fetchEmployeeExperienceInfoSuccess,
  fetchEmployeeExperienceInfoError,
  editEmployeeExperienceInfoRequest,
  editEmployeeExperienceInfoSuccess,
  editEmployeeExperienceInfoError,
  deleteEmployeeExperienceInfoRequest,
  deleteEmployeeExperienceInfoSuccess,
  deleteEmployeeExperienceInfoError,
};
