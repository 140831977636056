import { toast } from "react-toastify";
import {
  getDepartments,
  createDepartment,
  updateDepartment,
  removeDepartment,
} from "services/department";
import { default as departmentsActions } from "./actions";

const {
  addDepartmentRequest,
  addDepartmentSuccess,
  addDepartmentError,
  fetchDepartmentsRequest,
  fetchDepartmentsSuccess,
  fetchDepartmentsError,
} = departmentsActions;

const fetchDepartments = () => {
  return async (dispatch) => {
    dispatch(fetchDepartmentsRequest());

    try {
      const departments = await getDepartments();
      dispatch(fetchDepartmentsSuccess(departments));
    } catch (err) {
      dispatch(fetchDepartmentsError(err.message));
    }
  };
};

const addDepartment = (data) => {
  return async (dispatch) => {
    dispatch(addDepartmentRequest());

    try {
      await createDepartment(data);
      dispatch(addDepartmentSuccess());
      toast.success("Амжилттай нэмлээ");
    } catch (err) {
      dispatch(addDepartmentError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const editDepartment = (data) => {
  return async (dispatch) => {
    dispatch(addDepartmentRequest());

    try {
      await updateDepartment(data);
      dispatch(addDepartmentSuccess());
      toast.success("Амжилттай заслаа");
    } catch (err) {
      dispatch(addDepartmentError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const deleteDepartment = (data) => {
  return async (dispatch) => {
    dispatch(addDepartmentRequest());

    try {
      await removeDepartment(data);
      dispatch(addDepartmentSuccess());
      toast.success("Амжилттай устгалаа");
    } catch (err) {
      dispatch(addDepartmentError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

export default {
  fetchDepartments,
  addDepartment,
  editDepartment,
  deleteDepartment,
};
