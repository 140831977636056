import { getPositions, createPosition, updatePosition, removePosition } from "services/position";
import { default as positionsActions } from "./actions";

const {
  addPositionRequest,
  addPositionSuccess,
  addPositionError,
  fetchPositionsRequest,
  fetchPositionsSuccess,
  fetchPositionsError,
} = positionsActions;

const fetchPositions = () => {
  return async (dispatch) => {
    dispatch(fetchPositionsRequest());

    try {
      const positions = await getPositions();
      dispatch(fetchPositionsSuccess(positions));
    } catch (err) {
      dispatch(fetchPositionsError(err.message));
    }
  };
};

const addPosition = (data) => {
  return async (dispatch) => {
    dispatch(addPositionRequest());

    try {
      await createPosition(data);
      dispatch(addPositionSuccess());
    } catch (err) {
      dispatch(addPositionError(err.message));
    }
  };
};

const editPosition = (data) => {
  return async (dispatch) => {
    dispatch(addPositionRequest());

    try {
      await updatePosition(data);
      dispatch(addPositionSuccess());
    } catch (err) {
      dispatch(addPositionError(err.message));
    }
  };
};

const deletePosition = (data) => {
  return async (dispatch) => {
    dispatch(addPositionRequest());

    try {
      await removePosition(data);
      dispatch(addPositionSuccess());
    } catch (err) {
      dispatch(addPositionError(err.message));
    }
  };
};

export default { fetchPositions, addPosition, editPosition, deletePosition };