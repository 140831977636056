import { toast } from "react-toastify";
import {
  addContract as createContract,
  editContract as updateContract,
  deleteContract as removeContract,
  getContracts,
} from "services/contract";
import { default as contractsActions } from "./actions";

const {
  addContractRequest,
  addContractSuccess,
  addContractError,
  editContractRequest,
  editContractSuccess,
  editContractError,
  deleteContractRequest,
  deleteContractSuccess,
  deleteContractError,
  fetchContractsRequest,
  fetchContractsSuccess,
  fetchContractsError,
} = contractsActions;

const addContract = (data) => {
  return async (dispatch) => {
    dispatch(addContractRequest());

    try {
      await createContract(data);
      dispatch(addContractSuccess());
      toast.success("Амжилттай нэмлээ");
    } catch (err) {
      dispatch(addContractError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const editContract = (data) => {
  return async (dispatch) => {
    dispatch(editContractRequest());

    try {
      await updateContract(data);
      dispatch(editContractSuccess());
      toast.success("Амжилттай заслаа");
    } catch (err) {
      dispatch(editContractError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const deleteContract = (id) => {
  return async (dispatch) => {
    dispatch(deleteContractRequest());

    try {
      const res = await removeContract(id);
      dispatch(deleteContractSuccess());
      toast.success("Амжилттай устгалаа");
    } catch (err) {
      dispatch(deleteContractError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const fetchContracts = () => {
  return async (dispatch) => {
    dispatch(fetchContractsRequest());

    try {
      const contracts = await getContracts();
      dispatch(fetchContractsSuccess(contracts));
    } catch (err) {
      dispatch(fetchContractsError(err.message));
    }
  };
};

export default { addContract, editContract, deleteContract, fetchContracts };
