const ADD_DOCUMENTFOLDER_REQUEST = "ADD_DOCUMENTFOLDER_REQUEST";
const ADD_DOCUMENTFOLDER_SUCCESS = "ADD_DOCUMENTFOLDER_SUCCESS";
const ADD_DOCUMENTFOLDER_FAILURE = "ADD_DOCUMENTFOLDER_FAILURE";
const FETCH_DOCUMENTFOLDERS_REQUEST = "FETCH_DOCUMENTFOLDERS_REQUEST";
const FETCH_DOCUMENTFOLDERS_SUCCESS = "FETCH_DOCUMENTFOLDERS_SUCCESS";
const FETCH_DOCUMENTFOLDERS_FAILURE = "FETCH_DOCUMENTFOLDERS_FAILURE";

export default {
  ADD_DOCUMENTFOLDER_REQUEST,
  ADD_DOCUMENTFOLDER_SUCCESS,
  ADD_DOCUMENTFOLDER_FAILURE,
  FETCH_DOCUMENTFOLDERS_REQUEST,
  FETCH_DOCUMENTFOLDERS_SUCCESS,
  FETCH_DOCUMENTFOLDERS_FAILURE,
};
