import { toast } from "react-toastify";
import {
  getMonthlyAttendanceArchives,
  clearMonthlyAttendanceArchives as clear,
} from "services/archive";
import { default as monthlyAttendaceArchivesActions } from "./actions";

const {
  fetchMonthlyAttendanceArchivesRequest,
  fetchMonthlyAttendanceArchivesSuccess,
  fetchMonthlyAttendanceArchivesError,
  clearMonthlyAttendanceArchivesRequest,
  clearMonthlyAttendanceArchivesSuccess,
  clearMonthlyAttendanceArchivesError,
} = monthlyAttendaceArchivesActions;

const fetchMonthlyAttendanceArchives = (year, month) => {
  return async (dispatch) => {
    dispatch(fetchMonthlyAttendanceArchivesRequest());

    try {
      const monthlyAttendanceArchives = await getMonthlyAttendanceArchives(
        year,
        month
      );
      dispatch(
        fetchMonthlyAttendanceArchivesSuccess(monthlyAttendanceArchives)
      );
    } catch (err) {
      dispatch(fetchMonthlyAttendanceArchivesError(err.message));
    }
  };
};

const clearMonthlyAttendanceArchives = (year, month) => {
  return async (dispatch) => {
    dispatch(clearMonthlyAttendanceArchivesRequest());

    try {
      await clear(year, month);
      dispatch(clearMonthlyAttendanceArchivesSuccess());
      toast.success("Амжилттай цэвэрлэлээ");
    } catch (err) {
      dispatch(clearMonthlyAttendanceArchivesError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

export default {
  fetchMonthlyAttendanceArchives,
  clearMonthlyAttendanceArchives,
};
