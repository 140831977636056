const ADD_ORDER_REQUEST = "ADD_ORDER_REQUEST";
const ADD_ORDER_SUCCESS = "ADD_ORDER_SUCCESS";
const ADD_ORDER_FAILURE = "ADD_ORDER_FAILURE";
const EDIT_ORDER_REQUEST = "EDIT_ORDER_REQUEST";
const EDIT_ORDER_SUCCESS = "EDIT_ORDER_SUCCESS";
const EDIT_ORDER_FAILURE = "EDIT_ORDER_FAILURE";
const DELETE_ORDER_REQUEST = "DELETE_ORDER_REQUEST";
const DELETE_ORDER_SUCCESS = "DELETE_ORDER_SUCCESS";
const DELETE_ORDER_FAILURE = "DELETE_ORDER_FAILURE";
const FETCH_ORDERS_REQUEST = "FETCH_ORDERS_REQUEST";
const FETCH_ORDERS_SUCCESS = "FETCH_ORDERS_SUCCESS";
const FETCH_ORDERS_FAILURE = "FETCH_ORDERS_FAILURE";

export default {
  ADD_ORDER_REQUEST,
  ADD_ORDER_SUCCESS,
  ADD_ORDER_FAILURE,
  EDIT_ORDER_REQUEST,
  EDIT_ORDER_SUCCESS,
  EDIT_ORDER_FAILURE,
  DELETE_ORDER_REQUEST,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_FAILURE,
  FETCH_ORDERS_REQUEST,
  FETCH_ORDERS_SUCCESS,
  FETCH_ORDERS_FAILURE,
};
