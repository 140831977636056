import { getRaiseTypes } from "services/raiseType";
import { default as raiseTypesActions } from "./actions";

const {
  fetchRaiseTypesRequest,
  fetchRaiseTypesSuccess,
  fetchRaiseTypesError,
} = raiseTypesActions;

const fetchRaiseTypes = () => {
  return async (dispatch) => {
    dispatch(fetchRaiseTypesRequest());

    try {
      const raiseTypes = await getRaiseTypes();
      dispatch(fetchRaiseTypesSuccess(raiseTypes));
    } catch (err) {
      dispatch(fetchRaiseTypesError(err.message));
    }
  };
};

export default {
  fetchRaiseTypes,
};
