import { default as types } from "./types";

const {
  EDIT_USER_PASSWORD_REQUEST,
  EDIT_USER_PASSWORD_SUCCESS,
  EDIT_USER_PASSWORD_FAILURE,
  EDIT_USER_PASSWORD_BY_EMAIL_REQUEST,
  EDIT_USER_PASSWORD_BY_EMAIL_SUCCESS,
  EDIT_USER_PASSWORD_BY_EMAIL_FAILURE,
  EDIT_USER_PERMISSION_REQUEST,
  EDIT_USER_PERMISSION_SUCCESS,
  EDIT_USER_PERMISSION_FAILURE,
  FETCH_USER_PERMISSIONS_REQUEST,
  FETCH_USER_PERMISSIONS_SUCCESS,
  FETCH_USER_PERMISSIONS_FAILURE,
} = types;

const initialState = {
  isLoading: false,
  list: [],
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_USER_PASSWORD_REQUEST:
    case EDIT_USER_PASSWORD_BY_EMAIL_REQUEST:
    case EDIT_USER_PERMISSION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case EDIT_USER_PASSWORD_SUCCESS:
    case EDIT_USER_PASSWORD_BY_EMAIL_SUCCESS:
    case EDIT_USER_PERMISSION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case EDIT_USER_PASSWORD_FAILURE:
    case EDIT_USER_PASSWORD_BY_EMAIL_FAILURE:
    case EDIT_USER_PERMISSION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case FETCH_USER_PERMISSIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_USER_PERMISSIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: action.payload,
        error: "",
      };
    case FETCH_USER_PERMISSIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        list: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
