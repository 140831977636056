import { default as documentfilesTypes } from "./types";

const {
  ADD_DOCUMENTFILE_REQUEST,
  ADD_DOCUMENTFILE_SUCCESS,
  ADD_DOCUMENTFILE_FAILURE,
  FETCH_DOCUMENTFILES_REQUEST,
  FETCH_DOCUMENTFILES_SUCCESS,
  FETCH_DOCUMENTFILES_FAILURE,
} = documentfilesTypes;

const initialState = {
  isLoading: false,
  list: [],
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_DOCUMENTFILE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_DOCUMENTFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case ADD_DOCUMENTFILE_FAILURE:
      return {
        ...state,
        isLoading: false,
        list: [],
        error: action.payload,
      };
    case FETCH_DOCUMENTFILES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_DOCUMENTFILES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: action.payload,
        error: "",
      };
    case FETCH_DOCUMENTFILES_FAILURE:
      return {
        ...state,
        isLoading: false,
        list: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
