import { default as insurCompaniesTypes } from "./types";

const {
  FETCH_INSUR_COMPANIES_REQUEST,
  FETCH_INSUR_COMPANIES_SUCCESS,
  FETCH_INSUR_COMPANIES_FAILURE,
} = insurCompaniesTypes;

const fetchInsurCompaniesRequest = () => {
  return {
    type: FETCH_INSUR_COMPANIES_REQUEST,
  };
};

const fetchInsurCompaniesSuccess = (insurCompanies) => {
  return {
    type: FETCH_INSUR_COMPANIES_SUCCESS,
    payload: insurCompanies,
  };
};

const fetchInsurCompaniesError = (error) => {
  return {
    type: FETCH_INSUR_COMPANIES_FAILURE,
    payload: error,
  };
};

export default {
  fetchInsurCompaniesRequest,
  fetchInsurCompaniesSuccess,
  fetchInsurCompaniesError,
};
