import instance from "./axios.base";

const getFamilyInfo = async (id) => {
  const res = await instance.get(`/empfamily/${id}`);
  return res.data;
};

const editFamilyInfo = async (data) => {
  await instance.put("/empfamily", data);
};

const deleteFamilyInfo = async (id) => {
  await instance.delete(`/empfamily/${id}`);
};

export { getFamilyInfo, editFamilyInfo, deleteFamilyInfo };
