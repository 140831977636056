import { default as positionsTypes } from "./types";

const {
  ADD_POSITION_REQUEST,
  ADD_POSITION_SUCCESS,
  ADD_POSITION_FAILURE,
  FETCH_POSITIONS_REQUEST,
  FETCH_POSITIONS_SUCCESS,
  FETCH_POSITIONS_FAILURE,
} = positionsTypes;

const addPositionRequest = () => {
  return {
    type: ADD_POSITION_REQUEST,
  };
};

const addPositionSuccess = () => {
  return {
    type: ADD_POSITION_SUCCESS,
  };
};

const addPositionError = (error) => {
  return {
    type: ADD_POSITION_FAILURE,
    payload: error,
  };
};

const fetchPositionsRequest = () => {
  return {
    type: FETCH_POSITIONS_REQUEST,
  };
};

const fetchPositionsSuccess = (positions) => {
  return {
    type: FETCH_POSITIONS_SUCCESS,
    payload: positions,
  };
};

const fetchPositionsError = (error) => {
  return {
    type: FETCH_POSITIONS_FAILURE,
    payload: error,
  };
};

export default {
  addPositionRequest,
  addPositionSuccess,
  addPositionError,
  fetchPositionsRequest,
  fetchPositionsSuccess,
  fetchPositionsError,
};
