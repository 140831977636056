const ADD_PRE_JOURNAL_REQUEST = "ADD_PRE_JOURNAL_REQUEST";
const ADD_PRE_JOURNAL_SUCCESS = "ADD_PRE_JOURNAL_SUCCESS";
const ADD_PRE_JOURNAL_FAILURE = "ADD_PRE_JOURNAL_FAILURE";
const DELETE_PRE_JOURNAL_REQUEST = "DELETE_PRE_JOURNAL_REQUEST";
const DELETE_PRE_JOURNAL_SUCCESS = "DELETE_PRE_JOURNAL_SUCCESS";
const DELETE_PRE_JOURNAL_FAILURE = "DELETE_PRE_JOURNAL_FAILURE";
const FETCH_PRE_JOURNALS_REQUEST = "FETCH_PRE_JOURNALS_REQUEST";
const FETCH_PRE_JOURNALS_SUCCESS = "FETCH_PRE_JOURNALS_SUCCESS";
const FETCH_PRE_JOURNALS_FAILURE = "FETCH_PRE_JOURNALS_FAILURE";
const FETCH_PRE_JOURNAL_REQUEST = "FETCH_PRE_JOURNAL_REQUEST";
const FETCH_PRE_JOURNAL_SUCCESS = "FETCH_PRE_JOURNAL_SUCCESS";
const FETCH_PRE_JOURNAL_FAILURE = "FETCH_PRE_JOURNAL_FAILURE";
const ARCHIVE_PRE_JOURNAL_REQUEST = "ARCHIVE_PRE_JOURNAL_REQUEST";
const ARCHIVE_PRE_JOURNAL_SUCCESS = "ARCHIVE_PRE_JOURNAL_SUCCESS";
const ARCHIVE_PRE_JOURNAL_FAILURE = "ARCHIVE_PRE_JOURNAL_FAILURE";
const CALCULATE_PRE_JOURNAL_REQUEST = "CALCULATE_PRE_JOURNAL_REQUEST";
const CALCULATE_PRE_JOURNAL_SUCCESS = "CALCULATE_PRE_JOURNAL_SUCCESS";
const CALCULATE_PRE_JOURNAL_FAILURE = "CALCULATE_PRE_JOURNAL_FAILURE";
const CLEAR_PRE_JOURNAL = "CLEAR_PRE_JOURNAL";

export default {
  ADD_PRE_JOURNAL_REQUEST,
  ADD_PRE_JOURNAL_SUCCESS,
  ADD_PRE_JOURNAL_FAILURE,
  DELETE_PRE_JOURNAL_REQUEST,
  DELETE_PRE_JOURNAL_SUCCESS,
  DELETE_PRE_JOURNAL_FAILURE,
  FETCH_PRE_JOURNALS_REQUEST,
  FETCH_PRE_JOURNALS_SUCCESS,
  FETCH_PRE_JOURNALS_FAILURE,
  FETCH_PRE_JOURNAL_REQUEST,
  FETCH_PRE_JOURNAL_SUCCESS,
  FETCH_PRE_JOURNAL_FAILURE,
  ARCHIVE_PRE_JOURNAL_REQUEST,
  ARCHIVE_PRE_JOURNAL_SUCCESS,
  ARCHIVE_PRE_JOURNAL_FAILURE,
  CALCULATE_PRE_JOURNAL_REQUEST,
  CALCULATE_PRE_JOURNAL_SUCCESS,
  CALCULATE_PRE_JOURNAL_FAILURE,
  CLEAR_PRE_JOURNAL,
};
