import { default as types } from "./types";

const {
  FETCH_HALF_MONTHLY_ATTENDANCE_ARCHIVES_REQUEST,
  FETCH_HALF_MONTHLY_ATTENDANCE_ARCHIVES_SUCCESS,
  FETCH_HALF_MONTHLY_ATTENDANCE_ARCHIVES_FAILURE,
  CLEAR_HALF_MONTHLY_ATTENDANCE_ARCHIVES_REQUEST,
  CLEAR_HALF_MONTHLY_ATTENDANCE_ARCHIVES_SUCCESS,
  CLEAR_HALF_MONTHLY_ATTENDANCE_ARCHIVES_FAILURE,
} = types;

const fetchHalfMonthlyAttendanceArchivesRequest = () => {
  return {
    type: FETCH_HALF_MONTHLY_ATTENDANCE_ARCHIVES_REQUEST,
  };
};

const fetchHalfMonthlyAttendanceArchivesSuccess = (
  halfMonthlyAttendanceArchives
) => {
  return {
    type: FETCH_HALF_MONTHLY_ATTENDANCE_ARCHIVES_SUCCESS,
    payload: halfMonthlyAttendanceArchives,
  };
};

const fetchHalfMonthlyAttendanceArchivesError = (error) => {
  return {
    type: FETCH_HALF_MONTHLY_ATTENDANCE_ARCHIVES_FAILURE,
    payload: error,
  };
};
const clearHalfMonthlyAttendanceArchivesRequest = () => {
  return {
    type: CLEAR_HALF_MONTHLY_ATTENDANCE_ARCHIVES_REQUEST,
  };
};

const clearHalfMonthlyAttendanceArchivesSuccess = (
  halfMonthlyAttendanceArchives
) => {
  return {
    type: CLEAR_HALF_MONTHLY_ATTENDANCE_ARCHIVES_SUCCESS,
    payload: halfMonthlyAttendanceArchives,
  };
};

const clearHalfMonthlyAttendanceArchivesError = (error) => {
  return {
    type: CLEAR_HALF_MONTHLY_ATTENDANCE_ARCHIVES_FAILURE,
    payload: error,
  };
};

export default {
  fetchHalfMonthlyAttendanceArchivesRequest,
  fetchHalfMonthlyAttendanceArchivesSuccess,
  fetchHalfMonthlyAttendanceArchivesError,
  clearHalfMonthlyAttendanceArchivesRequest,
  clearHalfMonthlyAttendanceArchivesSuccess,
  clearHalfMonthlyAttendanceArchivesError,
};
