import { getNDSH7AReport } from "services/report";
import { default as NDSH7AReportActions } from "./actions";

const {
  fetchNDSH7AReportRequest,
  fetchNDSH7AReportSuccess,
  fetchNDSH7AReportError,
} = NDSH7AReportActions;

const fetchNDSH7AReport = () => {
  return async (dispatch) => {
    dispatch(fetchNDSH7AReportRequest());

    try {
      const report = await getNDSH7AReport();
      dispatch(fetchNDSH7AReportSuccess(report));
    } catch (err) {
      dispatch(fetchNDSH7AReportError(err.message));
    }
  };
};

export default {
  fetchNDSH7AReport,
};
