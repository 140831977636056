import { default as teammembersTypes } from "./types";

const {
  ADD_TEAMMEMBER_REQUEST,
  ADD_TEAMMEMBER_SUCCESS,
  ADD_TEAMMEMBER_FAILURE,
  FETCH_TEAMMEMBERS_REQUEST,
  FETCH_TEAMMEMBERS_SUCCESS,
  FETCH_TEAMMEMBERS_FAILURE,
} = teammembersTypes;

const addTeamMemberRequest = () => {
  return {
    type: ADD_TEAMMEMBER_REQUEST,
  };
};

const addTeamMemberSuccess = (teammembers) => {
  return {
    type: ADD_TEAMMEMBER_SUCCESS,
  };
};

const addTeamMemberError = (error) => {
  return {
    type: ADD_TEAMMEMBER_FAILURE,
    payload: error,
  };
};

const fetchTeamMembersRequest = () => {
  return {
    type: FETCH_TEAMMEMBERS_REQUEST,
  };
};

const fetchTeamMembersSuccess = (teammembers) => {
  return {
    type: FETCH_TEAMMEMBERS_SUCCESS,
    payload: teammembers,
  };
};

const fetchTeamMembersError = (error) => {
  return {
    type: FETCH_TEAMMEMBERS_FAILURE,
    payload: error,
  };
};

export default {
  addTeamMemberRequest,
  addTeamMemberSuccess,
  addTeamMemberError,
  fetchTeamMembersRequest,
  fetchTeamMembersSuccess,
  fetchTeamMembersError,
};
