import { getPeople } from "services/employee";
import { default as peopleActions } from "./actions";

const {
  fetchPeopleRequest,
  fetchPeopleSuccess,
  fetchPeopleError,
} = peopleActions;

const fetchPeople = () => {
  return async (dispatch) => {
    dispatch(fetchPeopleRequest());

    try {
      const people = await getPeople();
      dispatch(fetchPeopleSuccess(people));
    } catch (err) {
      dispatch(fetchPeopleError(err.message));
    }
  };
};

export default { fetchPeople };
