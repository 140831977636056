import { default as employeeTalentInfoTypes } from "./types";

const {
  FETCH_EMPLOYEE_TALENT_INFO_REQUEST,
  FETCH_EMPLOYEE_TALENT_INFO_SUCCESS,
  FETCH_EMPLOYEE_TALENT_INFO_FAILURE,
  EDIT_EMPLOYEE_TALENT_INFO_REQUEST,
  EDIT_EMPLOYEE_TALENT_INFO_SUCCESS,
  EDIT_EMPLOYEE_TALENT_INFO_FAILURE,
  DELETE_EMPLOYEE_TALENT_INFO_REQUEST,
  DELETE_EMPLOYEE_TALENT_INFO_SUCCESS,
  DELETE_EMPLOYEE_TALENT_INFO_FAILURE,
} = employeeTalentInfoTypes;

const fetchEmployeeTalentInfoRequest = () => {
  return {
    type: FETCH_EMPLOYEE_TALENT_INFO_REQUEST,
  };
};

const fetchEmployeeTalentInfoSuccess = (employeeTalentInfo) => {
  return {
    type: FETCH_EMPLOYEE_TALENT_INFO_SUCCESS,
    payload: employeeTalentInfo,
  };
};

const fetchEmployeeTalentInfoError = (error) => {
  return {
    type: FETCH_EMPLOYEE_TALENT_INFO_FAILURE,
    payload: error,
  };
};

const editEmployeeTalentInfoRequest = () => {
  return {
    type: EDIT_EMPLOYEE_TALENT_INFO_REQUEST,
  };
};

const editEmployeeTalentInfoSuccess = () => {
  return {
    type: EDIT_EMPLOYEE_TALENT_INFO_SUCCESS,
  };
};

const editEmployeeTalentInfoError = (error) => {
  return {
    type: EDIT_EMPLOYEE_TALENT_INFO_FAILURE,
    payload: error,
  };
};

const deleteEmployeeTalentInfoRequest = () => {
  return {
    type: DELETE_EMPLOYEE_TALENT_INFO_REQUEST,
  };
};

const deleteEmployeeTalentInfoSuccess = () => {
  return {
    type: DELETE_EMPLOYEE_TALENT_INFO_SUCCESS,
  };
};

const deleteEmployeeTalentInfoError = (error) => {
  return {
    type: DELETE_EMPLOYEE_TALENT_INFO_FAILURE,
    payload: error,
  };
};

export default {
  fetchEmployeeTalentInfoRequest,
  fetchEmployeeTalentInfoSuccess,
  fetchEmployeeTalentInfoError,
  editEmployeeTalentInfoRequest,
  editEmployeeTalentInfoSuccess,
  editEmployeeTalentInfoError,
  deleteEmployeeTalentInfoRequest,
  deleteEmployeeTalentInfoSuccess,
  deleteEmployeeTalentInfoError,
};
