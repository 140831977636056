const FETCH_EMPLOYEE_HEALTH_INFO_REQUEST = "FETCH_EMPLOYEE_HEALTH_INFO_REQUEST";
const FETCH_EMPLOYEE_HEALTH_INFO_SUCCESS = "FETCH_EMPLOYEE_HEALTH_INFO_SUCCESS";
const FETCH_EMPLOYEE_HEALTH_INFO_FAILURE = "FETCH_EMPLOYEE_HEALTH_INFO_FAILURE";
const ADD_EMPLOYEE_HEALTH_INFO_REQUEST = "ADD_EMPLOYEE_HEALTH_INFO_REQUEST";
const ADD_EMPLOYEE_HEALTH_INFO_SUCCESS = "ADD_EMPLOYEE_HEALTH_INFO_SUCCESS";
const ADD_EMPLOYEE_HEALTH_INFO_FAILURE = "ADD_EMPLOYEE_HEALTH_INFO_FAILURE";
const EDIT_EMPLOYEE_HEALTH_INFO_REQUEST = "EDIT_EMPLOYEE_HEALTH_INFO_REQUEST";
const EDIT_EMPLOYEE_HEALTH_INFO_SUCCESS = "EDIT_EMPLOYEE_HEALTH_INFO_SUCCESS";
const EDIT_EMPLOYEE_HEALTH_INFO_FAILURE = "EDIT_EMPLOYEE_HEALTH_INFO_FAILURE";
const DELETE_EMPLOYEE_HEALTH_INFO_REQUEST =
  "DELETE_EMPLOYEE_HEALTH_INFO_REQUEST";
const DELETE_EMPLOYEE_HEALTH_INFO_SUCCESS =
  "DELETE_EMPLOYEE_HEALTH_INFO_SUCCESS";
const DELETE_EMPLOYEE_HEALTH_INFO_FAILURE =
  "DELETE_EMPLOYEE_HEALTH_INFO_FAILURE";

export default {
  FETCH_EMPLOYEE_HEALTH_INFO_REQUEST,
  FETCH_EMPLOYEE_HEALTH_INFO_SUCCESS,
  FETCH_EMPLOYEE_HEALTH_INFO_FAILURE,
  ADD_EMPLOYEE_HEALTH_INFO_REQUEST,
  ADD_EMPLOYEE_HEALTH_INFO_SUCCESS,
  ADD_EMPLOYEE_HEALTH_INFO_FAILURE,
  EDIT_EMPLOYEE_HEALTH_INFO_REQUEST,
  EDIT_EMPLOYEE_HEALTH_INFO_SUCCESS,
  EDIT_EMPLOYEE_HEALTH_INFO_FAILURE,
  DELETE_EMPLOYEE_HEALTH_INFO_REQUEST,
  DELETE_EMPLOYEE_HEALTH_INFO_SUCCESS,
  DELETE_EMPLOYEE_HEALTH_INFO_FAILURE,
};
