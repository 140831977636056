import instance from "./axios.base";

const resetPasswordPost = async (data) => {
  const res = await instance.post("/resetpassword", data);
  return res.data;
};

const checkGuidGet = async (guid) => {
  const res = await instance.get(`/resetpassword/${guid}`);
  return res.data;
};

const resetPasswordPut = async (data) => {
  const res = await instance.put(`/resetpassword`, data);
  return res.data;
};

export {
  resetPasswordPost,
  checkGuidGet,
  resetPasswordPut,
};