import { getOfficePrograms } from "services/officeProgram";
import { default as officeProgramsActions } from "./actions";

const {
  fetchOfficeProgramsRequest,
  fetchOfficeProgramsSuccess,
  fetchOfficeProgramsError,
} = officeProgramsActions;

const fetchOfficePrograms = () => {
  return async (dispatch) => {
    dispatch(fetchOfficeProgramsRequest());

    try {
      const officePrograms = await getOfficePrograms();
      dispatch(fetchOfficeProgramsSuccess(officePrograms));
    } catch (err) {
      dispatch(fetchOfficeProgramsError(err.message));
    }
  };
};

export default { fetchOfficePrograms };
