import { default as eventsTypes } from "./types";

const {
  ADD_EVENT_REQUEST,
  ADD_EVENT_SUCCESS,
  ADD_EVENT_FAILURE,
  FETCH_EVENTS_REQUEST,
  FETCH_EVENTS_SUCCESS,
  FETCH_EVENTS_FAILURE,
} = eventsTypes;

const addEventRequest = () => {
  return {
    type: ADD_EVENT_REQUEST,
  };
};

const addEventSuccess = (events) => {
  return {
    type: ADD_EVENT_SUCCESS,
  };
};

const addEventError = (error) => {
  return {
    type: ADD_EVENT_FAILURE,
    payload: error,
  };
};

const fetchEventsRequest = () => {
  return {
    type: FETCH_EVENTS_REQUEST,
  };
};

const fetchEventsSuccess = (events) => {
  return {
    type: FETCH_EVENTS_SUCCESS,
    payload: events,
  };
};

const fetchEventsError = (error) => {
  return {
    type: FETCH_EVENTS_FAILURE,
    payload: error,
  };
};

export default {
  addEventRequest,
  addEventSuccess,
  addEventError,
  fetchEventsRequest,
  fetchEventsSuccess,
  fetchEventsError,
};
