import instance from "./axios.base";

const getDashboardAge = async () => {
  const res = await instance.get("/dashboard/age");
  
  return res.data;
};

const getDashboardEmpCount = async () => {
  const res = await instance.get("/dashboard/countemployee");
  
  return res.data;
};

const getDashboardDegree = async () => {
  const res = await instance.get("/dashboard/degree");
  
  return res.data;
};

const getDashboardGender = async () => {
  const res = await instance.get("/dashboard/gender");
  
  return res.data;
};

const getDashboardMarital = async () => {
  const res = await instance.get("/dashboard/maritalstatus");
  
  return res.data;
};

const getDashboardSalary = async () => {
  const res = await instance.get("/dashboard/salary");
  
  return res.data;
};

const getDashboardWorkYear = async () => {
  const res = await instance.get("/dashboard/workedyear");
  
  return res.data;
};

export { getDashboardAge, getDashboardEmpCount, getDashboardDegree, getDashboardGender, getDashboardMarital, getDashboardSalary, getDashboardWorkYear };