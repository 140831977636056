import { toast } from "react-toastify";
import {
  getOfficeSkillInfo,
  editOfficeSkillInfo,
  deleteOfficeSkillInfo,
} from "services/officeSkillInfo";
import { default as employeeOfficeSkillInfoActions } from "./actions";

const {
  fetchEmployeeOfficeSkillInfoRequest,
  fetchEmployeeOfficeSkillInfoSuccess,
  fetchEmployeeOfficeSkillInfoError,
  editEmployeeOfficeSkillInfoRequest,
  editEmployeeOfficeSkillInfoSuccess,
  editEmployeeOfficeSkillInfoError,
  deleteEmployeeOfficeSkillInfoRequest,
  deleteEmployeeOfficeSkillInfoSuccess,
  deleteEmployeeOfficeSkillInfoError,
} = employeeOfficeSkillInfoActions;

const fetchEmployeeOfficeSkillInfo = (data) => {
  return async (dispatch) => {
    dispatch(fetchEmployeeOfficeSkillInfoRequest());

    try {
      const employeeOfficeSkillInfo = await getOfficeSkillInfo(data);
      dispatch(fetchEmployeeOfficeSkillInfoSuccess(employeeOfficeSkillInfo));
    } catch (err) {
      dispatch(fetchEmployeeOfficeSkillInfoError(err.message));
    }
  };
};

const editEmployeeOfficeSkillInfo = (data) => {
  return async (dispatch) => {
    dispatch(editEmployeeOfficeSkillInfoRequest());

    try {
      await editOfficeSkillInfo(data);
      dispatch(editEmployeeOfficeSkillInfoSuccess());
      toast.success("Амжилттай заслаа");
    } catch (err) {
      dispatch(editEmployeeOfficeSkillInfoError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const deleteEmployeeOfficeSkillInfo = (id) => {
  return async (dispatch) => {
    dispatch(deleteEmployeeOfficeSkillInfoRequest());

    try {
      await deleteOfficeSkillInfo(id);
      dispatch(deleteEmployeeOfficeSkillInfoSuccess());
      toast.success("Амжилттай устгалаа");
    } catch (err) {
      dispatch(deleteEmployeeOfficeSkillInfoError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

export default {
  fetchEmployeeOfficeSkillInfo,
  editEmployeeOfficeSkillInfo,
  deleteEmployeeOfficeSkillInfo,
};
