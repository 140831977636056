const FETCH_DASHBOARDS_REQUEST = "FETCH_DASHBOARDS_REQUEST";
const FETCH_DASHBOARDAGE_SUCCESS = "FETCH_DASHBOARDAGE_SUCCESS";
const FETCH_DASHBOARDAGE_FAILURE = "FETCH_DASHBOARDAGE_FAILURE";
const FETCH_DASHBOARDEMPCOUNT_SUCCESS = "FETCH_DASHBOARDEMPCOUNT_SUCCESS";
const FETCH_DASHBOARDEMPCOUNT_FAILURE = "FETCH_DASHBOARDEMPCOUNT_FAILURE";
const FETCH_DASHBOARDDEGREE_SUCCESS = "FETCH_DASHBOARDDEGREE_SUCCESS";
const FETCH_DASHBOARDDEGREE_FAILURE = "FETCH_DASHBOARDDEGREE_FAILURE";
const FETCH_DASHBOARDGENDER_SUCCESS = "FETCH_DASHBOARDGENDER_SUCCESS";
const FETCH_DASHBOARDGENDER_FAILURE = "FETCH_DASHBOARDGENDER_FAILURE";
const FETCH_DASHBOARDMARITAL_SUCCESS = "FETCH_DASHBOARDMARITAL_SUCCESS";
const FETCH_DASHBOARDMARITAL_FAILURE = "FETCH_DASHBOARDMARITAL_FAILURE";
const FETCH_DASHBOARDSALARY_SUCCESS = "FETCH_DASHBOARDSALARY_SUCCESS";
const FETCH_DASHBOARDSALARY_FAILURE = "FETCH_DASHBOARDSALARY_FAILURE";
const FETCH_DASHBOARDWORKEDYEAR_SUCCESS = "FETCH_DASHBOARDWORKEDYEAR_SUCCESS";
const FETCH_DASHBOARDWORKEDYEAR_FAILURE = "FETCH_DASHBOARDWORKEDYEAR_FAILURE";

export default {
  FETCH_DASHBOARDS_REQUEST,
  FETCH_DASHBOARDAGE_SUCCESS,
  FETCH_DASHBOARDAGE_FAILURE,
  FETCH_DASHBOARDEMPCOUNT_SUCCESS,
  FETCH_DASHBOARDEMPCOUNT_FAILURE,
  FETCH_DASHBOARDDEGREE_SUCCESS,
  FETCH_DASHBOARDDEGREE_FAILURE,
  FETCH_DASHBOARDGENDER_SUCCESS,
  FETCH_DASHBOARDGENDER_FAILURE,
  FETCH_DASHBOARDMARITAL_SUCCESS,
  FETCH_DASHBOARDMARITAL_FAILURE,
  FETCH_DASHBOARDSALARY_SUCCESS,
  FETCH_DASHBOARDSALARY_FAILURE,
  FETCH_DASHBOARDWORKEDYEAR_SUCCESS,
  FETCH_DASHBOARDWORKEDYEAR_FAILURE
};
