import { getSectors, createSector, updateSector, removeSector } from "services/sector";
import { default as sectorsActions } from "./actions";

const {
  addSectorRequest,
  addSectorSuccess,
  addSectorError,
  fetchSectorsRequest,
  fetchSectorsSuccess,
  fetchSectorsError,
} = sectorsActions;

const fetchSectors = () => {
  return async (dispatch) => {
    dispatch(fetchSectorsRequest());

    try {
      const sectors = await getSectors();
      dispatch(fetchSectorsSuccess(sectors));
    } catch (err) {
      dispatch(fetchSectorsError(err.message));
    }
  };
};

const addSector = (data) => {
  return async (dispatch) => {
    dispatch(addSectorRequest());

    try {
      await createSector(data);
      dispatch(addSectorSuccess());
    } catch (err) {
      dispatch(addSectorError(err.message));
    }
  };
};

const editSector = (data) => {
  return async (dispatch) => {
    dispatch(addSectorRequest());

    try {
      await updateSector(data);
      dispatch(addSectorSuccess());
    } catch (err) {
      dispatch(addSectorError(err.message));
    }
  }; 
};

const deleteSector = (data) => {
  return async (dispatch) => {
    dispatch(addSectorRequest());

    try {
      await removeSector(data);
      dispatch(addSectorSuccess());
    } catch (err) {
      dispatch(addSectorError(err.message));
    }
  }; 
};

export default { fetchSectors, addSector, editSector, deleteSector };
