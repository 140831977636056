const ADD_EMP_DEDUCTION_REQUEST = "ADD_EMP_DEDUCTION_REQUEST";
const ADD_EMP_DEDUCTION_SUCCESS = "ADD_EMP_DEDUCTION_SUCCESS";
const ADD_EMP_DEDUCTION_FAILURE = "ADD_EMP_DEDUCTION_FAILURE";
const EDIT_EMP_DEDUCTION_REQUEST = "EDIT_EMP_DEDUCTION_REQUEST";
const EDIT_EMP_DEDUCTION_SUCCESS = "EDIT_EMP_DEDUCTION_SUCCESS";
const EDIT_EMP_DEDUCTION_FAILURE = "EDIT_EMP_DEDUCTION_FAILURE";
const DELETE_EMP_DEDUCTION_REQUEST = "DELETE_EMP_DEDUCTION_REQUEST";
const DELETE_EMP_DEDUCTION_SUCCESS = "DELETE_EMP_DEDUCTION_SUCCESS";
const DELETE_EMP_DEDUCTION_FAILURE = "DELETE_EMP_DEDUCTION_FAILURE";
const FETCH_EMP_DEDUCTIONS_REQUEST = "FETCH_EMP_DEDUCTIONS_REQUEST";
const FETCH_EMP_DEDUCTIONS_SUCCESS = "FETCH_EMP_DEDUCTIONS_SUCCESS";
const FETCH_EMP_DEDUCTIONS_FAILURE = "FETCH_EMP_DEDUCTIONS_FAILURE";
const CLEAR_EMP_DEDUCTION = "CLEAR_EMP_DEDUCTION";

export default {
  ADD_EMP_DEDUCTION_REQUEST,
  ADD_EMP_DEDUCTION_SUCCESS,
  ADD_EMP_DEDUCTION_FAILURE,
  EDIT_EMP_DEDUCTION_REQUEST,
  EDIT_EMP_DEDUCTION_SUCCESS,
  EDIT_EMP_DEDUCTION_FAILURE,
  DELETE_EMP_DEDUCTION_REQUEST,
  DELETE_EMP_DEDUCTION_SUCCESS,
  DELETE_EMP_DEDUCTION_FAILURE,
  FETCH_EMP_DEDUCTIONS_REQUEST,
  FETCH_EMP_DEDUCTIONS_SUCCESS,
  FETCH_EMP_DEDUCTIONS_FAILURE,
  CLEAR_EMP_DEDUCTION,
};
