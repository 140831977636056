const ADD_PREPAYMENT_JOURNAL_REQUEST = "ADD_PREPAYMENT_JOURNAL_REQUEST";
const ADD_PREPAYMENT_JOURNAL_SUCCESS = "ADD_PREPAYMENT_JOURNAL_SUCCESS";
const ADD_PREPAYMENT_JOURNAL_FAILURE = "ADD_PREPAYMENT_JOURNAL_FAILURE";
const DELETE_PREPAYMENT_JOURNAL_REQUEST = "DELETE_PREPAYMENT_JOURNAL_REQUEST";
const DELETE_PREPAYMENT_JOURNAL_SUCCESS = "DELETE_PREPAYMENT_JOURNAL_SUCCESS";
const DELETE_PREPAYMENT_JOURNAL_FAILURE = "DELETE_PREPAYMENT_JOURNAL_FAILURE";
const FETCH_PREPAYMENT_JOURNALS_REQUEST = "FETCH_PREPAYMENT_JOURNALS_REQUEST";
const FETCH_PREPAYMENT_JOURNALS_SUCCESS = "FETCH_PREPAYMENT_JOURNALS_SUCCESS";
const FETCH_PREPAYMENT_JOURNALS_FAILURE = "FETCH_PREPAYMENT_JOURNALS_FAILURE";
const FETCH_PREPAYMENT_JOURNAL_REQUEST = "FETCH_PREPAYMENT_JOURNAL_REQUEST";
const FETCH_PREPAYMENT_JOURNAL_SUCCESS = "FETCH_PREPAYMENT_JOURNAL_SUCCESS";
const FETCH_PREPAYMENT_JOURNAL_FAILURE = "FETCH_PREPAYMENT_JOURNAL_FAILURE";
const ARCHIVE_PREPAYMENT_JOURNAL_REQUEST = "ARCHIVE_PREPAYMENT_JOURNAL_REQUEST";
const ARCHIVE_PREPAYMENT_JOURNAL_SUCCESS = "ARCHIVE_PREPAYMENT_JOURNAL_SUCCESS";
const ARCHIVE_PREPAYMENT_JOURNAL_FAILURE = "ARCHIVE_PREPAYMENT_JOURNAL_FAILURE";
const CALCULATE_PREPAYMENT_JOURNAL_REQUEST =
  "CALCULATE_PREPAYMENT_JOURNAL_REQUEST";
const CALCULATE_PREPAYMENT_JOURNAL_SUCCESS =
  "CALCULATE_PREPAYMENT_JOURNAL_SUCCESS";
const CALCULATE_PREPAYMENT_JOURNAL_FAILURE =
  "CALCULATE_PREPAYMENT_JOURNAL_FAILURE";

export default {
  ADD_PREPAYMENT_JOURNAL_REQUEST,
  ADD_PREPAYMENT_JOURNAL_SUCCESS,
  ADD_PREPAYMENT_JOURNAL_FAILURE,
  DELETE_PREPAYMENT_JOURNAL_REQUEST,
  DELETE_PREPAYMENT_JOURNAL_SUCCESS,
  DELETE_PREPAYMENT_JOURNAL_FAILURE,
  FETCH_PREPAYMENT_JOURNALS_REQUEST,
  FETCH_PREPAYMENT_JOURNALS_SUCCESS,
  FETCH_PREPAYMENT_JOURNALS_FAILURE,
  FETCH_PREPAYMENT_JOURNAL_REQUEST,
  FETCH_PREPAYMENT_JOURNAL_SUCCESS,
  FETCH_PREPAYMENT_JOURNAL_FAILURE,
  ARCHIVE_PREPAYMENT_JOURNAL_REQUEST,
  ARCHIVE_PREPAYMENT_JOURNAL_SUCCESS,
  ARCHIVE_PREPAYMENT_JOURNAL_FAILURE,
  CALCULATE_PREPAYMENT_JOURNAL_REQUEST,
  CALCULATE_PREPAYMENT_JOURNAL_SUCCESS,
  CALCULATE_PREPAYMENT_JOURNAL_FAILURE,
};
