import instance from "./axios.base";

const getInsurCareers = async () => {
  const res = await instance.get("/careermaincategories");
  return res.data;
};

const getInsurSubCareers = async (careerId) => {
  const res = await instance.get(`/careersubcategories/${careerId}`);
  return res.data;
};

export { getInsurCareers, getInsurSubCareers };
