import { default as specialdaysTypes } from "./types";

const {
  ADD_SPECIALDAY_REQUEST,
  ADD_SPECIALDAY_SUCCESS,
  ADD_SPECIALDAY_FAILURE,
  FETCH_SPECIALDAYS_REQUEST,
  FETCH_SPECIALDAYS_SUCCESS,
  FETCH_SPECIALDAYS_FAILURE,
} = specialdaysTypes;

const addSpecialDayRequest = () => {
  return {
    type: ADD_SPECIALDAY_REQUEST,
  };
};

const addSpecialDaySuccess = () => {
  return {
    type: ADD_SPECIALDAY_SUCCESS,
  };
};

const addSpecialDayError = (error) => {
  return {
    type: ADD_SPECIALDAY_FAILURE,
    payload: error,
  };
};

const fetchSpecialDaysRequest = () => {
  return {
    type: FETCH_SPECIALDAYS_REQUEST,
  };
};

const fetchSpecialDaysSuccess = (specialdays) => {
  return {
    type: FETCH_SPECIALDAYS_SUCCESS,
    payload: specialdays,
  };
};

const fetchSpecialDaysError = (error) => {
  return {
    type: FETCH_SPECIALDAYS_FAILURE,
    payload: error,
  };
};

export default {
  addSpecialDayRequest,
  addSpecialDaySuccess,
  addSpecialDayError,
  fetchSpecialDaysRequest,
  fetchSpecialDaysSuccess,
  fetchSpecialDaysError,
};
