const EDIT_USER_PASSWORD_REQUEST = "EDIT_USER_PASSWORD_REQUEST";
const EDIT_USER_PASSWORD_SUCCESS = "EDIT_USER_PASSWORD_SUCCESS";
const EDIT_USER_PASSWORD_FAILURE = "EDIT_USER_PASSWORD_FAILURE";
const EDIT_USER_PASSWORD_BY_EMAIL_REQUEST =
  "EDIT_USER_PASSWORD_BY_EMAIL_REQUEST";
const EDIT_USER_PASSWORD_BY_EMAIL_SUCCESS =
  "EDIT_USER_PASSWORD_BY_EMAIL_SUCCESS";
const EDIT_USER_PASSWORD_BY_EMAIL_FAILURE =
  "EDIT_USER_PASSWORD_BY_EMAIL_FAILURE";
const EDIT_USER_PERMISSION_REQUEST = "EDIT_USER_PERMISSION_REQUEST";
const EDIT_USER_PERMISSION_SUCCESS = "EDIT_USER_PERMISSION_SUCCESS";
const EDIT_USER_PERMISSION_FAILURE = "EDIT_USER_PERMISSION_FAILURE";
const FETCH_USER_PERMISSIONS_REQUEST = "FETCH_USER_PERMISSIONS_REQUEST";
const FETCH_USER_PERMISSIONS_SUCCESS = "FETCH_USER_PERMISSIONS_SUCCESS";
const FETCH_USER_PERMISSIONS_FAILURE = "FETCH_USER_PERMISSIONS_FAILURE";

export default {
  EDIT_USER_PASSWORD_REQUEST,
  EDIT_USER_PASSWORD_SUCCESS,
  EDIT_USER_PASSWORD_FAILURE,
  EDIT_USER_PASSWORD_BY_EMAIL_REQUEST,
  EDIT_USER_PASSWORD_BY_EMAIL_SUCCESS,
  EDIT_USER_PASSWORD_BY_EMAIL_FAILURE,
  EDIT_USER_PERMISSION_REQUEST,
  EDIT_USER_PERMISSION_SUCCESS,
  EDIT_USER_PERMISSION_FAILURE,
  FETCH_USER_PERMISSIONS_REQUEST,
  FETCH_USER_PERMISSIONS_SUCCESS,
  FETCH_USER_PERMISSIONS_FAILURE,
};
