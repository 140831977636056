import { default as employeePunishmentInfoTypes } from "./types";

const {
  FETCH_EMPLOYEE_PUNISHMENT_INFO_REQUEST,
  FETCH_EMPLOYEE_PUNISHMENT_INFO_SUCCESS,
  FETCH_EMPLOYEE_PUNISHMENT_INFO_FAILURE,
  EDIT_EMPLOYEE_PUNISHMENT_INFO_REQUEST,
  EDIT_EMPLOYEE_PUNISHMENT_INFO_SUCCESS,
  EDIT_EMPLOYEE_PUNISHMENT_INFO_FAILURE,
  DELETE_EMPLOYEE_PUNISHMENT_INFO_REQUEST,
  DELETE_EMPLOYEE_PUNISHMENT_INFO_SUCCESS,
  DELETE_EMPLOYEE_PUNISHMENT_INFO_FAILURE,
} = employeePunishmentInfoTypes;

const fetchEmployeePunishmentInfoRequest = () => {
  return {
    type: FETCH_EMPLOYEE_PUNISHMENT_INFO_REQUEST,
  };
};

const fetchEmployeePunishmentInfoSuccess = (employeePunishmentInfo) => {
  return {
    type: FETCH_EMPLOYEE_PUNISHMENT_INFO_SUCCESS,
    payload: employeePunishmentInfo,
  };
};

const fetchEmployeePunishmentInfoError = (error) => {
  return {
    type: FETCH_EMPLOYEE_PUNISHMENT_INFO_FAILURE,
    payload: error,
  };
};

const editEmployeePunishmentInfoRequest = () => {
  return {
    type: EDIT_EMPLOYEE_PUNISHMENT_INFO_REQUEST,
  };
};

const editEmployeePunishmentInfoSuccess = () => {
  return {
    type: EDIT_EMPLOYEE_PUNISHMENT_INFO_SUCCESS,
  };
};

const editEmployeePunishmentInfoError = (error) => {
  return {
    type: EDIT_EMPLOYEE_PUNISHMENT_INFO_FAILURE,
    payload: error,
  };
};

const deleteEmployeePunishmentInfoRequest = () => {
  return {
    type: DELETE_EMPLOYEE_PUNISHMENT_INFO_REQUEST,
  };
};

const deleteEmployeePunishmentInfoSuccess = () => {
  return {
    type: DELETE_EMPLOYEE_PUNISHMENT_INFO_SUCCESS,
  };
};

const deleteEmployeePunishmentInfoError = (error) => {
  return {
    type: DELETE_EMPLOYEE_PUNISHMENT_INFO_FAILURE,
    payload: error,
  };
};

export default {
  fetchEmployeePunishmentInfoRequest,
  fetchEmployeePunishmentInfoSuccess,
  fetchEmployeePunishmentInfoError,
  editEmployeePunishmentInfoRequest,
  editEmployeePunishmentInfoSuccess,
  editEmployeePunishmentInfoError,
  deleteEmployeePunishmentInfoRequest,
  deleteEmployeePunishmentInfoSuccess,
  deleteEmployeePunishmentInfoError,
};
