import { getTT11Report } from "services/report";
import { default as hhoatReportActions } from "./actions";

const {
  fetchTT11ReportRequest,
  fetchTT11ReportSuccess,
  fetchTT11ReportError,
} = hhoatReportActions;

const fetchTT11Report = (year, month) => {
  return async (dispatch) => {
    dispatch(fetchTT11ReportRequest());

    try {
      const report = await getTT11Report(year, month);
      dispatch(fetchTT11ReportSuccess(report));
    } catch (err) {
      dispatch(fetchTT11ReportError(err.message));
    }
  };
};

export default {
  fetchTT11Report,
};
