import instance from "./axios.base";

const getPositions = async () => {
  const res = await instance.get("/positions");
  
  return res.data;
};

const createPosition = async (data) => {
  const res = await instance.post("/positions", data);

  return res.data;
};

const updatePosition = async (data) => {
  console.log('Update data : ', data);
  const res = await instance.put("/positions", data);

  return res.data;
};

const removePosition = async (data) => {
  const res = await instance.delete(`/positions/${data.id}`);

  return res.data;
};

export { getPositions, createPosition, updatePosition, removePosition };