import { default as districtsTypes } from "./types";

const {
  FETCH_DISTRICTS_REQUEST,
  FETCH_DISTRICTS_SUCCESS,
  FETCH_DISTRICTS_FAILURE,
} = districtsTypes;

const fetchDistrictsRequest = () => {
  return {
    type: FETCH_DISTRICTS_REQUEST,
  };
};

const fetchDistrictsSuccess = (districts) => {
  return {
    type: FETCH_DISTRICTS_SUCCESS,
    payload: districts,
  };
};

const fetchDistrictsError = (error) => {
  return {
    type: FETCH_DISTRICTS_FAILURE,
    payload: error,
  };
};

export default {
  fetchDistrictsRequest,
  fetchDistrictsSuccess,
  fetchDistrictsError,
};
