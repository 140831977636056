import { default as companysTypes } from "./types";

const {
  ADD_COMPANY_REQUEST,
  ADD_COMPANY_SUCCESS,
  ADD_COMPANY_FAILURE,
  FETCH_COMPANYS_REQUEST,
  FETCH_COMPANYS_SUCCESS,
  FETCH_COMPANYS_FAILURE,
} = companysTypes;

const addCompanyRequest = () => {
  return {
    type: ADD_COMPANY_REQUEST,
  };
};

const addCompanySuccess = () => {
  return {
    type: ADD_COMPANY_SUCCESS,
  };
};

const addCompanyError = (error) => {
  return {
    type: ADD_COMPANY_FAILURE,
    payload: error,
  };
};

const fetchCompanysRequest = () => {
  return {
    type: FETCH_COMPANYS_REQUEST,
  };
};

const fetchCompanysSuccess = (companys) => {
  return {
    type: FETCH_COMPANYS_SUCCESS,
    payload: companys,
  };
};

const fetchCompanysError = (error) => {
  return {
    type: FETCH_COMPANYS_FAILURE,
    payload: error,
  };
};

export default {
  addCompanyRequest,
  addCompanySuccess,
  addCompanyError,
  fetchCompanysRequest,
  fetchCompanysSuccess,
  fetchCompanysError,
};
