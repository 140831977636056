import { getHoroscopes } from "services/horoscope";
import { default as horoscopesActions } from "./actions";

const {
  fetchHoroscopesRequest,
  fetchHoroscopesSuccess,
  fetchHoroscopesError,
} = horoscopesActions;

const fetchHoroscopes = () => {
  return async (dispatch) => {
    dispatch(fetchHoroscopesRequest());

    try {
      const horoscopes = await getHoroscopes();
      dispatch(fetchHoroscopesSuccess(horoscopes));
    } catch (err) {
      dispatch(fetchHoroscopesError(err.message));
    }
  };
};

export default { fetchHoroscopes };
