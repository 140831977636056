import {
  getPunishmentInfo,
  editPunishmentInfo,
  deletePunishmentInfo,
} from "services/punishmentInfo";
import { default as employeePunishmentInfoActions } from "./actions";

const {
  fetchEmployeePunishmentInfoRequest,
  fetchEmployeePunishmentInfoSuccess,
  fetchEmployeePunishmentInfoError,
  editEmployeePunishmentInfoRequest,
  editEmployeePunishmentInfoSuccess,
  editEmployeePunishmentInfoError,
  deleteEmployeePunishmentInfoRequest,
  deleteEmployeePunishmentInfoSuccess,
  deleteEmployeePunishmentInfoError,
} = employeePunishmentInfoActions;

const fetchEmployeePunishmentInfo = (data) => {
  return async (dispatch) => {
    dispatch(fetchEmployeePunishmentInfoRequest());

    try {
      const employeePunishmentInfo = await getPunishmentInfo(data);
      dispatch(fetchEmployeePunishmentInfoSuccess(employeePunishmentInfo));
    } catch (err) {
      dispatch(fetchEmployeePunishmentInfoError(err.message));
    }
  };
};

const editEmployeePunishmentInfo = (data) => {
  return async (dispatch) => {
    dispatch(editEmployeePunishmentInfoRequest());

    try {
      await editPunishmentInfo(data);
      dispatch(editEmployeePunishmentInfoSuccess());
    } catch (err) {
      dispatch(editEmployeePunishmentInfoError(err.message));
    }
  };
};

const deleteEmployeePunishmentInfo = (id) => {
  return async (dispatch) => {
    dispatch(deleteEmployeePunishmentInfoRequest());

    try {
      await deletePunishmentInfo(id);
      dispatch(deleteEmployeePunishmentInfoSuccess());
    } catch (err) {
      dispatch(deleteEmployeePunishmentInfoError(err.message));
    }
  };
};

export default {
  fetchEmployeePunishmentInfo,
  editEmployeePunishmentInfo,
  deleteEmployeePunishmentInfo,
};
