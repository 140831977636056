import { default as teamsTypes } from "./types";

const {
  ADD_TEAM_REQUEST,
  ADD_TEAM_SUCCESS,
  ADD_TEAM_FAILURE,
  FETCH_TEAMS_REQUEST,
  FETCH_TEAMS_SUCCESS,
  FETCH_TEAMS_FAILURE,
} = teamsTypes;

const addTeamRequest = () => {
  return {
    type: ADD_TEAM_REQUEST,
  };
};

const addTeamSuccess = (teams) => {
  return {
    type: ADD_TEAM_SUCCESS,
  };
};

const addTeamError = (error) => {
  return {
    type: ADD_TEAM_FAILURE,
    payload: error,
  };
};

const fetchTeamsRequest = () => {
  return {
    type: FETCH_TEAMS_REQUEST,
  };
};

const fetchTeamsSuccess = (teams) => {
  return {
    type: FETCH_TEAMS_SUCCESS,
    payload: teams,
  };
};

const fetchTeamsError = (error) => {
  return {
    type: FETCH_TEAMS_FAILURE,
    payload: error,
  };
};

export default {
  addTeamRequest,
  addTeamSuccess,
  addTeamError,
  fetchTeamsRequest,
  fetchTeamsSuccess,
  fetchTeamsError,
};
