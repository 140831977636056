import instance from "./axios.base";

const getExperienceInfo = async (id) => {
  const res = await instance.get(`/empworkhistory/${id}`);
  return res.data;
};

const editExperienceInfo = async (data) => {
  await instance.put("/empworkhistory", data);
};

const deleteExperienceInfo = async (id) => {
  await instance.delete(`/empworkhistory/${id}`);
};

export { getExperienceInfo, editExperienceInfo, deleteExperienceInfo };
