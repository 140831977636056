const ADD_SYSTEMCONFIGTA_REQUEST = "ADD_SYSTEMCONFIGTA_REQUEST";
const ADD_SYSTEMCONFIGTA_SUCCESS = "ADD_SYSTEMCONFIGTA_SUCCESS";
const ADD_SYSTEMCONFIGTA_FAILURE = "ADD_SYSTEMCONFIGTA_FAILURE";
const ADD_SYSTEMCONFIGPR_REQUEST = "ADD_SYSTEMCONFIGPR_REQUEST";
const ADD_SYSTEMCONFIGPR_SUCCESS = "ADD_SYSTEMCONFIGPR_SUCCESS";
const ADD_SYSTEMCONFIGPR_FAILURE = "ADD_SYSTEMCONFIGPR_FAILURE";
const ADD_SYSTEMCONFIGTAXCREDIT_REQUEST = "ADD_SYSTEMCONFIGTAXCREDIT_REQUEST";
const ADD_SYSTEMCONFIGTAXCREDIT_SUCCESS = "ADD_SYSTEMCONFIGTAXCREDIT_SUCCESS";
const ADD_SYSTEMCONFIGTAXCREDIT_FAILURE = "ADD_SYSTEMCONFIGTAXCREDIT_FAILURE";
const FETCH_SYSTEMCONFIGTA_REQUEST = "FETCH_SYSTEMCONFIGTA_REQUEST";
const FETCH_SYSTEMCONFIGTA_SUCCESS = "FETCH_SYSTEMCONFIGTA_SUCCESS";
const FETCH_SYSTEMCONFIGTA_FAILURE = "FETCH_SYSTEMCONFIGTA_FAILURE";
const FETCH_SYSTEMCONFIGPR_REQUEST = "FETCH_SYSTEMCONFIGPR_REQUEST";
const FETCH_SYSTEMCONFIGPR_SUCCESS = "FETCH_SYSTEMCONFIGPR_SUCCESS";
const FETCH_SYSTEMCONFIGPR_FAILURE = "FETCH_SYSTEMCONFIGPR_FAILURE";
const FETCH_SYSTEMCONFIGTAXCREDIT_REQUEST =
  "FETCH_SYSTEMCONFIGTAXCREDIT_REQUEST";
const FETCH_SYSTEMCONFIGTAXCREDIT_SUCCESS =
  "FETCH_SYSTEMCONFIGTAXCREDIT_SUCCESS";
const FETCH_SYSTEMCONFIGTAXCREDIT_FAILURE =
  "FETCH_SYSTEMCONFIGTAXCREDIT_FAILURE";
const ADD_MANAGER_REQUEST = "ADD_MANAGER_REQUEST";
const ADD_MANAGER_SUCCESS = "ADD_MANAGER_SUCCESS";
const ADD_MANAGER_FAILURE = "ADD_MANAGER_FAILURE";
const DELETE_MANAGER_REQUEST = "DELETE_MANAGER_REQUEST";
const DELETE_MANAGER_SUCCESS = "DELETE_MANAGER_SUCCESS";
const DELETE_MANAGER_FAILURE = "DELETE_MANAGER_FAILURE";
const FETCH_MANAGERS_REQUEST = "FETCH_MANAGERS_REQUEST";
const FETCH_MANAGERS_SUCCESS = "FETCH_MANAGERS_SUCCESS";
const FETCH_MANAGERS_FAILURE = "FETCH_MANAGERS_FAILURE";

export default {
  ADD_SYSTEMCONFIGTA_REQUEST,
  ADD_SYSTEMCONFIGTA_SUCCESS,
  ADD_SYSTEMCONFIGTA_FAILURE,
  ADD_SYSTEMCONFIGPR_REQUEST,
  ADD_SYSTEMCONFIGPR_SUCCESS,
  ADD_SYSTEMCONFIGPR_FAILURE,
  ADD_SYSTEMCONFIGTAXCREDIT_REQUEST,
  ADD_SYSTEMCONFIGTAXCREDIT_SUCCESS,
  ADD_SYSTEMCONFIGTAXCREDIT_FAILURE,
  FETCH_SYSTEMCONFIGTA_REQUEST,
  FETCH_SYSTEMCONFIGTA_SUCCESS,
  FETCH_SYSTEMCONFIGTA_FAILURE,
  FETCH_SYSTEMCONFIGPR_REQUEST,
  FETCH_SYSTEMCONFIGPR_SUCCESS,
  FETCH_SYSTEMCONFIGPR_FAILURE,
  FETCH_SYSTEMCONFIGTAXCREDIT_REQUEST,
  FETCH_SYSTEMCONFIGTAXCREDIT_SUCCESS,
  FETCH_SYSTEMCONFIGTAXCREDIT_FAILURE,
  ADD_MANAGER_REQUEST,
  ADD_MANAGER_SUCCESS,
  ADD_MANAGER_FAILURE,
  DELETE_MANAGER_REQUEST,
  DELETE_MANAGER_SUCCESS,
  DELETE_MANAGER_FAILURE,
  FETCH_MANAGERS_REQUEST,
  FETCH_MANAGERS_SUCCESS,
  FETCH_MANAGERS_FAILURE,
};
