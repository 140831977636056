import { default as employeeMigrationInfoTypes } from "./types";

const {
  FETCH_EMPLOYEE_MIGRATION_INFO_REQUEST,
  FETCH_EMPLOYEE_MIGRATION_INFO_SUCCESS,
  FETCH_EMPLOYEE_MIGRATION_INFO_FAILURE,
} = employeeMigrationInfoTypes;

const fetchEmployeeMigrationInfoRequest = () => {
  return {
    type: FETCH_EMPLOYEE_MIGRATION_INFO_REQUEST,
  };
};

const fetchEmployeeMigrationInfoSuccess = (employeeMigrationInfo) => {
  return {
    type: FETCH_EMPLOYEE_MIGRATION_INFO_SUCCESS,
    payload: employeeMigrationInfo,
  };
};

const fetchEmployeeMigrationInfoError = (error) => {
  return {
    type: FETCH_EMPLOYEE_MIGRATION_INFO_FAILURE,
    payload: error,
  };
};

export default {
  fetchEmployeeMigrationInfoRequest,
  fetchEmployeeMigrationInfoSuccess,
  fetchEmployeeMigrationInfoError,
};
