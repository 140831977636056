import { default as feedbacksTypes } from "./types";

const {
  ADD_FEEDBACK_REQUEST,
  ADD_FEEDBACK_SUCCESS,
  ADD_FEEDBACK_FAILURE,
  FETCH_FEEDBACKS_REQUEST,
  FETCH_FEEDBACKS_SUCCESS,
  FETCH_FEEDBACKS_FAILURE,
} = feedbacksTypes;

const initialState = {
  isLoading: false,
  list: [],
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_FEEDBACK_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_FEEDBACK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case ADD_FEEDBACK_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case FETCH_FEEDBACKS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_FEEDBACKS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: action.payload,
        error: "",
      };
    case FETCH_FEEDBACKS_FAILURE:
      return {
        ...state,
        isLoading: false,
        list: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
