const FETCH_HALF_MONTHLY_ATTENDANCE_ARCHIVES_REQUEST =
  "FETCH_HALF_MONTHLY_ATTENDANCE_ARCHIVES_REQUEST";
const FETCH_HALF_MONTHLY_ATTENDANCE_ARCHIVES_SUCCESS =
  "FETCH_HALF_MONTHLY_ATTENDANCE_ARCHIVES_SUCCESS";
const FETCH_HALF_MONTHLY_ATTENDANCE_ARCHIVES_FAILURE =
  "FETCH_HALF_MONTHLY_ATTENDANCE_ARCHIVES_FAILURE";
const CLEAR_HALF_MONTHLY_ATTENDANCE_ARCHIVES_REQUEST =
  "CLEAR_HALF_MONTHLY_ATTENDANCE_ARCHIVES_REQUEST";
const CLEAR_HALF_MONTHLY_ATTENDANCE_ARCHIVES_SUCCESS =
  "CLEAR_HALF_MONTHLY_ATTENDANCE_ARCHIVES_SUCCESS";
const CLEAR_HALF_MONTHLY_ATTENDANCE_ARCHIVES_FAILURE =
  "CLEAR_HALF_MONTHLY_ATTENDANCE_ARCHIVES_FAILURE";

export default {
  FETCH_HALF_MONTHLY_ATTENDANCE_ARCHIVES_REQUEST,
  FETCH_HALF_MONTHLY_ATTENDANCE_ARCHIVES_SUCCESS,
  FETCH_HALF_MONTHLY_ATTENDANCE_ARCHIVES_FAILURE,
  CLEAR_HALF_MONTHLY_ATTENDANCE_ARCHIVES_REQUEST,
  CLEAR_HALF_MONTHLY_ATTENDANCE_ARCHIVES_SUCCESS,
  CLEAR_HALF_MONTHLY_ATTENDANCE_ARCHIVES_FAILURE,
};
