import instance from "./axios.base";

const add = async (data) => {
  const res = await instance.post("/prempdeduction", data);
  return res.data;
};

const edit = async (data) => {
  const res = await instance.put("/prempdeduction", data);
  return res.data;
};

const remove = async (id) => {
  const res = await instance.delete(`/prempdeduction/${id}`);
  return res.data;
};

const getEmpDeductions = async (empId) => {
  const res = await instance.get(`/prempdeduction/${empId}`);
  return res.data;
};

export { add, edit, remove, getEmpDeductions };
