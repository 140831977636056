import { default as preJournalsTypes } from "./types";

const {
  ADD_PRE_JOURNAL_REQUEST,
  ADD_PRE_JOURNAL_SUCCESS,
  ADD_PRE_JOURNAL_FAILURE,
  EDIT_PRE_JOURNAL_REQUEST,
  EDIT_PRE_JOURNAL_SUCCESS,
  EDIT_PRE_JOURNAL_FAILURE,
  FETCH_PRE_JOURNALS_REQUEST,
  FETCH_PRE_JOURNALS_SUCCESS,
  FETCH_PRE_JOURNALS_FAILURE,
  FETCH_PRE_JOURNAL_REQUEST,
  FETCH_PRE_JOURNAL_SUCCESS,
  FETCH_PRE_JOURNAL_FAILURE,
  ARCHIVE_PRE_JOURNAL_REQUEST,
  ARCHIVE_PRE_JOURNAL_SUCCESS,
  ARCHIVE_PRE_JOURNAL_FAILURE,
  CALCULATE_PRE_JOURNAL_REQUEST,
  CALCULATE_PRE_JOURNAL_SUCCESS,
  CALCULATE_PRE_JOURNAL_FAILURE,
  CLEAR_PRE_JOURNAL,
} = preJournalsTypes;

const initialState = {
  isCalculating: false,
  isLoading: false,
  list: [],
  detail: null,
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PRE_JOURNAL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_PRE_JOURNAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case ADD_PRE_JOURNAL_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case EDIT_PRE_JOURNAL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case EDIT_PRE_JOURNAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case EDIT_PRE_JOURNAL_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case FETCH_PRE_JOURNALS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_PRE_JOURNALS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: action.payload,
        error: "",
      };
    case FETCH_PRE_JOURNALS_FAILURE:
      return {
        ...state,
        isLoading: false,
        list: [],
        error: action.payload,
      };
    case FETCH_PRE_JOURNAL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_PRE_JOURNAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        detail: action.payload,
        error: "",
      };
    case FETCH_PRE_JOURNAL_FAILURE:
      return {
        ...state,
        isLoading: false,
        detail: null,
        error: action.payload,
      };
    case ARCHIVE_PRE_JOURNAL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ARCHIVE_PRE_JOURNAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case ARCHIVE_PRE_JOURNAL_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case CALCULATE_PRE_JOURNAL_REQUEST:
      return {
        ...state,
        isCalculating: true,
      };
    case CALCULATE_PRE_JOURNAL_SUCCESS:
      return {
        ...state,
        isCalculating: false,
        error: "",
      };
    case CALCULATE_PRE_JOURNAL_FAILURE:
      return {
        ...state,
        isCalculating: false,
        error: action.payload,
      };
    case CLEAR_PRE_JOURNAL:
      return {
        ...state,
        isLoading: false,
        isCalculating: false,
        detail: null,
        error: "",
      };
    default:
      return state;
  }
};

export default reducer;
