import { default as types } from "./types";

const {
  FETCH_TT11_1_REPORT_REQUEST,
  FETCH_TT11_1_REPORT_SUCCESS,
  FETCH_TT11_1_REPORT_FAILURE,
} = types;

const fetchTT11_1ReportRequest = () => {
  return {
    type: FETCH_TT11_1_REPORT_REQUEST,
  };
};

const fetchTT11_1ReportSuccess = (report) => {
  return {
    type: FETCH_TT11_1_REPORT_SUCCESS,
    payload: report,
  };
};

const fetchTT11_1ReportError = (error) => {
  return {
    type: FETCH_TT11_1_REPORT_FAILURE,
    payload: error,
  };
};

export default {
  fetchTT11_1ReportRequest,
  fetchTT11_1ReportSuccess,
  fetchTT11_1ReportError,
};
