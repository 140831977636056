import instance from "./axios.base";
import { dateFormat } from "utils/formatter";

const getAnnouncements = async () => {
  const res = await instance.get("/announcements");

  return res.data;
};

const createAnnouncement = async (data) => {
  var formData = new FormData();
  formData.append('title', data.title);
  formData.append('type', data.type);
  formData.append('descr', data.descr);
  formData.append('image', data.image);

  const res = await instance.post("/announcements", formData, { headers: {'Content-Type': 'multipart/form-data' }});

  return res.data;
};

const updateAnnouncement = async (data) => {
  var formData = new FormData();
  formData.append('id', data.id);
  formData.append('title', data.title);
  formData.append('type', data.type);
  formData.append('descr', data.descr);
  formData.append('image', data.image);

  const res = await instance.put("/announcements", formData, { headers: {'Content-Type': 'multipart/form-data' }});

  return res.data;
};

const removeAnnouncement = async (data) => {
  const res = await instance.delete(`/announcements/${data.id}`);

  return res.data;
};

export { getAnnouncements, createAnnouncement, updateAnnouncement, removeAnnouncement };
