import { default as teammembersTypes } from "./types";

const {
  ADD_TEAMMEMBER_REQUEST,
  ADD_TEAMMEMBER_SUCCESS,
  ADD_TEAMMEMBER_FAILURE,
  FETCH_TEAMMEMBERS_REQUEST,
  FETCH_TEAMMEMBERS_SUCCESS,
  FETCH_TEAMMEMBERS_FAILURE,
} = teammembersTypes;

const initialState = {
  isLoading: false,
  list: [],
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TEAMMEMBER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_TEAMMEMBER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case ADD_TEAMMEMBER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case FETCH_TEAMMEMBERS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_TEAMMEMBERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: action.payload,
        error: "",
      };
    case FETCH_TEAMMEMBERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        list: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
