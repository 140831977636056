import instance from "./axios.base";

const getLanguages = async () => {
  const res = await instance.get(
    "/service?name=languageName&tablename=hr_emp_language_skill"
  );
  return res.data;
};

export { getLanguages };
