import { format } from "date-fns";

const mntFormat = (amount) => {
  const formatter = new Intl.NumberFormat("en-US");

  return `${formatter.format(amount)}₮`;
};

const dateFormat = (date, dateFormat = "yyyy-MM-dd") => {
  return format(new Date(date), dateFormat);
};

export { mntFormat, dateFormat };
