import { default as empRaisesAndDeductionsTypes } from "./types";

const {
  FETCH_EMP_RAISES_AND_DEDUCTIONS_REQUEST,
  FETCH_EMP_RAISES_AND_DEDUCTIONS_SUCCESS,
  FETCH_EMP_RAISES_AND_DEDUCTIONS_FAILURE,
} = empRaisesAndDeductionsTypes;

const fetchEmpRaisesAndDeductionsRequest = () => {
  return {
    type: FETCH_EMP_RAISES_AND_DEDUCTIONS_REQUEST,
  };
};

const fetchEmpRaisesAndDeductionsSuccess = (empRaisesAndDeductions) => {
  return {
    type: FETCH_EMP_RAISES_AND_DEDUCTIONS_SUCCESS,
    payload: empRaisesAndDeductions,
  };
};

const fetchEmpRaisesAndDeductionsError = (error) => {
  return {
    type: FETCH_EMP_RAISES_AND_DEDUCTIONS_FAILURE,
    payload: error,
  };
};

export default {
  fetchEmpRaisesAndDeductionsRequest,
  fetchEmpRaisesAndDeductionsSuccess,
  fetchEmpRaisesAndDeductionsError,
};
