import { toast } from "react-toastify";
import {
  getHalfMonthlyAttendanceArchives,
  clearHalfMonthlyAttendanceArchives as clear,
} from "services/archive";
import { default as halfMonthlyAttendaceArchivesActions } from "./actions";

const {
  fetchHalfMonthlyAttendanceArchivesRequest,
  fetchHalfMonthlyAttendanceArchivesSuccess,
  fetchHalfMonthlyAttendanceArchivesError,
  clearHalfMonthlyAttendanceArchivesRequest,
  clearHalfMonthlyAttendanceArchivesSuccess,
  clearHalfMonthlyAttendanceArchivesError,
} = halfMonthlyAttendaceArchivesActions;

const fetchHalfMonthlyAttendanceArchives = (year, month) => {
  return async (dispatch) => {
    dispatch(fetchHalfMonthlyAttendanceArchivesRequest());

    try {
      const halfMonthlyAttendanceArchives = await getHalfMonthlyAttendanceArchives(
        year,
        month
      );
      dispatch(
        fetchHalfMonthlyAttendanceArchivesSuccess(halfMonthlyAttendanceArchives)
      );
    } catch (err) {
      dispatch(fetchHalfMonthlyAttendanceArchivesError(err.message));
    }
  };
};

const clearHalfMonthlyAttendanceArchives = (year, month) => {
  return async (dispatch) => {
    dispatch(clearHalfMonthlyAttendanceArchivesRequest());

    try {
      await clear(year, month);
      dispatch(clearHalfMonthlyAttendanceArchivesSuccess());
      toast.success("Амжилттай цэвэрлэлээ");
    } catch (err) {
      dispatch(clearHalfMonthlyAttendanceArchivesError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

export default {
  fetchHalfMonthlyAttendanceArchives,
  clearHalfMonthlyAttendanceArchives,
};
