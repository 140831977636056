import { toast } from "react-toastify";
import {
  getEmpHolidays,
  createEmpHoliday,
  updateEmpHoliday,
  removeEmpHoliday,
} from "services/empHoliday";
import { default as empholidaysActions } from "./actions";

const {
  addEmpHolidayRequest,
  addEmpHolidaySuccess,
  addEmpHolidayError,
  fetchEmpHolidaysRequest,
  fetchEmpHolidaysSuccess,
  fetchEmpHolidaysError,
} = empholidaysActions;

const fetchEmpHolidays = () => {
  return async (dispatch) => {
    dispatch(fetchEmpHolidaysRequest());

    try {
      const empholidays = await getEmpHolidays();
      dispatch(fetchEmpHolidaysSuccess(empholidays));
    } catch (err) {
      dispatch(fetchEmpHolidaysError(err.message));
    }
  };
};

const addEmpHoliday = (data) => {
  return async (dispatch) => {
    dispatch(addEmpHolidayRequest());

    try {
      await createEmpHoliday(data);
      dispatch(addEmpHolidaySuccess());
      toast.success("Амжилттай нэмлээ");
    } catch (err) {
      dispatch(addEmpHolidayError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const editEmpHoliday = (data) => {
  return async (dispatch) => {
    dispatch(addEmpHolidayRequest());

    try {
      await updateEmpHoliday(data);
      dispatch(addEmpHolidaySuccess());
    } catch (err) {
      dispatch(addEmpHolidayError(err.message));
    }
  };
};

const deleteEmpHoliday = (data) => {
  return async (dispatch) => {
    dispatch(addEmpHolidayRequest());

    try {
      await removeEmpHoliday(data);
      dispatch(addEmpHolidaySuccess());
    } catch (err) {
      dispatch(addEmpHolidayError(err.message));
    }
  };
};

export default {
  fetchEmpHolidays,
  addEmpHoliday,
  editEmpHoliday,
  deleteEmpHoliday,
};
