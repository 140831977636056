import { default as employeeAidsInfoTypes } from "./types";

const {
  FETCH_EMPLOYEE_AIDS_INFO_REQUEST,
  FETCH_EMPLOYEE_AIDS_INFO_SUCCESS,
  FETCH_EMPLOYEE_AIDS_INFO_FAILURE,
  ADD_EMPLOYEE_AIDS_INFO_REQUEST,
  ADD_EMPLOYEE_AIDS_INFO_SUCCESS,
  ADD_EMPLOYEE_AIDS_INFO_FAILURE,
  EDIT_EMPLOYEE_AIDS_INFO_REQUEST,
  EDIT_EMPLOYEE_AIDS_INFO_SUCCESS,
  EDIT_EMPLOYEE_AIDS_INFO_FAILURE,
  DELETE_EMPLOYEE_AIDS_INFO_REQUEST,
  DELETE_EMPLOYEE_AIDS_INFO_SUCCESS,
  DELETE_EMPLOYEE_AIDS_INFO_FAILURE,
} = employeeAidsInfoTypes;

const fetchEmployeeAidsInfoRequest = () => {
  return {
    type: FETCH_EMPLOYEE_AIDS_INFO_REQUEST,
  };
};

const fetchEmployeeAidsInfoSuccess = (employeeAidsInfo) => {
  return {
    type: FETCH_EMPLOYEE_AIDS_INFO_SUCCESS,
    payload: employeeAidsInfo,
  };
};

const fetchEmployeeAidsInfoError = (error) => {
  return {
    type: FETCH_EMPLOYEE_AIDS_INFO_FAILURE,
    payload: error,
  };
};

const addEmployeeAidsInfoRequest = () => {
  return {
    type: ADD_EMPLOYEE_AIDS_INFO_REQUEST,
  };
};

const addEmployeeAidsInfoSuccess = () => {
  return {
    type: ADD_EMPLOYEE_AIDS_INFO_SUCCESS,
  };
};

const addEmployeeAidsInfoError = (error) => {
  return {
    type: ADD_EMPLOYEE_AIDS_INFO_FAILURE,
    payload: error,
  };
};

const editEmployeeAidsInfoRequest = () => {
  return {
    type: EDIT_EMPLOYEE_AIDS_INFO_REQUEST,
  };
};

const editEmployeeAidsInfoSuccess = () => {
  return {
    type: EDIT_EMPLOYEE_AIDS_INFO_SUCCESS,
  };
};

const editEmployeeAidsInfoError = (error) => {
  return {
    type: EDIT_EMPLOYEE_AIDS_INFO_FAILURE,
    payload: error,
  };
};

const deleteEmployeeAidsInfoRequest = () => {
  return {
    type: DELETE_EMPLOYEE_AIDS_INFO_REQUEST,
  };
};

const deleteEmployeeAidsInfoSuccess = () => {
  return {
    type: DELETE_EMPLOYEE_AIDS_INFO_SUCCESS,
  };
};

const deleteEmployeeAidsInfoError = (error) => {
  return {
    type: DELETE_EMPLOYEE_AIDS_INFO_FAILURE,
    payload: error,
  };
};

export default {
  fetchEmployeeAidsInfoRequest,
  fetchEmployeeAidsInfoSuccess,
  fetchEmployeeAidsInfoError,
  addEmployeeAidsInfoRequest,
  addEmployeeAidsInfoSuccess,
  addEmployeeAidsInfoError,
  editEmployeeAidsInfoRequest,
  editEmployeeAidsInfoSuccess,
  editEmployeeAidsInfoError,
  deleteEmployeeAidsInfoRequest,
  deleteEmployeeAidsInfoSuccess,
  deleteEmployeeAidsInfoError,
};
