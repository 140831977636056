import { default as types } from "./types";

const {
  FETCH_MONTHLY_ATTENDANCE_ARCHIVES_REQUEST,
  FETCH_MONTHLY_ATTENDANCE_ARCHIVES_SUCCESS,
  FETCH_MONTHLY_ATTENDANCE_ARCHIVES_FAILURE,
  CLEAR_MONTHLY_ATTENDANCE_ARCHIVES_REQUEST,
  CLEAR_MONTHLY_ATTENDANCE_ARCHIVES_SUCCESS,
  CLEAR_MONTHLY_ATTENDANCE_ARCHIVES_FAILURE,
} = types;

const fetchMonthlyAttendanceArchivesRequest = () => {
  return {
    type: FETCH_MONTHLY_ATTENDANCE_ARCHIVES_REQUEST,
  };
};

const fetchMonthlyAttendanceArchivesSuccess = (monthlyAttendanceArchives) => {
  return {
    type: FETCH_MONTHLY_ATTENDANCE_ARCHIVES_SUCCESS,
    payload: monthlyAttendanceArchives,
  };
};

const fetchMonthlyAttendanceArchivesError = (error) => {
  return {
    type: FETCH_MONTHLY_ATTENDANCE_ARCHIVES_FAILURE,
    payload: error,
  };
};

const clearMonthlyAttendanceArchivesRequest = () => {
  return {
    type: CLEAR_MONTHLY_ATTENDANCE_ARCHIVES_REQUEST,
  };
};

const clearMonthlyAttendanceArchivesSuccess = () => {
  return {
    type: CLEAR_MONTHLY_ATTENDANCE_ARCHIVES_SUCCESS,
  };
};

const clearMonthlyAttendanceArchivesError = (error) => {
  return {
    type: CLEAR_MONTHLY_ATTENDANCE_ARCHIVES_FAILURE,
    payload: error,
  };
};

export default {
  fetchMonthlyAttendanceArchivesRequest,
  fetchMonthlyAttendanceArchivesSuccess,
  fetchMonthlyAttendanceArchivesError,
  clearMonthlyAttendanceArchivesRequest,
  clearMonthlyAttendanceArchivesSuccess,
  clearMonthlyAttendanceArchivesError,
};
