import { default as dashboardsTypes } from "./types";

const {
  FETCH_DASHBOARDS_REQUEST,
  FETCH_DASHBOARDAGE_SUCCESS,
  FETCH_DASHBOARDAGE_FAILURE,
  FETCH_DASHBOARDEMPCOUNT_SUCCESS,
  FETCH_DASHBOARDEMPCOUNT_FAILURE,
  FETCH_DASHBOARDDEGREE_SUCCESS,
  FETCH_DASHBOARDDEGREE_FAILURE,
  FETCH_DASHBOARDGENDER_SUCCESS,
  FETCH_DASHBOARDGENDER_FAILURE,
  FETCH_DASHBOARDMARITAL_SUCCESS,
  FETCH_DASHBOARDMARITAL_FAILURE,
  FETCH_DASHBOARDSALARY_SUCCESS,
  FETCH_DASHBOARDSALARY_FAILURE,
  FETCH_DASHBOARDWORKEDYEAR_SUCCESS,
  FETCH_DASHBOARDWORKEDYEAR_FAILURE,
} = dashboardsTypes;

const initialState = {
  isLoading: false,
  ageChart: [],
  employeeChart: [],
  degreeChart: [],
  genderChart: [],
  maritalChart: [],
  salaryChart: [],
  workedYearChart: [],
  list: [],
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DASHBOARDS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_DASHBOARDAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ageChart: action.payload,
        error: "",
      };
    case FETCH_DASHBOARDAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        ageChart: [],
        error: action.payload,
      };
    case FETCH_DASHBOARDEMPCOUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        employeeCount: action.payload,
        error: "",
      };
    case FETCH_DASHBOARDEMPCOUNT_FAILURE:
      return {
        ...state,
        isLoading: false,
        employeeCount: [],
        error: action.payload,
      };
    case FETCH_DASHBOARDDEGREE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        degreeChart: action.payload,
        error: "",
      };
    case FETCH_DASHBOARDDEGREE_FAILURE:
      return {
        ...state,
        isLoading: false,
        degreeChart: [],
        error: action.payload,
      };
    case FETCH_DASHBOARDGENDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        genderChart: action.payload,
        error: "",
      };
    case FETCH_DASHBOARDGENDER_FAILURE:
      return {
        ...state,
        isLoading: false,
        genderChart: [],
        error: action.payload,
      };
    case FETCH_DASHBOARDMARITAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        maritalChart: action.payload,
        error: "",
      };
    case FETCH_DASHBOARDMARITAL_FAILURE:
      return {
        ...state,
        isLoading: false,
        maritalChart: [],
        error: action.payload,
      };
    case FETCH_DASHBOARDSALARY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        salaryChart: action.payload,
        error: "",
      };
    case FETCH_DASHBOARDSALARY_FAILURE:
      return {
        ...state,
        isLoading: false,
        salaryChart: [],
        error: action.payload,
      };
    case FETCH_DASHBOARDWORKEDYEAR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        workedYearChart: action.payload,
        error: "",
      };
    case FETCH_DASHBOARDWORKEDYEAR_FAILURE:
      return {
        ...state,
        isLoading: false,
        workedYearChart: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
