import { getInsurSubCareers } from "services/insurCareer";
import { default as insurSubCareersActions } from "./actions";

const {
  fetchInsurSubCareersRequest,
  fetchInsurSubCareersSuccess,
  fetchInsurSubCareersError,
} = insurSubCareersActions;

const fetchInsurSubCareers = (careerId) => {
  return async (dispatch) => {
    dispatch(fetchInsurSubCareersRequest());

    try {
      const insurSubCareers = await getInsurSubCareers(careerId);
      dispatch(fetchInsurSubCareersSuccess(insurSubCareers));
    } catch (err) {
      dispatch(fetchInsurSubCareersError(err.message));
    }
  };
};

export default { fetchInsurSubCareers };
