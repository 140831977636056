import { default as employeeFamilyInfoTypes } from "./types";

const {
  FETCH_EMPLOYEE_FAMILY_INFO_REQUEST,
  FETCH_EMPLOYEE_FAMILY_INFO_SUCCESS,
  FETCH_EMPLOYEE_FAMILY_INFO_FAILURE,
  EDIT_EMPLOYEE_FAMILY_INFO_REQUEST,
  EDIT_EMPLOYEE_FAMILY_INFO_SUCCESS,
  EDIT_EMPLOYEE_FAMILY_INFO_FAILURE,
  DELETE_EMPLOYEE_FAMILY_INFO_REQUEST,
  DELETE_EMPLOYEE_FAMILY_INFO_SUCCESS,
  DELETE_EMPLOYEE_FAMILY_INFO_FAILURE,
} = employeeFamilyInfoTypes;

const fetchEmployeeFamilyInfoRequest = () => {
  return {
    type: FETCH_EMPLOYEE_FAMILY_INFO_REQUEST,
  };
};

const fetchEmployeeFamilyInfoSuccess = (employeeFamilyInfo) => {
  return {
    type: FETCH_EMPLOYEE_FAMILY_INFO_SUCCESS,
    payload: employeeFamilyInfo,
  };
};

const fetchEmployeeFamilyInfoError = (error) => {
  return {
    type: FETCH_EMPLOYEE_FAMILY_INFO_FAILURE,
    payload: error,
  };
};

const editEmployeeFamilyInfoRequest = () => {
  return {
    type: EDIT_EMPLOYEE_FAMILY_INFO_REQUEST,
  };
};

const editEmployeeFamilyInfoSuccess = () => {
  return {
    type: EDIT_EMPLOYEE_FAMILY_INFO_SUCCESS,
  };
};

const editEmployeeFamilyInfoError = (error) => {
  return {
    type: EDIT_EMPLOYEE_FAMILY_INFO_FAILURE,
    payload: error,
  };
};

const deleteEmployeeFamilyInfoRequest = () => {
  return {
    type: DELETE_EMPLOYEE_FAMILY_INFO_REQUEST,
  };
};

const deleteEmployeeFamilyInfoSuccess = () => {
  return {
    type: DELETE_EMPLOYEE_FAMILY_INFO_SUCCESS,
  };
};

const deleteEmployeeFamilyInfoError = (error) => {
  return {
    type: DELETE_EMPLOYEE_FAMILY_INFO_FAILURE,
    payload: error,
  };
};

export default {
  fetchEmployeeFamilyInfoRequest,
  fetchEmployeeFamilyInfoSuccess,
  fetchEmployeeFamilyInfoError,
  editEmployeeFamilyInfoRequest,
  editEmployeeFamilyInfoSuccess,
  editEmployeeFamilyInfoError,
  deleteEmployeeFamilyInfoRequest,
  deleteEmployeeFamilyInfoSuccess,
  deleteEmployeeFamilyInfoError,
};
