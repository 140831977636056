import { default as types } from "./types";

const {
  FETCH_RAISE_NDTYPES_REQUEST,
  FETCH_RAISE_NDTYPES_SUCCESS,
  FETCH_RAISE_NDTYPES_FAILURE,
} = types;

const fetchRaiseNdTypesRequest = () => {
  return {
    type: FETCH_RAISE_NDTYPES_REQUEST,
  };
};

const fetchRaiseNdTypesSuccess = (raiseNdTypes) => {
  return {
    type: FETCH_RAISE_NDTYPES_SUCCESS,
    payload: raiseNdTypes,
  };
};

const fetchRaiseNdTypesError = (error) => {
  return {
    type: FETCH_RAISE_NDTYPES_FAILURE,
    payload: error,
  };
};

export default {
  fetchRaiseNdTypesRequest,
  fetchRaiseNdTypesSuccess,
  fetchRaiseNdTypesError,
};
