import instance from "./axios.base";

const getLangSkillInfo = async (id) => {
  const res = await instance.get(`/emplanguageskills/${id}`);
  return res.data;
};

const editLangSkillInfo = async (data) => {
  await instance.put("/emplanguageskills", data);
};

const deleteLangSkillInfo = async (id) => {
  await instance.delete(`/emplanguageskills/${id}`);
};

export { getLangSkillInfo, editLangSkillInfo, deleteLangSkillInfo };
