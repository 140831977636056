import { toast } from "react-toastify";
import {
  getEmergencyContractInfo,
  editEmergencyContractInfo,
  deleteEmergencyContractInfo,
} from "services/emergencyContractInfo";
import { default as employeeEmergencyContractInfoActions } from "./actions";

const {
  fetchEmployeeEmergencyContractInfoRequest,
  fetchEmployeeEmergencyContractInfoSuccess,
  fetchEmployeeEmergencyContractInfoError,
  editEmployeeEmergencyContractInfoRequest,
  editEmployeeEmergencyContractInfoSuccess,
  editEmployeeEmergencyContractInfoError,
  deleteEmployeeEmergencyContractInfoRequest,
  deleteEmployeeEmergencyContractInfoSuccess,
  deleteEmployeeEmergencyContractInfoError,
} = employeeEmergencyContractInfoActions;

const fetchEmployeeEmergencyContractInfo = (data) => {
  return async (dispatch) => {
    dispatch(fetchEmployeeEmergencyContractInfoRequest());

    try {
      const employeeEmergencyContractInfo = await getEmergencyContractInfo(
        data
      );
      dispatch(
        fetchEmployeeEmergencyContractInfoSuccess(employeeEmergencyContractInfo)
      );
    } catch (err) {
      dispatch(fetchEmployeeEmergencyContractInfoError(err.message));
    }
  };
};

const editEmployeeEmergencyContractInfo = (data) => {
  return async (dispatch) => {
    dispatch(editEmployeeEmergencyContractInfoRequest());

    try {
      await editEmergencyContractInfo(data);
      dispatch(editEmployeeEmergencyContractInfoSuccess());
      toast.success("Амжилттай заслаа");
    } catch (err) {
      dispatch(editEmployeeEmergencyContractInfoError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const deleteEmployeeEmergencyContractInfo = (id) => {
  return async (dispatch) => {
    dispatch(deleteEmployeeEmergencyContractInfoRequest());

    try {
      await deleteEmergencyContractInfo(id);
      dispatch(deleteEmployeeEmergencyContractInfoSuccess());
      toast.success("Амжилттай устгалаа");
    } catch (err) {
      dispatch(deleteEmployeeEmergencyContractInfoError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

export default {
  fetchEmployeeEmergencyContractInfo,
  editEmployeeEmergencyContractInfo,
  deleteEmployeeEmergencyContractInfo,
};
