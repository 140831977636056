import { getBanks } from "services/bank";
import { default as banksActions } from "./actions";

const { fetchBanksRequest, fetchBanksSuccess, fetchBanksError } = banksActions;

const fetchBanks = () => {
  return async (dispatch) => {
    dispatch(fetchBanksRequest());

    try {
      const banks = await getBanks();
      dispatch(fetchBanksSuccess(banks));
    } catch (err) {
      dispatch(fetchBanksError(err.message));
    }
  };
};

export default { fetchBanks };
