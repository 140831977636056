import { default as types } from "./types";

const {
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  CHECK_GUID_REQUEST,
  CHECK_GUID_SUCCESS,
  CHECK_GUID_FAILURE,
  PUT_PASSWORD_REQUEST,
  PUT_PASSWORD_SUCCESS,
  PUT_PASSWORD_FAILURE,
} = types;

const resetPasswordRequest = () => {
  return {
    type: RESET_PASSWORD_REQUEST,
  };
};

const resetPasswordSuccess = () => {
  return {
    type: RESET_PASSWORD_SUCCESS,
  };
};

const resetPasswordError = (error) => {
  return {
    type: RESET_PASSWORD_FAILURE,
    payload: error,
  };
};

const putPasswordRequest = () => {
  return {
    type: PUT_PASSWORD_REQUEST,
  };
};

const putPasswordSuccess = () => {
  return {
    type: PUT_PASSWORD_SUCCESS,
  };
};

const putPasswordError = (error) => {
  return {
    type: PUT_PASSWORD_FAILURE,
    payload: error,
  };
};

const checkGuidRequest = () => {
  return {
    type: CHECK_GUID_REQUEST,
    payload: 1
  };
};

const checkGuidSuccess = () => {
  return {
    type: CHECK_GUID_SUCCESS,
  };
};

const checkGuidError = (error) => {
  return {
    type: CHECK_GUID_FAILURE,
    payload: error,
  };
};

export default {
  resetPasswordRequest,
  resetPasswordSuccess,
  resetPasswordError,
  putPasswordRequest,
  putPasswordSuccess,
  putPasswordError,
  checkGuidRequest,
  checkGuidSuccess,
  checkGuidError,
};