import { default as types } from "./types";

const {
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  PUT_PASSWORD_REQUEST,
  PUT_PASSWORD_SUCCESS,
  PUT_PASSWORD_FAILURE,
  CHECK_GUID_REQUEST,
  CHECK_GUID_SUCCESS,
  CHECK_GUID_FAILURE,
} = types;

const initialState = {
  isLoading: false,
  list: [],
  isActive: false,
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_PASSWORD_REQUEST:
    case PUT_PASSWORD_REQUEST:
    case CHECK_GUID_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case RESET_PASSWORD_SUCCESS:
    case PUT_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case CHECK_GUID_SUCCESS:
      return {
        ...state,
        isActive: true,
        isLoading: false,
        error: "",
      };
    case RESET_PASSWORD_FAILURE:
    case PUT_PASSWORD_FAILURE:
    case CHECK_GUID_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
