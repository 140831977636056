const FETCH_EMP_RAISES_AND_DEDUCTIONS_REQUEST =
  "FETCH_EMP_RAISES_AND_DEDUCTIONS_REQUEST";
const FETCH_EMP_RAISES_AND_DEDUCTIONS_SUCCESS =
  "FETCH_EMP_RAISES_AND_DEDUCTIONS_SUCCESS";
const FETCH_EMP_RAISES_AND_DEDUCTIONS_FAILURE =
  "FETCH_EMP_RAISES_AND_DEDUCTIONS_FAILURE";

export default {
  FETCH_EMP_RAISES_AND_DEDUCTIONS_REQUEST,
  FETCH_EMP_RAISES_AND_DEDUCTIONS_SUCCESS,
  FETCH_EMP_RAISES_AND_DEDUCTIONS_FAILURE,
};
