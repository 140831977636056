import { default as dashboardsTypes } from "./types";

const {
  FETCH_DASHBOARDS_REQUEST,
  FETCH_DASHBOARDAGE_SUCCESS,
  FETCH_DASHBOARDAGE_FAILURE,
  FETCH_DASHBOARDEMPCOUNT_SUCCESS,
  FETCH_DASHBOARDEMPCOUNT_FAILURE,
  FETCH_DASHBOARDDEGREE_SUCCESS,
  FETCH_DASHBOARDDEGREE_FAILURE,
  FETCH_DASHBOARDGENDER_SUCCESS,
  FETCH_DASHBOARDGENDER_FAILURE,
  FETCH_DASHBOARDMARITAL_SUCCESS,
  FETCH_DASHBOARDMARITAL_FAILURE,
  FETCH_DASHBOARDSALARY_SUCCESS,
  FETCH_DASHBOARDSALARY_FAILURE,
  FETCH_DASHBOARDWORKEDYEAR_SUCCESS,
  FETCH_DASHBOARDWORKEDYEAR_FAILURE,
} = dashboardsTypes;

const fetchDashboardsRequest = () => {
  return {
    type: FETCH_DASHBOARDS_REQUEST,
  };
};

const fetchDashboardAgeSuccess = (dashboards) => {
  return {
    type: FETCH_DASHBOARDAGE_SUCCESS,
    payload: dashboards,
  };
};

const fetchDashboardAgeError = (error) => {
  return {
    type: FETCH_DASHBOARDAGE_FAILURE,
    payload: error,
  };
};

const fetchDashboardEmpCountSuccess = (dashboards) => {
  return {
    type: FETCH_DASHBOARDEMPCOUNT_SUCCESS,
    payload: dashboards,
  };
};

const fetchDashboardEmpCountError = (error) => {
  return {
    type: FETCH_DASHBOARDEMPCOUNT_FAILURE,
    payload: error,
  };
};

const fetchDashboardDegreeSuccess = (dashboards) => {
  return {
    type: FETCH_DASHBOARDDEGREE_SUCCESS,
    payload: dashboards,
  };
};

const fetchDashboardDegreeError = (error) => {
  return {
    type: FETCH_DASHBOARDDEGREE_FAILURE,
    payload: error,
  };
};

const fetchDashboardGenderSuccess = (dashboards) => {
  return {
    type: FETCH_DASHBOARDGENDER_SUCCESS,
    payload: dashboards,
  };
};

const fetchDashboardGenderError = (error) => {
  return {
    type: FETCH_DASHBOARDGENDER_FAILURE,
    payload: error,
  };
};

const fetchDashboardMaritalSuccess = (dashboards) => {
  return {
    type: FETCH_DASHBOARDMARITAL_SUCCESS,
    payload: dashboards,
  };
};

const fetchDashboardMaritalError = (error) => {
  return {
    type: FETCH_DASHBOARDMARITAL_FAILURE,
    payload: error,
  };
};

const fetchDashboardSalarySuccess = (dashboards) => {
  return {
    type: FETCH_DASHBOARDSALARY_SUCCESS,
    payload: dashboards,
  };
};

const fetchDashboardSalaryError = (error) => {
  return {
    type: FETCH_DASHBOARDSALARY_FAILURE,
    payload: error,
  };
};

const fetchDashboardWorkedYearSuccess = (dashboards) => {
  return {
    type: FETCH_DASHBOARDWORKEDYEAR_SUCCESS,
    payload: dashboards,
  };
};

const fetchDashboardWorkedYearError = (error) => {
  return {
    type: FETCH_DASHBOARDWORKEDYEAR_FAILURE,
    payload: error,
  };
};

export default {
  fetchDashboardsRequest,
  fetchDashboardAgeSuccess,
  fetchDashboardAgeError,
  fetchDashboardEmpCountSuccess,
  fetchDashboardEmpCountError,
  fetchDashboardDegreeSuccess,
  fetchDashboardDegreeError,
  fetchDashboardGenderSuccess,
  fetchDashboardGenderError,
  fetchDashboardMaritalSuccess,
  fetchDashboardMaritalError,
  fetchDashboardSalarySuccess,
  fetchDashboardSalaryError,
  fetchDashboardWorkedYearSuccess,
  fetchDashboardWorkedYearError,
};