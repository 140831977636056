import { toast } from "react-toastify";
import { add, edit, remove, getEmpDeductions } from "services/empDeduction";
import { default as empDeductionsActions } from "./actions";

const {
  addEmpDeductionRequest,
  addEmpDeductionSuccess,
  addEmpDeductionError,
  editEmpDeductionRequest,
  editEmpDeductionSuccess,
  editEmpDeductionError,
  deleteEmpDeductionRequest,
  deleteEmpDeductionSuccess,
  deleteEmpDeductionError,
  fetchEmpDeductionsRequest,
  fetchEmpDeductionsSuccess,
  fetchEmpDeductionsError,
} = empDeductionsActions;

const addEmpDeduction = (data) => {
  return async (dispatch) => {
    dispatch(addEmpDeductionRequest());

    try {
      await add(data);
      dispatch(addEmpDeductionSuccess());
      toast.success("Амжилттай нэмлээ");
    } catch (err) {
      dispatch(addEmpDeductionError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const editEmpDeduction = (data) => {
  return async (dispatch) => {
    dispatch(editEmpDeductionRequest());

    try {
      await edit(data);
      dispatch(editEmpDeductionSuccess());
      toast.success("Амжилттай заслаа");
    } catch (err) {
      dispatch(editEmpDeductionError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const deleteEmpDeduction = (id) => {
  return async (dispatch) => {
    dispatch(deleteEmpDeductionRequest());

    try {
      await remove(id);
      dispatch(deleteEmpDeductionSuccess());
      toast.success("Амжилттай устгалаа");
    } catch (err) {
      dispatch(deleteEmpDeductionError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const fetchEmpDeductions = (empId) => {
  return async (dispatch) => {
    dispatch(fetchEmpDeductionsRequest());

    try {
      const empDeductions = await getEmpDeductions(empId);
      dispatch(fetchEmpDeductionsSuccess(empDeductions));
    } catch (err) {
      dispatch(fetchEmpDeductionsError(err.message));
    }
  };
};

export default {
  addEmpDeduction,
  editEmpDeduction,
  deleteEmpDeduction,
  fetchEmpDeductions,
};
