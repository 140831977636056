import { toast } from "react-toastify";
import {
  getDocumentFolders,
  createDocumentFolder,
  updateDocumentFolder,
  removeDocumentFolder,
} from "services/documentFolder";
import { default as documentfoldersActions } from "./actions";

const {
  addDocumentFolderRequest,
  addDocumentFolderSuccess,
  addDocumentFolderError,
  fetchDocumentFoldersRequest,
  fetchDocumentFoldersSuccess,
  fetchDocumentFoldersError,
} = documentfoldersActions;

const fetchDocumentFolders = () => {
  return async (dispatch) => {
    dispatch(fetchDocumentFoldersRequest());

    try {
      const documentfolders = await getDocumentFolders();
      dispatch(fetchDocumentFoldersSuccess(documentfolders));
    } catch (err) {
      dispatch(fetchDocumentFoldersError(err.message));
    }
  };
};

const addDocumentFolder = (data) => {
  return async (dispatch) => {
    dispatch(addDocumentFolderRequest());

    try {
      await createDocumentFolder(data);
      dispatch(addDocumentFolderSuccess());
      toast.success("Амжилттай нэмлээ");
    } catch (err) {
      dispatch(addDocumentFolderError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const editDocumentFolder = (data) => {
  return async (dispatch) => {
    dispatch(addDocumentFolderRequest());

    try {
      await updateDocumentFolder(data);
      dispatch(addDocumentFolderSuccess());
      toast.success("Амжилттай заслаа");
    } catch (err) {
      dispatch(addDocumentFolderError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const deleteDocumentFolder = (data) => {
  return async (dispatch) => {
    dispatch(addDocumentFolderRequest());

    try {
      await removeDocumentFolder(data);
      dispatch(addDocumentFolderSuccess());
      toast.success("Амжилттай устгалаа");
    } catch (err) {
      dispatch(addDocumentFolderError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

export default {
  fetchDocumentFolders,
  addDocumentFolder,
  editDocumentFolder,
  deleteDocumentFolder,
};
