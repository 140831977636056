import { default as familyMembersTypes } from "./types";

const {
  FETCH_FAMILY_MEMBERS_REQUEST,
  FETCH_FAMILY_MEMBERS_SUCCESS,
  FETCH_FAMILY_MEMBERS_FAILURE,
} = familyMembersTypes;

const fetchFamilyMembersRequest = () => {
  return {
    type: FETCH_FAMILY_MEMBERS_REQUEST,
  };
};

const fetchFamilyMembersSuccess = (familyMembers) => {
  return {
    type: FETCH_FAMILY_MEMBERS_SUCCESS,
    payload: familyMembers,
  };
};

const fetchFamilyMembersError = (error) => {
  return {
    type: FETCH_FAMILY_MEMBERS_FAILURE,
    payload: error,
  };
};

export default {
  fetchFamilyMembersRequest,
  fetchFamilyMembersSuccess,
  fetchFamilyMembersError,
};
