import { default as empSchedulesTypes } from "./types";

const {
  ADD_EMP_SCHEDULE_REQUEST,
  ADD_EMP_SCHEDULE_SUCCESS,
  ADD_EMP_SCHEDULE_FAILURE,
  EDIT_EMP_SCHEDULE_REQUEST,
  EDIT_EMP_SCHEDULE_SUCCESS,
  EDIT_EMP_SCHEDULE_FAILURE,
  FETCH_EMP_SCHEDULES_REQUEST,
  FETCH_EMP_SCHEDULES_SUCCESS,
  FETCH_EMP_SCHEDULES_FAILURE,
  FETCH_EMP_SCHEDULE_REQUEST,
  FETCH_EMP_SCHEDULE_SUCCESS,
  FETCH_EMP_SCHEDULE_FAILURE,
  CLEAR_EMP_SCHEDULE,
} = empSchedulesTypes;

const initialState = {
  isLoading: false,
  list: [],
  detail: null,
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_EMP_SCHEDULE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_EMP_SCHEDULE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case ADD_EMP_SCHEDULE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case EDIT_EMP_SCHEDULE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case EDIT_EMP_SCHEDULE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case EDIT_EMP_SCHEDULE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case FETCH_EMP_SCHEDULES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_EMP_SCHEDULES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: action.payload,
        error: "",
      };
    case FETCH_EMP_SCHEDULES_FAILURE:
      return {
        ...state,
        isLoading: false,
        list: [],
        error: action.payload,
      };
    case FETCH_EMP_SCHEDULE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_EMP_SCHEDULE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        detail: action.payload,
        error: "",
      };
    case FETCH_EMP_SCHEDULE_FAILURE:
      return {
        ...state,
        isLoading: false,
        detail: null,
        error: action.payload,
      };
    case CLEAR_EMP_SCHEDULE:
      return {
        ...state,
        detail: null,
      };
    default:
      return state;
  }
};

export default reducer;
