import { default as empholidaysTypes } from "./types";

const {
  ADD_EMPHOLIDAY_REQUEST,
  ADD_EMPHOLIDAY_SUCCESS,
  ADD_EMPHOLIDAY_FAILURE,
  FETCH_EMPHOLIDAYS_REQUEST,
  FETCH_EMPHOLIDAYS_SUCCESS,
  FETCH_EMPHOLIDAYS_FAILURE,
} = empholidaysTypes;

const initialState = {
  isLoading: false,
  list: [],
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_EMPHOLIDAY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_EMPHOLIDAY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case ADD_EMPHOLIDAY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case FETCH_EMPHOLIDAYS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_EMPHOLIDAYS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: action.payload,
        error: "",
      };
    case FETCH_EMPHOLIDAYS_FAILURE:
      return {
        ...state,
        isLoading: false,
        list: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
