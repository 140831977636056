import { toast } from "react-toastify";
import { add, edit, remove, getEmpRaises } from "services/empRaise";
import { default as empRaisesActions } from "./actions";

const {
  addEmpRaiseRequest,
  addEmpRaiseSuccess,
  addEmpRaiseError,
  editEmpRaiseRequest,
  editEmpRaiseSuccess,
  editEmpRaiseError,
  deleteEmpRaiseRequest,
  deleteEmpRaiseSuccess,
  deleteEmpRaiseError,
  fetchEmpRaisesRequest,
  fetchEmpRaisesSuccess,
  fetchEmpRaisesError,
} = empRaisesActions;

const addEmpRaise = (data) => {
  return async (dispatch) => {
    dispatch(addEmpRaiseRequest());

    try {
      await add(data);
      dispatch(addEmpRaiseSuccess());
      toast.success("Амжилттай нэмлээ");
    } catch (err) {
      dispatch(addEmpRaiseError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const editEmpRaise = (data) => {
  return async (dispatch) => {
    dispatch(editEmpRaiseRequest());

    try {
      await edit(data);
      dispatch(editEmpRaiseSuccess());
      toast.success("Амжилттай заслаа");
    } catch (err) {
      dispatch(editEmpRaiseError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const deleteEmpRaise = (id) => {
  return async (dispatch) => {
    dispatch(deleteEmpRaiseRequest());

    try {
      await remove(id);
      dispatch(deleteEmpRaiseSuccess());
      toast.success("Амжилттай устгалаа");
    } catch (err) {
      dispatch(deleteEmpRaiseError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const fetchEmpRaises = (empId) => {
  return async (dispatch) => {
    dispatch(fetchEmpRaisesRequest());

    try {
      const empRaises = await getEmpRaises(empId);
      dispatch(fetchEmpRaisesSuccess(empRaises));
    } catch (err) {
      dispatch(fetchEmpRaisesError(err.message));
    }
  };
};

export default {
  addEmpRaise,
  editEmpRaise,
  deleteEmpRaise,
  fetchEmpRaises,
};
