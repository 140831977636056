import { toast } from "react-toastify";
import {
  addSchedule,
  addScheduleShift,
  editSchedule,
  editScheduleShift,
  deleteSchedule,
  getSchedules,
  getSchedule,
} from "services/schedule";
import { default as schedulesActions } from "./actions";

const {
  addScheduleRequest,
  addScheduleSuccess,
  addScheduleError,
  editScheduleRequest,
  editScheduleSuccess,
  editScheduleError,
  deleteScheduleRequest,
  deleteScheduleSuccess,
  deleteScheduleError,
  fetchSchedulesRequest,
  fetchSchedulesSuccess,
  fetchSchedulesError,
  fetchScheduleRequest,
  fetchScheduleSuccess,
  fetchScheduleError,
} = schedulesActions;

const add = (data) => {
  return async (dispatch) => {
    dispatch(addScheduleRequest());

    try {
      await addSchedule(data);
      dispatch(addScheduleSuccess());
      toast.success("Амжилттай нэмлээ");
    } catch (err) {
      dispatch(addScheduleError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const addShift = (data) => {
  return async (dispatch) => {
    dispatch(addScheduleRequest());

    try {
      await addScheduleShift(data);
      dispatch(addScheduleSuccess());
      toast.success("Амжилттай нэмлээ");
    } catch (err) {
      dispatch(addScheduleError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const edit = (data) => {
  return async (dispatch) => {
    dispatch(editScheduleRequest());

    try {
      await editSchedule(data);
      dispatch(editScheduleSuccess());
      toast.success("Амжилттай заслаа");
    } catch (err) {
      dispatch(editScheduleError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const editShift = (data) => {
  return async (dispatch) => {
    dispatch(editScheduleRequest());

    try {
      await editScheduleShift(data);
      dispatch(editScheduleSuccess());
      toast.success("Амжилттай заслаа");
    } catch (err) {
      dispatch(editScheduleError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const remove = (id) => {
  return async (dispatch) => {
    dispatch(deleteScheduleRequest());

    try {
      await deleteSchedule(id);
      dispatch(deleteScheduleSuccess());
      toast.success("Амжилттай устгалаа");
    } catch (err) {
      dispatch(deleteScheduleError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const fetchSchedules = () => {
  return async (dispatch) => {
    dispatch(fetchSchedulesRequest());

    try {
      const schedules = await getSchedules();
      dispatch(fetchSchedulesSuccess(schedules));
    } catch (err) {
      dispatch(fetchSchedulesError(err.message));
    }
  };
};

const fetchSchedule = (id) => {
  return async (dispatch) => {
    dispatch(fetchScheduleRequest());

    try {
      const schedule = await getSchedule(id);
      dispatch(fetchScheduleSuccess(schedule));
    } catch (err) {
      dispatch(fetchScheduleError(err.message));
    }
  };
};

export default {
  add,
  addShift,
  edit,
  editShift,
  remove,
  fetchSchedules,
  fetchSchedule,
};
