import { toast } from "react-toastify";
import {
  add,
  edit,
  remove,
  getDeductions,
  getDeduction,
} from "services/deduction";
import { default as deductionsActions } from "./actions";

const {
  addDeductionRequest,
  addDeductionSuccess,
  addDeductionError,
  editDeductionRequest,
  editDeductionSuccess,
  editDeductionError,
  deleteDeductionRequest,
  deleteDeductionSuccess,
  deleteDeductionError,
  fetchDeductionsRequest,
  fetchDeductionsSuccess,
  fetchDeductionsError,
  fetchDeductionRequest,
  fetchDeductionSuccess,
  fetchDeductionError,
} = deductionsActions;

const addDeduction = (data) => {
  return async (dispatch) => {
    dispatch(addDeductionRequest());

    try {
      await add(data);
      dispatch(addDeductionSuccess());
      toast.success("Амжилттай нэмлээ");
    } catch (err) {
      dispatch(addDeductionError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const editDeduction = (data) => {
  return async (dispatch) => {
    dispatch(editDeductionRequest());

    try {
      await edit(data);
      dispatch(editDeductionSuccess());
      toast.success("Амжилттай заслаа");
    } catch (err) {
      dispatch(editDeductionError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const deleteDeduction = (id) => {
  return async (dispatch) => {
    dispatch(deleteDeductionRequest());

    try {
      await remove(id);
      dispatch(deleteDeductionSuccess());
      toast.success("Амжилттай устгалаа");
    } catch (err) {
      dispatch(deleteDeductionError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const fetchDeductions = () => {
  return async (dispatch) => {
    dispatch(fetchDeductionsRequest());

    try {
      const deductions = await getDeductions();
      dispatch(fetchDeductionsSuccess(deductions));
    } catch (err) {
      dispatch(fetchDeductionsError(err.message));
    }
  };
};

const fetchDeduction = (id) => {
  return async (dispatch) => {
    dispatch(fetchDeductionRequest());

    try {
      const deduction = await getDeduction(id);
      dispatch(fetchDeductionSuccess(deduction));
    } catch (err) {
      dispatch(fetchDeductionError(err.message));
    }
  };
};

export default {
  addDeduction,
  editDeduction,
  deleteDeduction,
  fetchDeductions,
  fetchDeduction,
};
