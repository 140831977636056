const FETCH_EMPLOYEE_MIGRATION_INFO_REQUEST =
  "FETCH_EMPLOYEE_MIGRATION_INFO_REQUEST";
const FETCH_EMPLOYEE_MIGRATION_INFO_SUCCESS =
  "FETCH_EMPLOYEE_MIGRATION_INFO_SUCCESS";
const FETCH_EMPLOYEE_MIGRATION_INFO_FAILURE =
  "FETCH_EMPLOYEE_MIGRATION_INFO_FAILURE";

export default {
  FETCH_EMPLOYEE_MIGRATION_INFO_REQUEST,
  FETCH_EMPLOYEE_MIGRATION_INFO_SUCCESS,
  FETCH_EMPLOYEE_MIGRATION_INFO_FAILURE,
};
