import { default as insurSubCareersTypes } from "./types";

const {
  FETCH_INSUR_SUBCAREERS_REQUEST,
  FETCH_INSUR_SUBCAREERS_SUCCESS,
  FETCH_INSUR_SUBCAREERS_FAILURE,
} = insurSubCareersTypes;

const fetchInsurSubCareersRequest = () => {
  return {
    type: FETCH_INSUR_SUBCAREERS_REQUEST,
  };
};

const fetchInsurSubCareersSuccess = (insurSubCareers) => {
  return {
    type: FETCH_INSUR_SUBCAREERS_SUCCESS,
    payload: insurSubCareers,
  };
};

const fetchInsurSubCareersError = (error) => {
  return {
    type: FETCH_INSUR_SUBCAREERS_FAILURE,
    payload: error,
  };
};

export default {
  fetchInsurSubCareersRequest,
  fetchInsurSubCareersSuccess,
  fetchInsurSubCareersError,
};
