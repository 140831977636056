import { default as languagesTypes } from "./types";

const {
  FETCH_LANGUAGES_REQUEST,
  FETCH_LANGUAGES_SUCCESS,
  FETCH_LANGUAGES_FAILURE,
} = languagesTypes;

const fetchLanguagesRequest = () => {
  return {
    type: FETCH_LANGUAGES_REQUEST,
  };
};

const fetchLanguagesSuccess = (languages) => {
  return {
    type: FETCH_LANGUAGES_SUCCESS,
    payload: languages,
  };
};

const fetchLanguagesError = (error) => {
  return {
    type: FETCH_LANGUAGES_FAILURE,
    payload: error,
  };
};

export default {
  fetchLanguagesRequest,
  fetchLanguagesSuccess,
  fetchLanguagesError,
};
