import { default as employeeOfficeSkillInfoTypes } from "./types";

const {
  FETCH_EMPLOYEE_OFFICE_SKILL_INFO_REQUEST,
  FETCH_EMPLOYEE_OFFICE_SKILL_INFO_SUCCESS,
  FETCH_EMPLOYEE_OFFICE_SKILL_INFO_FAILURE,
  EDIT_EMPLOYEE_OFFICE_SKILL_INFO_REQUEST,
  EDIT_EMPLOYEE_OFFICE_SKILL_INFO_SUCCESS,
  EDIT_EMPLOYEE_OFFICE_SKILL_INFO_FAILURE,
  DELETE_EMPLOYEE_OFFICE_SKILL_INFO_REQUEST,
  DELETE_EMPLOYEE_OFFICE_SKILL_INFO_SUCCESS,
  DELETE_EMPLOYEE_OFFICE_SKILL_INFO_FAILURE,
} = employeeOfficeSkillInfoTypes;

const fetchEmployeeOfficeSkillInfoRequest = () => {
  return {
    type: FETCH_EMPLOYEE_OFFICE_SKILL_INFO_REQUEST,
  };
};

const fetchEmployeeOfficeSkillInfoSuccess = (employeeOfficeSkillInfo) => {
  return {
    type: FETCH_EMPLOYEE_OFFICE_SKILL_INFO_SUCCESS,
    payload: employeeOfficeSkillInfo,
  };
};

const fetchEmployeeOfficeSkillInfoError = (error) => {
  return {
    type: FETCH_EMPLOYEE_OFFICE_SKILL_INFO_FAILURE,
    payload: error,
  };
};

const editEmployeeOfficeSkillInfoRequest = () => {
  return {
    type: EDIT_EMPLOYEE_OFFICE_SKILL_INFO_REQUEST,
  };
};

const editEmployeeOfficeSkillInfoSuccess = () => {
  return {
    type: EDIT_EMPLOYEE_OFFICE_SKILL_INFO_SUCCESS,
  };
};

const editEmployeeOfficeSkillInfoError = (error) => {
  return {
    type: EDIT_EMPLOYEE_OFFICE_SKILL_INFO_FAILURE,
    payload: error,
  };
};

const deleteEmployeeOfficeSkillInfoRequest = () => {
  return {
    type: DELETE_EMPLOYEE_OFFICE_SKILL_INFO_REQUEST,
  };
};

const deleteEmployeeOfficeSkillInfoSuccess = () => {
  return {
    type: DELETE_EMPLOYEE_OFFICE_SKILL_INFO_SUCCESS,
  };
};

const deleteEmployeeOfficeSkillInfoError = (error) => {
  return {
    type: DELETE_EMPLOYEE_OFFICE_SKILL_INFO_FAILURE,
    payload: error,
  };
};

export default {
  fetchEmployeeOfficeSkillInfoRequest,
  fetchEmployeeOfficeSkillInfoSuccess,
  fetchEmployeeOfficeSkillInfoError,
  editEmployeeOfficeSkillInfoRequest,
  editEmployeeOfficeSkillInfoSuccess,
  editEmployeeOfficeSkillInfoError,
  deleteEmployeeOfficeSkillInfoRequest,
  deleteEmployeeOfficeSkillInfoSuccess,
  deleteEmployeeOfficeSkillInfoError,
};
