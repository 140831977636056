import { default as announcementsTypes } from "./types";

const {
  ADD_ANNOUNCEMENT_REQUEST,
  ADD_ANNOUNCEMENT_SUCCESS,
  ADD_ANNOUNCEMENT_FAILURE,
  FETCH_ANNOUNCEMENTS_REQUEST,
  FETCH_ANNOUNCEMENTS_SUCCESS,
  FETCH_ANNOUNCEMENTS_FAILURE,
} = announcementsTypes;

const initialState = {
  isLoading: false,
  list: [],
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ANNOUNCEMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case ADD_ANNOUNCEMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        list: [],
        error: action.payload,
      };
    case FETCH_ANNOUNCEMENTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_ANNOUNCEMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: action.payload,
        error: "",
      };
    case FETCH_ANNOUNCEMENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        list: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
