import { default as deductionsTypes } from "./types";

const {
  ADD_DEDUCTION_REQUEST,
  ADD_DEDUCTION_SUCCESS,
  ADD_DEDUCTION_FAILURE,
  EDIT_DEDUCTION_REQUEST,
  EDIT_DEDUCTION_SUCCESS,
  EDIT_DEDUCTION_FAILURE,
  DELETE_DEDUCTION_REQUEST,
  DELETE_DEDUCTION_SUCCESS,
  DELETE_DEDUCTION_FAILURE,
  FETCH_DEDUCTIONS_REQUEST,
  FETCH_DEDUCTIONS_SUCCESS,
  FETCH_DEDUCTIONS_FAILURE,
  FETCH_DEDUCTION_REQUEST,
  FETCH_DEDUCTION_SUCCESS,
  FETCH_DEDUCTION_FAILURE,
  CLEAR_DEDUCTION,
} = deductionsTypes;

const addDeductionRequest = () => {
  return {
    type: ADD_DEDUCTION_REQUEST,
  };
};

const addDeductionSuccess = () => {
  return {
    type: ADD_DEDUCTION_SUCCESS,
  };
};

const addDeductionError = (error) => {
  return {
    type: ADD_DEDUCTION_FAILURE,
    payload: error,
  };
};

const editDeductionRequest = () => {
  return {
    type: EDIT_DEDUCTION_REQUEST,
  };
};

const editDeductionSuccess = () => {
  return {
    type: EDIT_DEDUCTION_SUCCESS,
  };
};

const editDeductionError = (error) => {
  return {
    type: EDIT_DEDUCTION_FAILURE,
    payload: error,
  };
};

const deleteDeductionRequest = () => {
  return {
    type: DELETE_DEDUCTION_REQUEST,
  };
};

const deleteDeductionSuccess = () => {
  return {
    type: DELETE_DEDUCTION_SUCCESS,
  };
};

const deleteDeductionError = (error) => {
  return {
    type: DELETE_DEDUCTION_FAILURE,
    payload: error,
  };
};

const fetchDeductionsRequest = () => {
  return {
    type: FETCH_DEDUCTIONS_REQUEST,
  };
};

const fetchDeductionsSuccess = (deductions) => {
  return {
    type: FETCH_DEDUCTIONS_SUCCESS,
    payload: deductions,
  };
};

const fetchDeductionsError = (error) => {
  return {
    type: FETCH_DEDUCTIONS_FAILURE,
    payload: error,
  };
};

const fetchDeductionRequest = () => {
  return {
    type: FETCH_DEDUCTION_REQUEST,
  };
};

const fetchDeductionSuccess = (raise) => {
  return {
    type: FETCH_DEDUCTION_SUCCESS,
    payload: raise,
  };
};

const fetchDeductionError = (error) => {
  return {
    type: FETCH_DEDUCTION_FAILURE,
    payload: error,
  };
};

const clearDeduction = () => {
  return {
    type: CLEAR_DEDUCTION,
  };
};

export default {
  addDeductionRequest,
  addDeductionSuccess,
  addDeductionError,
  editDeductionRequest,
  editDeductionSuccess,
  editDeductionError,
  deleteDeductionRequest,
  deleteDeductionSuccess,
  deleteDeductionError,
  fetchDeductionsRequest,
  fetchDeductionsSuccess,
  fetchDeductionsError,
  fetchDeductionRequest,
  fetchDeductionSuccess,
  fetchDeductionError,
  clearDeduction,
};
