import { default as insurPeopleTypes } from "./types";

const {
  FETCH_INSUR_PEOPLE_REQUEST,
  FETCH_INSUR_PEOPLE_SUCCESS,
  FETCH_INSUR_PEOPLE_FAILURE,
} = insurPeopleTypes;

const fetchInsurPeopleRequest = () => {
  return {
    type: FETCH_INSUR_PEOPLE_REQUEST,
  };
};

const fetchInsurPeopleSuccess = (insurPeople) => {
  return {
    type: FETCH_INSUR_PEOPLE_SUCCESS,
    payload: insurPeople,
  };
};

const fetchInsurPeopleError = (error) => {
  return {
    type: FETCH_INSUR_PEOPLE_FAILURE,
    payload: error,
  };
};

export default {
  fetchInsurPeopleRequest,
  fetchInsurPeopleSuccess,
  fetchInsurPeopleError,
};
