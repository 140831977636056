import instance from "./axios.base";
import { dateFormat } from "utils/formatter";

const getPayrollConfig = async () => {
  const res = await instance.get("/prconfigs");

  return res.data;
};

const updatePayrollConfig = async (data) => {
  const res = await instance.post("/prconfigs", data);

  return res.data;
};

const getTimeAttendanceConfig = async () => {
  const res = await instance.get("/taconfigs");

  return res.data;
};

const updateTimeAttendanceConfig = async (data) => {
  const res = await instance.post("/taconfigs", data);

  return res.data;
};

const getTaxCredits = async () => {
  const res = await instance.get("/prtaxcreditconfig");

  return res.data;
};

const createTaxCredit = async (data) => {
  const res = await instance.post("/prtaxcreditconfig", data);

  return res.data;
};

const updateTaxCredit = async (data) => {
  const res = await instance.put("/prtaxcreditconfig", data);

  return res.data;
};

const removeTaxCredit = async (data) => {
  const res = await instance.delete(`/prtaxcreditconfig/${data.id}`);

  return res.data;
};

const addDirectManager = async (data) => {
  const res = await instance.post("/hrtacalculationdirect", data);

  return res.data;
};

const deleteDirectManager = async (id) => {
  const res = await instance.delete(`/hrtacalculationdirect/${id}`);

  return res.data;
};

const getManagers = async () => {
  const res = await instance.get("/hrtacalculationdirect");

  return res.data;
};

export {
  getPayrollConfig,
  updatePayrollConfig,
  getTimeAttendanceConfig,
  updateTimeAttendanceConfig,
  getTaxCredits,
  createTaxCredit,
  updateTaxCredit,
  removeTaxCredit,
  addDirectManager,
  deleteDirectManager,
  getManagers,
};
