import { default as types } from "./types";

const {
  FETCH_RAISE_TYPES_REQUEST,
  FETCH_RAISE_TYPES_SUCCESS,
  FETCH_RAISE_TYPES_FAILURE,
} = types;

const fetchRaiseTypesRequest = () => {
  return {
    type: FETCH_RAISE_TYPES_REQUEST,
  };
};

const fetchRaiseTypesSuccess = (raiseTypes) => {
  return {
    type: FETCH_RAISE_TYPES_SUCCESS,
    payload: raiseTypes,
  };
};

const fetchRaiseTypesError = (error) => {
  return {
    type: FETCH_RAISE_TYPES_FAILURE,
    payload: error,
  };
};

export default {
  fetchRaiseTypesRequest,
  fetchRaiseTypesSuccess,
  fetchRaiseTypesError,
};
