import { toast } from "react-toastify";
import {
  getFamilyInfo,
  editFamilyInfo,
  deleteFamilyInfo,
} from "services/familyInfo";
import { default as employeeFamilyInfoActions } from "./actions";

const {
  fetchEmployeeFamilyInfoRequest,
  fetchEmployeeFamilyInfoSuccess,
  fetchEmployeeFamilyInfoError,
  editEmployeeFamilyInfoRequest,
  editEmployeeFamilyInfoSuccess,
  editEmployeeFamilyInfoError,
  deleteEmployeeFamilyInfoRequest,
  deleteEmployeeFamilyInfoSuccess,
  deleteEmployeeFamilyInfoError,
} = employeeFamilyInfoActions;

const fetchEmployeeFamilyInfo = (id) => {
  return async (dispatch) => {
    dispatch(fetchEmployeeFamilyInfoRequest());

    try {
      const employeeFamilyInfo = await getFamilyInfo(id);
      dispatch(fetchEmployeeFamilyInfoSuccess(employeeFamilyInfo));
    } catch (err) {
      dispatch(fetchEmployeeFamilyInfoError(err.message));
    }
  };
};

const editEmployeeFamilyInfo = (data) => {
  return async (dispatch) => {
    dispatch(editEmployeeFamilyInfoRequest());

    try {
      await editFamilyInfo(data);
      dispatch(editEmployeeFamilyInfoSuccess());
      toast.success("Амжилттай заслаа");
    } catch (err) {
      dispatch(editEmployeeFamilyInfoError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const deleteEmployeeFamilyInfo = (id) => {
  return async (dispatch) => {
    dispatch(deleteEmployeeFamilyInfoRequest());

    try {
      await deleteFamilyInfo(id);
      dispatch(deleteEmployeeFamilyInfoSuccess());
      toast.success("Амжилттай устгалаа");
    } catch (err) {
      dispatch(deleteEmployeeFamilyInfoError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

export default {
  fetchEmployeeFamilyInfo,
  editEmployeeFamilyInfo,
  deleteEmployeeFamilyInfo,
};
