import { default as basicSalariesTypes } from "./types";

const {
  ADD_BASIC_SALARY_REQUEST,
  ADD_BASIC_SALARY_SUCCESS,
  ADD_BASIC_SALARY_FAILURE,
  EDIT_BASIC_SALARY_REQUEST,
  EDIT_BASIC_SALARY_SUCCESS,
  EDIT_BASIC_SALARY_FAILURE,
  DELETE_BASIC_SALARY_REQUEST,
  DELETE_BASIC_SALARY_SUCCESS,
  DELETE_BASIC_SALARY_FAILURE,
  FETCH_BASIC_SALARIES_REQUEST,
  FETCH_BASIC_SALARIES_SUCCESS,
  FETCH_BASIC_SALARIES_FAILURE,
  FETCH_BASIC_SALARY_REQUEST,
  FETCH_BASIC_SALARY_SUCCESS,
  FETCH_BASIC_SALARY_FAILURE,
  ARCHIVE_BASIC_SALARY_REQUEST,
  ARCHIVE_BASIC_SALARY_SUCCESS,
  ARCHIVE_BASIC_SALARY_FAILURE,
  RECALCULATE_SALARY_REQUEST,
  RECALCULATE_SALARY_SUCCESS,
  RECALCULATE_SALARY_FAILURE,
} = basicSalariesTypes;

const addBasicSalaryRequest = () => {
  return {
    type: ADD_BASIC_SALARY_REQUEST,
  };
};

const addBasicSalarySuccess = () => {
  return {
    type: ADD_BASIC_SALARY_SUCCESS,
  };
};

const addBasicSalaryError = (error) => {
  return {
    type: ADD_BASIC_SALARY_FAILURE,
    payload: error,
  };
};

const editBasicSalaryRequest = () => {
  return {
    type: EDIT_BASIC_SALARY_REQUEST,
  };
};

const editBasicSalarySuccess = () => {
  return {
    type: EDIT_BASIC_SALARY_SUCCESS,
  };
};

const editBasicSalaryError = (error) => {
  return {
    type: EDIT_BASIC_SALARY_FAILURE,
    payload: error,
  };
};

const deleteBasicSalaryRequest = () => {
  return {
    type: DELETE_BASIC_SALARY_REQUEST,
  };
};

const deleteBasicSalarySuccess = () => {
  return {
    type: DELETE_BASIC_SALARY_SUCCESS,
  };
};

const deleteBasicSalaryError = (error) => {
  return {
    type: DELETE_BASIC_SALARY_FAILURE,
    payload: error,
  };
};

const fetchBasicSalariesRequest = () => {
  return {
    type: FETCH_BASIC_SALARIES_REQUEST,
  };
};

const fetchBasicSalariesSuccess = (schedules) => {
  return {
    type: FETCH_BASIC_SALARIES_SUCCESS,
    payload: schedules,
  };
};

const fetchBasicSalariesError = (error) => {
  return {
    type: FETCH_BASIC_SALARIES_FAILURE,
    payload: error,
  };
};

const fetchBasicSalaryRequest = () => {
  return {
    type: FETCH_BASIC_SALARY_REQUEST,
  };
};

const fetchBasicSalarySuccess = (data) => {
  return {
    type: FETCH_BASIC_SALARY_SUCCESS,
    payload: data,
  };
};

const fetchBasicSalaryError = (error) => {
  return {
    type: FETCH_BASIC_SALARY_FAILURE,
    payload: error,
  };
};

const archiveBasicSalaryRequest = () => {
  return {
    type: ARCHIVE_BASIC_SALARY_REQUEST,
  };
};

const archiveBasicSalarySuccess = () => {
  return {
    type: ARCHIVE_BASIC_SALARY_SUCCESS,
  };
};

const archiveBasicSalaryError = (error) => {
  return {
    type: ARCHIVE_BASIC_SALARY_FAILURE,
    payload: error,
  };
};

const recalculateSalaryRequest = () => {
  return {
    type: RECALCULATE_SALARY_REQUEST,
  };
};

const recalculateSalarySuccess = () => {
  return {
    type: RECALCULATE_SALARY_SUCCESS,
  };
};

const recalculateSalaryError = (error) => {
  return {
    type: RECALCULATE_SALARY_FAILURE,
    payload: error,
  };
};

export default {
  addBasicSalaryRequest,
  addBasicSalarySuccess,
  addBasicSalaryError,
  editBasicSalaryRequest,
  editBasicSalarySuccess,
  editBasicSalaryError,
  deleteBasicSalaryRequest,
  deleteBasicSalarySuccess,
  deleteBasicSalaryError,
  fetchBasicSalariesRequest,
  fetchBasicSalariesSuccess,
  fetchBasicSalariesError,
  fetchBasicSalaryRequest,
  fetchBasicSalarySuccess,
  fetchBasicSalaryError,
  archiveBasicSalaryRequest,
  archiveBasicSalarySuccess,
  archiveBasicSalaryError,
  recalculateSalaryRequest,
  recalculateSalarySuccess,
  recalculateSalaryError,
};
