import instance from "./axios.base";
import { dateFormat } from "utils/formatter";

const getFeedbacks = async () => {
  const res = await instance.get("/empfeedbacks");
  res.data.map((row) => {
    row.createdAt = dateFormat(row.createdAt)
  })
  
  return res.data;
};

const createFeedback = async (data) => {
  const res = await instance.post("/empfeedbacks", data);

  return res.data;
};

const updateFeedback = async (data) => {
  const res = await instance.put("/empfeedbacks", data);

  return res.data;
};

const removeFeedback = async (data) => {
  const res = await instance.delete(`/empfeedbacks/${data.id}`);

  return res.data;
};

export { getFeedbacks, createFeedback, updateFeedback, removeFeedback };