const ADD_EMPHOLIDAY_REQUEST = "ADD_EMPHOLIDAY_REQUEST";
const ADD_EMPHOLIDAY_SUCCESS = "ADD_EMPHOLIDAY_SUCCESS";
const ADD_EMPHOLIDAY_FAILURE = "ADD_EMPHOLIDAY_FAILURE";
const FETCH_EMPHOLIDAYS_REQUEST = "FETCH_EMPHOLIDAYS_REQUEST";
const FETCH_EMPHOLIDAYS_SUCCESS = "FETCH_EMPHOLIDAYS_SUCCESS";
const FETCH_EMPHOLIDAYS_FAILURE = "FETCH_EMPHOLIDAYS_FAILURE";

export default {
  ADD_EMPHOLIDAY_REQUEST,
  ADD_EMPHOLIDAY_SUCCESS,
  ADD_EMPHOLIDAY_FAILURE,
  FETCH_EMPHOLIDAYS_REQUEST,
  FETCH_EMPHOLIDAYS_SUCCESS,
  FETCH_EMPHOLIDAYS_FAILURE,
};
