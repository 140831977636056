import { toast } from "react-toastify";
import {
  addJournal,
  deleteJournal,
  getJournals,
  getJournal,
  getStandardTime,
  calculateSalaryJournal,
  getEmpLateMinuteList,
  archiveJournal,
  editOvertime as editJournalOvertime,
  getJournalExportData
} from "services/journal";
import { default as journalsActions } from "./actions";

const {
  addJournalRequest,
  addJournalSuccess,
  addJournalError,
  deleteJournalRequest,
  deleteJournalSuccess,
  deleteJournalError,
  fetchJournalsRequest,
  fetchJournalsSuccess,
  fetchJournalsError,
  fetchJournalDataRequest,
  fetchJournalDataSuccess,
  fetchJournalDataError,
  fetchJournalRequest,
  fetchJournalSuccess,
  fetchJournalError,
  fetchStandardTimeRequest,
  fetchStandardTimeSuccess,
  fetchStandardTimeError,
  calculateJournalRequest,
  calculateJournalSuccess,
  calculateJournalError,
  fetchEmpLateMinutesRequest,
  fetchEmpLateMinutesSuccess,
  fetchEmpLateMinutesFailure,
  archiveJournalRequest,
  archiveJournalSuccess,
  archiveJournalFailure,
  editOvertimeRequest,
  editOvertimeSuccess,
  editOvertimeFailure,
} = journalsActions;

const add = (data) => {
  return async (dispatch) => {
    dispatch(addJournalRequest());

    try {
      await addJournal(data);
      dispatch(addJournalSuccess());
      toast.success("Амжилттай нэмлээ");
    } catch (err) {
      dispatch(addJournalError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const remove = (id) => {
  return async (dispatch) => {
    dispatch(deleteJournalRequest());

    try {
      await deleteJournal(id);
      dispatch(deleteJournalSuccess());
      toast.success("Амжилттай устгалаа");
    } catch (err) {
      dispatch(deleteJournalError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const fetchJournals = (date) => {
  return async (dispatch) => {
    dispatch(fetchJournalsRequest());

    try {
      const journals = await getJournals(date);
      dispatch(fetchJournalsSuccess(journals));
    } catch (err) {
      dispatch(fetchJournalsError(err.message));
    }
  };
};

const fetchJournalDataExport = (date) => {
  return async (dispatch) => {
    dispatch(fetchJournalDataRequest());

    try {
      const journals = await getJournalExportData(date);
      dispatch(fetchJournalDataSuccess(journals));
    } catch (err) {
      dispatch(fetchJournalDataError(err.message));
    }
  };
};

const fetchJournal = (empId, date) => {
  return async (dispatch) => {
    dispatch(fetchJournalRequest());

    try {
      const journal = await getJournal(empId, date);
      dispatch(fetchJournalSuccess(journal));
    } catch (err) {
      dispatch(fetchJournalError(err.message));
    }
  };
};

const fetchStandardTime = (date) => {
  return async (dispatch) => {
    dispatch(fetchStandardTimeRequest());

    try {
      const data = await getStandardTime(date);
      dispatch(fetchStandardTimeSuccess(data));
    } catch (err) {
      dispatch(fetchStandardTimeError(err.message));
    }
  };
};

const calculateJournal = (data) => {
  return async (dispatch) => {
    dispatch(calculateJournalRequest());

    try {
      await calculateSalaryJournal(data);
      dispatch(calculateJournalSuccess());
      toast.success("Амжилттай дахин бодлоо");
    } catch (err) {
      dispatch(calculateJournalError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const fetchEmpLateMinuteList = (empId, year, month) => {
  return async (dispatch) => {
    dispatch(fetchEmpLateMinutesRequest());

    try {
      const empLateMinuteList = await getEmpLateMinuteList(empId, year, month);
      dispatch(fetchEmpLateMinutesSuccess(empLateMinuteList));
    } catch (err) {
      dispatch(fetchEmpLateMinutesFailure(err.message));
    }
  };
};

const archive = (selected) => {
  return async (dispatch) => {
    dispatch(archiveJournalRequest());

    try {
      await archiveJournal(selected);
      dispatch(archiveJournalSuccess());
      toast.success("Амжилттай архивлалаа");
    } catch (err) {
      dispatch(archiveJournalFailure(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const editOvertime = (data) => {
  return async (dispatch) => {
    dispatch(editOvertimeRequest());

    try {
      await editJournalOvertime(data);
      dispatch(editOvertimeSuccess());
      toast.success("Амжилттай заслаа");
    } catch (err) {
      dispatch(editOvertimeFailure(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

export default {
  add,
  remove,
  fetchJournals,
  fetchJournal,
  fetchStandardTime,
  calculateJournal,
  fetchEmpLateMinuteList,
  archive,
  editOvertime,
  fetchJournalDataExport,
};
