import instance from "./axios.base";

const getOfficeSkillInfo = async (id) => {
  const res = await instance.get(`/empofficeprogramskills/${id}`);
  return res.data;
};

const editOfficeSkillInfo = async (data) => {
  await instance.put("/empofficeprogramskills", data);
};

const deleteOfficeSkillInfo = async (id) => {
  await instance.delete(`/empofficeprogramskills/${id}`);
};

export { getOfficeSkillInfo, editOfficeSkillInfo, deleteOfficeSkillInfo };
