import axios from "axios";

import { ls } from "utils/ls";

const config = {
  baseURL: process.env.REACT_APP_API_URL,
  responseType: "json",
};

const instance = axios.create(config);

instance.interceptors.request.use(
  function (config) {
    const token = ls.get("access_token");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);

export default instance;
