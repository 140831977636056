import { toast } from "react-toastify";
import {
  getEducationInfo,
  editEducationInfo,
  deleteEducationInfo,
} from "services/educationInfo";
import { default as employeeEducationInfoActions } from "./actions";

const {
  fetchEmployeeEducationInfoRequest,
  fetchEmployeeEducationInfoSuccess,
  fetchEmployeeEducationInfoError,
  editEmployeeEducationInfoRequest,
  editEmployeeEducationInfoSuccess,
  editEmployeeEducationInfoError,
  deleteEmployeeEducationInfoRequest,
  deleteEmployeeEducationInfoSuccess,
  deleteEmployeeEducationInfoError,
} = employeeEducationInfoActions;

const fetchEmployeeEducationInfo = (data) => {
  return async (dispatch) => {
    dispatch(fetchEmployeeEducationInfoRequest());

    try {
      const employeeEducationInfo = await getEducationInfo(data);
      dispatch(fetchEmployeeEducationInfoSuccess(employeeEducationInfo));
    } catch (err) {
      dispatch(fetchEmployeeEducationInfoError(err.message));
    }
  };
};

const editEmployeeEducationInfo = (data) => {
  return async (dispatch) => {
    dispatch(editEmployeeEducationInfoRequest());

    try {
      await editEducationInfo(data);
      dispatch(editEmployeeEducationInfoSuccess());
      toast.success("Амжилттай заслаа");
    } catch (err) {
      dispatch(editEmployeeEducationInfoError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const deleteEmployeeEducationInfo = (id) => {
  return async (dispatch) => {
    dispatch(deleteEmployeeEducationInfoRequest());

    try {
      await deleteEducationInfo(id);
      dispatch(deleteEmployeeEducationInfoSuccess());
      toast.success("Амжилттай устгалаа");
    } catch (err) {
      dispatch(deleteEmployeeEducationInfoError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

export default {
  fetchEmployeeEducationInfo,
  editEmployeeEducationInfo,
  deleteEmployeeEducationInfo,
};
