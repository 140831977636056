import { getTeamMembers, createTeamMember, updateTeamMember, removeTeamMember } from "services/teamMember";
import { default as teammembersActions } from "./actions";

const {
  addTeamMemberRequest,
  addTeamMemberSuccess,
  addTeamMemberError,
  fetchTeamMembersRequest,
  fetchTeamMembersSuccess,
  fetchTeamMembersError,
} = teammembersActions;

const fetchTeamMembers = (data) => {
  return async (dispatch) => {
    dispatch(fetchTeamMembersRequest());

    try {
      const teammembers = await getTeamMembers(data);
      dispatch(fetchTeamMembersSuccess(teammembers));
    } catch (err) {
      dispatch(fetchTeamMembersError(err.message));
    }
  };
};

const addTeamMember = (data) => {
  return async (dispatch) => {
    dispatch(addTeamMemberRequest());

    console.log('Team member data: ', data);
    try {
      await createTeamMember(data);
      dispatch(addTeamMemberSuccess());
    } catch (err) {
      dispatch(addTeamMemberError(err.message));
    }
  };
};

const editTeamMember = (data) => {
  return async (dispatch) => {
    dispatch(addTeamMemberRequest());

    try {
      await updateTeamMember(data);
      dispatch(addTeamMemberSuccess());
    } catch (err) {
      dispatch(addTeamMemberError(err.message));
    }
  }; 
};

const deleteTeamMember = (data) => {
  return async (dispatch) => {
    dispatch(addTeamMemberRequest());

    try {
      await removeTeamMember(data);
      dispatch(addTeamMemberSuccess());
    } catch (err) {
      dispatch(addTeamMemberError(err.message));
    }
  }; 
};

export default { fetchTeamMembers, addTeamMember, editTeamMember, deleteTeamMember };
