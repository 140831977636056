import instance from "./axios.base";

const getHealthInfo = async (id) => {
  const res = await instance.get(`/health_checkups/${id}`);
  return res.data;
};

const addHealthInfo = async (data) => {
  await instance.post("/health_checkups", data);
};

const editHealthInfo = async (data) => {
  await instance.put("/health_checkups", data);
};

const deleteHealthInfo = async (id) => {
  await instance.delete(`/health_checkups/${id}`);
};

export { getHealthInfo, addHealthInfo, editHealthInfo, deleteHealthInfo };
