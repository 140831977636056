import { default as types } from "./types";

const {
  FETCH_NDSH7B_REPORT_REQUEST,
  FETCH_NDSH7B_REPORT_SUCCESS,
  FETCH_NDSH7B_REPORT_FAILURE,
} = types;

const fetchNDSH7BReportRequest = () => {
  return {
    type: FETCH_NDSH7B_REPORT_REQUEST,
  };
};

const fetchNDSH7BReportSuccess = (report) => {
  return {
    type: FETCH_NDSH7B_REPORT_SUCCESS,
    payload: report,
  };
};

const fetchNDSH7BReportError = (error) => {
  return {
    type: FETCH_NDSH7B_REPORT_FAILURE,
    payload: error,
  };
};

export default {
  fetchNDSH7BReportRequest,
  fetchNDSH7BReportSuccess,
  fetchNDSH7BReportError,
};
