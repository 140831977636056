import { getNationalities } from "services/nationality";
import { default as nationalitiesActions } from "./actions";

const {
  fetchNationalitiesRequest,
  fetchNationalitiesSuccess,
  fetchNationalitiesError,
} = nationalitiesActions;

const fetchNationalities = () => {
  return async (dispatch) => {
    dispatch(fetchNationalitiesRequest());

    try {
      const nationalities = await getNationalities();
      dispatch(fetchNationalitiesSuccess(nationalities));
    } catch (err) {
      dispatch(fetchNationalitiesError(err.message));
    }
  };
};

export default { fetchNationalities };
