const ADD_SCHEDULE_REQUEST = "ADD_SCHEDULE_REQUEST";
const ADD_SCHEDULE_SUCCESS = "ADD_SCHEDULE_SUCCESS";
const ADD_SCHEDULE_FAILURE = "ADD_SCHEDULE_FAILURE";
const EDIT_SCHEDULE_REQUEST = "EDIT_SCHEDULE_REQUEST";
const EDIT_SCHEDULE_SUCCESS = "EDIT_SCHEDULE_SUCCESS";
const EDIT_SCHEDULE_FAILURE = "EDIT_SCHEDULE_FAILURE";
const DELETE_SCHEDULE_REQUEST = "DELETE_SCHEDULE_REQUEST";
const DELETE_SCHEDULE_SUCCESS = "DELETE_SCHEDULE_SUCCESS";
const DELETE_SCHEDULE_FAILURE = "DELETE_SCHEDULE_FAILURE";
const FETCH_SCHEDULES_REQUEST = "FETCH_SCHEDULES_REQUEST";
const FETCH_SCHEDULES_SUCCESS = "FETCH_SCHEDULES_SUCCESS";
const FETCH_SCHEDULES_FAILURE = "FETCH_SCHEDULES_FAILURE";
const FETCH_SCHEDULE_REQUEST = "FETCH_SCHEDULE_REQUEST";
const FETCH_SCHEDULE_SUCCESS = "FETCH_SCHEDULE_SUCCESS";
const FETCH_SCHEDULE_FAILURE = "FETCH_SCHEDULE_FAILURE";
const CLEAR_SCHEDULE = "CLEAR_SCHEDULE";

export default {
  ADD_SCHEDULE_REQUEST,
  ADD_SCHEDULE_SUCCESS,
  ADD_SCHEDULE_FAILURE,
  EDIT_SCHEDULE_REQUEST,
  EDIT_SCHEDULE_SUCCESS,
  EDIT_SCHEDULE_FAILURE,
  DELETE_SCHEDULE_REQUEST,
  DELETE_SCHEDULE_SUCCESS,
  DELETE_SCHEDULE_FAILURE,
  FETCH_SCHEDULES_REQUEST,
  FETCH_SCHEDULES_SUCCESS,
  FETCH_SCHEDULES_FAILURE,
  FETCH_SCHEDULE_REQUEST,
  FETCH_SCHEDULE_SUCCESS,
  FETCH_SCHEDULE_FAILURE,
  CLEAR_SCHEDULE,
};
