const ADD_DEPARTMENT_REQUEST = "ADD_DEPARTMENT_REQUEST";
const ADD_DEPARTMENT_SUCCESS = "ADD_DEPARTMENT_SUCCESS";
const ADD_DEPARTMENT_FAILURE = "ADD_DEPARTMENT_FAILURE";
const FETCH_DEPARTMENTS_REQUEST = "FETCH_DEPARTMENTS_REQUEST";
const FETCH_DEPARTMENTS_SUCCESS = "FETCH_DEPARTMENTS_SUCCESS";
const FETCH_DEPARTMENTS_FAILURE = "FETCH_DEPARTMENTS_FAILURE";

export default {
  ADD_DEPARTMENT_REQUEST,
  ADD_DEPARTMENT_SUCCESS,
  ADD_DEPARTMENT_FAILURE,
  FETCH_DEPARTMENTS_REQUEST,
  FETCH_DEPARTMENTS_SUCCESS,
  FETCH_DEPARTMENTS_FAILURE,
};
