import instance from "./axios.base";

const getAwardInfo = async (id) => {
  const res = await instance.get(`/empawwards/${id}`);
  return res.data;
};

const editAwardInfo = async (data) => {
  await instance.put("/empawwards", data);
};

const deleteAwardInfo = async (id) => {
  await instance.delete(`/empawwards/${id}`);
};

export { getAwardInfo, editAwardInfo, deleteAwardInfo };
