import instance from "./axios.base";

const getEducationDegrees = async () => {
  const res = await instance.get(
    "/service?name=degreeOfEducation&tablename=hr_emp_degree_education"
  );
  return res.data;
};

export { getEducationDegrees };
