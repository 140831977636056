import { default as teamsTypes } from "./types";

const {
  ADD_TEAM_REQUEST,
  ADD_TEAM_SUCCESS,
  ADD_TEAM_FAILURE,
  FETCH_TEAMS_REQUEST,
  FETCH_TEAMS_SUCCESS,
  FETCH_TEAMS_FAILURE,
} = teamsTypes;

const initialState = {
  isLoading: false,
  list: [],
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TEAM_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_TEAM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case ADD_TEAM_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case FETCH_TEAMS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_TEAMS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: action.payload,
        error: "",
      };
    case FETCH_TEAMS_FAILURE:
      return {
        ...state,
        isLoading: false,
        list: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
