import instance from "./axios.base";

const getTalentInfo = async (id) => {
  const res = await instance.get(`/emptalentskills/${id}`);
  return res.data;
};

const editTalentInfo = async (data) => {
  await instance.put("/emptalentskills", data);
};

const deleteTalentInfo = async (id) => {
  await instance.delete(`/emptalentskills/${id}`);
};

export { getTalentInfo, editTalentInfo, deleteTalentInfo };
