const ADD_TASK_REQUEST = "ADD_TASK_REQUEST";
const ADD_TASK_SUCCESS = "ADD_TASK_SUCCESS";
const ADD_TASK_FAILURE = "ADD_TASK_FAILURE";
const FETCH_TASKS_REQUEST = "FETCH_TASKS_REQUEST";
const FETCH_TASKS_SUCCESS = "FETCH_TASKS_SUCCESS";
const FETCH_TASKS_FAILURE = "FETCH_TASKS_FAILURE";

export default {
  ADD_TASK_REQUEST,
  ADD_TASK_SUCCESS,
  ADD_TASK_FAILURE,
  FETCH_TASKS_REQUEST,
  FETCH_TASKS_SUCCESS,
  FETCH_TASKS_FAILURE,
};
