import { default as documentfilesTypes } from "./types";

const {
  ADD_DOCUMENTFILE_REQUEST,
  ADD_DOCUMENTFILE_SUCCESS,
  ADD_DOCUMENTFILE_FAILURE,
  FETCH_DOCUMENTFILES_REQUEST,
  FETCH_DOCUMENTFILES_SUCCESS,
  FETCH_DOCUMENTFILES_FAILURE,
} = documentfilesTypes;

const addDocumentFileRequest = () => {
  return {
    type: ADD_DOCUMENTFILE_REQUEST,
  };
};

const addDocumentFileSuccess = () => {
  return {
    type: ADD_DOCUMENTFILE_SUCCESS,
  };
};

const addDocumentFileError = (error) => {
  return {
    type: ADD_DOCUMENTFILE_FAILURE,
    payload: error,
  };
};

const fetchDocumentFilesRequest = () => {
  return {
    type: FETCH_DOCUMENTFILES_REQUEST,
  };
};

const fetchDocumentFilesSuccess = (documentfiles) => {
  return {
    type: FETCH_DOCUMENTFILES_SUCCESS,
    payload: documentfiles,
  };
};

const fetchDocumentFilesError = (error) => {
  return {
    type: FETCH_DOCUMENTFILES_FAILURE,
    payload: error,
  };
};

export default {
  addDocumentFileRequest,
  addDocumentFileSuccess,
  addDocumentFileError,
  fetchDocumentFilesRequest,
  fetchDocumentFilesSuccess,
  fetchDocumentFilesError,
};
