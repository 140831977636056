import { default as employeeTypes } from "./types";

const {
  FETCH_EMPLOYEE_REQUEST,
  FETCH_EMPLOYEE_SUCCESS,
  FETCH_EMPLOYEE_FAILURE,
  CLEAR_EMPLOYEE,
} = employeeTypes;

const fetchEmployeeRequest = () => {
  return {
    type: FETCH_EMPLOYEE_REQUEST,
  };
};

const fetchEmployeeSuccess = (employee) => {
  return {
    type: FETCH_EMPLOYEE_SUCCESS,
    payload: employee,
  };
};

const fetchEmployeeError = (error) => {
  return {
    type: FETCH_EMPLOYEE_FAILURE,
    payload: error,
  };
};

const clearEmployee = () => {
  return {
    type: CLEAR_EMPLOYEE,
  };
};

export default {
  fetchEmployeeRequest,
  fetchEmployeeSuccess,
  fetchEmployeeError,
  clearEmployee,
};
