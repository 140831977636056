import { default as employeesTypes } from "./types";

const {
  ADD_EMPLOYEE_REQUEST,
  ADD_EMPLOYEE_SUCCESS,
  ADD_EMPLOYEE_FAILURE,
  EDIT_EMPLOYEE_REQUEST,
  EDIT_EMPLOYEE_SUCCESS,
  EDIT_EMPLOYEE_FAILURE,
  FETCH_EMPLOYEES_REQUEST,
  FETCH_EMPLOYEES_SUCCESS,
  FETCH_EMPLOYEES_FAILURE,
  EDIT_EMPLOYEE_EXPERIENCE_REQUEST,
  EDIT_EMPLOYEE_EXPERIENCE_SUCCESS,
  EDIT_EMPLOYEE_EXPERIENCE_FAILURE,
  DELETE_EMPLOYEE_EXPERIENCE_REQUEST,
  DELETE_EMPLOYEE_EXPERIENCE_SUCCESS,
  DELETE_EMPLOYEE_EXPERIENCE_FAILURE,
} = employeesTypes;

const initialState = {
  isLoading: false,
  list: [],
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_EMPLOYEE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_EMPLOYEE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case ADD_EMPLOYEE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case EDIT_EMPLOYEE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case EDIT_EMPLOYEE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case EDIT_EMPLOYEE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case FETCH_EMPLOYEES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_EMPLOYEES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: action.payload,
        error: "",
      };
    case FETCH_EMPLOYEES_FAILURE:
      return {
        ...state,
        isLoading: false,
        list: [],
        error: action.payload,
      };
    case EDIT_EMPLOYEE_EXPERIENCE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case EDIT_EMPLOYEE_EXPERIENCE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case EDIT_EMPLOYEE_EXPERIENCE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case DELETE_EMPLOYEE_EXPERIENCE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case DELETE_EMPLOYEE_EXPERIENCE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case DELETE_EMPLOYEE_EXPERIENCE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
