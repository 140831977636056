import { default as horoscopesTypes } from "./types";

const {
  FETCH_HOROSCOPES_REQUEST,
  FETCH_HOROSCOPES_SUCCESS,
  FETCH_HOROSCOPES_FAILURE,
} = horoscopesTypes;

const fetchHoroscopesRequest = () => {
  return {
    type: FETCH_HOROSCOPES_REQUEST,
  };
};

const fetchHoroscopesSuccess = (horoscopes) => {
  return {
    type: FETCH_HOROSCOPES_SUCCESS,
    payload: horoscopes,
  };
};

const fetchHoroscopesError = (error) => {
  return {
    type: FETCH_HOROSCOPES_FAILURE,
    payload: error,
  };
};

export default {
  fetchHoroscopesRequest,
  fetchHoroscopesSuccess,
  fetchHoroscopesError,
};
