import { getTrainings, createTraining, updateTraining, removeTraining, getTrainingEmployees } from "services/training";
import { default as trainingsActions } from "./actions";

const {
  addTrainingRequest,
  addTrainingSuccess,
  addTrainingError,
  fetchTrainingsRequest,
  fetchTrainingsSuccess,
  fetchTrainingsError,
} = trainingsActions;

const fetchTrainings = () => {
  return async (dispatch) => {
    dispatch(fetchTrainingsRequest());

    try {
      const trainings = await getTrainings();
      dispatch(fetchTrainingsSuccess(trainings));
    } catch (err) {
      dispatch(fetchTrainingsError(err.message));
    }
  };
};

const addTraining = (data) => {
  return async (dispatch) => {
    dispatch(addTrainingRequest());

    try {
      await createTraining(data);
      dispatch(addTrainingSuccess());
    } catch (err) {
      dispatch(addTrainingError(err.message));
    }
  };
};

const editTraining = (data) => {
  return async (dispatch) => {
    dispatch(addTrainingRequest());

    try {
      await updateTraining(data);
      dispatch(addTrainingSuccess());
    } catch (err) {
      dispatch(addTrainingError(err.message));
    }
  };
};

const deleteTraining = (data) => {
  return async (dispatch) => {
    dispatch(addTrainingRequest());

    try {
      await removeTraining(data);
      dispatch(addTrainingSuccess());
    } catch (err) {
      dispatch(addTrainingError(err.message));
    }
  };
};

const fetchTrainingEmployees = () => {
  return async (dispatch) => {
    dispatch(fetchTrainingsRequest());

    try {
      const trainings = await getTrainingEmployees();
      dispatch(fetchTrainingsSuccess(trainings));
    } catch (err) {
      dispatch(fetchTrainingsError(err.message));
    }
  };
};

export default { fetchTrainings, addTraining, editTraining, deleteTraining, fetchTrainingEmployees };