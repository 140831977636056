import { getTeams, createTeam, updateTeam, removeTeam } from "services/team";
import { default as teamsActions } from "./actions";

const {
  addTeamRequest,
  addTeamSuccess,
  addTeamError,
  fetchTeamsRequest,
  fetchTeamsSuccess,
  fetchTeamsError,
} = teamsActions;

const fetchTeams = () => {
  return async (dispatch) => {
    dispatch(fetchTeamsRequest());

    try {
      const teams = await getTeams();
      dispatch(fetchTeamsSuccess(teams));
    } catch (err) {
      dispatch(fetchTeamsError(err.message));
    }
  };
};

const addTeam = (data) => {
  return async (dispatch) => {
    dispatch(addTeamRequest());

    try {
      await createTeam(data);
      dispatch(addTeamSuccess());
    } catch (err) {
      dispatch(addTeamError(err.message));
    }
  };
};

const editTeam = (data) => {
  return async (dispatch) => {
    dispatch(addTeamRequest());

    try {
      await updateTeam(data);
      dispatch(addTeamSuccess());
    } catch (err) {
      dispatch(addTeamError(err.message));
    }
  }; 
};

const deleteTeam = (data) => {
  return async (dispatch) => {
    dispatch(addTeamRequest());

    try {
      await removeTeam(data);
      dispatch(addTeamSuccess());
    } catch (err) {
      dispatch(addTeamError(err.message));
    }
  }; 
};

export default { fetchTeams, addTeam, editTeam, deleteTeam };
