const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
const PUT_PASSWORD_REQUEST = "PUT_PASSWORD_REQUEST";
const PUT_PASSWORD_SUCCESS = "PUT_PASSWORD_SUCCESS";
const PUT_PASSWORD_FAILURE = "PUT_PASSWORD_FAILURE";
const CHECK_GUID_REQUEST = "CHECK_GUID_REQUEST";
const CHECK_GUID_SUCCESS = "CHECK_GUID_SUCCESS";
const CHECK_GUID_FAILURE = "CHECK_GUID_FAILURE";

export default {
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  PUT_PASSWORD_REQUEST,
  PUT_PASSWORD_SUCCESS,
  PUT_PASSWORD_FAILURE,
  CHECK_GUID_REQUEST,
  CHECK_GUID_SUCCESS,
  CHECK_GUID_FAILURE,
};
