const ADD_BASIC_SALARY_REQUEST = "ADD_BASIC_SALARY_REQUEST";
const ADD_BASIC_SALARY_SUCCESS = "ADD_BASIC_SALARY_SUCCESS";
const ADD_BASIC_SALARY_FAILURE = "ADD_BASIC_SALARY_FAILURE";
const EDIT_BASIC_SALARY_REQUEST = "EDIT_BASIC_SALARY_REQUEST";
const EDIT_BASIC_SALARY_SUCCESS = "EDIT_BASIC_SALARY_SUCCESS";
const EDIT_BASIC_SALARY_FAILURE = "EDIT_BASIC_SALARY_FAILURE";
const DELETE_BASIC_SALARY_REQUEST = "DELETE_BASIC_SALARY_REQUEST";
const DELETE_BASIC_SALARY_SUCCESS = "DELETE_BASIC_SALARY_SUCCESS";
const DELETE_BASIC_SALARY_FAILURE = "DELETE_BASIC_SALARY_FAILURE";
const FETCH_BASIC_SALARIES_REQUEST = "FETCH_BASIC_SALARIES_REQUEST";
const FETCH_BASIC_SALARIES_SUCCESS = "FETCH_BASIC_SALARIES_SUCCESS";
const FETCH_BASIC_SALARIES_FAILURE = "FETCH_BASIC_SALARIES_FAILURE";
const FETCH_BASIC_SALARY_REQUEST = "FETCH_BASIC_SALARY_REQUEST";
const FETCH_BASIC_SALARY_SUCCESS = "FETCH_BASIC_SALARY_SUCCESS";
const FETCH_BASIC_SALARY_FAILURE = "FETCH_BASIC_SALARY_FAILURE";
const ARCHIVE_BASIC_SALARY_REQUEST = "ARCHIVE_BASIC_SALARY_REQUEST";
const ARCHIVE_BASIC_SALARY_SUCCESS = "ARCHIVE_BASIC_SALARY_SUCCESS";
const ARCHIVE_BASIC_SALARY_FAILURE = "ARCHIVE_BASIC_SALARY_FAILURE";
const RECALCULATE_SALARY_REQUEST = "RECALCULATE_SALARY_REQUEST";
const RECALCULATE_SALARY_SUCCESS = "RECALCULATE_SALARY_SUCCESS";
const RECALCULATE_SALARY_FAILURE = "RECALCULATE_SALARY_FAILURE";

export default {
  ADD_BASIC_SALARY_REQUEST,
  ADD_BASIC_SALARY_SUCCESS,
  ADD_BASIC_SALARY_FAILURE,
  EDIT_BASIC_SALARY_REQUEST,
  EDIT_BASIC_SALARY_SUCCESS,
  EDIT_BASIC_SALARY_FAILURE,
  DELETE_BASIC_SALARY_REQUEST,
  DELETE_BASIC_SALARY_SUCCESS,
  DELETE_BASIC_SALARY_FAILURE,
  FETCH_BASIC_SALARIES_REQUEST,
  FETCH_BASIC_SALARIES_SUCCESS,
  FETCH_BASIC_SALARIES_FAILURE,
  FETCH_BASIC_SALARY_REQUEST,
  FETCH_BASIC_SALARY_SUCCESS,
  FETCH_BASIC_SALARY_FAILURE,
  ARCHIVE_BASIC_SALARY_REQUEST,
  ARCHIVE_BASIC_SALARY_SUCCESS,
  ARCHIVE_BASIC_SALARY_FAILURE,
  RECALCULATE_SALARY_REQUEST,
  RECALCULATE_SALARY_SUCCESS,
  RECALCULATE_SALARY_FAILURE,
};
