const FETCH_MONTHLY_ATTENDANCE_ARCHIVES_REQUEST =
  "FETCH_MONTHLY_ATTENDANCE_ARCHIVES_REQUEST";
const FETCH_MONTHLY_ATTENDANCE_ARCHIVES_SUCCESS =
  "FETCH_MONTHLY_ATTENDANCE_ARCHIVES_SUCCESS";
const FETCH_MONTHLY_ATTENDANCE_ARCHIVES_FAILURE =
  "FETCH_MONTHLY_ATTENDANCE_ARCHIVES_FAILURE";
const CLEAR_MONTHLY_ATTENDANCE_ARCHIVES_REQUEST =
  "CLEAR_MONTHLY_ATTENDANCE_ARCHIVES_REQUEST";
const CLEAR_MONTHLY_ATTENDANCE_ARCHIVES_SUCCESS =
  "CLEAR_MONTHLY_ATTENDANCE_ARCHIVES_SUCCESS";
const CLEAR_MONTHLY_ATTENDANCE_ARCHIVES_FAILURE =
  "CLEAR_MONTHLY_ATTENDANCE_ARCHIVES_FAILURE";

export default {
  FETCH_MONTHLY_ATTENDANCE_ARCHIVES_REQUEST,
  FETCH_MONTHLY_ATTENDANCE_ARCHIVES_SUCCESS,
  FETCH_MONTHLY_ATTENDANCE_ARCHIVES_FAILURE,
  CLEAR_MONTHLY_ATTENDANCE_ARCHIVES_REQUEST,
  CLEAR_MONTHLY_ATTENDANCE_ARCHIVES_SUCCESS,
  CLEAR_MONTHLY_ATTENDANCE_ARCHIVES_FAILURE,
};
