import { default as employeeMigrationInfoItemTypes } from "./types";

const {
  FETCH_EMPLOYEE_MIGRATION_INFO_ITEM_REQUEST,
  FETCH_EMPLOYEE_MIGRATION_INFO_ITEM_SUCCESS,
  FETCH_EMPLOYEE_MIGRATION_INFO_ITEM_FAILURE,
  ADD_EMPLOYEE_MIGRATION_INFO_ITEM_REQUEST,
  ADD_EMPLOYEE_MIGRATION_INFO_ITEM_SUCCESS,
  ADD_EMPLOYEE_MIGRATION_INFO_ITEM_FAILURE,
  EDIT_EMPLOYEE_MIGRATION_INFO_ITEM_REQUEST,
  EDIT_EMPLOYEE_MIGRATION_INFO_ITEM_SUCCESS,
  EDIT_EMPLOYEE_MIGRATION_INFO_ITEM_FAILURE,
  DELETE_EMPLOYEE_MIGRATION_INFO_ITEM_REQUEST,
  DELETE_EMPLOYEE_MIGRATION_INFO_ITEM_SUCCESS,
  DELETE_EMPLOYEE_MIGRATION_INFO_ITEM_FAILURE,
  CLEAR_EMPLOYEE_MIGRATION_INFO_ITEM,
} = employeeMigrationInfoItemTypes;

const initialState = {
  isLoading: false,
  detail: null,
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EMPLOYEE_MIGRATION_INFO_ITEM_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_EMPLOYEE_MIGRATION_INFO_ITEM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        detail: action.payload,
        error: "",
      };
    case FETCH_EMPLOYEE_MIGRATION_INFO_ITEM_FAILURE:
      return {
        ...state,
        isLoading: false,
        detail: null,
        error: action.payload,
      };
    case ADD_EMPLOYEE_MIGRATION_INFO_ITEM_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_EMPLOYEE_MIGRATION_INFO_ITEM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case ADD_EMPLOYEE_MIGRATION_INFO_ITEM_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case EDIT_EMPLOYEE_MIGRATION_INFO_ITEM_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case EDIT_EMPLOYEE_MIGRATION_INFO_ITEM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case EDIT_EMPLOYEE_MIGRATION_INFO_ITEM_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case DELETE_EMPLOYEE_MIGRATION_INFO_ITEM_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case DELETE_EMPLOYEE_MIGRATION_INFO_ITEM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case DELETE_EMPLOYEE_MIGRATION_INFO_ITEM_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case CLEAR_EMPLOYEE_MIGRATION_INFO_ITEM:
      return {
        ...state,
        detail: null,
      };
    default:
      return state;
  }
};

export default reducer;
