import instance from "./axios.base";

const addEmpSchedule = async (data) => {
  await instance.post("/taempschedule", data);
};

const deleteEmpSchedule = async (id) => {
  await instance.delete(`/taempschedule/${id}`);
};

const getEmpSchedules = async (date) => {
  const res = await instance.get(
    `/taempschedule${date ? "?date=" + date : ""}`
  );
  return res.data;
};

export { addEmpSchedule, deleteEmpSchedule, getEmpSchedules };
