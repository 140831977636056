const ADD_DEDUCTION_REQUEST = "ADD_DEDUCTION_REQUEST";
const ADD_DEDUCTION_SUCCESS = "ADD_DEDUCTION_SUCCESS";
const ADD_DEDUCTION_FAILURE = "ADD_DEDUCTION_FAILURE";
const EDIT_DEDUCTION_REQUEST = "EDIT_DEDUCTION_REQUEST";
const EDIT_DEDUCTION_SUCCESS = "EDIT_DEDUCTION_SUCCESS";
const EDIT_DEDUCTION_FAILURE = "EDIT_DEDUCTION_FAILURE";
const DELETE_DEDUCTION_REQUEST = "DELETE_DEDUCTION_REQUEST";
const DELETE_DEDUCTION_SUCCESS = "DELETE_DEDUCTION_SUCCESS";
const DELETE_DEDUCTION_FAILURE = "DELETE_DEDUCTION_FAILURE";
const FETCH_DEDUCTIONS_REQUEST = "FETCH_DEDUCTIONS_REQUEST";
const FETCH_DEDUCTIONS_SUCCESS = "FETCH_DEDUCTIONS_SUCCESS";
const FETCH_DEDUCTIONS_FAILURE = "FETCH_DEDUCTIONS_FAILURE";
const FETCH_DEDUCTION_REQUEST = "FETCH_DEDUCTION_REQUEST";
const FETCH_DEDUCTION_SUCCESS = "FETCH_DEDUCTION_SUCCESS";
const FETCH_DEDUCTION_FAILURE = "FETCH_DEDUCTION_FAILURE";
const CLEAR_DEDUCTION = "CLEAR_DEDUCTION";

export default {
  ADD_DEDUCTION_REQUEST,
  ADD_DEDUCTION_SUCCESS,
  ADD_DEDUCTION_FAILURE,
  EDIT_DEDUCTION_REQUEST,
  EDIT_DEDUCTION_SUCCESS,
  EDIT_DEDUCTION_FAILURE,
  DELETE_DEDUCTION_REQUEST,
  DELETE_DEDUCTION_SUCCESS,
  DELETE_DEDUCTION_FAILURE,
  FETCH_DEDUCTIONS_REQUEST,
  FETCH_DEDUCTIONS_SUCCESS,
  FETCH_DEDUCTIONS_FAILURE,
  FETCH_DEDUCTION_REQUEST,
  FETCH_DEDUCTION_SUCCESS,
  FETCH_DEDUCTION_FAILURE,
  CLEAR_DEDUCTION,
};
