import { getEmpRaisesAndDeductions } from "services/empRaisesAndDeductions";
import { default as empRaisesAndDeductionsActions } from "./actions";

const {
  fetchEmpRaisesAndDeductionsRequest,
  fetchEmpRaisesAndDeductionsSuccess,
  fetchEmpRaisesAndDeductionsError,
} = empRaisesAndDeductionsActions;

const fetchEmpRaisesAndDeductions = () => {
  return async (dispatch) => {
    dispatch(fetchEmpRaisesAndDeductionsRequest());

    try {
      const empRaisesAndDeductions = await getEmpRaisesAndDeductions();
      dispatch(fetchEmpRaisesAndDeductionsSuccess(empRaisesAndDeductions));
    } catch (err) {
      dispatch(fetchEmpRaisesAndDeductionsError(err.message));
    }
  };
};

export default {
  fetchEmpRaisesAndDeductions,
};
