import instance from "./axios.base";
import { dateFormat } from "utils/formatter";

const getContracts = async () => {
  const res = await instance.get("/contracts");

  res.data.map((row) => {
    row.startDate = dateFormat(row.startDate);
    row.endDate = dateFormat(row.endDate);
  });

  return res.data;
};

const addContract = async (data) => {
  var formData = new FormData();
  formData.append("code", data.code);
  formData.append("name", data.name);
  formData.append("startDate", data.startDate);
  formData.append("endDate", data.endDate);
  formData.append("descr", data.descr);
  formData.append("file", data.file);

  const res = await instance.post("/contracts", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

  return res.data;
};

const editContract = async (data) => {
  var formData = new FormData();
  formData.append("id", data.id);
  formData.append("code", data.code);
  formData.append("name", data.name);
  formData.append("startDate", data.startDate);
  formData.append("endDate", data.endDate);
  formData.append("descr", data.descr);
  formData.append("file", data.file);

  const res = await instance.put("/contracts", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

  return res.data;
};

const deleteContract = async (id) => {
  const res = await instance.delete(`/contracts/${id}`);

  return res.data;
};

export { getContracts, addContract, editContract, deleteContract };
