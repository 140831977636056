import { getEmployee } from "services/employee";
import { default as employeeActions } from "./actions";

const {
  fetchEmployeeRequest,
  fetchEmployeeSuccess,
  fetchEmployeeError,
} = employeeActions;

const fetchEmployee = (id) => {
  return async (dispatch) => {
    dispatch(fetchEmployeeRequest());

    try {
      const employee = await getEmployee(id);
      dispatch(fetchEmployeeSuccess(employee));
    } catch (err) {
      dispatch(fetchEmployeeError(err.message));
    }
  };
};

export default { fetchEmployee };
