import { default as schedulesTypes } from "./types";

const {
  ADD_SCHEDULE_REQUEST,
  ADD_SCHEDULE_SUCCESS,
  ADD_SCHEDULE_FAILURE,
  EDIT_SCHEDULE_REQUEST,
  EDIT_SCHEDULE_SUCCESS,
  EDIT_SCHEDULE_FAILURE,
  DELETE_SCHEDULE_REQUEST,
  DELETE_SCHEDULE_SUCCESS,
  DELETE_SCHEDULE_FAILURE,
  FETCH_SCHEDULES_REQUEST,
  FETCH_SCHEDULES_SUCCESS,
  FETCH_SCHEDULES_FAILURE,
  FETCH_SCHEDULE_REQUEST,
  FETCH_SCHEDULE_SUCCESS,
  FETCH_SCHEDULE_FAILURE,
  CLEAR_SCHEDULE,
} = schedulesTypes;

const initialState = {
  isLoading: false,
  list: [],
  detail: null,
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SCHEDULE_REQUEST:
    case EDIT_SCHEDULE_REQUEST:
    case DELETE_SCHEDULE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_SCHEDULE_SUCCESS:
    case EDIT_SCHEDULE_SUCCESS:
    case DELETE_SCHEDULE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case ADD_SCHEDULE_FAILURE:
    case EDIT_SCHEDULE_FAILURE:
    case DELETE_SCHEDULE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case FETCH_SCHEDULES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_SCHEDULES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: action.payload,
        error: "",
      };
    case FETCH_SCHEDULES_FAILURE:
      return {
        ...state,
        isLoading: false,
        list: [],
        error: action.payload,
      };
    case FETCH_SCHEDULE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_SCHEDULE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        detail: action.payload,
        error: "",
      };
    case FETCH_SCHEDULE_FAILURE:
      return {
        ...state,
        isLoading: false,
        detail: null,
        error: action.payload,
      };
    case CLEAR_SCHEDULE:
      return {
        ...state,
        detail: null,
      };
    default:
      return state;
  }
};

export default reducer;
