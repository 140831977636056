import { default as types } from "./types";

const {
  FETCH_PROFILE_REQUEST,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_FAILURE,
  CLEAR_PROFILE,
} = types;

const fetchProfileRequest = () => {
  return {
    type: FETCH_PROFILE_REQUEST,
  };
};

const fetchProfileSuccess = (profile) => {
  return {
    type: FETCH_PROFILE_SUCCESS,
    payload: profile,
  };
};

const fetchProfileError = (error) => {
  return {
    type: FETCH_PROFILE_FAILURE,
    payload: error,
  };
};

const clearProfile = () => {
  return {
    type: CLEAR_PROFILE,
  };
};

export default {
  fetchProfileRequest,
  fetchProfileSuccess,
  fetchProfileError,
  clearProfile,
};
