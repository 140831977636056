import {
  getPayrollConfig,
  updatePayrollConfig,
  getTimeAttendanceConfig,
  updateTimeAttendanceConfig,
  getTaxCredits,
  createTaxCredit,
  updateTaxCredit,
  removeTaxCredit,
  addDirectManager,
  deleteDirectManager,
  getManagers,
} from "services/systemConfig";
import { default as systemconfigsActions } from "./actions";

const {
  addSystemConfigTaRequest,
  addSystemConfigTaSuccess,
  addSystemConfigPrError,
  addSystemConfigPrRequest,
  addSystemConfigPrSuccess,
  addSystemConfigTaError,
  addSystemConfigTaxCreditRequest,
  addSystemConfigTaxCreditSuccess,
  addSystemConfigTaxCreditError,
  fetchSystemConfigTaRequest,
  fetchSystemConfigTaSuccess,
  fetchSystemConfigTaError,
  fetchSystemConfigPrRequest,
  fetchSystemConfigPrSuccess,
  fetchSystemConfigPrError,
  fetchSystemConfigTaxCreditRequest,
  fetchSystemConfigTaxCreditSuccess,
  fetchSystemConfigTaxCreditError,
  addManagerRequest,
  addManagerSuccess,
  addManagerError,
  deleteManagerRequest,
  deleteManagerSuccess,
  deleteManagerError,
  fetchManagersRequest,
  fetchManagersSuccess,
  fetchManagersError,
} = systemconfigsActions;

const fetchTimeAttendanceConfig = () => {
  return async (dispatch) => {
    dispatch(fetchSystemConfigTaRequest());

    try {
      const systemconfigs = await getTimeAttendanceConfig();

      dispatch(fetchSystemConfigTaSuccess(systemconfigs));
    } catch (err) {
      dispatch(fetchSystemConfigTaError(err.message));
    }
  };
};

const editTimeAttendanceConfig = (data) => {
  return async (dispatch) => {
    dispatch(addSystemConfigTaRequest());

    try {
      await updateTimeAttendanceConfig(data);
      dispatch(addSystemConfigTaSuccess());
    } catch (err) {
      dispatch(addSystemConfigTaError(err.message));
    }
  };
};

const fetchPayrollConfig = () => {
  return async (dispatch) => {
    dispatch(fetchSystemConfigPrRequest());

    try {
      const systemconfigs = await getPayrollConfig();
      dispatch(fetchSystemConfigPrSuccess(systemconfigs));
    } catch (err) {
      dispatch(fetchSystemConfigPrError(err.message));
    }
  };
};

const editPayrollConfig = (data) => {
  return async (dispatch) => {
    dispatch(addSystemConfigPrRequest());

    try {
      await updatePayrollConfig(data);
      dispatch(addSystemConfigPrSuccess());
    } catch (err) {
      dispatch(addSystemConfigPrError(err.message));
    }
  };
};

const fetchTaxCredits = () => {
  return async (dispatch) => {
    dispatch(fetchSystemConfigTaxCreditRequest());

    try {
      const trainings = await getTaxCredits();
      dispatch(fetchSystemConfigTaxCreditSuccess(trainings));
    } catch (err) {
      dispatch(fetchSystemConfigTaxCreditError(err.message));
    }
  };
};

const addTaxCredit = (data) => {
  return async (dispatch) => {
    dispatch(addSystemConfigTaxCreditRequest());

    try {
      await createTaxCredit(data);
      dispatch(addSystemConfigTaxCreditSuccess());
    } catch (err) {
      dispatch(addSystemConfigTaxCreditError(err.message));
    }
  };
};

const editTaxCredit = (data) => {
  return async (dispatch) => {
    dispatch(addSystemConfigTaxCreditRequest());

    try {
      await updateTaxCredit(data);
      dispatch(addSystemConfigTaxCreditSuccess());
    } catch (err) {
      dispatch(addSystemConfigTaxCreditError(err.message));
    }
  };
};

const deleteTaxCredit = (data) => {
  return async (dispatch) => {
    dispatch(addSystemConfigTaxCreditRequest());

    try {
      await removeTaxCredit(data);
      dispatch(addSystemConfigTaxCreditSuccess());
    } catch (err) {
      dispatch(addSystemConfigTaxCreditError(err.message));
    }
  };
};

const addManager = (data) => {
  return async (dispatch) => {
    dispatch(addManagerRequest());

    try {
      await addDirectManager(data);
      dispatch(addManagerSuccess());
    } catch (err) {
      dispatch(addManagerError(err.message));
    }
  };
};

const deleteManager = (id) => {
  return async (dispatch) => {
    dispatch(deleteManagerRequest());

    try {
      await deleteDirectManager(id);
      dispatch(deleteManagerSuccess());
    } catch (err) {
      dispatch(deleteManagerError(err.message));
    }
  };
};

const fetchManagers = () => {
  return async (dispatch) => {
    dispatch(fetchManagersRequest());

    try {
      const managers = await getManagers();
      dispatch(fetchManagersSuccess(managers));
    } catch (err) {
      dispatch(fetchManagersError(err.message));
    }
  };
};

export default {
  fetchPayrollConfig,
  editPayrollConfig,
  fetchTimeAttendanceConfig,
  editTimeAttendanceConfig,
  fetchTaxCredits,
  addTaxCredit,
  editTaxCredit,
  deleteTaxCredit,
  addManager,
  deleteManager,
  fetchManagers,
};
