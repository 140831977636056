import { getMigrationInfo } from "services/migrationInfo";
import { default as employeeMigrationInfoActions } from "./actions";

const {
  fetchEmployeeMigrationInfoRequest,
  fetchEmployeeMigrationInfoSuccess,
  fetchEmployeeMigrationInfoError,
} = employeeMigrationInfoActions;

const fetchEmployeeMigrationInfo = (id) => {
  return async (dispatch) => {
    dispatch(fetchEmployeeMigrationInfoRequest());

    try {
      const employeeMigrationInfo = await getMigrationInfo(id);
      dispatch(fetchEmployeeMigrationInfoSuccess(employeeMigrationInfo));
    } catch (err) {
      dispatch(fetchEmployeeMigrationInfoError(err.message));
    }
  };
};

export default { fetchEmployeeMigrationInfo };
