import { default as raisesTypes } from "./types";

const {
  ADD_RAISE_REQUEST,
  ADD_RAISE_SUCCESS,
  ADD_RAISE_FAILURE,
  EDIT_RAISE_REQUEST,
  EDIT_RAISE_SUCCESS,
  EDIT_RAISE_FAILURE,
  DELETE_RAISE_REQUEST,
  DELETE_RAISE_SUCCESS,
  DELETE_RAISE_FAILURE,
  FETCH_RAISES_REQUEST,
  FETCH_RAISES_SUCCESS,
  FETCH_RAISES_FAILURE,
  FETCH_RAISE_REQUEST,
  FETCH_RAISE_SUCCESS,
  FETCH_RAISE_FAILURE,
  CLEAR_RAISE,
} = raisesTypes;

const addRaiseRequest = () => {
  return {
    type: ADD_RAISE_REQUEST,
  };
};

const addRaiseSuccess = () => {
  return {
    type: ADD_RAISE_SUCCESS,
  };
};

const addRaiseError = (error) => {
  return {
    type: ADD_RAISE_FAILURE,
    payload: error,
  };
};

const editRaiseRequest = () => {
  return {
    type: EDIT_RAISE_REQUEST,
  };
};

const editRaiseSuccess = () => {
  return {
    type: EDIT_RAISE_SUCCESS,
  };
};

const editRaiseError = (error) => {
  return {
    type: EDIT_RAISE_FAILURE,
    payload: error,
  };
};

const deleteRaiseRequest = () => {
  return {
    type: DELETE_RAISE_REQUEST,
  };
};

const deleteRaiseSuccess = () => {
  return {
    type: DELETE_RAISE_SUCCESS,
  };
};

const deleteRaiseError = (error) => {
  return {
    type: DELETE_RAISE_FAILURE,
    payload: error,
  };
};

const fetchRaisesRequest = () => {
  return {
    type: FETCH_RAISES_REQUEST,
  };
};

const fetchRaisesSuccess = (raises) => {
  return {
    type: FETCH_RAISES_SUCCESS,
    payload: raises,
  };
};

const fetchRaisesError = (error) => {
  return {
    type: FETCH_RAISES_FAILURE,
    payload: error,
  };
};

const fetchRaiseRequest = () => {
  return {
    type: FETCH_RAISE_REQUEST,
  };
};

const fetchRaiseSuccess = (raise) => {
  return {
    type: FETCH_RAISE_SUCCESS,
    payload: raise,
  };
};

const fetchRaiseError = (error) => {
  return {
    type: FETCH_RAISE_FAILURE,
    payload: error,
  };
};

const clearRaise = () => {
  return {
    type: CLEAR_RAISE,
  };
};

export default {
  addRaiseRequest,
  addRaiseSuccess,
  addRaiseError,
  editRaiseRequest,
  editRaiseSuccess,
  editRaiseError,
  deleteRaiseRequest,
  deleteRaiseSuccess,
  deleteRaiseError,
  fetchRaisesRequest,
  fetchRaisesSuccess,
  fetchRaisesError,
  fetchRaiseRequest,
  fetchRaiseSuccess,
  fetchRaiseError,
  clearRaise,
};
