import { toast } from "react-toastify";
import {
  getLangSkillInfo,
  editLangSkillInfo,
  deleteLangSkillInfo,
} from "services/langSkillInfo";
import { default as employeeLangSkillInfoActions } from "./actions";

const {
  fetchEmployeeLangSkillInfoRequest,
  fetchEmployeeLangSkillInfoSuccess,
  fetchEmployeeLangSkillInfoError,
  editEmployeeLangSkillInfoRequest,
  editEmployeeLangSkillInfoSuccess,
  editEmployeeLangSkillInfoError,
  deleteEmployeeLangSkillInfoRequest,
  deleteEmployeeLangSkillInfoSuccess,
  deleteEmployeeLangSkillInfoError,
} = employeeLangSkillInfoActions;

const fetchEmployeeLangSkillInfo = (data) => {
  return async (dispatch) => {
    dispatch(fetchEmployeeLangSkillInfoRequest());

    try {
      const employeeLangSkillInfo = await getLangSkillInfo(data);
      dispatch(fetchEmployeeLangSkillInfoSuccess(employeeLangSkillInfo));
    } catch (err) {
      dispatch(fetchEmployeeLangSkillInfoError(err.message));
    }
  };
};

const editEmployeeLangSkillInfo = (data) => {
  return async (dispatch) => {
    dispatch(editEmployeeLangSkillInfoRequest());

    try {
      await editLangSkillInfo(data);
      dispatch(editEmployeeLangSkillInfoSuccess());
      toast.success("Амжилттай заслаа");
    } catch (err) {
      dispatch(editEmployeeLangSkillInfoError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const deleteEmployeeLangSkillInfo = (id) => {
  return async (dispatch) => {
    dispatch(deleteEmployeeLangSkillInfoRequest());

    try {
      await deleteLangSkillInfo(id);
      dispatch(deleteEmployeeLangSkillInfoSuccess());
      toast.success("Амжилттай устгалаа");
    } catch (err) {
      dispatch(deleteEmployeeLangSkillInfoError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

export default {
  fetchEmployeeLangSkillInfo,
  editEmployeeLangSkillInfo,
  deleteEmployeeLangSkillInfo,
};
