import { getCities } from "services/city";
import { default as citiesActions } from "./actions";

const {
  fetchCitiesRequest,
  fetchCitiesSuccess,
  fetchCitiesError,
} = citiesActions;

const fetchCities = () => {
  return async (dispatch) => {
    dispatch(fetchCitiesRequest());

    try {
      const cities = await getCities();
      dispatch(fetchCitiesSuccess(cities));
    } catch (err) {
      dispatch(fetchCitiesError(err.message));
    }
  };
};

export default { fetchCities };
