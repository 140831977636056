import { default as types } from "./types";

const {
  ADD_CONTRACT_REQUEST,
  ADD_CONTRACT_SUCCESS,
  ADD_CONTRACT_FAILURE,
  EDIT_CONTRACT_REQUEST,
  EDIT_CONTRACT_SUCCESS,
  EDIT_CONTRACT_FAILURE,
  DELETE_CONTRACT_REQUEST,
  DELETE_CONTRACT_SUCCESS,
  DELETE_CONTRACT_FAILURE,
  FETCH_CONTRACTS_REQUEST,
  FETCH_CONTRACTS_SUCCESS,
  FETCH_CONTRACTS_FAILURE,
} = types;

const addContractRequest = () => {
  return {
    type: ADD_CONTRACT_REQUEST,
  };
};

const addContractSuccess = () => {
  return {
    type: ADD_CONTRACT_SUCCESS,
  };
};

const addContractError = (error) => {
  return {
    type: ADD_CONTRACT_FAILURE,
    payload: error,
  };
};

const editContractRequest = () => {
  return {
    type: EDIT_CONTRACT_REQUEST,
  };
};

const editContractSuccess = () => {
  return {
    type: EDIT_CONTRACT_SUCCESS,
  };
};

const editContractError = (error) => {
  return {
    type: EDIT_CONTRACT_FAILURE,
    payload: error,
  };
};

const deleteContractRequest = () => {
  return {
    type: DELETE_CONTRACT_REQUEST,
  };
};

const deleteContractSuccess = () => {
  return {
    type: DELETE_CONTRACT_SUCCESS,
  };
};

const deleteContractError = (error) => {
  return {
    type: DELETE_CONTRACT_FAILURE,
    payload: error,
  };
};

const fetchContractsRequest = () => {
  return {
    type: FETCH_CONTRACTS_REQUEST,
  };
};

const fetchContractsSuccess = (contracts) => {
  return {
    type: FETCH_CONTRACTS_SUCCESS,
    payload: contracts,
  };
};

const fetchContractsError = (error) => {
  return {
    type: FETCH_CONTRACTS_FAILURE,
    payload: error,
  };
};

export default {
  addContractRequest,
  addContractSuccess,
  addContractError,
  editContractRequest,
  editContractSuccess,
  editContractError,
  deleteContractRequest,
  deleteContractSuccess,
  deleteContractError,
  fetchContractsRequest,
  fetchContractsSuccess,
  fetchContractsError,
};
