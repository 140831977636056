import { toast } from "react-toastify";
import {
  addPreJournal,
  deletePreJournal,
  getPreJournals,
  getPreJournal,
  archivePreJournal,
  calculatePreJournal,
} from "services/preJournal";
import { default as preJournalsActions } from "./actions";

const {
  addPreJournalRequest,
  addPreJournalSuccess,
  addPreJournalError,
  deletePreJournalRequest,
  deletePreJournalSuccess,
  deletePreJournalError,
  fetchPreJournalsRequest,
  fetchPreJournalsSuccess,
  fetchPreJournalsError,
  fetchPreJournalRequest,
  fetchPreJournalSuccess,
  fetchPreJournalError,
  archivePreJournalRequest,
  archivePreJournalSuccess,
  archivePreJournalError,
  calculatePreJournalRequest,
  calculatePreJournalSuccess,
  calculatePreJournalError,
} = preJournalsActions;

const add = (data) => {
  return async (dispatch) => {
    dispatch(addPreJournalRequest());

    try {
      await addPreJournal(data);
      dispatch(addPreJournalSuccess());
      toast.success("Амжилттай нэмлээ");
    } catch (err) {
      dispatch(addPreJournalError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const remove = (id) => {
  return async (dispatch) => {
    dispatch(deletePreJournalRequest());

    try {
      await deletePreJournal(id);
      dispatch(deletePreJournalSuccess());
      toast.success("Амжилттай устгалаа");
    } catch (err) {
      dispatch(deletePreJournalError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const fetchPreJournals = (startDate, finishDate) => {
  return async (dispatch) => {
    dispatch(fetchPreJournalsRequest());

    try {
      const journals = await getPreJournals(startDate, finishDate);
      dispatch(fetchPreJournalsSuccess(journals));
    } catch (err) {
      dispatch(fetchPreJournalsError(err.message));
    }
  };
};

const fetchPreJournal = (empId, date) => {
  return async (dispatch) => {
    dispatch(fetchPreJournalRequest());

    try {
      const preJournal = await getPreJournal(empId, date);
      dispatch(fetchPreJournalSuccess(preJournal));
    } catch (err) {
      dispatch(fetchPreJournalError(err.message));
    }
  };
};

const archive = (selected) => {
  return async (dispatch) => {
    dispatch(archivePreJournalRequest());

    try {
      await archivePreJournal(selected);
      dispatch(archivePreJournalSuccess());
      toast.success("Амжилттай архивлалаа");
    } catch (err) {
      dispatch(archivePreJournalError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const calculate = (data) => {
  return async (dispatch) => {
    dispatch(calculatePreJournalRequest());

    try {
      await calculatePreJournal(data);
      dispatch(calculatePreJournalSuccess());
      toast.success("Амжилттай дахин бодлоо");
    } catch (err) {
      dispatch(calculatePreJournalError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

export default {
  add,
  remove,
  fetchPreJournals,
  fetchPreJournal,
  archive,
  calculate,
};
