import instance from "./axios.base";
import { dateFormat } from "utils/formatter";

const getCompanyInfo = async () => {
  const res = await instance.get("/company");
  
  console.log('Company info ', res);
  res.data.launchDate = dateFormat(res.data.launchDate)

  return res.data;
};

const updateCompanyInfo = async (data) => {
  var formData = new FormData();
  formData.append('id', data.id);
  formData.append('regNo', data.regNo);
  formData.append('name', data.name);
  formData.append('launchDate', data.launchDate);
  formData.append('type', data.type);
  formData.append('slogan', data.slogan);
  formData.append('image', data.file);

  const res = await instance.put("/company", formData, { headers: {'Content-Type': 'multipart/form-data' }});

  return res.data;
};

export { getCompanyInfo, updateCompanyInfo };
