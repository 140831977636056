import instance from "./axios.base";

const editUserPassword = async (data) => {
  const res = await instance.post("/changeuserpassword", data);
  return res.data;
};

const editUserPasswordByEmail = async (data) => {
  const res = await instance.post("/resetpasswordmail", data);
  return res.data;
};

const editUserPermission = async (data) => {
  const res = await instance.post("/changeuserpermission", data);
  return res.data;
};

const getUserPermissions = async () => {
  const res = await instance.get("/userpermission");
  return res.data;
};

export {
  editUserPassword,
  editUserPasswordByEmail,
  editUserPermission,
  getUserPermissions,
};
