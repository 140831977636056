import instance from "./axios.base";

const getSectors = async () => {
  const res = await instance.get("/sectors");
  
  return res.data;
};

const createSector = async (data) => {  
  const res = await instance.post("/sectors", data);

  return res.data;
};

const updateSector = async (data) => {
  const res = await instance.put("/sectors", data);

  return res.data;
};

const removeSector = async (id) => {
  const res = await instance.delete(`/sectors/${id}`);

  return res.data;
};

export { getSectors, createSector, updateSector, removeSector };