const ADD_TEAMMEMBER_REQUEST = "ADD_TEAMMEMBER_REQUEST";
const ADD_TEAMMEMBER_SUCCESS = "ADD_TEAMMEMBER_SUCCESS";
const ADD_TEAMMEMBER_FAILURE = "ADD_TEAMMEMBER_FAILURE";
const FETCH_TEAMMEMBERS_REQUEST = "FETCH_TEAMMEMBERS_REQUEST";
const FETCH_TEAMMEMBERS_SUCCESS = "FETCH_TEAMMEMBERS_SUCCESS";
const FETCH_TEAMMEMBERS_FAILURE = "FETCH_TEAMMEMBERS_FAILURE";

export default {
  ADD_TEAMMEMBER_REQUEST,
  ADD_TEAMMEMBER_SUCCESS,
  ADD_TEAMMEMBER_FAILURE,
  FETCH_TEAMMEMBERS_REQUEST,
  FETCH_TEAMMEMBERS_SUCCESS,
  FETCH_TEAMMEMBERS_FAILURE,
};
