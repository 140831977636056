import { default as types } from "./types";

const {
  EDIT_USER_PASSWORD_REQUEST,
  EDIT_USER_PASSWORD_SUCCESS,
  EDIT_USER_PASSWORD_FAILURE,
  EDIT_USER_PASSWORD_BY_EMAIL_REQUEST,
  EDIT_USER_PASSWORD_BY_EMAIL_SUCCESS,
  EDIT_USER_PASSWORD_BY_EMAIL_FAILURE,
  EDIT_USER_PERMISSION_REQUEST,
  EDIT_USER_PERMISSION_SUCCESS,
  EDIT_USER_PERMISSION_FAILURE,
  FETCH_USER_PERMISSIONS_REQUEST,
  FETCH_USER_PERMISSIONS_SUCCESS,
  FETCH_USER_PERMISSIONS_FAILURE,
} = types;

const editUserPasswordRequest = () => {
  return {
    type: EDIT_USER_PASSWORD_REQUEST,
  };
};

const editUserPasswordSuccess = () => {
  return {
    type: EDIT_USER_PASSWORD_SUCCESS,
  };
};

const editUserPasswordError = (error) => {
  return {
    type: EDIT_USER_PASSWORD_FAILURE,
    payload: error,
  };
};

const editUserPasswordByEmailRequest = () => {
  return {
    type: EDIT_USER_PASSWORD_BY_EMAIL_REQUEST,
  };
};

const editUserPasswordByEmailSuccess = () => {
  return {
    type: EDIT_USER_PASSWORD_BY_EMAIL_SUCCESS,
  };
};

const editUserPasswordByEmailError = (error) => {
  return {
    type: EDIT_USER_PASSWORD_BY_EMAIL_FAILURE,
    payload: error,
  };
};

const editUserPermissionRequest = () => {
  return {
    type: EDIT_USER_PERMISSION_REQUEST,
  };
};

const editUserPermissionSuccess = () => {
  return {
    type: EDIT_USER_PERMISSION_SUCCESS,
  };
};

const editUserPermissionError = (error) => {
  return {
    type: EDIT_USER_PERMISSION_FAILURE,
    payload: error,
  };
};

const fetchUserPermissionsRequest = () => {
  return {
    type: FETCH_USER_PERMISSIONS_REQUEST,
  };
};

const fetchUserPermissionsSuccess = (userPermissions) => {
  return {
    type: FETCH_USER_PERMISSIONS_SUCCESS,
    payload: userPermissions,
  };
};

const fetchUserPermissionsError = (error) => {
  return {
    type: FETCH_USER_PERMISSIONS_FAILURE,
    payload: error,
  };
};

export default {
  editUserPasswordRequest,
  editUserPasswordSuccess,
  editUserPasswordError,
  editUserPasswordByEmailRequest,
  editUserPasswordByEmailSuccess,
  editUserPasswordByEmailError,
  editUserPermissionRequest,
  editUserPermissionSuccess,
  editUserPermissionError,
  fetchUserPermissionsRequest,
  fetchUserPermissionsSuccess,
  fetchUserPermissionsError,
};
