import instance from "./axios.base";

const getEmergencyContractInfo = async (id) => {
  const res = await instance.get(`/empempgencycontact/${id}`);
  return res.data;
};

const editEmergencyContractInfo = async (data) => {
  await instance.put("/empempgencycontact", data);
};

const deleteEmergencyContractInfo = async (id) => {
  await instance.delete(`/empempgencycontact/${id}`);
};

export {
  getEmergencyContractInfo,
  editEmergencyContractInfo,
  deleteEmergencyContractInfo,
};
