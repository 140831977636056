import { default as trainingemployeesActions } from "./actions";
import { getTrainingEmployees, createTrainingEmployee, removeTrainingEmployee } from "services/training";

const {
  addTrainingEmployeeRequest,
  addTrainingEmployeeSuccess,
  addTrainingEmployeeError,
  fetchTrainingEmployeesRequest,
  fetchTrainingEmployeesSuccess,
  fetchTrainingEmployeesError,
} = trainingemployeesActions;

const fetchTrainingEmployees = (data) => {
  return async (dispatch) => {
    dispatch(fetchTrainingEmployeesRequest());

    try {      
      const trainingemployees = await getTrainingEmployees(data);
      dispatch(fetchTrainingEmployeesSuccess(trainingemployees));
    } catch (err) {
      dispatch(fetchTrainingEmployeesError(err.message));
    }
  };
};

const addTrainingEmployee = (data) => {
  return async (dispatch) => {
    dispatch(addTrainingEmployeeRequest());

    try {
      await createTrainingEmployee(data);
      dispatch(addTrainingEmployeeSuccess());
    } catch (err) {
      dispatch(addTrainingEmployeeError(err.message));
    }
  };
};

const deleteTrainingEmployee = (data) => {
  return async (dispatch) => {
    dispatch(addTrainingEmployeeRequest());

    try {
      await removeTrainingEmployee(data);
      dispatch(addTrainingEmployeeSuccess());
    } catch (err) {
      dispatch(addTrainingEmployeeError(err.message));
    }
  };
};

export default { fetchTrainingEmployees, addTrainingEmployee, deleteTrainingEmployee };