import { default as insurCareersTypes } from "./types";

const {
  FETCH_INSUR_CAREERS_REQUEST,
  FETCH_INSUR_CAREERS_SUCCESS,
  FETCH_INSUR_CAREERS_FAILURE,
} = insurCareersTypes;

const fetchInsurCareersRequest = () => {
  return {
    type: FETCH_INSUR_CAREERS_REQUEST,
  };
};

const fetchInsurCareersSuccess = (insurCareers) => {
  return {
    type: FETCH_INSUR_CAREERS_SUCCESS,
    payload: insurCareers,
  };
};

const fetchInsurCareersError = (error) => {
  return {
    type: FETCH_INSUR_CAREERS_FAILURE,
    payload: error,
  };
};

export default {
  fetchInsurCareersRequest,
  fetchInsurCareersSuccess,
  fetchInsurCareersError,
};
