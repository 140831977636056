import { getInsurCareers } from "services/insurCareer";
import { default as insurCareersActions } from "./actions";

const {
  fetchInsurCareersRequest,
  fetchInsurCareersSuccess,
  fetchInsurCareersError,
} = insurCareersActions;

const fetchInsurCareers = () => {
  return async (dispatch) => {
    dispatch(fetchInsurCareersRequest());

    try {
      const insurCareers = await getInsurCareers();
      dispatch(fetchInsurCareersSuccess(insurCareers));
    } catch (err) {
      dispatch(fetchInsurCareersError(err.message));
    }
  };
};

export default { fetchInsurCareers };
