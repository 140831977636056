import instance from "./axios.base";

const getEducationInfo = async (id) => {
  const res = await instance.get(`/empdegreeeducations/${id}`);
  return res.data;
};

const editEducationInfo = async (data) => {
  await instance.put("/empdegreeeducations", data);
};

const deleteEducationInfo = async (id) => {
  await instance.delete(`/empdegreeeducations/${id}`);
};

export { getEducationInfo, editEducationInfo, deleteEducationInfo };
