import { default as banksTypes } from "./types";

const {
  FETCH_BANKS_REQUEST,
  FETCH_BANKS_SUCCESS,
  FETCH_BANKS_FAILURE,
} = banksTypes;

const fetchBanksRequest = () => {
  return {
    type: FETCH_BANKS_REQUEST,
  };
};

const fetchBanksSuccess = (insurPeople) => {
  return {
    type: FETCH_BANKS_SUCCESS,
    payload: insurPeople,
  };
};

const fetchBanksError = (error) => {
  return {
    type: FETCH_BANKS_FAILURE,
    payload: error,
  };
};

export default {
  fetchBanksRequest,
  fetchBanksSuccess,
  fetchBanksError,
};
