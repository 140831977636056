import { default as types } from "./types";

const {
  FETCH_PERMISSIONS_REQUEST,
  FETCH_PERMISSIONS_SUCCESS,
  FETCH_PERMISSIONS_FAILURE,
} = types;

const fetchPermissionsRequest = () => {
  return {
    type: FETCH_PERMISSIONS_REQUEST,
  };
};

const fetchPermissionsSuccess = (permissions) => {
  return {
    type: FETCH_PERMISSIONS_SUCCESS,
    payload: permissions,
  };
};

const fetchPermissionsError = (error) => {
  return {
    type: FETCH_PERMISSIONS_FAILURE,
    payload: error,
  };
};

export default {
  fetchPermissionsRequest,
  fetchPermissionsSuccess,
  fetchPermissionsError,
};
