import { default as types } from "./types";

const {
  FETCH_NDSH8_REPORT_REQUEST,
  FETCH_NDSH8_REPORT_SUCCESS,
  FETCH_NDSH8_REPORT_FAILURE,
} = types;

const fetchNDSH8ReportRequest = () => {
  return {
    type: FETCH_NDSH8_REPORT_REQUEST,
  };
};

const fetchNDSH8ReportSuccess = (report) => {
  return {
    type: FETCH_NDSH8_REPORT_SUCCESS,
    payload: report,
  };
};

const fetchNDSH8ReportError = (error) => {
  return {
    type: FETCH_NDSH8_REPORT_FAILURE,
    payload: error,
  };
};

export default {
  fetchNDSH8ReportRequest,
  fetchNDSH8ReportSuccess,
  fetchNDSH8ReportError,
};
