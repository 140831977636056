import instance from "./axios.base";

const getTimeAttendanceLogs = async () => {
  const res = await instance.get("/talogs");
  
  return res.data;
};

const createTimeAttendanceLog = async (data) => {
  const res = await instance.post("/talogs", data);

  return res.data;
};

const updateTimeAttendanceLog = async (data) => {
  const res = await instance.put("/talogs", data);

  return res.data;
};

const removeTimeAttendanceLog = async (data) => {
  const res = await instance.delete(`/talogs/${data.id}`);

  return res.data;
};

export { getTimeAttendanceLogs, createTimeAttendanceLog, updateTimeAttendanceLog, removeTimeAttendanceLog };