import { default as departmentsTypes } from "./types";

const {
  ADD_DEPARTMENT_REQUEST,
  ADD_DEPARTMENT_SUCCESS,
  ADD_DEPARTMENT_FAILURE,
  FETCH_DEPARTMENTS_REQUEST,
  FETCH_DEPARTMENTS_SUCCESS,
  FETCH_DEPARTMENTS_FAILURE,
} = departmentsTypes;

const addDepartmentRequest = () => {
  return {
    type: ADD_DEPARTMENT_REQUEST,
  };
};

const addDepartmentSuccess = () => {
  return {
    type: ADD_DEPARTMENT_SUCCESS,
  };
};

const addDepartmentError = (error) => {
  return {
    type: ADD_DEPARTMENT_FAILURE,
    payload: error,
  };
};

const fetchDepartmentsRequest = () => {
  return {
    type: FETCH_DEPARTMENTS_REQUEST,
  };
};

const fetchDepartmentsSuccess = (departments) => {
  return {
    type: FETCH_DEPARTMENTS_SUCCESS,
    payload: departments,
  };
};

const fetchDepartmentsError = (error) => {
  return {
    type: FETCH_DEPARTMENTS_FAILURE,
    payload: error,
  };
};

export default {
  addDepartmentRequest,
  addDepartmentSuccess,
  addDepartmentError,
  fetchDepartmentsRequest,
  fetchDepartmentsSuccess,
  fetchDepartmentsError,
};
