import instance from "./axios.base";

const getMigrationInfoItem = async (id) => {
  const res = await instance.get(`/empmigrationsfindbyid/${id}`);
  return res.data;
};

const addMigrationInfoItem = async (data) => {
  await instance.post("/empmigrations", data);
};

const editMigrationInfoItem = async (data) => {
  await instance.put("/empmigrations", data);
};

const deleteMigrationInfoItem = async (id) => {
  await instance.delete(`/empmigrations/${id}`);
};

export {
  getMigrationInfoItem,
  addMigrationInfoItem,
  editMigrationInfoItem,
  deleteMigrationInfoItem,
};
