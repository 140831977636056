import { toast } from "react-toastify";
import {
  getFeedbacks,
  createFeedback,
  updateFeedback,
  removeFeedback,
} from "services/feedback";
import { default as feedbacksActions } from "./actions";

const {
  addFeedbackRequest,
  addFeedbackSuccess,
  addFeedbackError,
  fetchFeedbacksRequest,
  fetchFeedbacksSuccess,
  fetchFeedbacksError,
} = feedbacksActions;

const fetchFeedbacks = () => {
  return async (dispatch) => {
    dispatch(fetchFeedbacksRequest());

    try {
      const feedbacks = await getFeedbacks();
      dispatch(fetchFeedbacksSuccess(feedbacks));
    } catch (err) {
      dispatch(fetchFeedbacksError(err.message));
    }
  };
};

const addFeedback = (data) => {
  return async (dispatch) => {
    dispatch(addFeedbackRequest());

    try {
      await createFeedback(data);
      dispatch(addFeedbackSuccess());
      toast.success("Амжилттай нэмлээ");
    } catch (err) {
      dispatch(addFeedbackError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const editFeedback = (data) => {
  return async (dispatch) => {
    dispatch(addFeedbackRequest());

    try {
      await updateFeedback(data);
      dispatch(addFeedbackSuccess());
      toast.success("Амжилттай заслаа");
    } catch (err) {
      dispatch(addFeedbackError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const deleteFeedback = (data) => {
  return async (dispatch) => {
    dispatch(addFeedbackRequest());

    try {
      await removeFeedback(data);
      dispatch(addFeedbackSuccess());
      toast.success("Амжилттай устгалаа");
    } catch (err) {
      dispatch(addFeedbackError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

export default { fetchFeedbacks, addFeedback, editFeedback, deleteFeedback };
