import { getPunishmentList } from "services/punishment";
import { default as punishmentListActions } from "./actions";

const {
  fetchPunishmentListRequest,
  fetchPunishmentListSuccess,
  fetchPunishmentListError,
} = punishmentListActions;

const fetchPunishmentList = () => {
  return async (dispatch) => {
    dispatch(fetchPunishmentListRequest());

    try {
      const punishmentList = await getPunishmentList();
      dispatch(fetchPunishmentListSuccess(punishmentList));
    } catch (err) {
      dispatch(fetchPunishmentListError(err.message));
    }
  };
};

export default { fetchPunishmentList };
