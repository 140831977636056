import { default as systemconfigsTypes } from "./types";

const {
  ADD_SYSTEMCONFIGTA_REQUEST,
  ADD_SYSTEMCONFIGTA_SUCCESS,
  ADD_SYSTEMCONFIGTA_FAILURE,
  ADD_SYSTEMCONFIGPR_REQUEST,
  ADD_SYSTEMCONFIGPR_SUCCESS,
  ADD_SYSTEMCONFIGPR_FAILURE,
  ADD_SYSTEMCONFIGTAXCREDIT_REQUEST,
  ADD_SYSTEMCONFIGTAXCREDIT_SUCCESS,
  ADD_SYSTEMCONFIGTAXCREDIT_FAILURE,
  FETCH_SYSTEMCONFIGTA_REQUEST,
  FETCH_SYSTEMCONFIGTA_SUCCESS,
  FETCH_SYSTEMCONFIGTA_FAILURE,
  FETCH_SYSTEMCONFIGPR_REQUEST,
  FETCH_SYSTEMCONFIGPR_SUCCESS,
  FETCH_SYSTEMCONFIGPR_FAILURE,
  FETCH_SYSTEMCONFIGTAXCREDIT_REQUEST,
  FETCH_SYSTEMCONFIGTAXCREDIT_SUCCESS,
  FETCH_SYSTEMCONFIGTAXCREDIT_FAILURE,
  ADD_MANAGER_REQUEST,
  ADD_MANAGER_SUCCESS,
  ADD_MANAGER_FAILURE,
  DELETE_MANAGER_REQUEST,
  DELETE_MANAGER_SUCCESS,
  DELETE_MANAGER_FAILURE,
  FETCH_MANAGERS_REQUEST,
  FETCH_MANAGERS_SUCCESS,
  FETCH_MANAGERS_FAILURE,
} = systemconfigsTypes;

const initialState = {
  isLoading: false,
  list: [],
  payrollConfig: {},
  timeConfig: {},
  taxCredit: [],
  managers: [],
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SYSTEMCONFIGTA_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_SYSTEMCONFIGTA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        timeConfig: {},
        error: "",
      };
    case ADD_SYSTEMCONFIGTA_FAILURE:
      return {
        ...state,
        isLoading: false,
        timeConfig: {},
        error: action.payload,
      };
    case ADD_SYSTEMCONFIGPR_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_SYSTEMCONFIGPR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        payrollConfig: {},
        error: "",
      };
    case ADD_SYSTEMCONFIGPR_FAILURE:
      return {
        ...state,
        isLoading: false,
        payrollConfig: {},
        error: action.payload,
      };
    case FETCH_SYSTEMCONFIGTA_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_SYSTEMCONFIGTA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        timeConfig: action.payload,
        error: "",
      };
    case FETCH_SYSTEMCONFIGTA_FAILURE:
      return {
        ...state,
        isLoading: false,
        timeConfig: {},
        error: action.payload,
      };
    case FETCH_SYSTEMCONFIGPR_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_SYSTEMCONFIGPR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        payrollConfig: action.payload,
        error: "",
      };
    case FETCH_SYSTEMCONFIGPR_FAILURE:
      return {
        ...state,
        isLoading: false,
        payrollConfig: {},
        error: action.payload,
      };
    case ADD_SYSTEMCONFIGTAXCREDIT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_SYSTEMCONFIGTAXCREDIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case ADD_SYSTEMCONFIGTAXCREDIT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case FETCH_SYSTEMCONFIGTAXCREDIT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_SYSTEMCONFIGTAXCREDIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        taxCredit: action.payload,
        error: "",
      };
    case FETCH_SYSTEMCONFIGTAXCREDIT_FAILURE:
      return {
        ...state,
        isLoading: false,
        taxCredit: [],
        error: action.payload,
      };
    case ADD_MANAGER_REQUEST:
    case DELETE_MANAGER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_MANAGER_SUCCESS:
    case DELETE_MANAGER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case ADD_MANAGER_FAILURE:
    case DELETE_MANAGER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case FETCH_MANAGERS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_MANAGERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        managers: action.payload,
        error: "",
      };
    case FETCH_MANAGERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        managers: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
