const ADD_FEEDBACK_REQUEST = "ADD_FEEDBACK_REQUEST";
const ADD_FEEDBACK_SUCCESS = "ADD_FEEDBACK_SUCCESS";
const ADD_FEEDBACK_FAILURE = "ADD_FEEDBACK_FAILURE";
const FETCH_FEEDBACKS_REQUEST = "FETCH_FEEDBACKS_REQUEST";
const FETCH_FEEDBACKS_SUCCESS = "FETCH_FEEDBACKS_SUCCESS";
const FETCH_FEEDBACKS_FAILURE = "FETCH_FEEDBACKS_FAILURE";

export default {
  ADD_FEEDBACK_REQUEST,
  ADD_FEEDBACK_SUCCESS,
  ADD_FEEDBACK_FAILURE,
  FETCH_FEEDBACKS_REQUEST,
  FETCH_FEEDBACKS_SUCCESS,
  FETCH_FEEDBACKS_FAILURE,
};
