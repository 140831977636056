import { default as punishmentListTypes } from "./types";

const {
  FETCH_PUNISHMENT_LIST_REQUEST,
  FETCH_PUNISHMENT_LIST_SUCCESS,
  FETCH_PUNISHMENT_LIST_FAILURE,
} = punishmentListTypes;

const fetchPunishmentListRequest = () => {
  return {
    type: FETCH_PUNISHMENT_LIST_REQUEST,
  };
};

const fetchPunishmentListSuccess = (punishmentList) => {
  return {
    type: FETCH_PUNISHMENT_LIST_SUCCESS,
    payload: punishmentList,
  };
};

const fetchPunishmentListError = (error) => {
  return {
    type: FETCH_PUNISHMENT_LIST_FAILURE,
    payload: error,
  };
};

export default {
  fetchPunishmentListRequest,
  fetchPunishmentListSuccess,
  fetchPunishmentListError,
};
