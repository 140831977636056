const ADD_RAISE_REQUEST = "ADD_RAISE_REQUEST";
const ADD_RAISE_SUCCESS = "ADD_RAISE_SUCCESS";
const ADD_RAISE_FAILURE = "ADD_RAISE_FAILURE";
const EDIT_RAISE_REQUEST = "EDIT_RAISE_REQUEST";
const EDIT_RAISE_SUCCESS = "EDIT_RAISE_SUCCESS";
const EDIT_RAISE_FAILURE = "EDIT_RAISE_FAILURE";
const DELETE_RAISE_REQUEST = "DELETE_RAISE_REQUEST";
const DELETE_RAISE_SUCCESS = "DELETE_RAISE_SUCCESS";
const DELETE_RAISE_FAILURE = "DELETE_RAISE_FAILURE";
const FETCH_RAISES_REQUEST = "FETCH_RAISES_REQUEST";
const FETCH_RAISES_SUCCESS = "FETCH_RAISES_SUCCESS";
const FETCH_RAISES_FAILURE = "FETCH_RAISES_FAILURE";
const FETCH_RAISE_REQUEST = "FETCH_RAISE_REQUEST";
const FETCH_RAISE_SUCCESS = "FETCH_RAISE_SUCCESS";
const FETCH_RAISE_FAILURE = "FETCH_RAISE_FAILURE";
const FETCH_RAISE_NDTYPES_REQUEST = "FETCH_RAISE_NDTYPES_REQUEST";
const FETCH_RAISE_NDTYPES_SUCCESS = "FETCH_RAISE_NDTYPES_SUCCESS";
const FETCH_RAISE_NDTYPES_FAILURE = "FETCH_RAISE_NDTYPES_FAILURE";
const CLEAR_RAISE = "CLEAR_RAISE";

export default {
  ADD_RAISE_REQUEST,
  ADD_RAISE_SUCCESS,
  ADD_RAISE_FAILURE,
  EDIT_RAISE_REQUEST,
  EDIT_RAISE_SUCCESS,
  EDIT_RAISE_FAILURE,
  DELETE_RAISE_REQUEST,
  DELETE_RAISE_SUCCESS,
  DELETE_RAISE_FAILURE,
  FETCH_RAISES_REQUEST,
  FETCH_RAISES_SUCCESS,
  FETCH_RAISES_FAILURE,
  FETCH_RAISE_REQUEST,
  FETCH_RAISE_SUCCESS,
  FETCH_RAISE_FAILURE,
  CLEAR_RAISE,
};
