const ADD_DOCUMENTFILE_REQUEST = "ADD_DOCUMENTFILE_REQUEST";
const ADD_DOCUMENTFILE_SUCCESS = "ADD_DOCUMENTFILE_SUCCESS";
const ADD_DOCUMENTFILE_FAILURE = "ADD_DOCUMENTFILE_FAILURE";
const FETCH_DOCUMENTFILES_REQUEST = "FETCH_DOCUMENTFILES_REQUEST";
const FETCH_DOCUMENTFILES_SUCCESS = "FETCH_DOCUMENTFILES_SUCCESS";
const FETCH_DOCUMENTFILES_FAILURE = "FETCH_DOCUMENTFILES_FAILURE";

export default {
  ADD_DOCUMENTFILE_REQUEST,
  ADD_DOCUMENTFILE_SUCCESS,
  ADD_DOCUMENTFILE_FAILURE,
  FETCH_DOCUMENTFILES_REQUEST,
  FETCH_DOCUMENTFILES_SUCCESS,
  FETCH_DOCUMENTFILES_FAILURE,
};
