import { default as prepaymentJournalsTypes } from "./types";

const {
  ADD_PREPAYMENT_JOURNAL_REQUEST,
  ADD_PREPAYMENT_JOURNAL_SUCCESS,
  ADD_PREPAYMENT_JOURNAL_FAILURE,
  DELETE_PREPAYMENT_JOURNAL_REQUEST,
  DELETE_PREPAYMENT_JOURNAL_SUCCESS,
  DELETE_PREPAYMENT_JOURNAL_FAILURE,
  FETCH_PREPAYMENT_JOURNALS_REQUEST,
  FETCH_PREPAYMENT_JOURNALS_SUCCESS,
  FETCH_PREPAYMENT_JOURNALS_FAILURE,
  FETCH_PREPAYMENT_JOURNAL_REQUEST,
  FETCH_PREPAYMENT_JOURNAL_SUCCESS,
  FETCH_PREPAYMENT_JOURNAL_FAILURE,
  ARCHIVE_PREPAYMENT_JOURNAL_REQUEST,
  ARCHIVE_PREPAYMENT_JOURNAL_SUCCESS,
  ARCHIVE_PREPAYMENT_JOURNAL_FAILURE,
  CALCULATE_PREPAYMENT_JOURNAL_REQUEST,
  CALCULATE_PREPAYMENT_JOURNAL_SUCCESS,
  CALCULATE_PREPAYMENT_JOURNAL_FAILURE,
} = prepaymentJournalsTypes;

const addPrepaymentJournalRequest = () => {
  return {
    type: ADD_PREPAYMENT_JOURNAL_REQUEST,
  };
};

const addPrepaymentJournalSuccess = () => {
  return {
    type: ADD_PREPAYMENT_JOURNAL_SUCCESS,
  };
};

const addPrepaymentJournalError = (error) => {
  return {
    type: ADD_PREPAYMENT_JOURNAL_FAILURE,
    payload: error,
  };
};

const deletePrepaymentJournalRequest = () => {
  return {
    type: DELETE_PREPAYMENT_JOURNAL_REQUEST,
  };
};

const deletePrepaymentJournalSuccess = () => {
  return {
    type: DELETE_PREPAYMENT_JOURNAL_SUCCESS,
  };
};

const deletePrepaymentJournalError = (error) => {
  return {
    type: DELETE_PREPAYMENT_JOURNAL_FAILURE,
    payload: error,
  };
};

const fetchPrepaymentJournalsRequest = () => {
  return {
    type: FETCH_PREPAYMENT_JOURNALS_REQUEST,
  };
};

const fetchPrepaymentJournalsSuccess = (schedules) => {
  return {
    type: FETCH_PREPAYMENT_JOURNALS_SUCCESS,
    payload: schedules,
  };
};

const fetchPrepaymentJournalsError = (error) => {
  return {
    type: FETCH_PREPAYMENT_JOURNALS_FAILURE,
    payload: error,
  };
};

const fetchPrepaymentJournalRequest = () => {
  return {
    type: FETCH_PREPAYMENT_JOURNAL_REQUEST,
  };
};

const fetchPrepaymentJournalSuccess = (data) => {
  return {
    type: FETCH_PREPAYMENT_JOURNAL_SUCCESS,
    payload: data,
  };
};

const fetchPrepaymentJournalError = (error) => {
  return {
    type: FETCH_PREPAYMENT_JOURNAL_FAILURE,
    payload: error,
  };
};

const archivePrepaymentJournalRequest = () => {
  return {
    type: ARCHIVE_PREPAYMENT_JOURNAL_REQUEST,
  };
};

const archivePrepaymentJournalSuccess = () => {
  return {
    type: ARCHIVE_PREPAYMENT_JOURNAL_SUCCESS,
  };
};

const archivePrepaymentJournalError = (error) => {
  return {
    type: ARCHIVE_PREPAYMENT_JOURNAL_FAILURE,
    payload: error,
  };
};

const calculatePrepaymentJournalRequest = () => {
  return {
    type: CALCULATE_PREPAYMENT_JOURNAL_REQUEST,
  };
};

const calculatePrepaymentJournalSuccess = () => {
  return {
    type: CALCULATE_PREPAYMENT_JOURNAL_SUCCESS,
  };
};

const calculatePrepaymentJournalError = (error) => {
  return {
    type: CALCULATE_PREPAYMENT_JOURNAL_FAILURE,
    payload: error,
  };
};

export default {
  addPrepaymentJournalRequest,
  addPrepaymentJournalSuccess,
  addPrepaymentJournalError,
  deletePrepaymentJournalRequest,
  deletePrepaymentJournalSuccess,
  deletePrepaymentJournalError,
  fetchPrepaymentJournalsRequest,
  fetchPrepaymentJournalsSuccess,
  fetchPrepaymentJournalsError,
  fetchPrepaymentJournalRequest,
  fetchPrepaymentJournalSuccess,
  fetchPrepaymentJournalError,
  archivePrepaymentJournalRequest,
  archivePrepaymentJournalSuccess,
  archivePrepaymentJournalError,
  calculatePrepaymentJournalRequest,
  calculatePrepaymentJournalSuccess,
  calculatePrepaymentJournalError,
};
