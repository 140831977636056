import { default as employeeTypes } from "./types";

const {
  FETCH_EMPLOYEE_REQUEST,
  FETCH_EMPLOYEE_SUCCESS,
  FETCH_EMPLOYEE_FAILURE,
  CLEAR_EMPLOYEE,
} = employeeTypes;

const initialState = {
  isLoading: false,
  detail: null,
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EMPLOYEE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_EMPLOYEE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        detail: action.payload,
        error: "",
      };
    case FETCH_EMPLOYEE_FAILURE:
      return {
        ...state,
        isLoading: false,
        detail: null,
        error: action.payload,
      };
    case CLEAR_EMPLOYEE:
      return {
        ...state,
        detail: null,
      };
    default:
      return state;
  }
};

export default reducer;
