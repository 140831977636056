import { getSpecialDays, createSpecialDay, updateSpecialDay, removeSpecialDay } from "services/specialDay";
import { default as specialdaysActions } from "./actions";

const {
  addSpecialDayRequest,
  addSpecialDaySuccess,
  addSpecialDayError,
  fetchSpecialDaysRequest,
  fetchSpecialDaysSuccess,
  fetchSpecialDaysError,
} = specialdaysActions;

const fetchSpecialDays = () => {
  return async (dispatch) => {
    dispatch(fetchSpecialDaysRequest());

    try {
      const specialdays = await getSpecialDays();
      dispatch(fetchSpecialDaysSuccess(specialdays));
    } catch (err) {
      dispatch(fetchSpecialDaysError(err.message));
    }
  };
};

const addSpecialDay = (data) => {
  return async (dispatch) => {
    dispatch(addSpecialDayRequest());

    try {
      await createSpecialDay(data);
      dispatch(addSpecialDaySuccess());
    } catch (err) {
      dispatch(addSpecialDayError(err.message));
    }
  };
};

const editSpecialDay = (data) => {
  return async (dispatch) => {
    dispatch(addSpecialDayRequest());

    try {
      await updateSpecialDay(data);
      dispatch(addSpecialDaySuccess());
    } catch (err) {
      dispatch(addSpecialDayError(err.message));
    }
  };
};

const deleteSpecialDay = (data) => {
  return async (dispatch) => {
    dispatch(addSpecialDayRequest());

    try {
      await removeSpecialDay(data);
      dispatch(addSpecialDaySuccess());
    } catch (err) {
      dispatch(addSpecialDayError(err.message));
    }
  };
};

export default { fetchSpecialDays, addSpecialDay, editSpecialDay, deleteSpecialDay };