import instance from "./axios.base";

const add = async (data) => {
  const res = await instance.post("/prempadd", data);
  return res.data;
};

const edit = async (data) => {
  const res = await instance.put("/prempadd", data);
  return res.data;
};

const remove = async (id) => {
  const res = await instance.delete(`/prempadd/${id}`);
  return res.data;
};

const getEmpRaises = async (empId) => {
  const res = await instance.get(`/prempadd/${empId}`);
  return res.data;
};

export { add, edit, remove, getEmpRaises };
