import { default as preJournalsTypes } from "./types";

const {
  ADD_PRE_JOURNAL_REQUEST,
  ADD_PRE_JOURNAL_SUCCESS,
  ADD_PRE_JOURNAL_FAILURE,
  DELETE_PRE_JOURNAL_REQUEST,
  DELETE_PRE_JOURNAL_SUCCESS,
  DELETE_PRE_JOURNAL_FAILURE,
  FETCH_PRE_JOURNALS_REQUEST,
  FETCH_PRE_JOURNALS_SUCCESS,
  FETCH_PRE_JOURNALS_FAILURE,
  FETCH_PRE_JOURNAL_REQUEST,
  FETCH_PRE_JOURNAL_SUCCESS,
  FETCH_PRE_JOURNAL_FAILURE,
  ARCHIVE_PRE_JOURNAL_REQUEST,
  ARCHIVE_PRE_JOURNAL_SUCCESS,
  ARCHIVE_PRE_JOURNAL_FAILURE,
  CALCULATE_PRE_JOURNAL_REQUEST,
  CALCULATE_PRE_JOURNAL_SUCCESS,
  CALCULATE_PRE_JOURNAL_FAILURE,
  CLEAR_PRE_JOURNAL,
} = preJournalsTypes;

const addPreJournalRequest = () => {
  return {
    type: ADD_PRE_JOURNAL_REQUEST,
  };
};

const addPreJournalSuccess = () => {
  return {
    type: ADD_PRE_JOURNAL_SUCCESS,
  };
};

const addPreJournalError = (error) => {
  return {
    type: ADD_PRE_JOURNAL_FAILURE,
    payload: error,
  };
};

const deletePreJournalRequest = () => {
  return {
    type: DELETE_PRE_JOURNAL_REQUEST,
  };
};

const deletePreJournalSuccess = () => {
  return {
    type: DELETE_PRE_JOURNAL_SUCCESS,
  };
};

const deletePreJournalError = (error) => {
  return {
    type: DELETE_PRE_JOURNAL_FAILURE,
    payload: error,
  };
};

const fetchPreJournalsRequest = () => {
  return {
    type: FETCH_PRE_JOURNALS_REQUEST,
  };
};

const fetchPreJournalsSuccess = (schedules) => {
  return {
    type: FETCH_PRE_JOURNALS_SUCCESS,
    payload: schedules,
  };
};

const fetchPreJournalsError = (error) => {
  return {
    type: FETCH_PRE_JOURNALS_FAILURE,
    payload: error,
  };
};

const fetchPreJournalRequest = () => {
  return {
    type: FETCH_PRE_JOURNAL_REQUEST,
  };
};

const fetchPreJournalSuccess = (data) => {
  return {
    type: FETCH_PRE_JOURNAL_SUCCESS,
    payload: data,
  };
};

const fetchPreJournalError = (error) => {
  return {
    type: FETCH_PRE_JOURNAL_FAILURE,
    payload: error,
  };
};

const archivePreJournalRequest = () => {
  return {
    type: ARCHIVE_PRE_JOURNAL_REQUEST,
  };
};

const archivePreJournalSuccess = () => {
  return {
    type: ARCHIVE_PRE_JOURNAL_SUCCESS,
  };
};

const archivePreJournalError = (error) => {
  return {
    type: ARCHIVE_PRE_JOURNAL_FAILURE,
    payload: error,
  };
};

const calculatePreJournalRequest = () => {
  return {
    type: CALCULATE_PRE_JOURNAL_REQUEST,
  };
};

const calculatePreJournalSuccess = () => {
  return {
    type: CALCULATE_PRE_JOURNAL_SUCCESS,
  };
};

const calculatePreJournalError = (error) => {
  return {
    type: CALCULATE_PRE_JOURNAL_FAILURE,
    payload: error,
  };
};

const clearPreJournal = () => {
  return {
    type: CLEAR_PRE_JOURNAL,
  };
};

export default {
  addPreJournalRequest,
  addPreJournalSuccess,
  addPreJournalError,
  deletePreJournalRequest,
  deletePreJournalSuccess,
  deletePreJournalError,
  fetchPreJournalsRequest,
  fetchPreJournalsSuccess,
  fetchPreJournalsError,
  fetchPreJournalRequest,
  fetchPreJournalSuccess,
  fetchPreJournalError,
  archivePreJournalRequest,
  archivePreJournalSuccess,
  archivePreJournalError,
  calculatePreJournalRequest,
  calculatePreJournalSuccess,
  calculatePreJournalError,
  clearPreJournal,
};
