import instance from "./axios.base";
import { dateFormat } from "utils/formatter";

const getDocumentFiles = async (data) => {
  const res = await instance.get(`/companydocuments/${data}`);

  return res.data;
};

const createDocumentFile = async (data) => {
  var formData = new FormData();
  formData.append('name', data.name);
  formData.append('folderId', data.folderId);
  formData.append('file', data.file);

  const res = await instance.post("/companydocuments", formData, { headers: {'Content-Type': 'multipart/form-data' }});

  return res.data;
};

const updateDocumentFile = async (data) => {
  var formData = new FormData();
  formData.append('id', data.id);
  formData.append('name', data.name);
  formData.append('folderId', data.folderId);
  formData.append('file', data.file);

  const res = await instance.put("/companydocuments", formData, { headers: {'Content-Type': 'multipart/form-data' }});

  return res.data;
};

const removeDocumentFile = async (data) => {
  const res = await instance.delete(`/companydocuments/${data.id}`);

  return res.data;
};

export { getDocumentFiles, createDocumentFile, updateDocumentFile, removeDocumentFile };
