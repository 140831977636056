import instance from "./axios.base";

const getDocumentFolders = async () => {
  const res = await instance.get("/companyfolders");
  
  return res.data;
};

const createDocumentFolder = async (data) => {
  const res = await instance.post("/companyfolders", data);

  return res.data;
};

const updateDocumentFolder = async (data) => {
  const res = await instance.put("/companyfolders", data);

  return res.data;
};

const removeDocumentFolder = async (data) => {
  const res = await instance.delete(`/companyfolders/${data.id}`);

  return res.data;
};

export { getDocumentFolders, createDocumentFolder, updateDocumentFolder, removeDocumentFolder };