import { default as tasksTypes } from "./types";

const {
  ADD_TASK_REQUEST,
  ADD_TASK_SUCCESS,
  ADD_TASK_FAILURE,
  FETCH_TASKS_REQUEST,
  FETCH_TASKS_SUCCESS,
  FETCH_TASKS_FAILURE,
} = tasksTypes;

const addTaskRequest = () => {
  return {
    type: ADD_TASK_REQUEST,
  };
};

const addTaskSuccess = (tasks) => {
  return {
    type: ADD_TASK_SUCCESS,
  };
};

const addTaskError = (error) => {
  return {
    type: ADD_TASK_FAILURE,
    payload: error,
  };
};

const fetchTasksRequest = () => {
  return {
    type: FETCH_TASKS_REQUEST,
  };
};

const fetchTasksSuccess = (tasks) => {
  return {
    type: FETCH_TASKS_SUCCESS,
    payload: tasks,
  };
};

const fetchTasksError = (error) => {
  return {
    type: FETCH_TASKS_FAILURE,
    payload: error,
  };
};

export default {
  addTaskRequest,
  addTaskSuccess,
  addTaskError,
  fetchTasksRequest,
  fetchTasksSuccess,
  fetchTasksError,
};
