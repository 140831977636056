import instance from "./axios.base";

const getTeams = async () => {
  const res = await instance.get("/hrteams");
  
  return res.data;
};

const createTeam = async (data) => {  
  const res = await instance.post("/hrteams", data);

  return res.data;
};

const updateTeam = async (data) => {
  const res = await instance.put("/hrteams", data);

  return res.data;
};

const removeTeam = async (data) => {
  const res = await instance.delete(`/hrteams/${data.id}`);

  return res.data;
};

export { getTeams, createTeam, updateTeam, removeTeam };