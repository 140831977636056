import instance from "./axios.base";

const addSchedule = async (data) => {
  await instance.post("/taschedule", data);
};

const addScheduleShift = async (data) => {
  await instance.post("/tashiftschedule", data);
};

const editSchedule = async (data) => {
  await instance.put("/taschedule", data);
};

const editScheduleShift = async (data) => {
  await instance.put("/tashiftschedule", data);
};

const deleteSchedule = async (id) => {
  await instance.delete(`/taschedule/${id}`);
};

const getSchedules = async () => {
  const res = await instance.get("/taschedule");
  return res.data;
};

const getSchedule = async (id) => {
  const res = await instance.get(`/taschedule/${id}`);
  return res.data;
};

export {
  addSchedule,
  addScheduleShift,
  editSchedule,
  editScheduleShift,
  deleteSchedule,
  getSchedules,
  getSchedule,
};
