import { default as basicSalariesTypes } from "./types";

const {
  ADD_BASIC_SALARY_REQUEST,
  ADD_BASIC_SALARY_SUCCESS,
  ADD_BASIC_SALARY_FAILURE,
  EDIT_BASIC_SALARY_REQUEST,
  EDIT_BASIC_SALARY_SUCCESS,
  EDIT_BASIC_SALARY_FAILURE,
  DELETE_BASIC_SALARY_REQUEST,
  DELETE_BASIC_SALARY_SUCCESS,
  DELETE_BASIC_SALARY_FAILURE,
  FETCH_BASIC_SALARIES_REQUEST,
  FETCH_BASIC_SALARIES_SUCCESS,
  FETCH_BASIC_SALARIES_FAILURE,
  FETCH_BASIC_SALARY_REQUEST,
  FETCH_BASIC_SALARY_SUCCESS,
  FETCH_BASIC_SALARY_FAILURE,
  ARCHIVE_BASIC_SALARY_REQUEST,
  ARCHIVE_BASIC_SALARY_SUCCESS,
  ARCHIVE_BASIC_SALARY_FAILURE,
  RECALCULATE_SALARY_REQUEST,
  RECALCULATE_SALARY_SUCCESS,
  RECALCULATE_SALARY_FAILURE,
} = basicSalariesTypes;

const initialState = {
  isLoading: false,
  list: [],
  detail: null,
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_BASIC_SALARY_REQUEST:
    case EDIT_BASIC_SALARY_REQUEST:
    case DELETE_BASIC_SALARY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_BASIC_SALARY_SUCCESS:
    case EDIT_BASIC_SALARY_SUCCESS:
    case DELETE_BASIC_SALARY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case ADD_BASIC_SALARY_FAILURE:
    case EDIT_BASIC_SALARY_FAILURE:
    case DELETE_BASIC_SALARY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case FETCH_BASIC_SALARIES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_BASIC_SALARIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: action.payload,
        error: "",
      };
    case FETCH_BASIC_SALARIES_FAILURE:
      return {
        ...state,
        isLoading: false,
        list: [],
        error: action.payload,
      };
    case FETCH_BASIC_SALARY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_BASIC_SALARY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        detail: action.payload,
        error: "",
      };
    case FETCH_BASIC_SALARY_FAILURE:
      return {
        ...state,
        isLoading: false,
        detail: null,
        error: action.payload,
      };
    case ARCHIVE_BASIC_SALARY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ARCHIVE_BASIC_SALARY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case ARCHIVE_BASIC_SALARY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case RECALCULATE_SALARY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case RECALCULATE_SALARY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case RECALCULATE_SALARY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
