import { toast } from "react-toastify";
import {
  editUserPassword as updateUserPassword,
  editUserPasswordByEmail as updateUserPasswordByEmail,
  editUserPermission as updateUserPermission,
  getUserPermissions,
} from "services/userPermission";
import { default as userPermissionsActions } from "./actions";

const {
  editUserPasswordRequest,
  editUserPasswordSuccess,
  editUserPasswordError,
  editUserPasswordByEmailRequest,
  editUserPasswordByEmailSuccess,
  editUserPasswordByEmailError,
  editUserPermissionRequest,
  editUserPermissionSuccess,
  editUserPermissionError,
  fetchUserPermissionsRequest,
  fetchUserPermissionsSuccess,
  fetchUserPermissionsError,
} = userPermissionsActions;

const editUserPassword = (data) => {
  return async (dispatch) => {
    dispatch(editUserPasswordRequest());

    try {
      await updateUserPassword(data);
      dispatch(editUserPasswordSuccess());
      toast.success("Амжилттай заслаа");
    } catch (err) {
      dispatch(editUserPasswordError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const editUserPasswordByEmail = (data) => {
  return async (dispatch) => {
    dispatch(editUserPasswordByEmailRequest());

    try {
      await updateUserPasswordByEmail(data);
      dispatch(editUserPasswordByEmailSuccess());
      toast.success("Амжилттай заслаа");
    } catch (err) {
      dispatch(editUserPasswordByEmailError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const editUserPermission = (data) => {
  return async (dispatch) => {
    dispatch(editUserPermissionRequest());

    try {
      await updateUserPermission(data);
      dispatch(editUserPermissionSuccess());
      toast.success("Амжилттай заслаа");
    } catch (err) {
      dispatch(editUserPermissionError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const fetchUserPermissions = () => {
  return async (dispatch) => {
    dispatch(fetchUserPermissionsRequest());

    try {
      const userPermissions = await getUserPermissions();
      dispatch(fetchUserPermissionsSuccess(userPermissions));
    } catch (err) {
      dispatch(fetchUserPermissionsError(err.message));
    }
  };
};

export default {
  editUserPassword,
  editUserPasswordByEmail,
  editUserPermission,
  fetchUserPermissions,
};
