import { getLanguages } from "services/language";
import { default as languagesActions } from "./actions";

const {
  fetchLanguagesRequest,
  fetchLanguagesSuccess,
  fetchLanguagesError,
} = languagesActions;

const fetchLanguages = () => {
  return async (dispatch) => {
    dispatch(fetchLanguagesRequest());

    try {
      const languages = await getLanguages();
      dispatch(fetchLanguagesSuccess(languages));
    } catch (err) {
      dispatch(fetchLanguagesError(err.message));
    }
  };
};

export default {
  fetchLanguages,
};
