import { toast } from "react-toastify";
import {
  getAwardInfo,
  editAwardInfo,
  deleteAwardInfo,
} from "services/awardInfo";
import { default as employeeAwardInfoActions } from "./actions";

const {
  fetchEmployeeAwardInfoRequest,
  fetchEmployeeAwardInfoSuccess,
  fetchEmployeeAwardInfoError,
  editEmployeeAwardInfoRequest,
  editEmployeeAwardInfoSuccess,
  editEmployeeAwardInfoError,
  deleteEmployeeAwardInfoRequest,
  deleteEmployeeAwardInfoSuccess,
  deleteEmployeeAwardInfoError,
} = employeeAwardInfoActions;

const fetchEmployeeAwardInfo = (data) => {
  return async (dispatch) => {
    dispatch(fetchEmployeeAwardInfoRequest());

    try {
      const employeeAwardInfo = await getAwardInfo(data);
      dispatch(fetchEmployeeAwardInfoSuccess(employeeAwardInfo));
    } catch (err) {
      dispatch(fetchEmployeeAwardInfoError(err.message));
    }
  };
};

const editEmployeeAwardInfo = (data) => {
  return async (dispatch) => {
    dispatch(editEmployeeAwardInfoRequest());

    try {
      await editAwardInfo(data);
      dispatch(editEmployeeAwardInfoSuccess());
      toast.success("Амжилттай заслаа");
    } catch (err) {
      dispatch(editEmployeeAwardInfoError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const deleteEmployeeAwardInfo = (id) => {
  return async (dispatch) => {
    dispatch(deleteEmployeeAwardInfoRequest());

    try {
      await deleteAwardInfo(id);
      dispatch(deleteEmployeeAwardInfoSuccess());
      toast.success("Амжилттай устгалаа");
    } catch (err) {
      dispatch(deleteEmployeeAwardInfoError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

export default {
  fetchEmployeeAwardInfo,
  editEmployeeAwardInfo,
  deleteEmployeeAwardInfo,
};
