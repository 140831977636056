import { getFamilyMembers } from "services/familyMember";
import { default as familyMembersActions } from "./actions";

const {
  fetchFamilyMembersRequest,
  fetchFamilyMembersSuccess,
  fetchFamilyMembersError,
} = familyMembersActions;

const fetchFamilyMembers = () => {
  return async (dispatch) => {
    dispatch(fetchFamilyMembersRequest());

    try {
      const familyMembers = await getFamilyMembers();
      dispatch(fetchFamilyMembersSuccess(familyMembers));
    } catch (err) {
      dispatch(fetchFamilyMembersError(err.message));
    }
  };
};

export default { fetchFamilyMembers };
