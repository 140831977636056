import { default as types } from "./types";

const {
  ADD_ORDER_REQUEST,
  ADD_ORDER_SUCCESS,
  ADD_ORDER_FAILURE,
  EDIT_ORDER_REQUEST,
  EDIT_ORDER_SUCCESS,
  EDIT_ORDER_FAILURE,
  DELETE_ORDER_REQUEST,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_FAILURE,
  FETCH_ORDERS_REQUEST,
  FETCH_ORDERS_SUCCESS,
  FETCH_ORDERS_FAILURE,
} = types;

const addOrderRequest = () => {
  return {
    type: ADD_ORDER_REQUEST,
  };
};

const addOrderSuccess = () => {
  return {
    type: ADD_ORDER_SUCCESS,
  };
};

const addOrderError = (error) => {
  return {
    type: ADD_ORDER_FAILURE,
    payload: error,
  };
};

const editOrderRequest = () => {
  return {
    type: EDIT_ORDER_REQUEST,
  };
};

const editOrderSuccess = () => {
  return {
    type: EDIT_ORDER_SUCCESS,
  };
};

const editOrderError = (error) => {
  return {
    type: EDIT_ORDER_FAILURE,
    payload: error,
  };
};

const deleteOrderRequest = () => {
  return {
    type: DELETE_ORDER_REQUEST,
  };
};

const deleteOrderSuccess = () => {
  return {
    type: DELETE_ORDER_SUCCESS,
  };
};

const deleteOrderError = (error) => {
  return {
    type: DELETE_ORDER_FAILURE,
    payload: error,
  };
};

const fetchOrdersRequest = () => {
  return {
    type: FETCH_ORDERS_REQUEST,
  };
};

const fetchOrdersSuccess = (orders) => {
  return {
    type: FETCH_ORDERS_SUCCESS,
    payload: orders,
  };
};

const fetchOrdersError = (error) => {
  return {
    type: FETCH_ORDERS_FAILURE,
    payload: error,
  };
};

export default {
  addOrderRequest,
  addOrderSuccess,
  addOrderError,
  editOrderRequest,
  editOrderSuccess,
  editOrderError,
  deleteOrderRequest,
  deleteOrderSuccess,
  deleteOrderError,
  fetchOrdersRequest,
  fetchOrdersSuccess,
  fetchOrdersError,
};
