import { default as trainingemployeesTypes } from "./types";

const {
  ADD_TRAININGEMPLOYEE_REQUEST,
  ADD_TRAININGEMPLOYEE_SUCCESS,
  ADD_TRAININGEMPLOYEE_FAILURE,
  FETCH_TRAININGEMPLOYEES_REQUEST,
  FETCH_TRAININGEMPLOYEES_SUCCESS,
  FETCH_TRAININGEMPLOYEES_FAILURE,
} = trainingemployeesTypes;

const addTrainingEmployeeRequest = () => {
  return {
    type: ADD_TRAININGEMPLOYEE_REQUEST,
  };
};

const addTrainingEmployeeSuccess = () => {
  return {
    type: ADD_TRAININGEMPLOYEE_SUCCESS,
  };
};

const addTrainingEmployeeError = (error) => {
  return {
    type: ADD_TRAININGEMPLOYEE_FAILURE,
    payload: error,
  };
};

const fetchTrainingEmployeesRequest = () => {
  return {
    type: FETCH_TRAININGEMPLOYEES_REQUEST,
  };
};

const fetchTrainingEmployeesSuccess = (trainingemployees) => {
  return {
    type: FETCH_TRAININGEMPLOYEES_SUCCESS,
    payload: trainingemployees,
  };
};

const fetchTrainingEmployeesError = (error) => {
  return {
    type: FETCH_TRAININGEMPLOYEES_FAILURE,
    payload: error,
  };
};

export default {
  addTrainingEmployeeRequest,
  addTrainingEmployeeSuccess,
  addTrainingEmployeeError,
  fetchTrainingEmployeesRequest,
  fetchTrainingEmployeesSuccess,
  fetchTrainingEmployeesError,
};
