import { toast } from "react-toastify";
import {
  getAnnouncements,
  createAnnouncement,
  updateAnnouncement,
  removeAnnouncement,
} from "services/announcement";
import { default as announcementsActions } from "./actions";

const {
  addAnnouncementRequest,
  addAnnouncementSuccess,
  addAnnouncementError,
  fetchAnnouncementsRequest,
  fetchAnnouncementsSuccess,
  fetchAnnouncementsError,
} = announcementsActions;

const fetchAnnouncements = () => {
  return async (dispatch) => {
    dispatch(fetchAnnouncementsRequest());

    try {
      const announcements = await getAnnouncements();
      dispatch(fetchAnnouncementsSuccess(announcements));
    } catch (err) {
      dispatch(fetchAnnouncementsError(err.message));
    }
  };
};

const addAnnouncement = (data) => {
  return async (dispatch) => {
    dispatch(addAnnouncementRequest());

    try {
      await createAnnouncement(data);
      dispatch(addAnnouncementSuccess());
      toast.success("Амжилттай нэмлээ");
    } catch (err) {
      dispatch(addAnnouncementError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const editAnnouncement = (data) => {
  return async (dispatch) => {
    dispatch(addAnnouncementRequest());

    try {
      await updateAnnouncement(data);
      dispatch(addAnnouncementSuccess());
      toast.success("Амжилттай заслаа");
    } catch (err) {
      dispatch(addAnnouncementError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const deleteAnnouncement = (data) => {
  return async (dispatch) => {
    dispatch(addAnnouncementRequest());

    try {
      await removeAnnouncement(data);
      dispatch(addAnnouncementSuccess());
      toast.success("Амжилттай устгалаа");
    } catch (err) {
      dispatch(addAnnouncementError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

export default {
  fetchAnnouncements,
  addAnnouncement,
  editAnnouncement,
  deleteAnnouncement,
};
