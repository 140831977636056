import { toast } from "react-toastify";
import {
  addPrepaymentJournal,
  deletePrepaymentJournal,
  getPrepaymentJournals,
  getPrepaymentJournal,
  archivePrepaymentJournal,
  calculatePrepaymentJournal,
} from "services/prepaymentJournal";
import { default as prepaymentJournalsActions } from "./actions";

const {
  addPrepaymentJournalRequest,
  addPrepaymentJournalSuccess,
  addPrepaymentJournalError,
  deletePrepaymentJournalRequest,
  deletePrepaymentJournalSuccess,
  deletePrepaymentJournalError,
  fetchPrepaymentJournalsRequest,
  fetchPrepaymentJournalsSuccess,
  fetchPrepaymentJournalsError,
  fetchPrepaymentJournalRequest,
  fetchPrepaymentJournalSuccess,
  fetchPrepaymentJournalError,
  archivePrepaymentJournalRequest,
  archivePrepaymentJournalSuccess,
  archivePrepaymentJournalError,
  calculatePrepaymentJournalRequest,
  calculatePrepaymentJournalSuccess,
  calculatePrepaymentJournalError,
} = prepaymentJournalsActions;

const add = (data) => {
  return async (dispatch) => {
    dispatch(addPrepaymentJournalRequest());

    try {
      await addPrepaymentJournal(data);
      dispatch(addPrepaymentJournalSuccess());
      toast.success("Амжилттай нэмлээ");
    } catch (err) {
      dispatch(addPrepaymentJournalError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const remove = (id) => {
  return async (dispatch) => {
    dispatch(deletePrepaymentJournalRequest());

    try {
      await deletePrepaymentJournal(id);
      dispatch(deletePrepaymentJournalSuccess());
      toast.success("Амжилттай устгалаа");
    } catch (err) {
      dispatch(deletePrepaymentJournalError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const fetchPrepaymentJournals = (startDate, finishDate) => {
  return async (dispatch) => {
    dispatch(fetchPrepaymentJournalsRequest());

    try {
      const journals = await getPrepaymentJournals(startDate, finishDate);
      dispatch(fetchPrepaymentJournalsSuccess(journals));
    } catch (err) {
      dispatch(fetchPrepaymentJournalsError(err.message));
    }
  };
};

const fetchPrepaymentJournal = (empId, date) => {
  return async (dispatch) => {
    dispatch(fetchPrepaymentJournalRequest());

    try {
      const prepaymentJournal = await getPrepaymentJournal(empId, date);
      dispatch(fetchPrepaymentJournalSuccess(prepaymentJournal));
    } catch (err) {
      dispatch(fetchPrepaymentJournalError(err.message));
    }
  };
};

const archive = (selected) => {
  return async (dispatch) => {
    dispatch(archivePrepaymentJournalRequest());

    try {
      await archivePrepaymentJournal(selected);
      dispatch(archivePrepaymentJournalSuccess());
      toast.success("Амжилттай архивлалаа");
    } catch (err) {
      dispatch(archivePrepaymentJournalError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const calculate = (year, month) => {
  return async (dispatch) => {
    dispatch(calculatePrepaymentJournalRequest());

    try {
      await calculatePrepaymentJournal(year, month);
      dispatch(calculatePrepaymentJournalSuccess());
      toast.success("Амжилттай дахин бодлоо");
    } catch (err) {
      dispatch(calculatePrepaymentJournalError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

export default {
  add,
  remove,
  fetchPrepaymentJournals,
  fetchPrepaymentJournal,
  archive,
  calculate,
};
