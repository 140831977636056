import { toast } from "react-toastify";
import {
  addBasicSalary,
  editBasicSalary,
  deleteBasicSalary,
  getBasicSalaries,
  getBasicSalary,
  archiveBasicSalaries,
  recalculateSalaries,
} from "services/basicSalary";
import { default as basicSalariesActions } from "./actions";

const {
  addBasicSalaryRequest,
  addBasicSalarySuccess,
  addBasicSalaryError,
  editBasicSalaryRequest,
  editBasicSalarySuccess,
  editBasicSalaryError,
  deleteBasicSalaryRequest,
  deleteBasicSalarySuccess,
  deleteBasicSalaryError,
  fetchBasicSalariesRequest,
  fetchBasicSalariesSuccess,
  fetchBasicSalariesError,
  fetchBasicSalaryRequest,
  fetchBasicSalarySuccess,
  fetchBasicSalaryError,
  archiveBasicSalaryRequest,
  archiveBasicSalarySuccess,
  archiveBasicSalaryError,
  recalculateSalaryRequest,
  recalculateSalarySuccess,
  recalculateSalaryError,
} = basicSalariesActions;

const add = (data) => {
  return async (dispatch) => {
    dispatch(addBasicSalaryRequest());

    try {
      await addBasicSalary(data);
      dispatch(addBasicSalarySuccess());
      toast.success("Амжилттай нэмлээ");
    } catch (err) {
      dispatch(addBasicSalaryError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const edit = (data) => {
  return async (dispatch) => {
    dispatch(editBasicSalaryRequest());

    try {
      await editBasicSalary(data);
      dispatch(editBasicSalarySuccess());
      toast.success("Амжилттай заслаа");
    } catch (err) {
      dispatch(editBasicSalaryError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const remove = (id) => {
  return async (dispatch) => {
    dispatch(deleteBasicSalaryRequest());

    try {
      await deleteBasicSalary(id);
      dispatch(deleteBasicSalarySuccess());
      toast.success("Амжилттай нэмлээ");
    } catch (err) {
      dispatch(deleteBasicSalaryError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const fetchBasicSalaries = (year, month) => {
  return async (dispatch) => {
    dispatch(fetchBasicSalariesRequest());

    try {
      const basicSalaries = await getBasicSalaries(year, month);
      dispatch(fetchBasicSalariesSuccess(basicSalaries));
    } catch (err) {
      dispatch(fetchBasicSalariesError(err.message));
    }
  };
};

const fetchBasicSalary = (empId, date) => {
  return async (dispatch) => {
    dispatch(fetchBasicSalaryRequest());

    try {
      const basicSalary = await getBasicSalary(empId, date);
      dispatch(fetchBasicSalarySuccess(basicSalary));
    } catch (err) {
      dispatch(fetchBasicSalaryError(err.message));
    }
  };
};

const archive = (selected) => {
  return async (dispatch) => {
    dispatch(archiveBasicSalaryRequest());

    try {
      await archiveBasicSalaries(selected);
      dispatch(archiveBasicSalarySuccess());
      toast.success("Амжилттай архивлалаа");
    } catch (err) {
      dispatch(archiveBasicSalaryError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const recalculate = (year, month) => {
  return async (dispatch) => {
    dispatch(recalculateSalaryRequest());

    try {
      await recalculateSalaries(year, month);
      dispatch(recalculateSalarySuccess());
      toast.success("Амжилттай дахин бодлоо");
    } catch (err) {
      dispatch(recalculateSalaryError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

export default {
  add,
  edit,
  remove,
  fetchBasicSalaries,
  fetchBasicSalary,
  archive,
  recalculate,
};
