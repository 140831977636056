import { default as deductionsTypes } from "./types";

const {
  ADD_DEDUCTION_REQUEST,
  ADD_DEDUCTION_SUCCESS,
  ADD_DEDUCTION_FAILURE,
  EDIT_DEDUCTION_REQUEST,
  EDIT_DEDUCTION_SUCCESS,
  EDIT_DEDUCTION_FAILURE,
  DELETE_DEDUCTION_REQUEST,
  DELETE_DEDUCTION_SUCCESS,
  DELETE_DEDUCTION_FAILURE,
  FETCH_DEDUCTIONS_REQUEST,
  FETCH_DEDUCTIONS_SUCCESS,
  FETCH_DEDUCTIONS_FAILURE,
  FETCH_DEDUCTION_REQUEST,
  FETCH_DEDUCTION_SUCCESS,
  FETCH_DEDUCTION_FAILURE,
  CLEAR_DEDUCTION,
} = deductionsTypes;

const initialState = {
  isLoading: false,
  list: [],
  detail: null,
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_DEDUCTION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_DEDUCTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case ADD_DEDUCTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case EDIT_DEDUCTION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case EDIT_DEDUCTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case EDIT_DEDUCTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case DELETE_DEDUCTION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case DELETE_DEDUCTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case DELETE_DEDUCTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case FETCH_DEDUCTIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_DEDUCTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: action.payload,
        error: "",
      };
    case FETCH_DEDUCTIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        list: [],
        error: action.payload,
      };
    case FETCH_DEDUCTION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_DEDUCTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        detail: action.payload,
        error: "",
      };
    case FETCH_DEDUCTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        detail: null,
        error: action.payload,
      };
    case CLEAR_DEDUCTION:
      return {
        ...state,
        isLoading: false,
        detail: null,
        error: "",
      };
    default:
      return state;
  }
};

export default reducer;
