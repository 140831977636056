import { default as schedulesTypes } from "./types";

const {
  ADD_SCHEDULE_REQUEST,
  ADD_SCHEDULE_SUCCESS,
  ADD_SCHEDULE_FAILURE,
  EDIT_SCHEDULE_REQUEST,
  EDIT_SCHEDULE_SUCCESS,
  EDIT_SCHEDULE_FAILURE,
  DELETE_SCHEDULE_REQUEST,
  DELETE_SCHEDULE_SUCCESS,
  DELETE_SCHEDULE_FAILURE,
  FETCH_SCHEDULES_REQUEST,
  FETCH_SCHEDULES_SUCCESS,
  FETCH_SCHEDULES_FAILURE,
  FETCH_SCHEDULE_REQUEST,
  FETCH_SCHEDULE_SUCCESS,
  FETCH_SCHEDULE_FAILURE,
  CLEAR_SCHEDULE,
} = schedulesTypes;

const addScheduleRequest = () => {
  return {
    type: ADD_SCHEDULE_REQUEST,
  };
};

const addScheduleSuccess = () => {
  return {
    type: ADD_SCHEDULE_SUCCESS,
  };
};

const addScheduleError = (error) => {
  return {
    type: ADD_SCHEDULE_FAILURE,
    payload: error,
  };
};

const editScheduleRequest = () => {
  return {
    type: EDIT_SCHEDULE_REQUEST,
  };
};

const editScheduleSuccess = () => {
  return {
    type: EDIT_SCHEDULE_SUCCESS,
  };
};

const editScheduleError = (error) => {
  return {
    type: EDIT_SCHEDULE_FAILURE,
    payload: error,
  };
};

const deleteScheduleRequest = () => {
  return {
    type: DELETE_SCHEDULE_REQUEST,
  };
};

const deleteScheduleSuccess = () => {
  return {
    type: DELETE_SCHEDULE_SUCCESS,
  };
};

const deleteScheduleError = (error) => {
  return {
    type: DELETE_SCHEDULE_FAILURE,
    payload: error,
  };
};

const fetchSchedulesRequest = () => {
  return {
    type: FETCH_SCHEDULES_REQUEST,
  };
};

const fetchSchedulesSuccess = (schedules) => {
  return {
    type: FETCH_SCHEDULES_SUCCESS,
    payload: schedules,
  };
};

const fetchSchedulesError = (error) => {
  return {
    type: FETCH_SCHEDULES_FAILURE,
    payload: error,
  };
};

const fetchScheduleRequest = () => {
  return {
    type: FETCH_SCHEDULE_REQUEST,
  };
};

const fetchScheduleSuccess = (schedule) => {
  return {
    type: FETCH_SCHEDULE_SUCCESS,
    payload: schedule,
  };
};

const fetchScheduleError = (error) => {
  return {
    type: FETCH_SCHEDULE_FAILURE,
    payload: error,
  };
};

const clearSchedule = () => {
  return {
    type: CLEAR_SCHEDULE,
  };
};

export default {
  addScheduleRequest,
  addScheduleSuccess,
  addScheduleError,
  editScheduleRequest,
  editScheduleSuccess,
  editScheduleError,
  deleteScheduleRequest,
  deleteScheduleSuccess,
  deleteScheduleError,
  fetchSchedulesRequest,
  fetchSchedulesSuccess,
  fetchSchedulesError,
  fetchScheduleRequest,
  fetchScheduleSuccess,
  fetchScheduleError,
  clearSchedule,
};
