const ADD_TRAININGEMPLOYEE_REQUEST = "ADD_TRAININGEMPLOYEE_REQUEST";
const ADD_TRAININGEMPLOYEE_SUCCESS = "ADD_TRAININGEMPLOYEE_SUCCESS";
const ADD_TRAININGEMPLOYEE_FAILURE = "ADD_TRAININGEMPLOYEE_FAILURE";
const FETCH_TRAININGEMPLOYEES_REQUEST = "FETCH_TRAININGEMPLOYEES_REQUEST";
const FETCH_TRAININGEMPLOYEES_SUCCESS = "FETCH_TRAININGEMPLOYEES_SUCCESS";
const FETCH_TRAININGEMPLOYEES_FAILURE = "FETCH_TRAININGEMPLOYEES_FAILURE";

export default {
  ADD_TRAININGEMPLOYEE_REQUEST,
  ADD_TRAININGEMPLOYEE_SUCCESS,
  ADD_TRAININGEMPLOYEE_FAILURE,
  FETCH_TRAININGEMPLOYEES_REQUEST,
  FETCH_TRAININGEMPLOYEES_SUCCESS,
  FETCH_TRAININGEMPLOYEES_FAILURE,
};
