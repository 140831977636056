import instance from "./axios.base";

const getEmployees = async () => {
  const res = await instance.get("/empemployees");
  return res.data;
};

const getEmployee = async (id) => {
  const res = await instance.get(`/employees/${id}?mode=edit`);
  return res.data;
};

const addEmployee = async (data) => {
  await instance.post("/employees", data);
};

const editEmployee = async (data) => {
  await instance.patch("/employees", data);
};

const getPeople = async () => {
  const res = await instance.get("/employees");
  return res.data;
};

export { addEmployee, editEmployee, getEmployees, getEmployee, getPeople };
