import { toast } from "react-toastify";
import {
  getMigrationInfoItem,
  addMigrationInfoItem,
  editMigrationInfoItem,
  deleteMigrationInfoItem,
} from "services/migrationInfoItem";
import { default as employeeMigrationInfoItemActions } from "./actions";

const {
  fetchEmployeeMigrationInfoItemRequest,
  fetchEmployeeMigrationInfoItemSuccess,
  fetchEmployeeMigrationInfoItemError,
  addEmployeeMigrationInfoItemRequest,
  addEmployeeMigrationInfoItemSuccess,
  addEmployeeMigrationInfoItemError,
  editEmployeeMigrationInfoItemRequest,
  editEmployeeMigrationInfoItemSuccess,
  editEmployeeMigrationInfoItemError,
  deleteEmployeeMigrationInfoItemRequest,
  deleteEmployeeMigrationInfoItemSuccess,
  deleteEmployeeMigrationInfoItemError,
} = employeeMigrationInfoItemActions;

const fetchEmployeeMigrationInfoItem = (id) => {
  return async (dispatch) => {
    dispatch(fetchEmployeeMigrationInfoItemRequest());

    try {
      const employeeMigrationInfoItem = await getMigrationInfoItem(id);
      dispatch(
        fetchEmployeeMigrationInfoItemSuccess(employeeMigrationInfoItem)
      );
    } catch (err) {
      dispatch(fetchEmployeeMigrationInfoItemError(err.message));
    }
  };
};

const addEmployeeMigrationInfoItem = (data) => {
  return async (dispatch) => {
    dispatch(addEmployeeMigrationInfoItemRequest());

    try {
      await addMigrationInfoItem(data);
      dispatch(addEmployeeMigrationInfoItemSuccess());
      toast.success("Амжилттай нэмлээ");
    } catch (err) {
      dispatch(addEmployeeMigrationInfoItemError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const editEmployeeMigrationInfoItem = (data) => {
  return async (dispatch) => {
    dispatch(editEmployeeMigrationInfoItemRequest());

    try {
      await editMigrationInfoItem(data);
      dispatch(editEmployeeMigrationInfoItemSuccess());
      toast.success("Амжилттай заслаа");
    } catch (err) {
      dispatch(editEmployeeMigrationInfoItemError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const deleteEmployeeMigrationInfoItem = (id) => {
  return async (dispatch) => {
    dispatch(deleteEmployeeMigrationInfoItemRequest());

    try {
      await deleteMigrationInfoItem(id);
      dispatch(deleteEmployeeMigrationInfoItemSuccess());
      toast.success("Амжилттай устгалаа");
    } catch (err) {
      dispatch(deleteEmployeeMigrationInfoItemError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

export default {
  fetchEmployeeMigrationInfoItem,
  addEmployeeMigrationInfoItem,
  editEmployeeMigrationInfoItem,
  deleteEmployeeMigrationInfoItem,
};
