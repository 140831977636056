import { default as employeeOfficeSkillInfoTypes } from "./types";

const {
  FETCH_EMPLOYEE_OFFICE_SKILL_INFO_REQUEST,
  FETCH_EMPLOYEE_OFFICE_SKILL_INFO_SUCCESS,
  FETCH_EMPLOYEE_OFFICE_SKILL_INFO_FAILURE,
  EDIT_EMPLOYEE_OFFICE_SKILL_INFO_REQUEST,
  EDIT_EMPLOYEE_OFFICE_SKILL_INFO_SUCCESS,
  EDIT_EMPLOYEE_OFFICE_SKILL_INFO_FAILURE,
  DELETE_EMPLOYEE_OFFICE_SKILL_INFO_REQUEST,
  DELETE_EMPLOYEE_OFFICE_SKILL_INFO_SUCCESS,
  DELETE_EMPLOYEE_OFFICE_SKILL_INFO_FAILURE,
} = employeeOfficeSkillInfoTypes;

const initialState = {
  isLoading: false,
  list: [],
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EMPLOYEE_OFFICE_SKILL_INFO_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_EMPLOYEE_OFFICE_SKILL_INFO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: action.payload,
        error: "",
      };
    case FETCH_EMPLOYEE_OFFICE_SKILL_INFO_FAILURE:
      return {
        ...state,
        isLoading: false,
        list: [],
        error: action.payload,
      };
    case EDIT_EMPLOYEE_OFFICE_SKILL_INFO_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case EDIT_EMPLOYEE_OFFICE_SKILL_INFO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case EDIT_EMPLOYEE_OFFICE_SKILL_INFO_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case DELETE_EMPLOYEE_OFFICE_SKILL_INFO_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case DELETE_EMPLOYEE_OFFICE_SKILL_INFO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case DELETE_EMPLOYEE_OFFICE_SKILL_INFO_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
