import { default as employeeAwardInfoTypes } from "./types";

const {
  FETCH_EMPLOYEE_AWARD_INFO_REQUEST,
  FETCH_EMPLOYEE_AWARD_INFO_SUCCESS,
  FETCH_EMPLOYEE_AWARD_INFO_FAILURE,
  EDIT_EMPLOYEE_AWARD_INFO_REQUEST,
  EDIT_EMPLOYEE_AWARD_INFO_SUCCESS,
  EDIT_EMPLOYEE_AWARD_INFO_FAILURE,
  DELETE_EMPLOYEE_AWARD_INFO_REQUEST,
  DELETE_EMPLOYEE_AWARD_INFO_SUCCESS,
  DELETE_EMPLOYEE_AWARD_INFO_FAILURE,
} = employeeAwardInfoTypes;

const fetchEmployeeAwardInfoRequest = () => {
  return {
    type: FETCH_EMPLOYEE_AWARD_INFO_REQUEST,
  };
};

const fetchEmployeeAwardInfoSuccess = (employeeAwardInfo) => {
  return {
    type: FETCH_EMPLOYEE_AWARD_INFO_SUCCESS,
    payload: employeeAwardInfo,
  };
};

const fetchEmployeeAwardInfoError = (error) => {
  return {
    type: FETCH_EMPLOYEE_AWARD_INFO_FAILURE,
    payload: error,
  };
};

const editEmployeeAwardInfoRequest = () => {
  return {
    type: EDIT_EMPLOYEE_AWARD_INFO_REQUEST,
  };
};

const editEmployeeAwardInfoSuccess = () => {
  return {
    type: EDIT_EMPLOYEE_AWARD_INFO_SUCCESS,
  };
};

const editEmployeeAwardInfoError = (error) => {
  return {
    type: EDIT_EMPLOYEE_AWARD_INFO_FAILURE,
    payload: error,
  };
};

const deleteEmployeeAwardInfoRequest = () => {
  return {
    type: DELETE_EMPLOYEE_AWARD_INFO_REQUEST,
  };
};

const deleteEmployeeAwardInfoSuccess = () => {
  return {
    type: DELETE_EMPLOYEE_AWARD_INFO_SUCCESS,
  };
};

const deleteEmployeeAwardInfoError = (error) => {
  return {
    type: DELETE_EMPLOYEE_AWARD_INFO_FAILURE,
    payload: error,
  };
};

export default {
  fetchEmployeeAwardInfoRequest,
  fetchEmployeeAwardInfoSuccess,
  fetchEmployeeAwardInfoError,
  editEmployeeAwardInfoRequest,
  editEmployeeAwardInfoSuccess,
  editEmployeeAwardInfoError,
  deleteEmployeeAwardInfoRequest,
  deleteEmployeeAwardInfoSuccess,
  deleteEmployeeAwardInfoError,
};
