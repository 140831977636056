import { getTimeAttendanceLogs, createTimeAttendanceLog, updateTimeAttendanceLog, removeTimeAttendanceLog } from "services/timeAttendanceLog";
import { default as timeattendancelogsActions } from "./actions";

const {
  addTimeAttendanceLogRequest,
  addTimeAttendanceLogSuccess,
  addTimeAttendanceLogError,
  fetchTimeAttendanceLogsRequest,
  fetchTimeAttendanceLogsSuccess,
  fetchTimeAttendanceLogsError,
} = timeattendancelogsActions;

const fetchTimeAttendanceLogs = () => {
  return async (dispatch) => {
    dispatch(fetchTimeAttendanceLogsRequest());

    try {
      const timeattendancelogs = await getTimeAttendanceLogs();
      dispatch(fetchTimeAttendanceLogsSuccess(timeattendancelogs));
    } catch (err) {
      dispatch(fetchTimeAttendanceLogsError(err.message));
    }
  };
};

const addTimeAttendanceLog = (data) => {
  return async (dispatch) => {
    dispatch(addTimeAttendanceLogRequest());

    try {
      await createTimeAttendanceLog(data);
      dispatch(addTimeAttendanceLogSuccess());
    } catch (err) {
      dispatch(addTimeAttendanceLogError(err.message));
    }
  };
};

const editTimeAttendanceLog = (data) => {
  return async (dispatch) => {
    dispatch(addTimeAttendanceLogRequest());

    try {
      await updateTimeAttendanceLog(data);
      dispatch(addTimeAttendanceLogSuccess());
    } catch (err) {
      dispatch(addTimeAttendanceLogError(err.message));
    }
  };
};

const deleteTimeAttendanceLog = (data) => {
  return async (dispatch) => {
    dispatch(addTimeAttendanceLogRequest());

    try {
      await removeTimeAttendanceLog(data);
      dispatch(addTimeAttendanceLogSuccess());
    } catch (err) {
      dispatch(addTimeAttendanceLogError(err.message));
    }
  };
};

export default { fetchTimeAttendanceLogs, addTimeAttendanceLog, editTimeAttendanceLog, deleteTimeAttendanceLog };