import { toast } from "react-toastify";
import {
  getCompanys,
  createCompany,
  updateCompany,
  removeCompany,
  getCompanyInfo,
  updateCompanyInfo,
} from "services/company";
import { default as companysActions } from "./actions";

const {
  addCompanyRequest,
  addCompanySuccess,
  addCompanyError,
  fetchCompanysRequest,
  fetchCompanysSuccess,
  fetchCompanysError,
} = companysActions;

const fetchCompanyInfo = () => {
  return async (dispatch) => {
    dispatch(fetchCompanysRequest());

    try {
      const companys = await getCompanyInfo();
      dispatch(fetchCompanysSuccess(companys));
    } catch (err) {
      dispatch(fetchCompanysError(err.message));
    }
  };
};

const editCompanyInfo = (data) => {
  return async (dispatch) => {
    dispatch(addCompanyRequest());

    try {
      await updateCompanyInfo(data);
      dispatch(addCompanySuccess());
      toast.success("Амжилттай заслаа");
    } catch (err) {
      dispatch(addCompanyError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

export default { fetchCompanyInfo, editCompanyInfo };
