import instance from "./axios.base";
import { dateFormat } from "utils/formatter";

const getEvents = async () => {
  const res = await instance.get("/events");
  res.data.map((row) => {
    row.eventDate = dateFormat(row.eventDate)
  })
  
  return res.data;
};

const createEvent = async (data) => {
  var formData = new FormData();
  formData.append('eventDate', data.eventDate);
  formData.append('name', data.name);
  formData.append('descr', data.descr);
  formData.append('image', data.coverImage);
  formData.append('location', data.location);  
  const res = await instance.post("/events", formData, { headers: {'Content-Type': 'multipart/form-data' }});

  return res.data;
};

const updateEvent = async (data) => {
  var formData = new FormData();
  formData.append('id', data.id);
  formData.append('eventDate', data.eventDate);
  formData.append('name', data.name);
  formData.append('descr', data.descr);
  formData.append('image', data.coverImage);
  formData.append('location', data.location); 
  const res = await instance.put("/events", formData, { headers: {'Content-Type': 'multipart/form-data' }});

  return res.data;
};

const removeEvent = async (data) => {
  const res = await instance.delete(`/events/${data.id}`);

  return res.data;
};

export { getEvents, createEvent, updateEvent, removeEvent };