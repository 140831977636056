import instance from "./axios.base";
import { dateFormat } from "utils/formatter";

const getTasks = async () => {
  const res = await instance.get("/hrtasks");
  res.data.map((row) => {
    row.finishDate = dateFormat(row.finishDate)
  })
  
  return res.data;
};

const createTask = async (data) => {  
  const res = await instance.post("/hrtasks", data);

  return res.data;
};

const updateTask = async (data) => {
  const res = await instance.put("/hrtasks", data);

  return res.data;
};

const removeTask = async (data) => {
  const res = await instance.delete(`/hrtasks/${data.id}`);

  return res.data;
};

export { getTasks, createTask, updateTask, removeTask };