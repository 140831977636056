import { default as officeProgramsTypes } from "./types";

const {
  FETCH_OFFICE_PROGRAMS_REQUEST,
  FETCH_OFFICE_PROGRAMS_SUCCESS,
  FETCH_OFFICE_PROGRAMS_FAILURE,
} = officeProgramsTypes;

const fetchOfficeProgramsRequest = () => {
  return {
    type: FETCH_OFFICE_PROGRAMS_REQUEST,
  };
};

const fetchOfficeProgramsSuccess = (officePrograms) => {
  return {
    type: FETCH_OFFICE_PROGRAMS_SUCCESS,
    payload: officePrograms,
  };
};

const fetchOfficeProgramsError = (error) => {
  return {
    type: FETCH_OFFICE_PROGRAMS_FAILURE,
    payload: error,
  };
};

export default {
  fetchOfficeProgramsRequest,
  fetchOfficeProgramsSuccess,
  fetchOfficeProgramsError,
};
