import { toast } from "react-toastify";
import {
  getEvents,
  createEvent,
  updateEvent,
  removeEvent,
} from "services/event";
import { default as eventsActions } from "./actions";

const {
  addEventRequest,
  addEventSuccess,
  addEventError,
  fetchEventsRequest,
  fetchEventsSuccess,
  fetchEventsError,
} = eventsActions;

const fetchEvents = () => {
  return async (dispatch) => {
    dispatch(fetchEventsRequest());

    try {
      const events = await getEvents();
      dispatch(fetchEventsSuccess(events));
    } catch (err) {
      dispatch(fetchEventsError(err.message));
    }
  };
};

const addEvent = (data) => {
  return async (dispatch) => {
    dispatch(addEventRequest());

    try {
      await createEvent(data);
      dispatch(addEventSuccess());
      toast.success("Амжилттай устгалаа");
    } catch (err) {
      dispatch(addEventError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const editEvent = (data) => {
  return async (dispatch) => {
    dispatch(addEventRequest());

    try {
      await updateEvent(data);
      dispatch(addEventSuccess());
      toast.success("Амжилттай устгалаа");
    } catch (err) {
      dispatch(addEventError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const deleteEvent = (data) => {
  return async (dispatch) => {
    dispatch(addEventRequest());

    try {
      await removeEvent(data);
      dispatch(addEventSuccess());
      toast.success("Амжилттай устгалаа");
    } catch (err) {
      dispatch(addEventError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

export default { fetchEvents, addEvent, editEvent, deleteEvent };
