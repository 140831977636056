import { getNDSH8Report } from "services/report";
import { default as NDSH8ReportActions } from "./actions";

const {
  fetchNDSH8ReportRequest,
  fetchNDSH8ReportSuccess,
  fetchNDSH8ReportError,
} = NDSH8ReportActions;

const fetchNDSH8Report = (year, month) => {
  return async (dispatch) => {
    dispatch(fetchNDSH8ReportRequest());

    try {
      const report = await getNDSH8Report(year, month);
      dispatch(fetchNDSH8ReportSuccess(report));
    } catch (err) {
      dispatch(fetchNDSH8ReportError(err.message));
    }
  };
};

export default {
  fetchNDSH8Report,
};
