import instance from "./axios.base";

const add = async (data) => {
  const res = await instance.post("/prdeduction", data);
  return res.data;
};

const edit = async (data) => {
  const res = await instance.put("/prdeduction", data);
  return res.data;
};

const remove = async (id) => {
  const res = await instance.delete(`/prdeduction/${id}`);
  return res.data;
};

const getDeductions = async () => {
  const res = await instance.get("/prdeduction");
  return res.data;
};

const getDeduction = async (id) => {
  const res = await instance.get(`/prdeduction/${id}`);
  return res.data;
};

export { add, edit, remove, getDeductions, getDeduction };
