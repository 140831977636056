import { default as systemconfigsTypes } from "./types";

const {
  ADD_SYSTEMCONFIGTA_REQUEST,
  ADD_SYSTEMCONFIGTA_SUCCESS,
  ADD_SYSTEMCONFIGTA_FAILURE,
  ADD_SYSTEMCONFIGPR_REQUEST,
  ADD_SYSTEMCONFIGPR_SUCCESS,
  ADD_SYSTEMCONFIGPR_FAILURE,
  ADD_SYSTEMCONFIGTAXCREDIT_REQUEST,
  ADD_SYSTEMCONFIGTAXCREDIT_SUCCESS,
  ADD_SYSTEMCONFIGTAXCREDIT_FAILURE,
  FETCH_SYSTEMCONFIGTA_REQUEST,
  FETCH_SYSTEMCONFIGTA_SUCCESS,
  FETCH_SYSTEMCONFIGTA_FAILURE,
  FETCH_SYSTEMCONFIGPR_REQUEST,
  FETCH_SYSTEMCONFIGPR_SUCCESS,
  FETCH_SYSTEMCONFIGPR_FAILURE,
  FETCH_SYSTEMCONFIGTAXCREDIT_REQUEST,
  FETCH_SYSTEMCONFIGTAXCREDIT_SUCCESS,
  FETCH_SYSTEMCONFIGTAXCREDIT_FAILURE,
  ADD_MANAGER_REQUEST,
  ADD_MANAGER_SUCCESS,
  ADD_MANAGER_FAILURE,
  DELETE_MANAGER_REQUEST,
  DELETE_MANAGER_SUCCESS,
  DELETE_MANAGER_FAILURE,
  FETCH_MANAGERS_REQUEST,
  FETCH_MANAGERS_SUCCESS,
  FETCH_MANAGERS_FAILURE,
} = systemconfigsTypes;

const addSystemConfigTaRequest = () => {
  return {
    type: ADD_SYSTEMCONFIGTA_REQUEST,
  };
};

const addSystemConfigTaSuccess = () => {
  return {
    type: ADD_SYSTEMCONFIGTA_SUCCESS,
  };
};

const addSystemConfigTaError = (error) => {
  return {
    type: ADD_SYSTEMCONFIGTA_FAILURE,
    payload: error,
  };
};

const addSystemConfigPrRequest = () => {
  return {
    type: ADD_SYSTEMCONFIGPR_REQUEST,
  };
};

const addSystemConfigPrSuccess = () => {
  return {
    type: ADD_SYSTEMCONFIGPR_SUCCESS,
  };
};

const addSystemConfigPrError = (error) => {
  return {
    type: ADD_SYSTEMCONFIGPR_FAILURE,
    payload: error,
  };
};

const addSystemConfigTaxCreditRequest = () => {
  return {
    type: ADD_SYSTEMCONFIGTAXCREDIT_REQUEST,
  };
};

const addSystemConfigTaxCreditSuccess = () => {
  return {
    type: ADD_SYSTEMCONFIGTAXCREDIT_SUCCESS,
  };
};

const addSystemConfigTaxCreditError = (error) => {
  return {
    type: ADD_SYSTEMCONFIGTAXCREDIT_FAILURE,
    payload: error,
  };
};

const fetchSystemConfigTaRequest = () => {
  return {
    type: FETCH_SYSTEMCONFIGTA_REQUEST,
  };
};

const fetchSystemConfigTaSuccess = (systemconfigs) => {
  return {
    type: FETCH_SYSTEMCONFIGTA_SUCCESS,
    payload: systemconfigs,
  };
};

const fetchSystemConfigTaError = (error) => {
  return {
    type: FETCH_SYSTEMCONFIGTA_FAILURE,
    payload: error,
  };
};

const fetchSystemConfigPrRequest = () => {
  return {
    type: FETCH_SYSTEMCONFIGPR_REQUEST,
  };
};

const fetchSystemConfigPrSuccess = (systemconfigs) => {
  return {
    type: FETCH_SYSTEMCONFIGPR_SUCCESS,
    payload: systemconfigs,
  };
};

const fetchSystemConfigPrError = (error) => {
  return {
    type: FETCH_SYSTEMCONFIGPR_FAILURE,
    payload: error,
  };
};

const fetchSystemConfigTaxCreditRequest = () => {
  return {
    type: FETCH_SYSTEMCONFIGTAXCREDIT_REQUEST,
  };
};

const fetchSystemConfigTaxCreditSuccess = (systemconfigs) => {
  return {
    type: FETCH_SYSTEMCONFIGTAXCREDIT_SUCCESS,
    payload: systemconfigs,
  };
};

const fetchSystemConfigTaxCreditError = (error) => {
  return {
    type: FETCH_SYSTEMCONFIGTAXCREDIT_FAILURE,
    payload: error,
  };
};

const addManagerRequest = () => {
  return {
    type: ADD_MANAGER_REQUEST,
  };
};

const addManagerSuccess = () => {
  return {
    type: ADD_MANAGER_SUCCESS,
  };
};

const addManagerError = (error) => {
  return {
    type: ADD_MANAGER_FAILURE,
    payload: error,
  };
};

const deleteManagerRequest = () => {
  return {
    type: DELETE_MANAGER_REQUEST,
  };
};

const deleteManagerSuccess = () => {
  return {
    type: DELETE_MANAGER_SUCCESS,
  };
};

const deleteManagerError = (error) => {
  return {
    type: DELETE_MANAGER_FAILURE,
    payload: error,
  };
};

const fetchManagersRequest = () => {
  return {
    type: FETCH_MANAGERS_REQUEST,
  };
};

const fetchManagersSuccess = (managers) => {
  return {
    type: FETCH_MANAGERS_SUCCESS,
    payload: managers,
  };
};

const fetchManagersError = (error) => {
  return {
    type: FETCH_MANAGERS_FAILURE,
    payload: error,
  };
};

export default {
  addSystemConfigTaRequest,
  addSystemConfigTaSuccess,
  addSystemConfigPrError,
  addSystemConfigPrRequest,
  addSystemConfigPrSuccess,
  addSystemConfigTaError,
  addSystemConfigTaxCreditRequest,
  addSystemConfigTaxCreditSuccess,
  addSystemConfigTaxCreditError,
  fetchSystemConfigTaRequest,
  fetchSystemConfigTaSuccess,
  fetchSystemConfigTaError,
  fetchSystemConfigPrRequest,
  fetchSystemConfigPrSuccess,
  fetchSystemConfigPrError,
  fetchSystemConfigTaxCreditRequest,
  fetchSystemConfigTaxCreditSuccess,
  fetchSystemConfigTaxCreditError,
  addManagerRequest,
  addManagerSuccess,
  addManagerError,
  deleteManagerRequest,
  deleteManagerSuccess,
  deleteManagerError,
  fetchManagersRequest,
  fetchManagersSuccess,
  fetchManagersError,
};
