import { getEducationDegrees } from "services/educationDegree";
import { default as employeeEducationDegreesActions } from "./actions";

const {
  fetchEmployeeEducationDegreesRequest,
  fetchEmployeeEducationDegreesSuccess,
  fetchEmployeeEducationDegreesError,
} = employeeEducationDegreesActions;

const fetchEmployeeEducationDegrees = () => {
  return async (dispatch) => {
    dispatch(fetchEmployeeEducationDegreesRequest());

    try {
      const employeeEducationDegrees = await getEducationDegrees();
      dispatch(fetchEmployeeEducationDegreesSuccess(employeeEducationDegrees));
    } catch (err) {
      dispatch(fetchEmployeeEducationDegreesError(err.message));
    }
  };
};

export default {
  fetchEmployeeEducationDegrees,
};
