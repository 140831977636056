import { getNDSH7BReport } from "services/report";
import { default as NDSH7BReportActions } from "./actions";

const {
  fetchNDSH7BReportRequest,
  fetchNDSH7BReportSuccess,
  fetchNDSH7BReportError,
} = NDSH7BReportActions;

const fetchNDSH7BReport = (year, month) => {
  return async (dispatch) => {
    dispatch(fetchNDSH7BReportRequest());

    try {
      const report = await getNDSH7BReport(year, month);
      dispatch(fetchNDSH7BReportSuccess(report));
    } catch (err) {
      dispatch(fetchNDSH7BReportError(err.message));
    }
  };
};

export default {
  fetchNDSH7BReport,
};
