import instance from "./axios.base";

const getTeamMembers = async (data) => {
  const res = await instance.get(`/teammembers/${data}`);
  
  return res.data;
};

const createTeamMember = async (data) => {  
  const res = await instance.post("/teammembers", data);

  return res.data;
};

const updateTeamMember = async (data) => {
  const res = await instance.put("/teammembers", data);

  return res.data;
};

const removeTeamMember = async (data) => {
  const res = await instance.delete(`/teammembers/${data.id}`);

  return res.data;
};

export { getTeamMembers, createTeamMember, updateTeamMember, removeTeamMember };