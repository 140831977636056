const ADD_POSITION_REQUEST = "ADD_POSITION_REQUEST";
const ADD_POSITION_SUCCESS = "ADD_POSITION_SUCCESS";
const ADD_POSITION_FAILURE = "ADD_POSITION_FAILURE";
const FETCH_POSITIONS_REQUEST = "FETCH_POSITIONS_REQUEST";
const FETCH_POSITIONS_SUCCESS = "FETCH_POSITIONS_SUCCESS";
const FETCH_POSITIONS_FAILURE = "FETCH_POSITIONS_FAILURE";

export default {
  ADD_POSITION_REQUEST,
  ADD_POSITION_SUCCESS,
  ADD_POSITION_FAILURE,
  FETCH_POSITIONS_REQUEST,
  FETCH_POSITIONS_SUCCESS,
  FETCH_POSITIONS_FAILURE,
};
