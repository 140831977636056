const ADD_ANNOUNCEMENT_REQUEST = "ADD_ANNOUNCEMENT_REQUEST";
const ADD_ANNOUNCEMENT_SUCCESS = "ADD_ANNOUNCEMENT_SUCCESS";
const ADD_ANNOUNCEMENT_FAILURE = "ADD_ANNOUNCEMENT_FAILURE";
const FETCH_ANNOUNCEMENTS_REQUEST = "FETCH_ANNOUNCEMENTS_REQUEST";
const FETCH_ANNOUNCEMENTS_SUCCESS = "FETCH_ANNOUNCEMENTS_SUCCESS";
const FETCH_ANNOUNCEMENTS_FAILURE = "FETCH_ANNOUNCEMENTS_FAILURE";

export default {
  ADD_ANNOUNCEMENT_REQUEST,
  ADD_ANNOUNCEMENT_SUCCESS,
  ADD_ANNOUNCEMENT_FAILURE,
  FETCH_ANNOUNCEMENTS_REQUEST,
  FETCH_ANNOUNCEMENTS_SUCCESS,
  FETCH_ANNOUNCEMENTS_FAILURE,
};
