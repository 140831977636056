import { default as types } from "./types";

const {
  FETCH_HALF_MONTHLY_ATTENDANCE_ARCHIVES_REQUEST,
  FETCH_HALF_MONTHLY_ATTENDANCE_ARCHIVES_SUCCESS,
  FETCH_HALF_MONTHLY_ATTENDANCE_ARCHIVES_FAILURE,
  CLEAR_HALF_MONTHLY_ATTENDANCE_ARCHIVES_REQUEST,
  CLEAR_HALF_MONTHLY_ATTENDANCE_ARCHIVES_SUCCESS,
  CLEAR_HALF_MONTHLY_ATTENDANCE_ARCHIVES_FAILURE,
} = types;

const initialState = {
  isLoading: false,
  list: [],
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_HALF_MONTHLY_ATTENDANCE_ARCHIVES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_HALF_MONTHLY_ATTENDANCE_ARCHIVES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: action.payload,
        error: "",
      };
    case FETCH_HALF_MONTHLY_ATTENDANCE_ARCHIVES_FAILURE:
      return {
        ...state,
        isLoading: false,
        list: [],
        error: action.payload,
      };
    case CLEAR_HALF_MONTHLY_ATTENDANCE_ARCHIVES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CLEAR_HALF_MONTHLY_ATTENDANCE_ARCHIVES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case CLEAR_HALF_MONTHLY_ATTENDANCE_ARCHIVES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
