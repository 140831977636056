const ADD_SPECIALDAY_REQUEST = "ADD_SPECIALDAY_REQUEST";
const ADD_SPECIALDAY_SUCCESS = "ADD_SPECIALDAY_SUCCESS";
const ADD_SPECIALDAY_FAILURE = "ADD_SPECIALDAY_FAILURE";
const FETCH_SPECIALDAYS_REQUEST = "FETCH_SPECIALDAYS_REQUEST";
const FETCH_SPECIALDAYS_SUCCESS = "FETCH_SPECIALDAYS_SUCCESS";
const FETCH_SPECIALDAYS_FAILURE = "FETCH_SPECIALDAYS_FAILURE";

export default {
  ADD_SPECIALDAY_REQUEST,
  ADD_SPECIALDAY_SUCCESS,
  ADD_SPECIALDAY_FAILURE,
  FETCH_SPECIALDAYS_REQUEST,
  FETCH_SPECIALDAYS_SUCCESS,
  FETCH_SPECIALDAYS_FAILURE,
};
