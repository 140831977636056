import { default as employeeEducationDegreesTypes } from "./types";

const {
  FETCH_EMPLOYEE_EDUCATION_DEGREES_REQUEST,
  FETCH_EMPLOYEE_EDUCATION_DEGREES_SUCCESS,
  FETCH_EMPLOYEE_EDUCATION_DEGREES_FAILURE,
} = employeeEducationDegreesTypes;

const fetchEmployeeEducationDegreesRequest = () => {
  return {
    type: FETCH_EMPLOYEE_EDUCATION_DEGREES_REQUEST,
  };
};

const fetchEmployeeEducationDegreesSuccess = (employeeEducationDegrees) => {
  return {
    type: FETCH_EMPLOYEE_EDUCATION_DEGREES_SUCCESS,
    payload: employeeEducationDegrees,
  };
};

const fetchEmployeeEducationDegreesError = (error) => {
  return {
    type: FETCH_EMPLOYEE_EDUCATION_DEGREES_FAILURE,
    payload: error,
  };
};

export default {
  fetchEmployeeEducationDegreesRequest,
  fetchEmployeeEducationDegreesSuccess,
  fetchEmployeeEducationDegreesError,
};
