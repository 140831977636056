const ADD_TEAM_REQUEST = "ADD_TEAM_REQUEST";
const ADD_TEAM_SUCCESS = "ADD_TEAM_SUCCESS";
const ADD_TEAM_FAILURE = "ADD_TEAM_FAILURE";
const FETCH_TEAMS_REQUEST = "FETCH_TEAMS_REQUEST";
const FETCH_TEAMS_SUCCESS = "FETCH_TEAMS_SUCCESS";
const FETCH_TEAMS_FAILURE = "FETCH_TEAMS_FAILURE";

export default {
  ADD_TEAM_REQUEST,
  ADD_TEAM_SUCCESS,
  ADD_TEAM_FAILURE,
  FETCH_TEAMS_REQUEST,
  FETCH_TEAMS_SUCCESS,
  FETCH_TEAMS_FAILURE,
};
