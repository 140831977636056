const ADD_SECTOR_REQUEST = "ADD_SECTOR_REQUEST";
const ADD_SECTOR_SUCCESS = "ADD_SECTOR_SUCCESS";
const ADD_SECTOR_FAILURE = "ADD_SECTOR_FAILURE";
const FETCH_SECTORS_REQUEST = "FETCH_SECTORS_REQUEST";
const FETCH_SECTORS_SUCCESS = "FETCH_SECTORS_SUCCESS";
const FETCH_SECTORS_FAILURE = "FETCH_SECTORS_FAILURE";

export default {
  ADD_SECTOR_REQUEST,
  ADD_SECTOR_SUCCESS,
  ADD_SECTOR_FAILURE,
  FETCH_SECTORS_REQUEST,
  FETCH_SECTORS_SUCCESS,
  FETCH_SECTORS_FAILURE,
};
