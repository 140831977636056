import { default as feedbacksTypes } from "./types";

const {
  ADD_FEEDBACK_REQUEST,
  ADD_FEEDBACK_SUCCESS,
  ADD_FEEDBACK_FAILURE,
  FETCH_FEEDBACKS_REQUEST,
  FETCH_FEEDBACKS_SUCCESS,
  FETCH_FEEDBACKS_FAILURE,
} = feedbacksTypes;

const addFeedbackRequest = () => {
  return {
    type: ADD_FEEDBACK_REQUEST,
  };
};

const addFeedbackSuccess = () => {
  return {
    type: ADD_FEEDBACK_SUCCESS,
  };
};

const addFeedbackError = (error) => {
  return {
    type: ADD_FEEDBACK_FAILURE,
    payload: error,
  };
};

const fetchFeedbacksRequest = () => {
  return {
    type: FETCH_FEEDBACKS_REQUEST,
  };
};

const fetchFeedbacksSuccess = (feedbacks) => {
  return {
    type: FETCH_FEEDBACKS_SUCCESS,
    payload: feedbacks,
  };
};

const fetchFeedbacksError = (error) => {
  return {
    type: FETCH_FEEDBACKS_FAILURE,
    payload: error,
  };
};

export default {
  addFeedbackRequest,
  addFeedbackSuccess,
  addFeedbackError,
  fetchFeedbacksRequest,
  fetchFeedbacksSuccess,
  fetchFeedbacksError,
};
