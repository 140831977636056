import { default as lunarSignsTypes } from "./types";

const {
  FETCH_LUNAR_SIGNS_REQUEST,
  FETCH_LUNAR_SIGNS_SUCCESS,
  FETCH_LUNAR_SIGNS_FAILURE,
} = lunarSignsTypes;

const fetchLunarSignsRequest = () => {
  return {
    type: FETCH_LUNAR_SIGNS_REQUEST,
  };
};

const fetchLunarSignsSuccess = (lunarSigns) => {
  return {
    type: FETCH_LUNAR_SIGNS_SUCCESS,
    payload: lunarSigns,
  };
};

const fetchLunarSignsError = (error) => {
  return {
    type: FETCH_LUNAR_SIGNS_FAILURE,
    payload: error,
  };
};

export default {
  fetchLunarSignsRequest,
  fetchLunarSignsSuccess,
  fetchLunarSignsError,
};
