import { default as timeattendancelogsTypes } from "./types";

const {
  ADD_TIMEATTENDANCELOG_REQUEST,
  ADD_TIMEATTENDANCELOG_SUCCESS,
  ADD_TIMEATTENDANCELOG_FAILURE,
  FETCH_TIMEATTENDANCELOGS_REQUEST,
  FETCH_TIMEATTENDANCELOGS_SUCCESS,
  FETCH_TIMEATTENDANCELOGS_FAILURE,
} = timeattendancelogsTypes;

const addTimeAttendanceLogRequest = () => {
  return {
    type: ADD_TIMEATTENDANCELOG_REQUEST,
  };
};

const addTimeAttendanceLogSuccess = () => {
  return {
    type: ADD_TIMEATTENDANCELOG_SUCCESS,
  };
};

const addTimeAttendanceLogError = (error) => {
  return {
    type: ADD_TIMEATTENDANCELOG_FAILURE,
    payload: error,
  };
};

const fetchTimeAttendanceLogsRequest = () => {
  return {
    type: FETCH_TIMEATTENDANCELOGS_REQUEST,
  };
};

const fetchTimeAttendanceLogsSuccess = (timeattendancelogs) => {
  return {
    type: FETCH_TIMEATTENDANCELOGS_SUCCESS,
    payload: timeattendancelogs,
  };
};

const fetchTimeAttendanceLogsError = (error) => {
  return {
    type: FETCH_TIMEATTENDANCELOGS_FAILURE,
    payload: error,
  };
};

export default {
  addTimeAttendanceLogRequest,
  addTimeAttendanceLogSuccess,
  addTimeAttendanceLogError,
  fetchTimeAttendanceLogsRequest,
  fetchTimeAttendanceLogsSuccess,
  fetchTimeAttendanceLogsError,
};
