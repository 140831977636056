const ADD_EMP_RAISE_REQUEST = "ADD_EMP_RAISE_REQUEST";
const ADD_EMP_RAISE_SUCCESS = "ADD_EMP_RAISE_SUCCESS";
const ADD_EMP_RAISE_FAILURE = "ADD_EMP_RAISE_FAILURE";
const EDIT_EMP_RAISE_REQUEST = "EDIT_EMP_RAISE_REQUEST";
const EDIT_EMP_RAISE_SUCCESS = "EDIT_EMP_RAISE_SUCCESS";
const EDIT_EMP_RAISE_FAILURE = "EDIT_EMP_RAISE_FAILURE";
const DELETE_EMP_RAISE_REQUEST = "DELETE_EMP_RAISE_REQUEST";
const DELETE_EMP_RAISE_SUCCESS = "DELETE_EMP_RAISE_SUCCESS";
const DELETE_EMP_RAISE_FAILURE = "DELETE_EMP_RAISE_FAILURE";
const FETCH_EMP_RAISES_REQUEST = "FETCH_EMP_RAISES_REQUEST";
const FETCH_EMP_RAISES_SUCCESS = "FETCH_EMP_RAISES_SUCCESS";
const FETCH_EMP_RAISES_FAILURE = "FETCH_EMP_RAISES_FAILURE";
const CLEAR_EMP_RAISE = "CLEAR_EMP_RAISE";

export default {
  ADD_EMP_RAISE_REQUEST,
  ADD_EMP_RAISE_SUCCESS,
  ADD_EMP_RAISE_FAILURE,
  EDIT_EMP_RAISE_REQUEST,
  EDIT_EMP_RAISE_SUCCESS,
  EDIT_EMP_RAISE_FAILURE,
  DELETE_EMP_RAISE_REQUEST,
  DELETE_EMP_RAISE_SUCCESS,
  DELETE_EMP_RAISE_FAILURE,
  FETCH_EMP_RAISES_REQUEST,
  FETCH_EMP_RAISES_SUCCESS,
  FETCH_EMP_RAISES_FAILURE,
  CLEAR_EMP_RAISE,
};
