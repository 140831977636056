import { toast } from "react-toastify";
import {
  getHealthInfo,
  addHealthInfo,
  editHealthInfo,
  deleteHealthInfo,
} from "services/healthInfo";
import { default as employeeHealthInfoActions } from "./actions";

const {
  fetchEmployeeHealthInfoRequest,
  fetchEmployeeHealthInfoSuccess,
  fetchEmployeeHealthInfoError,
  addEmployeeHealthInfoRequest,
  addEmployeeHealthInfoSuccess,
  addEmployeeHealthInfoError,
  editEmployeeHealthInfoRequest,
  editEmployeeHealthInfoSuccess,
  editEmployeeHealthInfoError,
  deleteEmployeeHealthInfoRequest,
  deleteEmployeeHealthInfoSuccess,
  deleteEmployeeHealthInfoError,
} = employeeHealthInfoActions;

const fetchEmployeeHealthInfo = (empId) => {
  return async (dispatch) => {
    dispatch(fetchEmployeeHealthInfoRequest());

    try {
      const employeeHealthInfo = await getHealthInfo(empId);
      dispatch(fetchEmployeeHealthInfoSuccess(employeeHealthInfo));
    } catch (err) {
      dispatch(fetchEmployeeHealthInfoError(err.message));
    }
  };
};

const addEmployeeHealthInfo = (data) => {
  return async (dispatch) => {
    dispatch(addEmployeeHealthInfoRequest());

    try {
      await addHealthInfo(data);
      dispatch(addEmployeeHealthInfoSuccess());
      toast.success("Амжилттай нэмлээ");
    } catch (err) {
      dispatch(addEmployeeHealthInfoError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const editEmployeeHealthInfo = (data) => {
  return async (dispatch) => {
    dispatch(editEmployeeHealthInfoRequest());

    try {
      await editHealthInfo(data);
      dispatch(editEmployeeHealthInfoSuccess());
      toast.success("Амжилттай заслаа");
    } catch (err) {
      dispatch(editEmployeeHealthInfoError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

const deleteEmployeeHealthInfo = (id) => {
  return async (dispatch) => {
    dispatch(deleteEmployeeHealthInfoRequest());

    try {
      await deleteHealthInfo(id);
      dispatch(deleteEmployeeHealthInfoSuccess());
      toast.success("Амжилттай устгалаа");
    } catch (err) {
      dispatch(deleteEmployeeHealthInfoError(err.message));
      if (err.response.status === 400) {
        toast.warn(err.response.data.value);
      }
    }
  };
};

export default {
  fetchEmployeeHealthInfo,
  addEmployeeHealthInfo,
  editEmployeeHealthInfo,
  deleteEmployeeHealthInfo,
};
