const ADD_EMPLOYEE_REQUEST = "ADD_EMPLOYEE_REQUEST";
const ADD_EMPLOYEE_SUCCESS = "ADD_EMPLOYEE_SUCCESS";
const ADD_EMPLOYEE_FAILURE = "ADD_EMPLOYEE_FAILURE";
const EDIT_EMPLOYEE_REQUEST = "EDIT_EMPLOYEE_REQUEST";
const EDIT_EMPLOYEE_SUCCESS = "EDIT_EMPLOYEE_SUCCESS";
const EDIT_EMPLOYEE_FAILURE = "EDIT_EMPLOYEE_FAILURE";
const FETCH_EMPLOYEES_REQUEST = "FETCH_EMPLOYEES_REQUEST";
const FETCH_EMPLOYEES_SUCCESS = "FETCH_EMPLOYEES_SUCCESS";
const FETCH_EMPLOYEES_FAILURE = "FETCH_EMPLOYEES_FAILURE";
const EDIT_EMPLOYEE_EXPERIENCE_REQUEST = "EDIT_EMPLOYEE_EXPERIENCE_REQUEST";
const EDIT_EMPLOYEE_EXPERIENCE_SUCCESS = "EDIT_EMPLOYEE_EXPERIENCE_SUCCESS";
const EDIT_EMPLOYEE_EXPERIENCE_FAILURE = "EDIT_EMPLOYEE_EXPERIENCE_FAILURE";
const DELETE_EMPLOYEE_EXPERIENCE_REQUEST = "DELETE_EMPLOYEE_EXPERIENCE_REQUEST";
const DELETE_EMPLOYEE_EXPERIENCE_SUCCESS = "DELETE_EMPLOYEE_EXPERIENCE_SUCCESS";
const DELETE_EMPLOYEE_EXPERIENCE_FAILURE = "DELETE_EMPLOYEE_EXPERIENCE_FAILURE";
const EDIT_EMPLOYEE_OFFICE_SKILL_REQUEST = "EDIT_EMPLOYEE_OFFICE_SKILL_REQUEST";
const EDIT_EMPLOYEE_OFFICE_SKILL_SUCCESS = "EDIT_EMPLOYEE_OFFICE_SKILL_SUCCESS";
const EDIT_EMPLOYEE_OFFICE_SKILL_FAILURE = "EDIT_EMPLOYEE_OFFICE_SKILL_FAILURE";
const DELETE_EMPLOYEE_OFFICE_SKILL_REQUEST =
  "DELETE_EMPLOYEE_OFFICE_SKILL_REQUEST";
const DELETE_EMPLOYEE_OFFICE_SKILL_SUCCESS =
  "DELETE_EMPLOYEE_OFFICE_SKILL_SUCCESS";
const DELETE_EMPLOYEE_OFFICE_SKILL_FAILURE =
  "DELETE_EMPLOYEE_OFFICE_SKILL_FAILURE";

export default {
  ADD_EMPLOYEE_REQUEST,
  ADD_EMPLOYEE_SUCCESS,
  ADD_EMPLOYEE_FAILURE,
  EDIT_EMPLOYEE_REQUEST,
  EDIT_EMPLOYEE_SUCCESS,
  EDIT_EMPLOYEE_FAILURE,
  FETCH_EMPLOYEES_REQUEST,
  FETCH_EMPLOYEES_SUCCESS,
  FETCH_EMPLOYEES_FAILURE,
  EDIT_EMPLOYEE_EXPERIENCE_REQUEST,
  EDIT_EMPLOYEE_EXPERIENCE_SUCCESS,
  EDIT_EMPLOYEE_EXPERIENCE_FAILURE,
  DELETE_EMPLOYEE_EXPERIENCE_REQUEST,
  DELETE_EMPLOYEE_EXPERIENCE_SUCCESS,
  DELETE_EMPLOYEE_EXPERIENCE_FAILURE,
};
