const FETCH_EMPLOYEE_REQUEST = "FETCH_EMPLOYEE_REQUEST";
const FETCH_EMPLOYEE_SUCCESS = "FETCH_EMPLOYEE_SUCCESS";
const FETCH_EMPLOYEE_FAILURE = "FETCH_EMPLOYEE_FAILURE";
const CLEAR_EMPLOYEE = "CLEAR_EMPLOYEE";

export default {
  FETCH_EMPLOYEE_REQUEST,
  FETCH_EMPLOYEE_SUCCESS,
  FETCH_EMPLOYEE_FAILURE,
  CLEAR_EMPLOYEE,
};
