import { default as empSchedulesTypes } from "./types";

const {
  ADD_EMP_SCHEDULE_REQUEST,
  ADD_EMP_SCHEDULE_SUCCESS,
  ADD_EMP_SCHEDULE_FAILURE,
  DELETE_EMP_SCHEDULE_REQUEST,
  DELETE_EMP_SCHEDULE_SUCCESS,
  DELETE_EMP_SCHEDULE_FAILURE,
  FETCH_EMP_SCHEDULES_REQUEST,
  FETCH_EMP_SCHEDULES_SUCCESS,
  FETCH_EMP_SCHEDULES_FAILURE,
} = empSchedulesTypes;

const addEmpScheduleRequest = () => {
  return {
    type: ADD_EMP_SCHEDULE_REQUEST,
  };
};

const addEmpScheduleSuccess = () => {
  return {
    type: ADD_EMP_SCHEDULE_SUCCESS,
  };
};

const addEmpScheduleError = (error) => {
  return {
    type: ADD_EMP_SCHEDULE_FAILURE,
    payload: error,
  };
};

const deleteEmpScheduleRequest = () => {
  return {
    type: DELETE_EMP_SCHEDULE_REQUEST,
  };
};

const deleteEmpScheduleSuccess = () => {
  return {
    type: DELETE_EMP_SCHEDULE_SUCCESS,
  };
};

const deleteEmpScheduleError = (error) => {
  return {
    type: DELETE_EMP_SCHEDULE_FAILURE,
    payload: error,
  };
};

const fetchEmpSchedulesRequest = () => {
  return {
    type: FETCH_EMP_SCHEDULES_REQUEST,
  };
};

const fetchEmpSchedulesSuccess = (schedules) => {
  return {
    type: FETCH_EMP_SCHEDULES_SUCCESS,
    payload: schedules,
  };
};

const fetchEmpSchedulesError = (error) => {
  return {
    type: FETCH_EMP_SCHEDULES_FAILURE,
    payload: error,
  };
};

export default {
  addEmpScheduleRequest,
  addEmpScheduleSuccess,
  addEmpScheduleError,
  deleteEmpScheduleRequest,
  deleteEmpScheduleSuccess,
  deleteEmpScheduleError,
  fetchEmpSchedulesRequest,
  fetchEmpSchedulesSuccess,
  fetchEmpSchedulesError,
};
