import instance from "./axios.base";

const getPunishmentInfo = async (id) => {
  const res = await instance.get(`/emppunishments/${id}`);
  return res.data;
};

const editPunishmentInfo = async (data) => {
  await instance.put("/emppunishments", data);
};

const deletePunishmentInfo = async (id) => {
  await instance.delete(`/emppunishments/${id}`);
};

export { getPunishmentInfo, editPunishmentInfo, deletePunishmentInfo };
