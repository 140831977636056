import {
  getTalentInfo,
  editTalentInfo,
  deleteTalentInfo,
} from "services/talentInfo";
import { default as employeeTalentInfoActions } from "./actions";

const {
  fetchEmployeeTalentInfoRequest,
  fetchEmployeeTalentInfoSuccess,
  fetchEmployeeTalentInfoError,
  editEmployeeTalentInfoRequest,
  editEmployeeTalentInfoSuccess,
  editEmployeeTalentInfoError,
  deleteEmployeeTalentInfoRequest,
  deleteEmployeeTalentInfoSuccess,
  deleteEmployeeTalentInfoError,
} = employeeTalentInfoActions;

const fetchEmployeeTalentInfo = (data) => {
  return async (dispatch) => {
    dispatch(fetchEmployeeTalentInfoRequest());

    try {
      const employeeTalentInfo = await getTalentInfo(data);
      dispatch(fetchEmployeeTalentInfoSuccess(employeeTalentInfo));
    } catch (err) {
      dispatch(fetchEmployeeTalentInfoError(err.message));
    }
  };
};

const editEmployeeTalentInfo = (data) => {
  return async (dispatch) => {
    dispatch(editEmployeeTalentInfoRequest());

    try {
      await editTalentInfo(data);
      dispatch(editEmployeeTalentInfoSuccess());
    } catch (err) {
      dispatch(editEmployeeTalentInfoError(err.message));
    }
  };
};

const deleteEmployeeTalentInfo = (id) => {
  return async (dispatch) => {
    dispatch(deleteEmployeeTalentInfoRequest());

    try {
      await deleteTalentInfo(id);
      dispatch(deleteEmployeeTalentInfoSuccess());
    } catch (err) {
      dispatch(deleteEmployeeTalentInfoError(err.message));
    }
  };
};

export default {
  fetchEmployeeTalentInfo,
  editEmployeeTalentInfo,
  deleteEmployeeTalentInfo,
};
