import instance from "./axios.base";
import { dateFormat } from "utils/formatter";

const getEmpHolidays = async () => {
  const res = await instance.get("/empholidays");
  res.data.map((row) => {
    row.startDate = dateFormat(row.startDate)
    row.endDate = dateFormat(row.endDate)
  })
  
  return res.data;
};

const createEmpHoliday = async (data) => {
  const res = await instance.post("/empholidays", data);

  return res.data;
};

const updateEmpHoliday = async (data) => {
  const res = await instance.put("/empholidays", data);

  return res.data;
};

const removeEmpHoliday = async (data) => {
  const res = await instance.delete(`/empholidays/${data.id}`);

  return res.data;
};

export { getEmpHolidays, createEmpHoliday, updateEmpHoliday, removeEmpHoliday };