import { default as empRaisesTypes } from "./types";

const {
  ADD_EMP_RAISE_REQUEST,
  ADD_EMP_RAISE_SUCCESS,
  ADD_EMP_RAISE_FAILURE,
  EDIT_EMP_RAISE_REQUEST,
  EDIT_EMP_RAISE_SUCCESS,
  EDIT_EMP_RAISE_FAILURE,
  DELETE_EMP_RAISE_REQUEST,
  DELETE_EMP_RAISE_SUCCESS,
  DELETE_EMP_RAISE_FAILURE,
  FETCH_EMP_RAISES_REQUEST,
  FETCH_EMP_RAISES_SUCCESS,
  FETCH_EMP_RAISES_FAILURE,
  CLEAR_EMP_RAISE,
} = empRaisesTypes;

const addEmpRaiseRequest = () => {
  return {
    type: ADD_EMP_RAISE_REQUEST,
  };
};

const addEmpRaiseSuccess = () => {
  return {
    type: ADD_EMP_RAISE_SUCCESS,
  };
};

const addEmpRaiseError = (error) => {
  return {
    type: ADD_EMP_RAISE_FAILURE,
    payload: error,
  };
};

const editEmpRaiseRequest = () => {
  return {
    type: EDIT_EMP_RAISE_REQUEST,
  };
};

const editEmpRaiseSuccess = () => {
  return {
    type: EDIT_EMP_RAISE_SUCCESS,
  };
};

const editEmpRaiseError = (error) => {
  return {
    type: EDIT_EMP_RAISE_FAILURE,
    payload: error,
  };
};

const deleteEmpRaiseRequest = () => {
  return {
    type: DELETE_EMP_RAISE_REQUEST,
  };
};

const deleteEmpRaiseSuccess = () => {
  return {
    type: DELETE_EMP_RAISE_SUCCESS,
  };
};

const deleteEmpRaiseError = (error) => {
  return {
    type: DELETE_EMP_RAISE_FAILURE,
    payload: error,
  };
};

const fetchEmpRaisesRequest = () => {
  return {
    type: FETCH_EMP_RAISES_REQUEST,
  };
};

const fetchEmpRaisesSuccess = (empRaises) => {
  return {
    type: FETCH_EMP_RAISES_SUCCESS,
    payload: empRaises,
  };
};

const fetchEmpRaisesError = (error) => {
  return {
    type: FETCH_EMP_RAISES_FAILURE,
    payload: error,
  };
};

const clearEmpRaise = () => {
  return {
    type: CLEAR_EMP_RAISE,
  };
};

export default {
  addEmpRaiseRequest,
  addEmpRaiseSuccess,
  addEmpRaiseError,
  editEmpRaiseRequest,
  editEmpRaiseSuccess,
  editEmpRaiseError,
  deleteEmpRaiseRequest,
  deleteEmpRaiseSuccess,
  deleteEmpRaiseError,
  fetchEmpRaisesRequest,
  fetchEmpRaisesSuccess,
  fetchEmpRaisesError,
  clearEmpRaise,
};
