import { default as employeeEmergencyContractInfoTypes } from "./types";

const {
  FETCH_EMPLOYEE_EMERGENCY_CONTRACT_INFO_REQUEST,
  FETCH_EMPLOYEE_EMERGENCY_CONTRACT_INFO_SUCCESS,
  FETCH_EMPLOYEE_EMERGENCY_CONTRACT_INFO_FAILURE,
  EDIT_EMPLOYEE_EMERGENCY_CONTRACT_INFO_REQUEST,
  EDIT_EMPLOYEE_EMERGENCY_CONTRACT_INFO_SUCCESS,
  EDIT_EMPLOYEE_EMERGENCY_CONTRACT_INFO_FAILURE,
  DELETE_EMPLOYEE_EMERGENCY_CONTRACT_INFO_REQUEST,
  DELETE_EMPLOYEE_EMERGENCY_CONTRACT_INFO_SUCCESS,
  DELETE_EMPLOYEE_EMERGENCY_CONTRACT_INFO_FAILURE,
} = employeeEmergencyContractInfoTypes;

const fetchEmployeeEmergencyContractInfoRequest = () => {
  return {
    type: FETCH_EMPLOYEE_EMERGENCY_CONTRACT_INFO_REQUEST,
  };
};

const fetchEmployeeEmergencyContractInfoSuccess = (
  employeeEmergencyContractInfo
) => {
  return {
    type: FETCH_EMPLOYEE_EMERGENCY_CONTRACT_INFO_SUCCESS,
    payload: employeeEmergencyContractInfo,
  };
};

const fetchEmployeeEmergencyContractInfoError = (error) => {
  return {
    type: FETCH_EMPLOYEE_EMERGENCY_CONTRACT_INFO_FAILURE,
    payload: error,
  };
};

const editEmployeeEmergencyContractInfoRequest = () => {
  return {
    type: EDIT_EMPLOYEE_EMERGENCY_CONTRACT_INFO_REQUEST,
  };
};

const editEmployeeEmergencyContractInfoSuccess = () => {
  return {
    type: EDIT_EMPLOYEE_EMERGENCY_CONTRACT_INFO_SUCCESS,
  };
};

const editEmployeeEmergencyContractInfoError = (error) => {
  return {
    type: EDIT_EMPLOYEE_EMERGENCY_CONTRACT_INFO_FAILURE,
    payload: error,
  };
};

const deleteEmployeeEmergencyContractInfoRequest = () => {
  return {
    type: DELETE_EMPLOYEE_EMERGENCY_CONTRACT_INFO_REQUEST,
  };
};

const deleteEmployeeEmergencyContractInfoSuccess = () => {
  return {
    type: DELETE_EMPLOYEE_EMERGENCY_CONTRACT_INFO_SUCCESS,
  };
};

const deleteEmployeeEmergencyContractInfoError = (error) => {
  return {
    type: DELETE_EMPLOYEE_EMERGENCY_CONTRACT_INFO_FAILURE,
    payload: error,
  };
};

export default {
  fetchEmployeeEmergencyContractInfoRequest,
  fetchEmployeeEmergencyContractInfoSuccess,
  fetchEmployeeEmergencyContractInfoError,
  editEmployeeEmergencyContractInfoRequest,
  editEmployeeEmergencyContractInfoSuccess,
  editEmployeeEmergencyContractInfoError,
  deleteEmployeeEmergencyContractInfoRequest,
  deleteEmployeeEmergencyContractInfoSuccess,
  deleteEmployeeEmergencyContractInfoError,
};
