const FETCH_EMPLOYEE_EDUCATION_DEGREES_REQUEST =
  "FETCH_EMPLOYEE_EDUCATION_DEGREES_REQUEST";
const FETCH_EMPLOYEE_EDUCATION_DEGREES_SUCCESS =
  "FETCH_EMPLOYEE_EDUCATION_DEGREES_SUCCESS";
const FETCH_EMPLOYEE_EDUCATION_DEGREES_FAILURE =
  "FETCH_EMPLOYEE_EDUCATION_DEGREES_FAILURE";

export default {
  FETCH_EMPLOYEE_EDUCATION_DEGREES_REQUEST,
  FETCH_EMPLOYEE_EDUCATION_DEGREES_SUCCESS,
  FETCH_EMPLOYEE_EDUCATION_DEGREES_FAILURE,
};
