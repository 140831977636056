const ADD_TRAINING_REQUEST = "ADD_TRAINING_REQUEST";
const ADD_TRAINING_SUCCESS = "ADD_TRAINING_SUCCESS";
const ADD_TRAINING_FAILURE = "ADD_TRAINING_FAILURE";
const FETCH_TRAININGS_REQUEST = "FETCH_TRAININGS_REQUEST";
const FETCH_TRAININGS_SUCCESS = "FETCH_TRAININGS_SUCCESS";
const FETCH_TRAININGS_FAILURE = "FETCH_TRAININGS_FAILURE";

export default {
  ADD_TRAINING_REQUEST,
  ADD_TRAINING_SUCCESS,
  ADD_TRAINING_FAILURE,
  FETCH_TRAININGS_REQUEST,
  FETCH_TRAININGS_SUCCESS,
  FETCH_TRAININGS_FAILURE,
};
