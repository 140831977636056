import instance from "./axios.base";
import { dateFormat } from "utils/formatter";

const getTrainings = async () => {
  const res = await instance.get("/hrtrainings");
  res.data.map((row) => {
    row.startDate = dateFormat(row.startDate);
    row.endDate = dateFormat(row.endDate);
  })
  
  return res.data;
};

const createTraining = async (data) => {
  const res = await instance.post("/hrtrainings", data);

  return res.data;
};

const updateTraining = async (data) => {
  const res = await instance.put("/hrtrainings", data);

  return res.data;
};

const removeTraining = async (data) => {
  const res = await instance.delete(`/hrtrainings/${data.id}`);

  return res.data;
};

const getTrainingEmployees = async (data) => {
  const res = await instance.get(`/trainingemployees/${data}`);

  return res.data;
};

const createTrainingEmployee = async (data) => {
  const res = await instance.post("/trainingemployees", data);

  return res.data;
};

const removeTrainingEmployee = async (data) => {
  const res = await instance.delete(`/trainingemployees/${data}`);

  return res.data;
};



export { getTrainings, createTraining, updateTraining, removeTraining, getTrainingEmployees, createTrainingEmployee, removeTrainingEmployee };