import { default as types } from "./types";

const {
  FETCH_TT11_REPORT_REQUEST,
  FETCH_TT11_REPORT_SUCCESS,
  FETCH_TT11_REPORT_FAILURE,
} = types;

const fetchTT11ReportRequest = () => {
  return {
    type: FETCH_TT11_REPORT_REQUEST,
  };
};

const fetchTT11ReportSuccess = (report) => {
  return {
    type: FETCH_TT11_REPORT_SUCCESS,
    payload: report,
  };
};

const fetchTT11ReportError = (error) => {
  return {
    type: FETCH_TT11_REPORT_FAILURE,
    payload: error,
  };
};

export default {
  fetchTT11ReportRequest,
  fetchTT11ReportSuccess,
  fetchTT11ReportError,
};
