import { default as empholidaysTypes } from "./types";

const {
  ADD_EMPHOLIDAY_REQUEST,
  ADD_EMPHOLIDAY_SUCCESS,
  ADD_EMPHOLIDAY_FAILURE,
  FETCH_EMPHOLIDAYS_REQUEST,
  FETCH_EMPHOLIDAYS_SUCCESS,
  FETCH_EMPHOLIDAYS_FAILURE,
} = empholidaysTypes;

const addEmpHolidayRequest = () => {
  return {
    type: ADD_EMPHOLIDAY_REQUEST,
  };
};

const addEmpHolidaySuccess = () => {
  return {
    type: ADD_EMPHOLIDAY_SUCCESS,
  };
};

const addEmpHolidayError = (error) => {
  return {
    type: ADD_EMPHOLIDAY_FAILURE,
    payload: error,
  };
};

const fetchEmpHolidaysRequest = () => {
  return {
    type: FETCH_EMPHOLIDAYS_REQUEST,
  };
};

const fetchEmpHolidaysSuccess = (empholidays) => {
  return {
    type: FETCH_EMPHOLIDAYS_SUCCESS,
    payload: empholidays,
  };
};

const fetchEmpHolidaysError = (error) => {
  return {
    type: FETCH_EMPHOLIDAYS_FAILURE,
    payload: error,
  };
};

export default {
  addEmpHolidayRequest,
  addEmpHolidaySuccess,
  addEmpHolidayError,
  fetchEmpHolidaysRequest,
  fetchEmpHolidaysSuccess,
  fetchEmpHolidaysError,
};
