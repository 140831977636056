import { default as employeesTypes } from "./types";

const {
  ADD_EMPLOYEE_REQUEST,
  ADD_EMPLOYEE_SUCCESS,
  ADD_EMPLOYEE_FAILURE,
  EDIT_EMPLOYEE_REQUEST,
  EDIT_EMPLOYEE_SUCCESS,
  EDIT_EMPLOYEE_FAILURE,
  FETCH_EMPLOYEES_REQUEST,
  FETCH_EMPLOYEES_SUCCESS,
  FETCH_EMPLOYEES_FAILURE,
  EDIT_EMPLOYEE_EXPERIENCE_REQUEST,
  EDIT_EMPLOYEE_EXPERIENCE_SUCCESS,
  EDIT_EMPLOYEE_EXPERIENCE_FAILURE,
  DELETE_EMPLOYEE_EXPERIENCE_REQUEST,
  DELETE_EMPLOYEE_EXPERIENCE_SUCCESS,
  DELETE_EMPLOYEE_EXPERIENCE_FAILURE,
} = employeesTypes;

const addEmployeeRequest = () => {
  return {
    type: ADD_EMPLOYEE_REQUEST,
  };
};

const addEmployeeSuccess = () => {
  return {
    type: ADD_EMPLOYEE_SUCCESS,
  };
};

const addEmployeeError = (error) => {
  return {
    type: ADD_EMPLOYEE_FAILURE,
    payload: error,
  };
};

const editEmployeeRequest = () => {
  return {
    type: EDIT_EMPLOYEE_REQUEST,
  };
};

const editEmployeeSuccess = () => {
  return {
    type: EDIT_EMPLOYEE_SUCCESS,
  };
};

const editEmployeeError = (error) => {
  return {
    type: EDIT_EMPLOYEE_FAILURE,
    payload: error,
  };
};

const fetchEmployeesRequest = () => {
  return {
    type: FETCH_EMPLOYEES_REQUEST,
  };
};

const fetchEmployeesSuccess = (employees) => {
  return {
    type: FETCH_EMPLOYEES_SUCCESS,
    payload: employees,
  };
};

const fetchEmployeesError = (error) => {
  return {
    type: FETCH_EMPLOYEES_FAILURE,
    payload: error,
  };
};

const editEmployeeExperienceRequest = () => {
  return {
    type: EDIT_EMPLOYEE_EXPERIENCE_REQUEST,
  };
};

const editEmployeeExperienceSuccess = () => {
  return {
    type: EDIT_EMPLOYEE_EXPERIENCE_SUCCESS,
  };
};

const editEmployeeExperienceError = (error) => {
  return {
    type: EDIT_EMPLOYEE_EXPERIENCE_FAILURE,
    payload: error,
  };
};

const deleteEmployeeExperienceRequest = () => {
  return {
    type: DELETE_EMPLOYEE_EXPERIENCE_REQUEST,
  };
};

const deleteEmployeeExperienceSuccess = () => {
  return {
    type: DELETE_EMPLOYEE_EXPERIENCE_SUCCESS,
  };
};

const deleteEmployeeExperienceError = (error) => {
  return {
    type: DELETE_EMPLOYEE_EXPERIENCE_FAILURE,
    payload: error,
  };
};

export default {
  addEmployeeRequest,
  addEmployeeSuccess,
  addEmployeeError,
  editEmployeeRequest,
  editEmployeeSuccess,
  editEmployeeError,
  fetchEmployeesRequest,
  fetchEmployeesSuccess,
  fetchEmployeesError,
  editEmployeeExperienceRequest,
  editEmployeeExperienceSuccess,
  editEmployeeExperienceError,
  deleteEmployeeExperienceRequest,
  deleteEmployeeExperienceSuccess,
  deleteEmployeeExperienceError,
};
