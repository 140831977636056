import { getInsurCompanies } from "services/insurCompany";
import { default as insurCompaniesActions } from "./actions";

const {
  fetchInsurCompaniesRequest,
  fetchInsurCompaniesSuccess,
  fetchInsurCompaniesError,
} = insurCompaniesActions;

const fetchInsurCompanies = () => {
  return async (dispatch) => {
    dispatch(fetchInsurCompaniesRequest());

    try {
      const insurCompanies = await getInsurCompanies();
      dispatch(fetchInsurCompaniesSuccess(insurCompanies));
    } catch (err) {
      dispatch(fetchInsurCompaniesError(err.message));
    }
  };
};

export default { fetchInsurCompanies };
