import { default as employeeEducationInfoTypes } from "./types";

const {
  FETCH_EMPLOYEE_EDUCATION_INFO_REQUEST,
  FETCH_EMPLOYEE_EDUCATION_INFO_SUCCESS,
  FETCH_EMPLOYEE_EDUCATION_INFO_FAILURE,
  EDIT_EMPLOYEE_EDUCATION_INFO_REQUEST,
  EDIT_EMPLOYEE_EDUCATION_INFO_SUCCESS,
  EDIT_EMPLOYEE_EDUCATION_INFO_FAILURE,
  DELETE_EMPLOYEE_EDUCATION_INFO_REQUEST,
  DELETE_EMPLOYEE_EDUCATION_INFO_SUCCESS,
  DELETE_EMPLOYEE_EDUCATION_INFO_FAILURE,
} = employeeEducationInfoTypes;

const fetchEmployeeEducationInfoRequest = () => {
  return {
    type: FETCH_EMPLOYEE_EDUCATION_INFO_REQUEST,
  };
};

const fetchEmployeeEducationInfoSuccess = (employeeEducationInfo) => {
  return {
    type: FETCH_EMPLOYEE_EDUCATION_INFO_SUCCESS,
    payload: employeeEducationInfo,
  };
};

const fetchEmployeeEducationInfoError = (error) => {
  return {
    type: FETCH_EMPLOYEE_EDUCATION_INFO_FAILURE,
    payload: error,
  };
};

const editEmployeeEducationInfoRequest = () => {
  return {
    type: EDIT_EMPLOYEE_EDUCATION_INFO_REQUEST,
  };
};

const editEmployeeEducationInfoSuccess = () => {
  return {
    type: EDIT_EMPLOYEE_EDUCATION_INFO_SUCCESS,
  };
};

const editEmployeeEducationInfoError = (error) => {
  return {
    type: EDIT_EMPLOYEE_EDUCATION_INFO_FAILURE,
    payload: error,
  };
};

const deleteEmployeeEducationInfoRequest = () => {
  return {
    type: DELETE_EMPLOYEE_EDUCATION_INFO_REQUEST,
  };
};

const deleteEmployeeEducationInfoSuccess = () => {
  return {
    type: DELETE_EMPLOYEE_EDUCATION_INFO_SUCCESS,
  };
};

const deleteEmployeeEducationInfoError = (error) => {
  return {
    type: DELETE_EMPLOYEE_EDUCATION_INFO_FAILURE,
    payload: error,
  };
};

export default {
  fetchEmployeeEducationInfoRequest,
  fetchEmployeeEducationInfoSuccess,
  fetchEmployeeEducationInfoError,
  editEmployeeEducationInfoRequest,
  editEmployeeEducationInfoSuccess,
  editEmployeeEducationInfoError,
  deleteEmployeeEducationInfoRequest,
  deleteEmployeeEducationInfoSuccess,
  deleteEmployeeEducationInfoError,
};
