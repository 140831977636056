const ADD_TIMEATTENDANCELOG_REQUEST = "ADD_TIMEATTENDANCELOG_REQUEST";
const ADD_TIMEATTENDANCELOG_SUCCESS = "ADD_TIMEATTENDANCELOG_SUCCESS";
const ADD_TIMEATTENDANCELOG_FAILURE = "ADD_TIMEATTENDANCELOG_FAILURE";
const FETCH_TIMEATTENDANCELOGS_REQUEST = "FETCH_TIMEATTENDANCELOGS_REQUEST";
const FETCH_TIMEATTENDANCELOGS_SUCCESS = "FETCH_TIMEATTENDANCELOGS_SUCCESS";
const FETCH_TIMEATTENDANCELOGS_FAILURE = "FETCH_TIMEATTENDANCELOGS_FAILURE";

export default {
  ADD_TIMEATTENDANCELOG_REQUEST,
  ADD_TIMEATTENDANCELOG_SUCCESS,
  ADD_TIMEATTENDANCELOG_FAILURE,
  FETCH_TIMEATTENDANCELOGS_REQUEST,
  FETCH_TIMEATTENDANCELOGS_SUCCESS,
  FETCH_TIMEATTENDANCELOGS_FAILURE,
};
