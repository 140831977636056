import { default as sectorsTypes } from "./types";

const {
  ADD_SECTOR_REQUEST,
  ADD_SECTOR_SUCCESS,
  ADD_SECTOR_FAILURE,
  FETCH_SECTORS_REQUEST,
  FETCH_SECTORS_SUCCESS,
  FETCH_SECTORS_FAILURE,
} = sectorsTypes;

const addSectorRequest = () => {
  return {
    type: ADD_SECTOR_REQUEST,
  };
};

const addSectorSuccess = (sectors) => {
  return {
    type: ADD_SECTOR_SUCCESS,
  };
};

const addSectorError = (error) => {
  return {
    type: ADD_SECTOR_FAILURE,
    payload: error,
  };
};

const fetchSectorsRequest = () => {
  return {
    type: FETCH_SECTORS_REQUEST,
  };
};

const fetchSectorsSuccess = (sectors) => {
  return {
    type: FETCH_SECTORS_SUCCESS,
    payload: sectors,
  };
};

const fetchSectorsError = (error) => {
  return {
    type: FETCH_SECTORS_FAILURE,
    payload: error,
  };
};

export default {
  addSectorRequest,
  addSectorSuccess,
  addSectorError,
  fetchSectorsRequest,
  fetchSectorsSuccess,
  fetchSectorsError,
};
